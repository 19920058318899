import { ErrorType } from '../../../__generated__/api-types-and-hooks'
import { ApiResponseError } from '../api-response-error'
import { handleFailedMeetingEmailInvitesError } from './failed-meeting-invites'
import { handleValidationError } from 'utils/error-handling/error-handlers/validationErrorHandler'
import { handleLicenseLimitReached } from './license-limit-reached-handler'
import { handleDisconnectedToolsError } from './disconnectedToolErrorHandler'

export const errorHandlers: Record<ErrorType, (error: ApiResponseError) => void> = {
  FailedMeetingEmailInvites: handleFailedMeetingEmailInvitesError,
  ValidationError: handleValidationError,
  LicenseLimitReached: handleLicenseLimitReached,
  DisconnectedToolError: handleDisconnectedToolsError,
  ForceLogoutError: (error) => {
    throw new Error(error.errorType)
  },
}
