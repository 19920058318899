import React from 'react'

function ImageIcon({ className }) {
  return (
    <svg
      className={className}
      width="33"
      height="44"
      viewBox="0 0 33 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.25 11V0H4.125C1.8468 0 0 1.8468 0 4.125V39.875C0 42.1523 1.8468 44 4.125 44H28.875C31.1532 44 33 42.1532 33 39.875V13.75H22.0773C20.4789 13.75 19.25 12.5211 19.25 11ZM8.25 19.25C9.76852 19.25 11 20.4815 11 22C11 23.5185 9.77109 24.75 8.25 24.75C6.72891 24.75 5.5 23.5211 5.5 22C5.5 20.4789 6.73148 19.25 8.25 19.25ZM27.3367 37.7695C27.0961 38.225 26.632 38.5 26.125 38.5H6.875C6.36797 38.5 5.90219 38.2209 5.66242 37.7737C5.42326 37.3268 5.44938 36.7837 5.73073 36.3626L10.3138 29.4876C10.493 29.107 10.9227 28.875 11.4555 28.875C11.9883 28.875 12.3449 29.1048 12.5993 29.4873L13.75 31.2125L17.6473 25.3662C17.9008 24.982 18.3305 24.75 18.7945 24.75C19.2586 24.75 19.684 24.9798 19.9384 25.3623L27.2714 36.3623C27.5516 36.7812 27.5773 37.3227 27.3367 37.7695ZM22 0V11H33L22 0Z"
        fill="black"
      />
    </svg>
  )
}
export default ImageIcon
