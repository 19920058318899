import React from 'react'
import get from 'lodash/get'
import ContentLoader from 'react-content-loader'

import colors from 'colors'

const AssessmentLoader = () => {
  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 350 260"
      backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
      foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
    >
      <rect x="40" y="50" rx="8" ry="8" width="255" height="15" />
      <rect x="40" y="80" rx="8" ry="8" width="255" height="15" />
      <rect x="40" y="110" rx="8" ry="8" width="255" height="15" />

      <rect x="40" y="170" rx="8" ry="8" width="255" height="15" />
      <rect x="40" y="200" rx="8" ry="8" width="255" height="15" />
      <rect x="40" y="230" rx="8" ry="8" width="255" height="15" />
    </ContentLoader>
  )
}

export default AssessmentLoader
