import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const PlaysLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="5" y="24" rx="0" ry="0" width="2" height="86" />
        <rect x="5" y="110" rx="0" ry="0" width="140" height="2" />
        <rect x="5" y="24" rx="0" ry="0" width="140" height="2" />
        <rect x="144" y="24" rx="0" ry="0" width="2" height="86" />

        <circle cx="28" cy="45" r="8" />
        <rect x="46" y="43" rx="0" ry="0" width="60" height="4" />

        <circle cx="28" cy="70" r="8" />
        <rect x="46" y="67" rx="0" ry="0" width="60" height="4" />

        <circle cx="28" cy="95" r="8" />
        <rect x="46" y="93" rx="0" ry="0" width="60" height="4" />

        <rect x="5" y="122" rx="0" ry="0" width="2" height="94" />
        <rect x="5" y="212" rx="0" ry="0" width="140" height="2" />
        <rect x="5" y="120" rx="0" ry="0" width="140" height="2" />
        <rect x="144" y="119" rx="0" ry="0" width="2" height="95" />

        <circle cx="28" cy="150" r="15" />
        <rect x="25" y="170" rx="0" ry="0" width="100" height="15" />

        <rect x="155" y="24" rx="0" ry="0" width="2" height="190" />
        <rect x="155" y="24" rx="0" ry="0" width="290" height="2" />
        <rect x="155" y="212" rx="0" ry="0" width="290" height="2" />
        <rect x="445" y="24" rx="0" ry="0" width="2" height="190" />
      </ContentLoader>
    </>
  )
}

export default PlaysLoader
