import styled from 'styled-components'
import tw from 'twin.macro'

export const AttestationSignatureDetailCardsWrapper = styled.div`
  ${tw`grid grid-rows-2 sm:grid-rows-none sm:grid-cols-2 gap-8 sm:gap-7 smd:gap-8 mt-9`}
`

export const CardContentWrapper = styled.div`
  ${tw`flex flex-col justify-center items-center gap-1.5 pt-4 pb-3`}
`

export const CountWrapper = styled.span`
  ${tw`text-6xl leading-[85px]`}
`
