import React from 'react'

function PlusIcon({ className }) {
  return (
    <svg className={className} viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.26318 2.42656C7.26318 1.88968 6.83042 1.45593 6.29476 1.45593C5.7591 1.45593 5.32633 1.88968 5.32633 2.42656V6.79437H0.968424C0.432765 6.79437 0 7.22812 0 7.765C0 8.30187 0.432765 8.73562 0.968424 8.73562H5.32633V13.1034C5.32633 13.6403 5.7591 14.0741 6.29476 14.0741C6.83042 14.0741 7.26318 13.6403 7.26318 13.1034V8.73562H11.6211C12.1567 8.73562 12.5895 8.30187 12.5895 7.765C12.5895 7.22812 12.1567 6.79437 11.6211 6.79437H7.26318V2.42656Z" />
    </svg>
  )
}

PlusIcon.defaultProps = {
  className: '',
}
export default PlusIcon
