import React from 'react'
import InputTextButton from 'components/Common/InputTextButton'
import RobotErrorIcon from 'components/Common/SvgIcons/RobotErrorIcon'

import { useHistory } from 'react-router-dom'
import { ErrorTextWrapper, ErrorWrapper } from 'components/Error/style'

const Error = () => {
  const history = useHistory()

  return (
    <ErrorWrapper>
      <div className="ErrorBody">
        <div className="ErrorBodyIconWrapper">
          <RobotErrorIcon className={'ErrorBodyIcon'} id="mobile" />
        </div>
        <ErrorTextWrapper>
          <p className="ErrorText font-bold">It’s not you—it’s us!</p>
          <label className="ErrorText font-medium	">
            We can’t find link or web address you just tried, but we’ll fix it... In the meantime,
            please try another web address or go to your Dashboard.
          </label>
          <InputTextButton
            title="Back"
            fontSize="text-lg"
            fontWeight="font-semibold"
            action={history.goBack}
            className="justify-center flex-center"
          />
        </ErrorTextWrapper>
        <div className="xs:hidden sm:block items-center">
          <RobotErrorIcon className={'ErrorBodyIcon'} id="desktop" />
        </div>
      </div>
    </ErrorWrapper>
  )
}

export default Error
