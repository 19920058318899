import React, { useMemo } from 'react'

import { LABEL } from 'config'
import { BusinessProfile } from '__generated__/api-types-and-hooks'
import { calculateYearMonths, formatDateIntoEnglish, getMonthAndYear } from 'utils/helper'
import { AppLabelWrapper } from 'components/AppAccount/AppAccount.style'
import {
  appBusinessStage,
  appWhereDoYouDoBusinessIds,
  registeredBusinessStructureV2,
} from 'components/MonthlyAttestation/utils'
import { AppExpandItemsAccordion } from 'components/Common/AppExpandItemsAccordian/AppExpandItemsAccordion.component'
import { AppPillTextBoxList } from 'components/AppAccount/AppBusinessProfile/AppPillTextBoxList/AppPillTextBoxList.component'
import { AppBusinessProfileField } from 'components/AppAccount/AppBusinessProfile/AppBusinessProfileField/AppBusinessProfileField.component'
import { AppBusinessProfileCheckBoxField } from 'components/AppAccount/AppBusinessProfile/AppBusinessProfileCheckBoxField/AppBusinessProfileCheckBoxField.component'

interface IAppViewBusinessProfile {
  businessProfile: BusinessProfile
}

export const AppViewBusinessProfile: React.FC<IAppViewBusinessProfile> = ({ businessProfile }) => {
  // is there other option in whereDoYouDoBusiness
  const isHaveOtherWhereDoYouDoBusiness = useMemo(() => {
    const whereDoYouDoBusinessOptionIds = businessProfile?.whereDoYouDoBusinessOptionIds || []
    if (whereDoYouDoBusinessOptionIds.some((id) => id === appWhereDoYouDoBusinessIds.other)) {
      return true
    }
    return false
  }, [businessProfile])

  return (
    <>
      <AppBusinessProfileField label={LABEL.BUSINESS_NAME} value={businessProfile?.businessName} />
      <AppBusinessProfileField label={LABEL.DBA} value={businessProfile?.dba} />
      {businessProfile?.businessAddress?.hasBusinessAddress !== false ? (
        <AppBusinessProfileField
          label={LABEL.REGISTER_BUSINESS_ADDRESS}
          value={businessProfile?.businessAddress?.addressLine1}
        />
      ) : (
        <AppBusinessProfileCheckBoxField
          label={LABEL.REGISTER_BUSINESS_ADDRESS}
          checkboxLabel={`I don’t have one`}
          checkboxValue={businessProfile?.businessAddress?.hasBusinessAddress === false}
        />
      )}
      <AppBusinessProfileField
        label={LABEL.BUSINESS_EMAIL}
        value={businessProfile?.businessEmail}
      />
      {businessProfile?.hasWebsite !== false ? (
        <AppBusinessProfileField
          label={LABEL.BUSINESS_WEBSITE}
          value={businessProfile?.domainName}
        />
      ) : (
        <AppBusinessProfileCheckBoxField
          label={LABEL.BUSINESS_WEBSITE}
          checkboxLabel={`I don’t have one`}
          checkboxValue={businessProfile?.hasWebsite === false}
        />
      )}
      <AppExpandItemsAccordion title="Business Social Media" open={false} onOpen={() => {}}>
        {businessProfile?.hasFacebook !== false ? (
          <AppBusinessProfileField label={LABEL.FACEBOOK} value={businessProfile?.facebook} />
        ) : (
          <AppBusinessProfileCheckBoxField
            label={LABEL.FACEBOOK}
            checkboxLabel={`I don’t have one`}
            checkboxValue={businessProfile?.hasFacebook === false}
          />
        )}
        {businessProfile?.hasInstagram !== false ? (
          <AppBusinessProfileField label={LABEL.INSTAGRAM} value={businessProfile?.instagram} />
        ) : (
          <AppBusinessProfileCheckBoxField
            label={LABEL.INSTAGRAM}
            checkboxLabel={`I don’t have one`}
            checkboxValue={businessProfile?.hasInstagram === false}
          />
        )}
        {businessProfile?.hasLinkedin !== false ? (
          <AppBusinessProfileField label={LABEL.LINKEDIN} value={businessProfile?.linkedin} />
        ) : (
          <AppBusinessProfileCheckBoxField
            label={LABEL.LINKEDIN}
            checkboxLabel={`I don’t have one`}
            checkboxValue={businessProfile?.hasLinkedin === false}
          />
        )}
        {businessProfile?.hasTikTok !== false ? (
          <AppBusinessProfileField label={LABEL.TIKTOK} value={businessProfile?.tiktok} />
        ) : (
          <AppBusinessProfileCheckBoxField
            label={LABEL.TIKTOK}
            checkboxLabel={`I don’t have one`}
            checkboxValue={businessProfile?.hasTikTok === false}
          />
        )}
        {businessProfile?.hasTwitter !== false ? (
          <AppBusinessProfileField label={LABEL.TWITTER} value={businessProfile?.twitter} />
        ) : (
          <AppBusinessProfileCheckBoxField
            label={LABEL.TWITTER}
            checkboxLabel={`I don’t have one`}
            checkboxValue={businessProfile?.hasTwitter === false}
          />
        )}
        {businessProfile?.hasYoutube !== false ? (
          <AppBusinessProfileField label={LABEL.YOUTUBE} value={businessProfile?.youtube} />
        ) : (
          <AppBusinessProfileCheckBoxField
            label={LABEL.YOUTUBE}
            checkboxLabel={`I don’t have one`}
            checkboxValue={businessProfile?.hasYoutube === false}
          />
        )}
      </AppExpandItemsAccordion>
      <AppLabelWrapper>
        {businessProfile.businessStageOptionId === appBusinessStage.launched
          ? LABEL.WHERE_DO_YOU_DO_BUSINEESS
          : LABEL.WHERE_DO_YOU_PLAN_TO_DO_BUSINEESS}
      </AppLabelWrapper>
      <div className="flex flex-wrap gap-1 !mt-1">
        <AppPillTextBoxList items={businessProfile?.whereDoYouDoBusiness || []} />
      </div>
      {isHaveOtherWhereDoYouDoBusiness && (
        <AppBusinessProfileField
          label={LABEL.OTHER_WHERE_DO_YOU_DO_BUSINEESS}
          value={businessProfile?.otherWhereDoYouDoBusiness}
        />
      )}
      {businessProfile?.hasRegisterBusinessStructure !== false ? (
        <AppBusinessProfileField
          label={LABEL.REGISTER_BUSINESS_STRUCTURE}
          value={businessProfile?.registerBusinessStructure}
        />
      ) : (
        <AppBusinessProfileCheckBoxField
          label={LABEL.REGISTER_BUSINESS_STRUCTURE}
          checkboxLabel={`I don’t have one`}
          checkboxValue={businessProfile?.hasRegisterBusinessStructure === false}
        />
      )}
      {businessProfile?.hasEIN !== false ? (
        <AppBusinessProfileField label={LABEL.EIN_NUMBER} value={businessProfile?.EIN} />
      ) : (
        <AppBusinessProfileCheckBoxField
          label={LABEL.EIN_NUMBER}
          checkboxLabel={`I don’t have one`}
          checkboxValue={businessProfile?.hasEIN === false}
        />
      )}
      <AppBusinessProfileField
        label={LABEL.DATE_ESTABLISHED}
        value={
          businessProfile?.registerBusinessStructureOptionId ===
          registeredBusinessStructureV2.noBusinessOptionId
            ? 'Not yet'
            : businessProfile?.yearEstablished
        }
      />
      <AppBusinessProfileField
        label={LABEL.YEARS_IN_BUSINESS}
        value={
          businessProfile?.registerBusinessStructureOptionId ===
          registeredBusinessStructureV2.noBusinessOptionId
            ? '0'
            : calculateYearMonths(
                getMonthAndYear(businessProfile?.yearEstablished).month,
                getMonthAndYear(businessProfile?.yearEstablished).year
              )
        }
      />
      <AppBusinessProfileField
        label={LABEL.BUSINESS_STAGE}
        value={businessProfile?.businessStage}
      />
      <AppBusinessProfileField
        label={LABEL.REVENUE_STATUS}
        value={businessProfile?.revenueStatus}
      />
      <AppBusinessProfileField
        label={LABEL.FULL_TIME_EMPLOYEES}
        value={businessProfile?.fullTimeEmployees}
      />
      <AppBusinessProfileField
        label={LABEL.PART_TIME_EMPLOYEES}
        value={businessProfile?.partTimeEmployees}
      />
      <AppBusinessProfileField
        label={LABEL.CONTRACT_EMPLOYESS}
        value={businessProfile?.contractors}
      />
      <AppBusinessProfileField label={LABEL.VOLUNTEERS} value={businessProfile?.volunteers} />
      <AppBusinessProfileField
        label={LABEL.MINORITY_OWNED}
        value={businessProfile?.minorityOwned}
      />
      <AppBusinessProfileField label={LABEL.SBA_CERTIFIED} value={businessProfile?.sbaCertified} />
      <AppBusinessProfileField label={LABEL.VETERA_OWNED} value={businessProfile?.veteranOwned} />
      <AppBusinessProfileField label={LABEL.HOURS_SPENT} value={businessProfile?.hoursSpent} />
      <AppBusinessProfileField
        label={LABEL.HOURS_SPENT_PER_WEEK}
        value={businessProfile?.hoursSpentPerWeek}
      />
      <AppLabelWrapper>
        {businessProfile.businessStageOptionId === appBusinessStage.launched
          ? LABEL.PRODUCT_AND_SERVICES_LAUNCH
          : LABEL.PRODUCT_AND_SERVICES_PRE_LAUNCH}
      </AppLabelWrapper>
      <div className="flex flex-wrap gap-1 !mt-1">
        <AppPillTextBoxList items={businessProfile?.productsAndServices ?? []} />
      </div>
      <AppBusinessProfileField label={LABEL.EXIT_PLAN} value={businessProfile?.exitPlan} />
      <AppBusinessProfileField
        label={LABEL.OTHER_EXIT_PLAN}
        value={businessProfile?.otherExitPlan}
      />
      <AppBusinessProfileField label={LABEL.MOTIVATION} value={businessProfile?.motivation} />
      <AppBusinessProfileField
        label={LABEL.BUSINESS_VISION}
        value={businessProfile?.businessVision}
      />
      <AppBusinessProfileField
        label={LABEL.PERSONAL_GOALS}
        value={businessProfile?.personalGoals}
      />
      <AppBusinessProfileField
        label={LABEL.LAUNCH_DATE}
        value={formatDateIntoEnglish(businessProfile?.launchDate ?? '')}
      />
      <AppBusinessProfileField
        label={LABEL.BUSINESS_DESCRIPTION}
        value={businessProfile?.businessDescription}
      />
      <AppBusinessProfileField
        label={LABEL.LAST_YEAR_REVENUE}
        value={businessProfile?.lastYearRevenue}
      />
      <AppBusinessProfileField
        label={LABEL.THIS_YEAR_REVENUE}
        value={businessProfile?.thisYearRevenue}
      />
      <AppBusinessProfileField
        label={LABEL.GROSS_REVENUE_GOAL_FOR_THIS_YEAR}
        value={businessProfile?.grossRevenueGoalForThisYear}
      />
      <AppBusinessProfileField
        label={LABEL.HAVE_YOU_START_GENERATING_SALES}
        value={
          businessProfile?.haveStartedGeneratingSales === true
            ? 'Yes'
            : businessProfile?.haveStartedGeneratingSales === false
            ? 'No'
            : ''
        }
      />
      <AppBusinessProfileField
        label={LABEL.LAST_UPDATED_BUSINESS_PLAN}
        value={formatDateIntoEnglish(businessProfile?.lastUpdatedBusinessPlan ?? '')}
      />

      <div className="border-primary-outline border-t-[1px] mt-4" />
      <h1 className="text-xl font-bold my-7">Assessment Info</h1>
      <AppBusinessProfileField label={LABEL.NAICS_CODE} value={businessProfile?.naicsCode} />
    </>
  )
}
