import React from 'react'
import get from 'lodash/get'

import { ROLES } from 'config'
import { clientTabs } from 'config/data'
import { getRoleFromPath, navigateTo } from 'utils/helper'
import TackleAvatarLogo from 'components/Common/TackleAvatarLogo'

import {
  LogoWrapper,
  ClientWrapper,
  TopGoalWrapper,
  GoalTextWrapper,
  GoalInfoWrapper,
  BusinessNameWrapper,
  BusinessTextWrapper,
  BusinessInfoWrapper,
  MobileClientWrapper,
  ActiveClientWrapper,
  CommunicationWrapper,
  HorizontalBreakWrapper,
  MobileClientInfoWrapper,
  MobileClientIconWrapper,
  TackleAvatarLogoWrapper,
  CommunicationIconWrapper,
  MobileBusinessNameWrapper,
  MobileActiveClientWrapper,
  MobileActiveClientCardWrapper,
  NavigateToClientDetailWrapper,
  StyledCommunicationIconWrapper,
  MobileCommunicationIconWrapper,
  MobileNavigateToClientDetailWrapper,
  MobileStyledCommunicationIconWrapper,
} from 'components/Clients/ActiveClientCard/style'

const ActiveClientCard = ({ isMobile = false, client, index }) => {
  const userRole = getRoleFromPath()

  const naviagateToClientDetailPageHandler = (client) => {
    if (window.location?.href?.includes('advisor')) {
      navigateTo(ROLES.BSO_ADVISOR, `clients/${client?.id}/${clientTabs[0].name}`)
    } else {
      navigateTo(ROLES.BSO_ADMIN, `clients/${client?.id}/${clientTabs[0].name}`)
    }
  }

  return (
    <>
      {!isMobile ? (
        <ActiveClientWrapper>
          <CommunicationIconWrapper onClick={() => navigateTo(userRole, `chat/${client?.id}`)}>
            <StyledCommunicationIconWrapper />
          </CommunicationIconWrapper>

          <ClientWrapper>
            <LogoWrapper onClick={() => naviagateToClientDetailPageHandler(client)}>
              <TackleAvatarLogoWrapper
                logo={client?.logo?.url}
                firstName={client.firstName}
                lastName={client.lastName}
              />
            </LogoWrapper>

            <NavigateToClientDetailWrapper
              onClick={() => naviagateToClientDetailPageHandler(client)}
            >
              {client.firstName && client.lastName ? (
                `${client.firstName} ${client.lastName}`
              ) : (
                <span>&nbsp;&nbsp;</span>
              )}
            </NavigateToClientDetailWrapper>
          </ClientWrapper>

          <BusinessInfoWrapper>
            <BusinessTextWrapper>Business</BusinessTextWrapper>
            <BusinessNameWrapper> {get(client, 'businessName', '')} </BusinessNameWrapper>
          </BusinessInfoWrapper>
          <HorizontalBreakWrapper />
          <GoalInfoWrapper>
            <GoalTextWrapper>Top Goal</GoalTextWrapper>
            <TopGoalWrapper>{get(client, 'topGoal', '')}</TopGoalWrapper>
          </GoalInfoWrapper>
        </ActiveClientWrapper>
      ) : (
        <MobileActiveClientWrapper key={index}>
          <MobileClientWrapper>
            <MobileClientInfoWrapper>
              <MobileClientIconWrapper>
                <span onClick={() => naviagateToClientDetailPageHandler(client)}>
                  <TackleAvatarLogo firstName={client.firstName} lastName={client.lastName} />
                </span>
              </MobileClientIconWrapper>

              <MobileActiveClientCardWrapper>
                <div>
                  <MobileNavigateToClientDetailWrapper
                    onClick={() => naviagateToClientDetailPageHandler(client)}
                  >
                    {client.firstName && client.lastName ? (
                      `${client.firstName} ${client.lastName}`
                    ) : (
                      <span>&nbsp;&nbsp;</span>
                    )}
                  </MobileNavigateToClientDetailWrapper>
                </div>
                <div>
                  <MobileBusinessNameWrapper>
                    {' '}
                    {get(client, 'businessName', '')}
                  </MobileBusinessNameWrapper>
                </div>
              </MobileActiveClientCardWrapper>
            </MobileClientInfoWrapper>

            <CommunicationWrapper>
              <MobileCommunicationIconWrapper
                onClick={() => navigateTo(userRole, `chat/${client?.id}`)}
              >
                <MobileStyledCommunicationIconWrapper />
              </MobileCommunicationIconWrapper>
              <GoalInfoWrapper>
                <TopGoalWrapper>{get(client, 'topGoal', '')}</TopGoalWrapper>
              </GoalInfoWrapper>
            </CommunicationWrapper>
          </MobileClientWrapper>
        </MobileActiveClientWrapper>
      )}
    </>
  )
}

export default ActiveClientCard
