import React from 'react'

export default function CheckIcon({ className = '' }) {
  return (
    <svg
      className={className}
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.9709 2.34746L4.4709 6.84746C4.14043 7.17793 3.60605 7.17793 3.2791 6.84746L1.0291 4.59746C0.698633 4.26699 0.698633 3.73262 1.0291 3.40566C1.35957 3.07871 1.89395 3.0752 2.2209 3.40566L3.87324 5.05801L7.77559 1.15215C8.10605 0.82168 8.64043 0.82168 8.96738 1.15215C9.29434 1.48262 9.29785 2.01699 8.96738 2.34395L8.9709 2.34746Z"
        fill="white"
      />
    </svg>
  )
}
