import React from 'react'
import { ArrowRightIcon } from 'components/Common/SvgIcons'
import ArrowLeftIcon from 'components/Common/SvgIcons/ArrowLeftIcon'
import CustomSelect from 'components/Common/CustomSelectBox'

interface ITablePaginationV2Props {
  options: { value: string; label: string }[]
  currentPage: number
  noOfItemsPerPage: number
  totalPages: number
  moveNextHandler: (pageNumber: number) => void
  movePreviousHandler: (pageNumber: number) => void
  updateNoOfItemPerPageHandler: (itemsPerPage: string) => void
}

const TablePaginationV2: React.FC<ITablePaginationV2Props> = ({
  options,
  noOfItemsPerPage,
  currentPage,
  totalPages,
  moveNextHandler,
  movePreviousHandler,
  updateNoOfItemPerPageHandler,
}) => {
  function isPrevDisabled() {
    return currentPage === 1
  }

  function isNextDisabled() {
    return currentPage * noOfItemsPerPage >= totalPages
  }
  return (
    <div className="space-y-10 md:space-y-0 flex items-center justify-center flex-col md:flex-row md:justify-between w-full xs:mt-5 sm:mt-12 p-4 border-t border-t-[#D4D4D8] border-b border-b-[#D4D4D8]">
      <div className="flex items-center justify-between space-x-4 w-[80%] md:w-auto">
        <span className="font-medium text-[16px] text-[#3F3F46]">Show rows per page:</span>
        <CustomSelect
          options={options}
          defaultOption={String(noOfItemsPerPage)}
          onChange={updateNoOfItemPerPageHandler}
        />
      </div>
      <div className="flex items-center justify-between space-x-6 w-[80%] md:w-auto">
        <span className="font-medium text-[16px] text-[#3F3F46]">
          {`${(currentPage - 1) * noOfItemsPerPage + 1} - ${Math.min(
            currentPage * noOfItemsPerPage,
            totalPages
          )} of ${totalPages}`}
        </span>
        <div className="space-x-14">
          <button
            className="self-center"
            disabled={isPrevDisabled()}
            onClick={() => movePreviousHandler(currentPage - 1)}
          >
            <ArrowLeftIcon
              className={`${!isPrevDisabled() ? 'fill-primary-brand' : 'fill-primary-outline'}`}
            />
          </button>
          <button
            className="self-center"
            disabled={isNextDisabled()}
            onClick={() => moveNextHandler(currentPage + 1)}
          >
            <ArrowRightIcon
              className={`${!isNextDisabled() ? 'fill-primary-brand' : 'fill-primary-outline'}`}
            />
          </button>
        </div>
      </div>
    </div>
  )
}
export default TablePaginationV2
