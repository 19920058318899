import React from 'react'

function EllipseIcon({ className }) {
  return (
    <svg className={className} viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="1" cx="14" cy="14" r="14" />
    </svg>
  )
}

EllipseIcon.defaultProps = {
  className: '',
}
export default EllipseIcon
