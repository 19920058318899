import { FORM, formKeys } from 'config'
import { pickBy } from 'lodash'
import { put, takeLatest } from 'redux-saga/effects'
import { setFormData } from 'store/actions/form'
import { SET_PROFILE_FORM } from 'store/types'

function* setProfileForms(action) {
  const { forms, profile } = action.payload
  const assessmentResponse = {}
  if (profile?.assessmentResponse) {
    profile.assessmentResponse = JSON.parse(profile.assessmentResponse)
    profile.assessmentResponse.forEach((answer) => {
      assessmentResponse[answer.id] = answer.value
    })
  }
  for (let i = 0; i < forms.length; i++) {
    if (forms[i] === FORM.PROFILE_FORM) {
      const profileFormData = pickBy(profile, (value, key) =>
        formKeys[FORM.PROFILE_FORM].includes(key)
      )
      yield put(setFormData({ form: [FORM.PROFILE_FORM], data: profileFormData }))
    }
    if (forms[i] === FORM.INITIAL_ASSESSMENT) {
      yield put(setFormData({ form: [FORM.INITIAL_ASSESSMENT], data: assessmentResponse }))
    }
    if (forms[i] === FORM.USER_PROFILE) {
      const userProfileData = pickBy(profile, (value, key) =>
        formKeys[FORM.USER_PROFILE].includes(key)
      )
      yield put(setFormData({ form: [FORM.USER_PROFILE], data: userProfileData }))
    }
    if (forms[i] === FORM.USER_PROFILE_FORM) {
      yield put(
        setFormData({
          form: [FORM.USER_PROFILE_FORM],
          data: pickBy(profile, (value, key) => formKeys[FORM.USER_PROFILE_FORM].includes(key)),
        })
      )
    }
  }
}

/// /////////// Watchers ///////////////////////
export function* watcherForm() {
  yield takeLatest(SET_PROFILE_FORM, setProfileForms)
}
