import React from 'react'

import {
  AppBadgeWrapper,
  AppBadgeLabel,
  AppDot,
  colorMap,
} from 'components/Common/AppBadge/AppBadge.style'

export type BadgeColor = 'Grey' | 'Primary' | 'Red' | 'Green' | 'Orange' | 'White' | 'Disabled'
export type BadgeType = 'Outline' | 'Filled' | 'Clear'
export type BadgeSize = 'xs' | 'sm' | 'md'

interface IIconProps {
  color?: string
}

interface IAppBadgeProps {
  label: string
  size: BadgeSize
  color: BadgeColor
  type: BadgeType
  dot?: boolean
  Icon?: React.ComponentType<IIconProps>
  onClick?: (e) => void
}

export const AppBadge: React.FC<IAppBadgeProps> = ({
  size,
  color,
  type,
  dot,
  Icon,
  label,
  onClick,
}) => (
  <AppBadgeWrapper size={size} color={color} type={type} onClick={onClick}>
    {dot && <AppDot color={color} size={size} />}
    {Icon && <Icon color={colorMap[color]} />}
    <AppBadgeLabel color={color}>{label}</AppBadgeLabel>
  </AppBadgeWrapper>
)
