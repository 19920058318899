import map from 'lodash/map'
import get from 'lodash/get'
import each from 'lodash/each'
import toLower from 'lodash/toLower'
import sortBy from 'lodash/sortBy'
import moment from 'moment'

export const intervalTypes = {
  year: 'YoY',
  month: 'MoM',
  week: 'WoW',
  date: 'date',
}

export enum IChartStatus {
  PROCESSING = 'processing',
  FEED_COMPLETE = 'feed complete',
}

export type CommonSalesData = {
  business_id: string
  reference_date: {
    value: string
  }
  total_sales_as_of_date: number
  total_sales_as_of_week: number
  total_sales_as_of_month: number
  total_sales_as_of_quarter: number
  total_sales_as_of_year: number
  dod_change_pct?: number
  wow_change_pct?: number
  qoq_change_pct?: number
  yoy_change_pct?: number
}

export interface SalesSummary extends CommonSalesData {
  previous_sales_as_of_date_total: number
  dod_change_pct: number
  previous_sales_as_of_week_total: number
  wow_change_pct: number
  previous_sales_as_of_month_total: number
  mom_change_pct: number
  previous_sales_as_of_quarter_total: number
  qoq_change_pct: number
  previous_sales_as_of_year_total: number
  yoy_change_pct: number
  system_source: string
}

export const getYearlyChartData = (filterYear: string, data: SalesSummary[] | undefined) => {
  const monthDetails = {}
  const chartData = sortBy(data || [], (row) => row.reference_date.value)
  each(chartData, (row) => {
    const year = moment(row.reference_date.value).format('YYYY')
    const key = moment(row.reference_date.value).format('MMM')
    if (year === filterYear)
      monthDetails[key] = {
        value: row.total_sales_as_of_month,
        aggValue: row.total_sales_as_of_year,
        percentageVal: row.yoy_change_pct,
      }
  })
  return map(moment.monthsShort(), (month) => ({
    name: month,
    value: get(monthDetails, `${month}.value`, ''),
    aggValue: get(monthDetails, `${month}.aggValue`, ''),
    percentageVal: get(monthDetails, `${month}.percentageVal`, ''),
  }))
}

export const getDaysBetweenDates = (startDate, endDate) => {
  const now = moment(startDate).clone()
  const dates: any[] = []

  while (now.isSameOrBefore(moment(endDate))) {
    dates.push(now.format('YYYY-MM-DD'))
    now.add(1, 'days')
  }
  return dates
}

export const getIntervalChartData = <T extends CommonSalesData>(
  data: T[] | undefined,
  startDate,
  endDate,
  intervalKey = 'month'
) => {
  const intervalDetails = {}
  const chartdata = sortBy(data || [], (row) => row?.reference_date?.value)
  chartdata.forEach((row) => {
    const key = row?.reference_date?.value
    if (moment(key).isBetween(startDate, endDate, undefined, '[]')) {
      intervalDetails[key] = {
        value: row.total_sales_as_of_date,
        aggValue: row[`total_sales_as_of_${intervalKey}`],
        percentageVal: get(row, `${toLower(intervalTypes[intervalKey])}_change_pct`, ''),
      }
    }
  })

  return map(getDaysBetweenDates(startDate, endDate), (date) => ({
    name: moment(date).format('MMM DD'),
    value: get(intervalDetails, `${date}.value`, ''),
    aggValue: get(intervalDetails, `${date}.aggValue`, ''),
    percentageVal: get(intervalDetails, `${date}.percentageVal`, ''),
  }))
}

export const getLastUpdatedData = (data) => {
  const updatedDates = map(data, (row) => {
    return moment(row?.last_updated_at?.value || new Date())
  })
  return moment.max(updatedDates).format('YYYY-MM-DD')
}
