import { useEffect } from 'react'

const useToggleButton = (
  enabled,
  isToggled,
  setEnabled,
  setIsToggled,
  isUserAddedToGroup,
  resetIsUserAddedAction
) => {
  useEffect(() => {
    if (isToggled && isUserAddedToGroup) {
      setEnabled(!enabled)
      resetIsUserAddedAction()
      setIsToggled(false)
    }
    // eslint-disable-next-line
  }, [isUserAddedToGroup])
}

export default useToggleButton
