import React from 'react'
import { LABEL } from 'config'

import Loader from 'components/Loaders'
import Field from 'components/Clients/Client/Details/Field'

const OtherDetails = ({ businessProfile, clientDetailsLoading, clientBusinessProfileLoading }) => {
  return (
    <div className="xs:col-span-12 xs:p-4 sm:p-0 col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-3 2xl:col-span-3 sm:border-r sm:border-grey-darker md:border-r md:border-grey-darker lg:border-0  xl:border-r xl:border-grey-darker">
      {clientDetailsLoading && clientBusinessProfileLoading ? (
        <Loader loader="AdvisorOwnerProfile" />
      ) : (
        <>
          <div className="font-primary text-2xl">Other Details </div>
          <div className="space-y-9 mt-[31px] pr-4">
            <Field label={LABEL.BUSINESS_VISION} value={businessProfile?.businessVision}></Field>
            <Field label={LABEL.CHALLENGES} value={businessProfile?.challenges}></Field>
            <Field label={LABEL.TOP_GOAL} value={businessProfile?.topGoal}></Field>
            <Field
              label={LABEL.OTHER_GOALS}
              value={businessProfile?.otherGoals?.map((goal) => (
                <p className="w-full">{goal}</p>
              ))}
            ></Field>

            <Field
              label={LABEL.BUSINESS_AREA}
              value={businessProfile?.strongBusinessAreas?.join(', ')}
            ></Field>

            <Field
              label={LABEL.SUPPORT_HELP}
              value={businessProfile?.supportAndHelp?.join(', ')}
            ></Field>
          </div>
        </>
      )}
    </div>
  )
}

export default OtherDetails
