// Read in AWS configuration from environment variables
const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_WEB_COGNITO_USER_POOL_CLIENT_ID,
  },
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_API_URL,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
}

export default awsConfig
