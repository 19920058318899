import { DateRanges } from '../../../__generated__/api-types-and-hooks'
import colors from '../../../colors'
import { DateRangesGrowthLabellings } from '../../../config'
import { formatNumber } from '../../../utils/helper'
import ArrowHead from '../../Common/SvgIcons/ArrowHead'
import Loader from '../../Loaders'
import { StatValue } from './styles'
import React from 'react'

interface IAccountingStatCardProps {
  chartValue: number
  chartGrowthValue: number
  isLoading: boolean
  dateRange: DateRanges
}

const AccountingStatValue = ({
  isLoading,
  chartValue,
  chartGrowthValue,
  dateRange,
}: IAccountingStatCardProps) => {
  return (
    <>
      {isLoading && <Loader loader="TableLoader" />}
      {chartValue !== null && chartValue !== undefined && (
        <div className={`flex gap-2 flex-col ${isLoading && 'hidden'} `}>
          <StatValue>${formatNumber(Number(chartValue || 0))}</StatValue>
          <div className="flex gap-3 text-2xl font-primary leading-7">
            {chartGrowthValue !== 0 && (
              <ArrowHead
                color={chartGrowthValue > 0 ? colors.green.DEFAULT : colors.primary.error}
                className={`cursor-pointer`}
                down={chartGrowthValue < 0}
              />
            )}
            {chartGrowthValue !== 0 && (
              <span className={chartGrowthValue > 0 ? 'text-green' : 'text-primary-error'}>
                {chartGrowthValue || 0}%
              </span>
            )}
            {chartGrowthValue === 0 && <span>{0}%</span>}
            {DateRangesGrowthLabellings[dateRange ?? DateRanges.ThisYearToDate]}
          </div>
        </div>
      )}
    </>
  )
}

export default AccountingStatValue
