import { setFlashUrlParams } from '../../flashParameters'
import { ApiResponseError } from '../api-response-error'

export const handleFailedMeetingEmailInvitesError = (failedInvitesError: ApiResponseError) => {
  const flashParams = {
    meetingId: failedInvitesError.errorInfo?.meetingId,
    failedEmails: failedInvitesError.errorInfo?.failedInvitesEmails.join(','),
    meetingCreationStatus: 'failed',
    toastVisibilityTimeout: '5000',
  }
  setFlashUrlParams(flashParams)
}
