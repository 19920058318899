import ChevronGreenIcon from 'components/Common/SvgIcons/ChevronGreenIcon'
import moment from 'moment'
import React from 'react'

const DateTime = ({ data, showCommentsAction, ownerIds }) => {
  return (
    <td className={`pt-[15px] pb-3 px-3 flex col-span-4 items-center`}>
      <div className="md:pl-[10px] lg:pl-[14px] md:pr-[24px] lg:pr-[66px]">
        <span className="cursor-pointer arrowIcon" onClick={() => showCommentsAction(data.id)}>
          <ChevronGreenIcon ownerIds={ownerIds} id={data.id} />
        </span>
      </div>
      <div className="text-lg font-primary">
        <div className="font-bold text-black-light">{data?.eventDate}</div>
        <div className="mt-[6px] text-primary-text">
          {' '}
          {moment(data?.start).format('hh:mm A')} &ndash; {moment(data?.end).format('hh:mm A')}
        </div>
      </div>
    </td>
  )
}

export default DateTime
