import React from 'react'
import ComingSoon from 'assets/images/coming-soon.png'

export const ComingSoonPlaceholder = ({ className = '', title, description = '' }) => {
  return (
    <div className={className}>
      <h1 className="text-2xl text-primary-text font-black leading-[26.4px] font-secondary !text-left w-full">
        {title}
      </h1>
      <p className="text-[16px] leading-6 font-medium">{description}</p>
      <img src={ComingSoon} alt="Coming Soon!" width="400px" />
    </div>
  )
}

export default ComingSoonPlaceholder
