import React, { FC, useMemo } from 'react'

import PlaybooksStarBlueIcon from 'components/Common/SvgIcons/PlaybooksStarBlueIcon'
import PlaybooksStarAquamarineIcon from 'components/Common/SvgIcons/PlaybooksStarAquamarineIcon'
import PlaybooksStarOrangeIcon from 'components/Common/SvgIcons/PlaybooksStarOrangeIcon'
import PlaybooksStarPinkIcon from 'components/Common/SvgIcons/PlaybooksStarPinkIcon'

export type PlaybookIconColor = 'orange' | 'pink' | 'blue' | 'aquamarine'

interface IPlaybookCategoryIconProps {
  color: PlaybookIconColor
  className?: string
}

const PlayBookCategoryIcon: FC<IPlaybookCategoryIconProps> = ({ color, className = '' }) => {
  const CategoryIcon = useMemo(() => {
    return {
      aquamarine: () => <PlaybooksStarAquamarineIcon className={className} />,
      pink: () => <PlaybooksStarPinkIcon className={className} />,
      blue: () => <PlaybooksStarBlueIcon className={className} />,
      orange: () => <PlaybooksStarOrangeIcon className={className} />,
    }[color]
  }, [className, color])
  return <CategoryIcon />
}

export default PlayBookCategoryIcon
