import React from 'react'

function PencilIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path d="M18.9842 6.45172C19.3417 6.09422 19.3417 5.49839 18.9842 5.15922L16.8392 3.01422C16.5 2.65672 15.9042 2.65672 15.5467 3.01422L13.86 4.69172L17.2975 8.12922M2.75 15.8109V19.2484H6.1875L16.3258 9.10089L12.8883 5.66339L2.75 15.8109Z" />
    </svg>
  )
}
export default PencilIcon
