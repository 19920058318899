import React from 'react'

function DataAndReportsIcon({ className = '' }) {
  return (
    <svg
      className={className}
      width={'46.43px'}
      height={'39.9px'}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'middle', overflow: 'hidden' }}
    >
      <path d="M32 400C32 426.5 53.49 448 80 448H496C504.8 448 512 455.2 512 464C512 472.8 504.8 480 496 480H80C35.82 480 0 444.2 0 400V48C0 39.16 7.164 32 16 32C24.84 32 32 39.16 32 48V400zM336 128H464C472.8 128 480 135.2 480 144V272C480 280.8 472.8 288 464 288C455.2 288 448 280.8 448 272V182.6L331.3 299.3C325.1 305.6 314.9 305.6 308.7 299.3L223.1 214.6L123.3 315.3C117.1 321.6 106.9 321.6 100.7 315.3C94.44 309.1 94.44 298.9 100.7 292.7L212.7 180.7C218.9 174.4 229.1 174.4 235.3 180.7L320 265.4L425.4 160H336C327.2 160 320 152.8 320 144C320 135.2 327.2 128 336 128L336 128z" />{' '}
    </svg>
  )
}
export default DataAndReportsIcon
