import { useState, useMemo } from 'react'
import moment from 'moment'

export interface ISelectedDate {
  startDate: string
  endDate: string
  type: 'year' | 'month' | 'week' | 'day'
}

export const useDatePickerHandler = () => {
  const [selectedDate, setSelectedDate] = useState<ISelectedDate>({
    startDate: moment().startOf('year').format('YYYY-MM-DD'),
    endDate: moment().endOf('year').format('YYYY-MM-DD'),
    type: 'year',
  })

  const formattedDate = useMemo(() => {
    if (selectedDate.type === 'year') return moment(selectedDate.startDate).format('YYYY')
    if (selectedDate.type === 'month') return moment(selectedDate.startDate).format('MMM YYYY')
    if (selectedDate.type === 'week')
      return `${moment(selectedDate.startDate).format('MMM DD,  YYYY')} - ${moment(
        selectedDate.endDate
      ).format('MMM DD,  YYYY')}`
    return ''
  }, [selectedDate])

  const handleClick = (startDate, endDate, type) => {
    setSelectedDate({ startDate, endDate, type })
  }

  const handleNextDate = () => {
    const startDate = moment(selectedDate.startDate).add(1, selectedDate.type).format('YYYY-MM-DD')
    const endDate = moment(selectedDate.endDate).add(1, selectedDate.type).format('YYYY-MM-DD')

    setSelectedDate({
      ...selectedDate,
      startDate,
      endDate,
    })
    return { startDate, endDate }
  }

  const handlePreviousDate = () => {
    const startDate = moment(selectedDate.startDate)
      .subtract(1, selectedDate.type)
      .format('YYYY-MM-DD')
    const endDate = moment(selectedDate.endDate).subtract(1, selectedDate.type).format('YYYY-MM-DD')

    setSelectedDate({
      ...selectedDate,
      startDate,
      endDate,
    })
    return { startDate, endDate }
  }
  return {
    selectedDate,
    handleClick,
    formattedDate,
    handleNextDate,
    handlePreviousDate,
  }
}
