import React from 'react'

function GradientCircleNotchIcon({ width = '21', height = '22' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="-10 -10 220 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="spinner-secondHalf">
          <stop offset="0" stopOpacity="0" stopColor="#BB80F6" />
          <stop offset="1" stopOpacity="0.5" stopColor="#D9D9D9" />
        </linearGradient>
        <linearGradient id="spinner-firstHalf">
          <stop offset="0" stopOpacity="1" stopColor="#BB80F6" />
          <stop offset="1" stopOpacity="0.5" stopColor="#D9D9D9" />
        </linearGradient>
      </defs>

      <g strokeWidth="20">
        <path stroke="url(#spinner-secondHalf)" d="M 4 100 A 96 96 0 0 1 196 100" />
        <path stroke="url(#spinner-firstHalf)" d="M 196 100 A 96 96 0 0 1 4 100" />

        <path stroke="#BB80F6" strokeLinecap="round" d="M 4 100 A 96 96 0 0 1 4 98" />
      </g>

      <animateTransform
        from="0 0 0"
        to="360 0 0"
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1300ms"
      />
    </svg>
  )
}
export default GradientCircleNotchIcon
