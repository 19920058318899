import React from 'react'

const CheckBoxField = ({ id, value, label, className, onChange, bgColor }) => {
  return (
    <div className={`${className} flex gap-4 mr-3`}>
      <input
        onChange={onChange}
        id={id}
        type="checkbox"
        checked={value}
        className={`appearance-none cursor-pointer w-7 h-7 !rounded-lg border border-primary ${
          bgColor ? bgColor : ''
        }
        ${
          value
            ? " !bg-primary !bg-[url('assets/svgs/WhiteTick.svg')] !bg-no-repeat !bg-center"
            : ''
        }
        `}
      />
      <div>
        <h2 className="font-semibold">{label}</h2>
      </div>
    </div>
  )
}

CheckBoxField.defaultProps = {
  className: '',
  onChange: () => {},
  label: '',
  bgColor: '',
}

export default CheckBoxField
