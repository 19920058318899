import { useEffect, useMemo } from 'react'
import { INDUSTRY_TYPE } from 'config'
import { isEmpty } from 'lodash'

export default function useSubIndustryContainer({
  assessment,
  profileForm,
  reset,
  isIndustryChanged,
  setIsSubIndustrySelected,
  setAssessmentList,
}) {
  let profileFormValues = profileForm?.getValues() || []

  const {
    industryQuestion,
    foodIndustryQuestionOption,
    subIndustryQuestion,
    serviceIndustryQuestionOption,
    servicesSubIndustryQuestion,
  } = useMemo(() => {
    const industryQuestion = assessment?.find((question) => question?.meta?.isIndustry)
    const foodIndustryQuestionOption = industryQuestion?.options?.find(
      (opt) => opt.value === INDUSTRY_TYPE.FOOD
    )
    const serviceIndustryQuestionOption = industryQuestion?.options?.find(
      (opt) => opt.value === INDUSTRY_TYPE.SERVICES
    )
    const subIndustryQuestion = assessment?.find((question) => question?.meta?.isSubIndustry)
    const servicesSubIndustryQuestion = assessment?.find(
      (question) => question?.meta?.isServicesSubIndustry
    )

    return {
      industryQuestion,
      foodIndustryQuestionOption,
      subIndustryQuestion,
      serviceIndustryQuestionOption,
      servicesSubIndustryQuestion,
    }
  }, [assessment])

  const IndustryQuestionFormValue = profileFormValues ? profileFormValues[industryQuestion?.id] : []
  const isSubIndustryExist = useMemo(() => {
    if (
      (profileFormValues[industryQuestion?.id] === foodIndustryQuestionOption?.id &&
        profileFormValues[subIndustryQuestion?.id]?.length === 0) ||
      (profileFormValues[industryQuestion?.id] === serviceIndustryQuestionOption?.id &&
        profileFormValues[servicesSubIndustryQuestion?.id]?.length === 0)
    )
      return true
    else return false
    // eslint-disable-next-line
  }, [profileFormValues])

  const retailBusinessLocationQuestion = assessment?.find(
    (item) => item?.meta?.showToRetailIndustry
  )
  const eCommerceBusinessLocationQuestion = assessment?.find(
    (item) => item?.meta?.showToECommerceIndustry
  )
  const industryBusinessLocationQuestion = assessment?.find(
    (item) => item?.meta?.showToServiceIndustry
  )
  const childCareBusinessLocationQuestion = assessment?.find(
    (item) => item?.meta?.isChildCareIndustry
  )
  const retailIndustryQuestionOption = industryQuestion?.options?.find(
    (opt) => opt.value === INDUSTRY_TYPE.RETAIL
  )
  const eCommerceIndustryQuestionOption = industryQuestion?.options?.find(
    (opt) => opt.value === INDUSTRY_TYPE.ECOMMERCE
  )

  const childCareIndustryQuestionOption = industryQuestion?.options?.find(
    (opt) => opt.value === INDUSTRY_TYPE.CHILDCARE
  )

  useEffect(() => {
    setIsSubIndustrySelected(false)
    if (
      profileFormValues &&
      profileFormValues[industryQuestion?.id] !== foodIndustryQuestionOption?.id &&
      profileFormValues[industryQuestion?.id] !== serviceIndustryQuestionOption?.id
    ) {
      reset({
        ...profileFormValues,
        [subIndustryQuestion?.id]: [],
        [servicesSubIndustryQuestion?.id]: [],
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
      profileFormValues = {
        ...profileFormValues,
        [subIndustryQuestion?.id]: [],
        [servicesSubIndustryQuestion?.id]: [],
      }
    } else if (
      (profileFormValues[industryQuestion?.id] === foodIndustryQuestionOption?.id &&
        profileFormValues[subIndustryQuestion?.id]?.length > 0) ||
      (profileFormValues[industryQuestion?.id] === serviceIndustryQuestionOption?.id &&
        profileFormValues[servicesSubIndustryQuestion?.id]?.length > 0)
    ) {
      setIsSubIndustrySelected(true)
    }
    if (!isEmpty(profileFormValues) && assessment) {
      setAssessmentList(assessment)

      if (
        profileFormValues[industryQuestion?.id] === retailIndustryQuestionOption?.id ||
        profileFormValues[industryQuestion?.id] === foodIndustryQuestionOption?.id
      ) {
        reset({
          ...profileFormValues,
          [childCareBusinessLocationQuestion?.id]: [],
          [eCommerceBusinessLocationQuestion?.id]: [],
          [industryBusinessLocationQuestion?.id]: [],
        })
        setAssessmentList(
          assessment?.filter(
            (list) =>
              list?.id !== eCommerceBusinessLocationQuestion?.id &&
              list?.id !== childCareBusinessLocationQuestion?.id &&
              list?.id !== industryBusinessLocationQuestion?.id
          )
        )
      } else if (profileFormValues[industryQuestion?.id] === eCommerceIndustryQuestionOption?.id) {
        reset({
          ...profileFormValues,

          [retailBusinessLocationQuestion?.id]: [],
          [childCareBusinessLocationQuestion?.id]: [],
          [industryBusinessLocationQuestion?.id]: [],
        })
        setAssessmentList(
          assessment?.filter(
            (list) =>
              list?.id !== retailBusinessLocationQuestion?.id &&
              list?.id !== childCareBusinessLocationQuestion?.id &&
              list?.id !== industryBusinessLocationQuestion?.id
          )
        )
      } else if (profileFormValues[industryQuestion?.id] === childCareIndustryQuestionOption?.id) {
        reset({
          ...profileFormValues,

          [retailBusinessLocationQuestion?.id]: [],
          [eCommerceBusinessLocationQuestion?.id]: [],
          [industryBusinessLocationQuestion?.id]: [],
        })
        setAssessmentList(
          assessment?.filter(
            (list) =>
              list?.id !== retailBusinessLocationQuestion?.id &&
              list?.id !== eCommerceBusinessLocationQuestion?.id &&
              list?.id !== industryBusinessLocationQuestion?.id
          )
        )
      } else if (profileFormValues[industryQuestion?.id] === serviceIndustryQuestionOption?.id) {
        reset({
          ...profileFormValues,

          [retailBusinessLocationQuestion?.id]: [],
          [eCommerceBusinessLocationQuestion?.id]: [],
          [childCareBusinessLocationQuestion?.id]: [],
        })
        setAssessmentList(
          assessment?.filter(
            (list) =>
              list?.id !== retailBusinessLocationQuestion?.id &&
              list?.id !== eCommerceBusinessLocationQuestion?.id &&
              list?.id !== childCareBusinessLocationQuestion?.id
          )
        )
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIndustryChanged, setIsSubIndustrySelected, isSubIndustryExist, IndustryQuestionFormValue])

  return {
    industryQuestion,
    foodIndustryQuestionOption,
    subIndustryQuestion,
    servicesSubIndustryQuestion,
    profileFormValues,
    serviceIndustryQuestionOption,
  }
}
