import React, { FC } from 'react'
import { capitalize } from 'lodash'

import { InlineLabel } from 'components/Common/InlineLabel'
import {
  InfoWrapper,
  UserInfoWrapper,
  UserNameWrapper,
  InlineLabelWrapper,
  UserBusinessNameWrapper,
} from 'components/Admin/UserManagement/UserManagementList/style'
import { IUser } from 'components/Admin/UserManagement/UserManagementList'
import { ADVISOR_STATUS } from 'config'

interface IUserInfoProps {
  user: IUser
}
const UserInfo: FC<IUserInfoProps> = ({ user }) => {
  return (
    <UserInfoWrapper>
      <InfoWrapper>
        <UserNameWrapper> {user?.name || ''}</UserNameWrapper>
        <UserBusinessNameWrapper> {user?.businessName || ''}</UserBusinessNameWrapper>
      </InfoWrapper>

      <InfoWrapper>
        <InlineLabelWrapper>
          <InlineLabel>Created: </InlineLabel>
          {user?.created}
        </InlineLabelWrapper>
        <InlineLabelWrapper>
          <InlineLabel>
            {`${capitalize('activated')}: `}
            <span className="capitalize">
              {user?.activated === ADVISOR_STATUS.ACTIVE ? user.activatedAt : user.activated}
            </span>
          </InlineLabel>
        </InlineLabelWrapper>
      </InfoWrapper>
    </UserInfoWrapper>
  )
}

export default UserInfo
