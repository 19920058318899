import { AttestationSummary } from '__generated__/api-types-and-hooks'
import { GetAttestationSummaryAction } from 'store/actions/attestationSummary'
import { GET_ATTESTATION_SUMMARY } from 'store/types'
import { AddValuesType } from 'types'
import { isObjectEmpty } from 'utils/helper'

// expand as needed
type FullfilledAction = GetAttestationSummaryAction.FULLFILLED

export interface IAttestationSummaryState {
  attestationSummary: AddValuesType<Required<Omit<AttestationSummary, '__typename'>>, null>
}

const initialState: IAttestationSummaryState = {
  attestationSummary: {
    signedCount: null,
    unsignedCount: null,
    tenantId: null,
    submissionEndDate: null,
    submissionStartDate: null,
    submissionReportDate: null,
    submissionReminderDate: null,
    newAggregatedReport: null,
    aggregationReportHistory: null,
  },
}

function reducer(state = initialState, action: FullfilledAction): IAttestationSummaryState {
  switch (action.type) {
    case GET_ATTESTATION_SUMMARY.FULLFILLED:
      if (action.payload === null || typeof action.payload === 'undefined') return state
      const {
        signedCount,
        unsignedCount,
        tenantId,
        newAggregatedReport,
        aggregationReportHistory,
      } = action.payload
      let newState = {
        attestationSummary: {
          ...initialState.attestationSummary,
        },
      }
      newState.attestationSummary = { ...newState.attestationSummary, ...action.payload }
      if (!isObjectEmpty(newAggregatedReport)) {
        newState.attestationSummary.aggregationReportHistory = [
          newAggregatedReport,
          ...(aggregationReportHistory || []),
        ]
      }
      if (typeof signedCount === 'number') {
        newState.attestationSummary.signedCount = signedCount
      }
      if (typeof unsignedCount === 'number') {
        newState.attestationSummary.unsignedCount = unsignedCount
      }
      if (typeof tenantId === 'string') {
        newState.attestationSummary.tenantId = tenantId
      }
      return newState
    default:
  }
  return state
}

export default reducer
