import React from 'react'
import { AppFC } from 'types'

import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppAlertBanner } from 'components/Common/AppAlertBanner/AppAlertBanner.component'
import { AppModalBox } from 'components/Common/AppModalBox/AppModalBox.component'
// import { STATUS } from 'config'

export interface IAppConfirmationModalProps {
  title: string
  status?: string
  isSuccess?: boolean
  isLoading?: boolean
  isError?: boolean
  description?: string
  errorMessage?: string
  onClose?: () => void
  successDescription?: string
  onConfirm?: () => void
}

export const AppConfirmationModal: AppFC<IAppConfirmationModalProps> = ({
  title = '',
  status = '',
  isSuccess,
  isLoading,
  isError,
  errorMessage,
  description = '',
  successDescription = '',
  onConfirm,
  onClose = () => {},
}) => {
  return (
    <AppModalBox title={title} onClose={onClose}>
      <div className="p-7">
        {(isSuccess || isError) && (
          <>
            {!isError && (
              <AppAlertBanner type="success" title="Succeeded">
                <p>{successDescription}</p>
              </AppAlertBanner>
            )}
            {isError && (
              <AppAlertBanner type="error" title="Error">
                <p>Please close and try again later.</p>
                <p>(Error Message: {errorMessage})</p>
              </AppAlertBanner>
            )}
            <div className="flex mt-2">
              <div className="flex-auto">
                <AppButton
                  variant="primary"
                  size="md"
                  label="Close"
                  onClick={onClose}
                  // className="w-full"
                />
              </div>
            </div>
          </>
        )}
        <>
          {!isError && !isSuccess && (
            <>
              <p className="mb-8">{description}</p>
              <div className="flex">
                <div className="flex-auto mr-2">
                  <AppButton
                    variant="secondary"
                    size="md"
                    label="Confirm"
                    // status={isLoading ? STATUS.LOADING : STATUS.NONE}
                    onClick={onConfirm}
                    // className="w-full"
                    disabled={isLoading}
                  />
                </div>
                <div className="flex-auto ml-2">
                  <AppButton
                    variant="secondary"
                    size="md"
                    label="Cancel"
                    onClick={onClose}
                    // className="w-full"
                  />
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </AppModalBox>
  )
}
