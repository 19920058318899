import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const CalendarToolsLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        className="xs:hidden sm:block"
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="0" y="1" rx="0" ry="0" width="1" height="165" />
        <rect x="0" y="165" rx="0" ry="0" width="200" height="1" />
        <rect x="0" y="1" rx="0" ry="0" width="200" height="1" />
        <rect x="200" y="1" rx="0" ry="0" width="1" height="165" />

        <rect x="20" y="20" rx="2" ry="2" width="150" height="70" />
        <rect x="20" y="102" rx="2" ry="2" width="90" height="10" />

        <rect x="230" y="1" rx="0" ry="0" width="1" height="165" />
        <rect x="230" y="165" rx="0" ry="0" width="200" height="1" />
        <rect x="230" y="1" rx="0" ry="0" width="200" height="1" />
        <rect x="430" y="1" rx="0" ry="0" width="1" height="165" />

        <rect x="250" y="20" rx="2" ry="2" width="150" height="70" />
        <rect x="250" y="102" rx="2" ry="2" width="90" height="10" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        className="xs:block sm:hidden"
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="0" y="1" rx="0" ry="0" width="1" height="165" />
        <rect x="0" y="165" rx="0" ry="0" width="100%" height="1" />
        <rect x="0" y="1" rx="0" ry="0" width="100%" height="1" />
        <rect x="445" y="1" rx="0" ry="0" width="1" height="165" />

        <rect x="20" y="20" rx="2" ry="2" width="80%" height="70" />
        <rect x="20" y="102" rx="2" ry="2" width="50%" height="10" />

        <rect x="0" y="230" rx="0" ry="0" width="1" height="165" />
        <rect x="0" y="395" rx="0" ry="0" width="100%" height="1" />
        <rect x="0" y="230" rx="0" ry="0" width="100%" height="1" />
        <rect x="445" y="230" rx="0" ry="0" width="1" height="165" />

        <rect x="20" y="250" rx="2" ry="2" width="80%" height="70" />
        <rect x="20" y="332" rx="2" ry="2" width="50%" height="10" />
      </ContentLoader>
    </>
  )
}

export default CalendarToolsLoader
