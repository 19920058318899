import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const EditBusinessProfileLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 235"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="15" y="10" rx="2" ry="2" width="90" height="15" />
        <rect x="15" y="35" rx="2" ry="2" width="50" height="10" />
        <rect x="15" y="50" rx="2" ry="2" width="415" height="25" />

        <rect x="15" y="85" rx="2" ry="2" width="50" height="10" />
        <rect x="225" y="85" rx="2" ry="2" width="50" height="10" />
        <rect x="15" y="100" rx="2" ry="2" width="205" height="25" />
        <rect x="225" y="100" rx="2" ry="2" width="205" height="25" />

        <rect x="15" y="135" rx="2" ry="2" width="50" height="10" />
        <rect x="225" y="135" rx="2" ry="2" width="50" height="10" />
        <rect x="15" y="150" rx="2" ry="2" width="205" height="25" />
        <rect x="225" y="150" rx="2" ry="2" width="205" height="25" />

        <rect x="15" y="185" rx="2" ry="2" width="50" height="10" />
        <rect x="15" y="200" rx="2" ry="2" width="205" height="25" />
      </ContentLoader>
    </>
  )
}

EditBusinessProfileLoader.defaultProps = {
  isMobile: false,
}

export default EditBusinessProfileLoader
