import { STATUS } from 'config'
import { CHANGE_PASSWORD } from 'store/types'

const initialState = {
  uploadedImage: '',
  status: '',
  errorMessage: '',
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PASSWORD.STARTED:
      return {
        ...state,
        status: STATUS.LOADING,
        errorMessage: '',
      }
    case CHANGE_PASSWORD.FULLFILLED:
      return {
        ...state,
        status: STATUS.COMPLETED,
        errorMessage: '',
      }
    case CHANGE_PASSWORD.RESET:
      return {
        ...state,
        status: initialState.status,
        errorMessage: initialState.errorMessage,
      }
    case CHANGE_PASSWORD.REJECTED:
      return {
        ...state,
        status: STATUS.COMPLETED,
        errorMessage: action.payload,
      }
    default:
  }
  return state
}

export default reducer
