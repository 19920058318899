import styled from 'styled-components'

export const StepperWrapper = styled.div`
  height: 100%;
  width: 100%;
  .container {
    align-content: Center;
    font-family: 'Futura Std';
    position: absolute;
  }
`
