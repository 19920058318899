import React from 'react'

function MeetingCreationErrorIcon() {
  return (
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0H56C61.5229 0 66 4.47715 66 10V56C66 61.5229 61.5228 66 56 66H10C4.47715 66 0 61.5228 0 56V10Z"
        fill="#F7E5E5"
      />
      <circle cx="33" cy="33" r="19" fill="#DC2626" />
      <path
        d="M31.582 21.6667H34.4154V37.2501H31.582V21.6667ZM34.4154 41.5001V44.3334H31.582V41.5001H34.4154Z"
        fill="#F7E5E5"
      />
    </svg>
  )
}

MeetingCreationErrorIcon.defaultProps = {
  className: '',
}

export default MeetingCreationErrorIcon
