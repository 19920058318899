import React from 'react'

const Grid = ({ children, className, paddingLeft = '' }) => {
  return <div className={`grid grid-cols-12 ${paddingLeft} ${className}`}>{children}</div>
}

Grid.defaultProps = {
  className: '',
}

export default Grid
