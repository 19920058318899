import React from 'react'

function AttestationPlusIcon({ className }) {
  return (
    <svg className={className} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.288 3.688V0.792H5.688V3.688H8.584V6.088H5.688V9H3.288V6.088H0.392V3.688H3.288Z" />
    </svg>
  )
}

AttestationPlusIcon.defaultProps = {
  className: 'h-2 w-2 fill-black-light',
}
export default AttestationPlusIcon
