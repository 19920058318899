import FilledArrowDownIcon from 'components/Common/SvgIcons/FilledArrowDownIcon'
import { isUndefined } from 'lodash'
import React, { useEffect, useState } from 'react'

interface IExpandItemsAccordion {
  title: string
  open: boolean
  onOpen: () => void
  onClose?: () => void
  children: React.ReactNode
}

const ExpandItemsAccordion: React.FC<IExpandItemsAccordion> = ({
  title,
  open,
  onOpen,
  onClose,
  children,
}) => {
  const [isOpen, setOpen] = useState(open)

  const handleToggle = () => {
    setOpen(!isOpen)
    if (!isOpen) {
      onOpen()
    } else {
      if (onClose) onClose()
    }
  }

  useEffect(() => {
    if (!isUndefined(open)) {
      setOpen(open)
    }
  }, [open])

  return (
    <div className="">
      <div className="flex items-center cursor-pointer" onClick={handleToggle}>
        <span className="space-x-2 text-primary text-base font-bold">{title}</span>
        <FilledArrowDownIcon className={isOpen ? 'rotate-180' : ''} />
      </div>
      {isOpen && <div className="pt-12">{children}</div>}
    </div>
  )
}

ExpandItemsAccordion.defaultProps = {
  open: false,
  onClose: () => {},
}

export default ExpandItemsAccordion
