import React from 'react'
import ComingSoonPlaceholder from 'components/Common/ComingSoonPlaceholder'
import { ClientDataCard } from '../../components/DataReports/ClientReportManagement/ClientData/ClientDataCard'
import { OptionTypes, useGetBusinessSummaryQuery } from '../../__generated__/api-types-and-hooks'
import { useTenantId } from '../../utils/useTenantId'
import { ClientDataCardWrapper } from './styles'
import { ClientDataDonutChart } from '../../components/DataReports/ClientReportManagement/ClientData/ClientDataDonutChart'

const Data = () => {
  const tenantId = useTenantId()
  const { data: response, isLoading } = useGetBusinessSummaryQuery({
    input: {
      tenantId,
      optionType: OptionTypes.BusinessClassification,
    },
  })
  const businessSummaryData = response?.getBusinessSummary?.data?.filter(
    (data) => data.optionType === OptionTypes.BusinessClassification
  )[0]?.data

  const total =
    businessSummaryData?.reduce((previousValue, currentEntry) => {
      return previousValue + (currentEntry.summary ?? 0)
    }, 0) ?? 0

  const shouldRenderEmptyState = total === 0 && !isLoading
  return (
    <div>
      <h1 className="text-2xl text-primary-text font-black leading-[26.4px] font-secondary pb-4">
        Client Data
      </h1>

      {shouldRenderEmptyState ? (
        <>
          <p className="mt-10 mb-5 font-medium">
            As your clients join GoTackle, we will provide valuable data on your clients'
            industries, top goals, strengths, and support needs.
          </p>
          <div className="flex mt-4">
            <ClientDataCardWrapper>
              <div className="flex flex-col items-center smd:items-start w-full smd:w-96 h-56 sm:h-64">
                <ClientDataDonutChart data={[{ name: '', value: 1 }]} total={0} />
                <p className="mt-5 italic">No clients have joined yet</p>
              </div>
            </ClientDataCardWrapper>
          </div>
        </>
      ) : (
        <div className="flex flex-col smd:flex-row">
          <div className="flex flex-col smd:mr-8">
            <ClientDataCard
              total={total}
              useDonutChart={true}
              title={'Industries'}
              description="Number of clients by industry"
              optionType={OptionTypes.BusinessClassification}
              tenantId={tenantId}
            />

            <ClientDataCard
              total={total}
              useDonutChart={false}
              title={'Areas of Strength'}
              description="Businesses identified one or more areas they consider strength:
          (each business can select 1 or more)"
              optionType={OptionTypes.StrongBusinessAreas}
              tenantId={tenantId}
            />
          </div>
          <div className="flex flex-col">
            <ClientDataCard
              total={total}
              useDonutChart={true}
              title={'Top Goals'}
              description="Number of clients by top goal for the next 6 months."
              optionType={OptionTypes.TopGoal}
              tenantId={tenantId}
            />

            <ClientDataCard
              total={total}
              useDonutChart={false}
              title={'Areas Needing Support'}
              description="Businesses identified one or more areas they need support:
        (each business can select 1 or more)"
              optionType={OptionTypes.SupportAndHelp}
              tenantId={tenantId}
            />
          </div>
        </div>
      )}

      <ComingSoonPlaceholder
        title="Economic Impact Metrics"
        description="As GoTackle gets to know your clients, and collects Monthly Impact Reports, this area will offer you time-saving data tracking."
        className="flex flex-col justify-center gap-3 mt-60"
      />
    </div>
  )
}

export default Data
