import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'
import colors from 'colors'

const ReviewAssessmentLoader = () => {
  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 350 260"
      backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
      foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
    >
      <rect x="10" y="20" rx="6" ry="6" width="221" height="10" />
      <rect x="10" y="60" rx="6" ry="6" width="221" height="10" />
      <rect x="10" y="100" rx="6" ry="6" width="221" height="10" />
      <rect x="10" y="140" rx="6" ry="6" width="221" height="10" />
      <rect x="10" y="180" rx="6" ry="6" width="221" height="10" />
    </ContentLoader>
  )
}

export default ReviewAssessmentLoader
