import React, { forwardRef, memo, ForwardRefRenderFunction } from 'react'

import Label from 'components/Common/Label'
import ErrorMessage from '../ErrorMessage'
import { FontSize, FontWeight } from '../../../types'

interface IInputTextAreaProps {
  name?: string
  label?: string
  mask?: string
  placeholder?: string
  rows?: any
  value?: string
  onChange?: any
  onChangeText?: any
  defaultValue?: any
  disabled?: boolean
  readonly?: boolean
  step?: string
  help?: string
  maxLength?: string | number
  autoFocus?: boolean
  hidden?: boolean
  className?: string
  isCheckBox?: boolean
  id?: any
  onMouseOut?: any
  onBlur?: any
  onFocus?: any
  onKeyPress?: any
  onKeyUp?: any
  errors?: { message?: string }
  labelFontWeight?: FontWeight
  labelFontSize?: FontSize
  textAreaClasses?: string
}

const InputTextArea: ForwardRefRenderFunction<any, IInputTextAreaProps> = (
  {
    name,
    label,
    placeholder,
    onChange,
    value,
    defaultValue,
    disabled,
    readonly,
    maxLength,
    autoFocus,
    hidden,
    id,
    onMouseOut,
    className,
    onBlur,
    onFocus,
    onKeyPress,
    onKeyUp,
    rows,
    errors = null,
    labelFontWeight,
    labelFontSize,
    textAreaClasses,
  },
  ref
) => {
  const getClasses = () => {
    const border = errors ? 'border-primary-error' : 'border-primary-outline'
    const hoverAndFocusStyles = errors
      ? 'hover:border-primary-error focus:border-primary-error focus:ring-transparent'
      : 'hover:border-primary focus:border-primary-brandDark focus:ring-primary-brandLight'
    const customClasses = `hover:border focus:ring focus:border bg-white ${hoverAndFocusStyles}`

    let classes = `rounded-[4px] placeholder:font-primary placeholder:font-medium placeholder:leading-7 placeholder:text-grey-darkest2x placeholder:font-xl font-primary outline-0
      text-base mt-3 block w-full px-3 py-3 border ${border} text-primary-text
      ${disabled ? 'bg-secondary ' : customClasses}  ${className} ${textAreaClasses ?? ''}`

    return classes
  }
  return (
    <div className={className}>
      <div className={`${hidden ? 'hidden' : ''}`}>
        {label && (
          <Label
            title={label}
            fontWeight={labelFontWeight ?? 'font-medium'}
            fontSize={labelFontSize}
          >
            {label}
          </Label>
        )}
        <div className="">
          <textarea
            name={name}
            placeholder={placeholder ? placeholder : ''}
            defaultValue={defaultValue}
            value={value}
            ref={ref}
            disabled={disabled}
            readOnly={readonly}
            autoFocus={autoFocus}
            maxLength={maxLength as number}
            hidden={hidden}
            onChange={onChange}
            id={id}
            onMouseOut={onMouseOut}
            className={getClasses()}
            onFocus={onFocus}
            onBlur={onBlur}
            title={label}
            data-testid={name}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
            rows={rows}
          />
          {errors && <ErrorMessage id={name}>{errors.message}</ErrorMessage>}
        </div>
      </div>
    </div>
  )
}
export default memo(forwardRef(InputTextArea))
