import API from 'api'

import * as api from '__generated__/api-types-and-hooks'

class USER_MANAGEMNT_API {
  getBusinesses = (getBusinessesInput) => {
    return API.fetch<'getBusinesses'>(api['GetBusinessesDocument'], {
      getBusinessesInput: getBusinessesInput,
    })
  }

  getAdvisors = (getAdvisorsInput) => {
    return API.fetch<'getAdvisors'>(api['GetAdvisorsDocument'], {
      getAdvisorsInput: getAdvisorsInput,
    })
  }
}

// eslint-disable-next-line
export default new USER_MANAGEMNT_API()
