import React, { useMemo } from 'react'

import { AppFC } from 'types'

import PillTextBox, { PillColorTheme } from 'components/Common/PillTextBox'

export enum BusinessCategory {
  SALES = 'Sales',
  FINANCING = 'Financing',
  MARKETING = 'Marketing',
  OPERATIONS = 'Operations',
  BUSINESS_ESSENTIALS = 'BusinessEssentials',
}

export interface IRecommendationCategoryPillBoxProps {
  businessCategory: BusinessCategory
}

const RecommendationCategoryPillBox: AppFC<IRecommendationCategoryPillBoxProps> = ({
  children,
  businessCategory,
}) => {
  const colorTheme: PillColorTheme = useMemo(() => {
    return ({
      [BusinessCategory.SALES]: PillColorTheme.LIME as const,
      [BusinessCategory.FINANCING]: PillColorTheme.BLUE as const,
      [BusinessCategory.MARKETING]: PillColorTheme.YELLOW as const,
      [BusinessCategory.OPERATIONS]: PillColorTheme.PURPLE as const,
      [BusinessCategory.BUSINESS_ESSENTIALS]: PillColorTheme.ORANGE as const,
    }[businessCategory] || PillColorTheme.ERROR) as PillColorTheme
  }, [businessCategory])

  return <PillTextBox colorTheme={colorTheme}>{children}</PillTextBox>
}

export default RecommendationCategoryPillBox
