import styled from 'styled-components'
import tw from 'twin.macro'

export const FileWrapper = styled.div`
  input[type='file'] {
    display: block !important;
    min-height: 100px !important;
  }
`

export const DropFileWrapper = styled.div`
  ${tw`flex flex-col justify-center items-center xs:pt-0 sm:px-4 sm:pt-6 sm:pb-11 relative bg-contain bg-no-repeat`} {
  }
`
export const FieldWrapper = styled.div`
  ${tw`space-y-1`} {
  }
`

export const LabelWrapper = styled.p`
  ${tw`text-base font-semibold font-primary leading-6`} {
  }
`

export const ValueWrapper = styled.p`
  ${tw`leading-6 font-primary text-lg`} {
  }
`
