import React, { ReactNode } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

export interface IAlertBannerProps {
  type: 'success' | 'error' | 'info' | 'warn'
  title?: string
  children?: ReactNode | undefined
}

const AlertBannerWrapper = styled.div<IAlertBannerProps>`
  ${(props) => props.type === 'success' && tw`border-green text-green`}
  ${(props) => props.type === 'error' && tw`border-gerberared text-gerberared`}
  ${tw`border-l-4 p-4`}
`

const AlertBanner = ({ type, title, children }: IAlertBannerProps) => {
  return (
    <AlertBannerWrapper type={type} role="alert">
      {title && <p className="font-bold">{title}</p>}
      {children}
    </AlertBannerWrapper>
  )
}

export default AlertBanner
