import React from 'react'

function EditIcon({ className }) {
  return (
    <svg
      className={className}
      width={'32px'}
      height={'30px'}
      viewBox="-60 0 570 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M286.2 140.7C304.1 121.1 335.3 121.1 354.1 140.7L371.4 158.1C390.2 176.8 390.2 207.2 371.4 225.9L245.1 352.3C238.9 358.5 231.2 362.8 222.8 364.1L147.9 383.7C142.4 385 136.7 383.4 132.7 379.5C128.7 375.5 127.1 369.7 128.5 364.3L147.2 289.4C149.3 280.9 153.7 273.2 159.8 267.1L286.2 140.7zM331.5 163.3C325.2 157.1 315.1 157.1 308.8 163.3L287.4 184.8L327.4 224.8L348.8 203.3C355.1 197.1 355.1 186.9 348.8 180.7L331.5 163.3zM178.2 297.1L165.1 346.2L215 333.9C217.8 333.2 220.4 331.7 222.4 329.7L304.8 247.4L264.8 207.4L182.5 289.7C180.4 291.8 178.9 294.3 178.2 297.1V297.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z" />{' '}
    </svg>
  )
}
export default EditIcon
