import React from 'react'
import { Controller } from 'react-hook-form'
import { FIELD_NAME, LABEL } from 'config'

import SelectField from 'components/Common/SelectField'
import { SelectFieldWrapper } from 'components/Admin/BusinessOwnerStepper/style'

const BusinessProfileMultiSelectFields = ({ options, control, applyStyling }) => {
  const fields = [
    {
      name: FIELD_NAME.STRONG_BUSINESS_AREAS_OPTION_IDS,
      isMulti: true,
      classes: 'mt-4',
      label: LABEL.BUSINESS_AREA,
      options: options[FIELD_NAME.STRONG_BUSINESS_AREAS],
      placeholder: `Select ${LABEL.BUSINESS_AREA}`,
    },
    {
      name: FIELD_NAME.SUPPORT_AND_HELP_OPTION_IDS,
      isMulti: true,
      classes: 'mt-4',
      label: LABEL.SUPPORT_HELP,
      options: options[FIELD_NAME.SUPPORT_AND_HELP],
      placeholder: `Select ${LABEL.SUPPORT_HELP}`,
    },
  ]

  return (
    <>
      {fields.map((f) => (
        <SelectFieldWrapper applyStyling={applyStyling} key={f.name}>
          <Controller
            name={f.name}
            control={control}
            render={({ field }) => (
              <SelectField
                placeholder={f.placeholder}
                {...field}
                isMulti={f.isMulti}
                classes={f.classes}
                label={f?.label}
                options={f?.options}
              />
            )}
          />
        </SelectFieldWrapper>
      ))}
    </>
  )
}

BusinessProfileMultiSelectFields.defaultProps = {
  applyStyling: false,
}

export default BusinessProfileMultiSelectFields
