import React from 'react'

import { AppFC } from 'types'
import Loader from 'components/Loaders'
import Card from 'components/Common/Card'
import DonutChart from 'components/Common/DonutChart'

export interface IStatCardProps {
  title: string
  description: string
  loading: boolean
  value: number | undefined
  total: number | undefined
}

/** Displays an important statistic related to a BSO client */
const Cards: AppFC<IStatCardProps> = ({ title = '', description = '', value, total, loading }) => {
  const displayTotal = total === undefined ? 0 : total
  const displayValue = value === undefined ? 0 : value
  // expanding height at lg size is temporary duct tape to prevent text mashing against the donut chart in an awkard width range
  // it should be fixed when horizontal scrolling is implemented for the cards instead
  return (
    <Card className="w-[226px] h-[314px] lg:h-[327px] xl:h-[314px] min-w-[215px] lg:min-w-0 lg:flex px-5 py-6 inline-block">
      {loading ? (
        <Loader loader="CardLoader" />
      ) : (
        <div className="w-full h-full grid grid-rows-2 lg:gap-1">
          <div className="flex justify-center">
            <div className="flex flex-col gap-3 whitespace-normal">
              <h1 className="text-2xl leading-7 font-secondary font-extrabold">{title}</h1>
              <p className="text-sm">{description}</p>
            </div>
          </div>
          {/* pointer-events-none prevents ugly outline of chart on focus */}
          <div className="flex items-end justify-start pointer-events-none">
            <DonutChart value={displayValue} total={displayTotal} />
          </div>
        </div>
      )}
    </Card>
  )
}

export default Cards
