import React, { FC } from 'react'

import { LABEL } from 'config'
import ModalBox from 'components/Common/ModalBox'
import { EditAccountModalBoxWrapper } from 'components/Admin/UserManagement/style'

import { EditAccountContainer } from 'pages/Admin/container/UserManagement'

interface IEditAccountModalBoxProps {
  isBusiness: boolean
  handleClose: () => void
  userData: { userId: string }
}

const EditAccountModalBox: FC<IEditAccountModalBoxProps> = ({
  userData,
  isBusiness,
  handleClose,
}) => {
  return (
    <EditAccountModalBoxWrapper>
      <ModalBox
        onClose={handleClose}
        title={LABEL.EDIT_ACCOUNT}
        rounded="xl"
        height="h-full"
        margin="my-4"
      >
        <EditAccountContainer
          isBusiness={isBusiness}
          selectedUser={userData}
          userId={userData?.userId}
          handleClose={handleClose}
        />
      </ModalBox>
    </EditAccountModalBoxWrapper>
  )
}

export default EditAccountModalBox
