import React from 'react'

export default function SortIcon({ className = '' }) {
  return (
    <svg
      className={className}
      width="10"
      height="15"
      viewBox="0 0 10 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.70451 14.6254C5.31389 15.0404 4.67951 15.0404 4.28889 14.6254L0.288886 10.3754C0.00138664 10.0699 -0.0829887 9.61505 0.0732613 9.21661C0.229511 8.81817 0.592011 8.55919 0.998261 8.55919H8.99826C9.40139 8.55919 9.76701 8.81817 9.92326 9.21661C10.0795 9.61505 9.99201 10.0699 9.70764 10.3754L5.70764 14.6254H5.70451ZM5.70451 0.371295L9.70451 4.62129C9.99201 4.92676 10.0764 5.38165 9.92014 5.78008C9.76389 6.17852 9.40139 6.43751 8.99514 6.43751H0.998261C0.595137 6.43751 0.229511 6.17852 0.0732613 5.78008C-0.0829887 5.38165 0.00451088 4.92676 0.288886 4.62129L4.28889 0.371295C4.67951 -0.0437441 5.31389 -0.0437441 5.70451 0.371295Z"
        fill="#5E06B9"
      />
    </svg>
  )
}
