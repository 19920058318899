import React, { useState, FC, useEffect, useRef } from 'react'
import InputField from 'components/Common/InputField'
import Heading from 'components/Common/Heading'
import Button from 'components/Common/Button'
import Alert from 'components/Common/Alert'
import { industrySupportData } from 'config'
import {
  IndustryReportWrapper,
  HeadingWrapper,
  BodyWrapper,
  BodyContentWrapper,
  AlertWrapper,
  FieldWrapper,
  ContentWrapper,
  BodyTextWrapper,
} from 'pages/DataReports/styles'
import { IndustryReport as IndustryReportType } from '__generated__/api-types-and-hooks'
import { isEmpty } from 'lodash'
import { CancelIcon } from 'components/Common/SvgIcons'
import IndustryReportResults from './IndustryReportResults'
import GradientCircleNotchIcon from 'components/Common/SvgIcons/GradientCircleNotchIcon'
import { ampli } from 'ampli'
interface IIndustryReportProps {
  industryReport: IndustryReportType
  searchIndustryReportAction: (payload: { keyword: string }) => void
  isIndustryReportLoading: boolean
  searchIndustryReportError: boolean
  bsoOrgName: string
  userId: string
}

const IndustryReport: FC<IIndustryReportProps> = ({
  industryReport,
  searchIndustryReportAction,
  isIndustryReportLoading,
  searchIndustryReportError,
  bsoOrgName,
  userId,
}) => {
  const [keyword, setKeyword] = useState('')
  const [searchMode, setSearchMode] = useState(false)

  const filteredData = industrySupportData.filter((data) => data.isVisible)
  const industryData = industrySupportData.map((item) => ({
    ...item,
    isVisible: true,
  }))
  const [tipData, setTipData] = useState(
    window.innerWidth > 500 && window.innerWidth < 901 ? filteredData : industryData
  )

  useEffect(() => {
    const handleResize = () => {
      setTipData(window.innerWidth > 500 && window.innerWidth < 901 ? filteredData : industryData)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line
  }, [])

  const handleSearch = () => {
    if (!isEmpty(keyword)) {
      searchIndustryReportAction({ keyword })
      setSearchMode(true)
      ampli.industryReportSearched({ userId, searchedKeyword: keyword, bsoOrgName })
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleReset = (reset?: boolean) => {
    setSearchMode(false)
    if (reset) setKeyword('')
  }

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus() // Focus the input field
      // Set the cursor position to the end of the text
      inputRef.current.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length
      )
    }
  }, [searchMode])

  return (
    <IndustryReportWrapper>
      <HeadingWrapper>
        <Heading variant="primary" className="text-primary-text" size="smaller">
          Industry Data
        </Heading>
        <p className="text-primary-text">(Powered by IBIS World)</p>
      </HeadingWrapper>
      <BodyWrapper>
        <BodyContentWrapper>
          <ContentWrapper>
            Industry trends paint a long-term picture of the market. You can use them to guide your
            clients and fill in knowledge gaps.<br></br>
            <br></br>GoTackle uses reports compiled with data from thousands of businesses in every
            industry and analyzed by economic experts.
          </ContentWrapper>

          <BodyTextWrapper>
            To discover the trends in an industry, enter a phrase that best describes what the
            business does and what you want to learn.
          </BodyTextWrapper>
        </BodyContentWrapper>
      </BodyWrapper>
      <AlertWrapper>
        {tipData.map((data) => (
          <Alert Icon={data.Icon} text={data.text} heading={data.heading} />
        ))}
      </AlertWrapper>
      <FieldWrapper>
        {searchMode && !isIndustryReportLoading ? (
          <div
            className="flex items-center justify-between rounded-lg w-full p-4 bg-grey-lighter font-bold text-base text-zinc"
            onClick={() => handleReset()}
          >
            <span className="max-w-[90%]">{keyword}</span>
            <span onClick={() => handleReset(true)}>
              <CancelIcon className=" cursor-pointer fill-zinc" />
            </span>
          </div>
        ) : (
          <InputField
            type="text"
            placeholder="Enter a phrase or keywords"
            name="phrase"
            className="w-full"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            ref={inputRef}
            onKeyPress={handleKeyPress}
          />
        )}
        <Button label="Search" variant="primary" className="!py-3" onClick={handleSearch} />
      </FieldWrapper>
      <div>
        {isIndustryReportLoading ? (
          <div className="h-full flex flex-col items-center p-10">
            <GradientCircleNotchIcon width="100" height="100" />
            <p className="font-bold mt-2">Searching industry report</p>
          </div>
        ) : (
          <div>
            {searchIndustryReportError ? (
              <div className="h-full flex flex-col items-center p-10">
                There was an error fetching industry reports
              </div>
            ) : (
              <IndustryReportResults industryReport={industryReport} />
            )}
          </div>
        )}
      </div>
    </IndustryReportWrapper>
  )
}

export default IndustryReport
