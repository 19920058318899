import React from 'react'

function ChatIcon({ className }) {
  return (
    <svg
      className={className}
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'middle', overflow: 'hidden' }}
    >
      <rect width="39" height="39" fill="none" />
      <g clipPath="url(#clip0_16287_156521)">
        <rect width="1728" height="1117" transform="translate(-358 -159)" fill="none" />
        <circle cx="19.5" cy="19.5" r="19.5" fill="#5E06B9" />
        <path
          d="M27.1315 11.4555L27.5602 11.8841C27.8932 12.2172 27.8932 12.7557 27.5602 13.0851L26.5292 14.1196L24.896 12.4864L25.9269 11.4555C26.26 11.1224 26.7985 11.1224 27.1279 11.4555H27.1315ZM18.9406 18.4454L23.695 13.6874L25.3282 15.3206L20.5703 20.0751C20.4675 20.1778 20.34 20.2522 20.2018 20.2912L18.1293 20.8828L18.7209 18.8103C18.7599 18.6721 18.8343 18.5446 18.937 18.4418L18.9406 18.4454ZM24.7259 10.2545L17.736 17.2408C17.4278 17.549 17.2046 17.9281 17.0877 18.3426L16.0745 21.8854C15.9894 22.183 16.0709 22.5019 16.2906 22.7215C16.5102 22.9412 16.8291 23.0226 17.1267 22.9376L20.6695 21.9244C21.0875 21.8039 21.4666 21.5807 21.7713 21.2761L28.7612 14.2897C29.7567 13.2942 29.7567 11.6787 28.7612 10.6831L28.3325 10.2545C27.337 9.25893 25.7215 9.25893 24.7259 10.2545ZM14.6255 11.6361C12.9037 11.6361 11.5078 13.032 11.5078 14.7538V24.3902C11.5078 26.112 12.9037 27.5078 14.6255 27.5078H24.2618C25.9836 27.5078 27.3795 26.112 27.3795 24.3902V20.4222C27.3795 19.9511 27.0004 19.572 26.5292 19.572C26.058 19.572 25.6789 19.9511 25.6789 20.4222V24.3902C25.6789 25.1731 25.0448 25.8073 24.2618 25.8073H14.6255C13.8425 25.8073 13.2083 25.1731 13.2083 24.3902V14.7538C13.2083 13.9708 13.8425 13.3367 14.6255 13.3367H18.5934C19.0646 13.3367 19.4436 12.9576 19.4436 12.4864C19.4436 12.0152 19.0646 11.6361 18.5934 11.6361H14.6255Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_16287_156521">
          <rect width="1728" height="1117" fill="none" transform="translate(-358 -159)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ChatIcon
