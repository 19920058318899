import styled from 'styled-components'

interface ToolTipWrapperProps {
  variant: 'primary' | 'clear'
}

export const AppToolTipWrapper = styled.div<ToolTipWrapperProps>`
  .react-tooltip {
    border-radius: 8px !important;
    box-shadow: 0px 1px 0px -1px rgba(0, 0, 0, 0.05), 0px 1px 2px -1px rgba(0, 0, 0, 0.05),
      0px 2px 4px -2px rgba(0, 0, 0, 0.05), 0px 3px 6px -3px rgba(0, 0, 0, 0.05);
    font-family: 'Gill Sans';
    font-size: 14px;
    /* Conditionally apply border if variant is 'clear' */
    ${(props) =>
      props.variant === 'clear' &&
      `
          border: 1px solid var(--grey-200, #ECECEC) !important
        `}
  }
`
