import React from 'react'
import Button from 'components/Common/Button'
import { AnnouncementYayIcon } from 'components/Common/SvgIcons'

const AnnouncementSuccessErrorScreen = ({
  announcementError,
  reset,
  setAnnouncementActiveStepAction,
  announcementSteps,
}) => {
  return (
    <>
      {announcementError ? (
        <div className="mb-10">
          <p className="font-primary font-bold mt-2 text-lg text-primary-error text-center px-6">
            {announcementError}
          </p>
          <div className="flex justify-center">
            <Button
              variant="primary"
              label="Retry"
              className="mt-8"
              onClick={() => {
                reset()
                setAnnouncementActiveStepAction(announcementSteps[0])
              }}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-center">
            <AnnouncementYayIcon className="mt-1" />
          </div>
          <div className="flex justify-center">
            <p className="text-black-light text-2xl font-secondary pb-14">Notification Published</p>
          </div>
        </>
      )}
    </>
  )
}
export default AnnouncementSuccessErrorScreen
