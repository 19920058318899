import React, { useEffect } from 'react'

import { EventDetailContainer } from 'pages/Clients/container'
import { UpComingMeetingMobileViewStyle } from 'components/Clients/Client/Meeting/style'

const UpComingMeetingMobileView = ({ getTackleMeetingEventsAction, filter, ownerId }) => {
  useEffect(() => {
    getTackleMeetingEventsAction({ filter: filter, ownerId })
    // eslint-disable-next-line
  }, [filter])

  return (
    <UpComingMeetingMobileViewStyle>
      <EventDetailContainer filter={filter} />
    </UpComingMeetingMobileViewStyle>
  )
}

export default UpComingMeetingMobileView
