import React from 'react'

function CircleIcon({ className }) {
  return (
    <svg className={className} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="7.5" fill="white" stroke="#D4D4D8" strokeWidth="3" />
    </svg>
  )
}

export default CircleIcon
