import React, { useEffect } from 'react'

import isEmpty from 'lodash/isEmpty'
import { ASSESSMENTS, CURRENT_TAB, PathNames } from 'config'

import {
  TextWrapper,
  LoaderWrapper,
  ListItemWrapper,
  RecommendationsWrapper,
  RecommendationsWidthWrapper,
} from 'components/Assessment/AssessmentRecommendations/style'
import Loaders from 'components/Loaders'
import { PlayBooksRecommendationCardContainer } from 'pages/Assessment/containers'
import { getReturnToPath, trackAmpliEventForAssessmentAndPlay } from 'utils/helper'

const AssessmentRecommendations = ({
  userSummary,
  recommendations,
  getRecommendedPlaysAssessmentsAction,
  assessmentType,
  description,
  moveToNext,
}) => {
  useEffect(() => {
    getRecommendedPlaysAssessmentsAction(assessmentType)
    // eslint-disable-next-line
  }, [])

  let queryParam = getReturnToPath()
  const redirectPath = queryParam?.returnTo || PathNames.Dashboard

  return recommendations.isLoading ? (
    <LoaderWrapper>
      <Loaders loader="RecommendationsLoader" />
    </LoaderWrapper>
  ) : !isEmpty(recommendations.data) ? (
    <>
      <TextWrapper>
        Based on your answers, we recommend these up next for your business.
      </TextWrapper>

      <RecommendationsWrapper>
        <RecommendationsWidthWrapper>
          <ul>
            {recommendations.data.map((recommendation, index) => {
              return (
                <ListItemWrapper
                  key={recommendation?.id}
                  onClick={() => {
                    moveToNext(
                      null,
                      `${
                        recommendation?.meta?.type === 'assessment'
                          ? `assessmentQuestion/${recommendation.id}`
                          : `plays/${recommendation.id}/guide?returnTo=${redirectPath}`
                      }`
                    )
                    trackAmpliEventForAssessmentAndPlay(
                      userSummary,
                      assessmentType,
                      recommendation?.meta?.type,
                      CURRENT_TAB.ASSESSMENT,
                      recommendation?.description,
                      recommendation?.meta?.category,
                      `${ASSESSMENTS[recommendation?.meta?.assessmentType]?.level}`
                    )
                  }}
                >
                  <PlayBooksRecommendationCardContainer
                    key={index}
                    title={`${
                      recommendation?.meta?.type === 'assessment'
                        ? `${ASSESSMENTS[recommendation.id]?.title}`
                        : `${recommendation?.description}`
                    }`}
                    variant={recommendation?.meta?.type}
                  />
                </ListItemWrapper>
              )
            })}
          </ul>
        </RecommendationsWidthWrapper>
      </RecommendationsWrapper>
    </>
  ) : (
    <section className="font-primary text-xl whitespace-pre-line text-left">{description}</section>
  )
}

export default AssessmentRecommendations
