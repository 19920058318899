import React from 'react'

import useNavigateToRecommendation from 'hooks/useNavigateToRecommendation'
import { AppFC, IUser, RecommendationCategory } from 'types'

import { RecommendableStatus } from 'components/Owner/PlayBooksCategoryDetails'
import { RecommendationCardHeader } from 'components/Common/RecommendationCard'
import RecommendationProgressBubble from 'components/Owner/PlayBooksCategoryDetails/RecommendationProgressBubble'
import RecommendableLockedIcon from 'components/Owner/PlayBooksCategoryDetails/RecommendableLockedIcon'
import RightArrowIconButton from 'components/Common/RightArrowIconButton'

import {
  CardWrapper,
  PaddingBoxWrapper,
  ContentBoxWrapper,
  VerticalSpacerWrapper,
  TopContentRowWrapper,
  ButtonContainerWrapper,
  TitleWrapper,
} from 'components/Owner/PlayBooksCategoryDetails/PlayBooksRecommendationCard/styles'
import { trackAmpliEventForAssessmentAndPlay } from 'utils/helper'

export interface IPlayBooksRecommendationCardProps {
  variant: RecommendationCategory
  status?: RecommendableStatus
  category?: string
  level?: string
  title: string
  locked?: boolean
  loading?: boolean
  userSummary: IUser
  viewedContentFrom?: string
}

const PlayBooksRecommendationCard: AppFC<IPlayBooksRecommendationCardProps> = ({
  level,
  category,
  id,
  userSummary,
  locked,
  variant,
  status,
  title,
  loading = false,
  viewedContentFrom,
}) => {
  const navigate = useNavigateToRecommendation({
    id,
    category: variant,
    isCompleted: status === RecommendableStatus.COMPLETED ? true : false,
  })
  return (
    <CardWrapper>
      <PaddingBoxWrapper>
        <ContentBoxWrapper>
          <VerticalSpacerWrapper>
            <TopContentRowWrapper>
              <RecommendationCardHeader category={variant} />
              {status && !loading && <RecommendationProgressBubble status={status} />}
            </TopContentRowWrapper>
            <TitleWrapper>{title}</TitleWrapper>
          </VerticalSpacerWrapper>
          <ButtonContainerWrapper>
            {status && !loading && (
              <div className="animate-fade-in">
                {locked ? (
                  <RecommendableLockedIcon />
                ) : (
                  <RightArrowIconButton
                    size="large"
                    onClick={() => {
                      navigate()
                      if (userSummary)
                        trackAmpliEventForAssessmentAndPlay(
                          userSummary,
                          id || '',
                          variant,
                          viewedContentFrom || '',
                          title || '',
                          category || '',
                          level || '',
                          status
                        )
                    }}
                  />
                )}
              </div>
            )}
          </ButtonContainerWrapper>
        </ContentBoxWrapper>
      </PaddingBoxWrapper>
    </CardWrapper>
  )
}
PlayBooksRecommendationCard.defaultProps = {
  viewedContentFrom: '',
  category: '',
  level: '',
}

export default PlayBooksRecommendationCard
