import React, { useEffect } from 'react'
import ChatApplication from './ChatApplication'
import { getImage } from './assets'
import { getChannelListOptions } from './channelListOptions'
import { UnreadProvider } from 'components/Chat/contexts/UnreadContext'
import './index.css'
import { APP_STREAM_KEY } from 'config'
import GrowingContainer from 'components/Common/GrowingContainer'
import uuid from 'react-uuid'

export interface IChatAttachment {
  title: string | undefined
  fileSize: string | undefined
  fileType: string | undefined
  url: string | undefined
}

const Chat = ({
  user,
  token,
  chatUsers,
  chatUnreadMessageCount,
  usersAssessmentResponseByQuestion,
  saveUserFileAction,
  getChatUsersAction,
  chatUnreadMessageCountAction,
  getUsersAssessmentResponseByQuestionAction,
}) => {
  const apiKey = APP_STREAM_KEY
  const urlParams = new URLSearchParams(window.location.search)
  const targetOrigin = urlParams.get('target_origin') || process.env.REACT_APP_TARGET_ORIGIN
  const noChannelNameFilter = urlParams.get('no_channel_name_filter') || false
  const skipNameImageSet = urlParams.get('skip_name_image_set') || false

  const channelListOptions = getChannelListOptions(!!noChannelNameFilter, user)
  const userToConnect: { id: string; name?: string; image?: string } = {
    id: user?.id!,
    name: skipNameImageSet ? undefined : user!,
    image: skipNameImageSet ? undefined : getImage(user!),
  }

  useEffect(() => {
    getChatUsersAction()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <GrowingContainer className={'mdl:py-2'}>
      <div className="w-full m-0">
        <UnreadProvider>
          <ChatApplication
            token={token}
            userId={user?.id}
            chatUsers={chatUsers}
            apiKey={apiKey!}
            userToConnect={userToConnect}
            targetOrigin={targetOrigin!}
            channelListOptions={channelListOptions}
            usersAssessmentResponseByQuestion={usersAssessmentResponseByQuestion}
            getUsersAssessmentResponseByQuestionAction={getUsersAssessmentResponseByQuestionAction}
            chatUnreadMessageCountAction={chatUnreadMessageCountAction}
            chatUnreadMessageCount={chatUnreadMessageCount}
            saveUserFileAction={(streamMsgId: string, attachment: IChatAttachment) =>
              saveUserFileAction({
                userId: user?.id,
                streamMessageId: streamMsgId,
                name: attachment.title,
                url: attachment.url,
                type: attachment.fileType,
                size: attachment.fileSize,
                id: uuid(),
              })
            }
          />
        </UnreadProvider>
      </div>
    </GrowingContainer>
  )
}

export default Chat
