import React from 'react'

import { AppFC } from 'types'

import Button, { IButtonProps } from 'components/Common/Button'

type ISortButtonProps = Omit<IButtonProps, 'variant' | 'rightContent' | 'rightIcon' | 'label'>

const SortButton: AppFC<ISortButtonProps> = ({ onClick, ...rest }) => {
  return (
    <Button
      variant="secondary"
      onClick={onClick}
      className="!rounded-xl !font-primary !font-medium"
      rightIcon="sort"
      label="Sort"
      size="small"
      {...rest}
    />
  )
}

export default SortButton
