import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const PlayBookLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        className="xs:hidden md:block"
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <circle cx="20" cy="20" r="8" />
        <rect x="35" y="15" width="75" height="9" />
        <rect x="15" y="40" width="100" height="40" />
        <rect x="150" y="15" width="290" height="60" />
        <rect x="155" y="100" width="275" height="9" />
        <rect x="155" y="120" width="275" height="9" />
        <rect x="145" y="155" width="290" height="20" />
      </ContentLoader>

      <ContentLoader
        speed={2}
        className="xs:block md:hidden"
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <circle cx="160" cy="30" r="12" />
        <rect x="180" y="24" width="120" height="12" />
        <rect x="25" y="60" width="380" height="85" />
        <rect x="25" y="195" width="380" height="140" />
      </ContentLoader>
    </>
  )
}

export default PlayBookLoader
