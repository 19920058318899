import React from 'react'

function DropDownArrowIcon() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mr-[10px]"
    >
      <path
        d="M10.7254 15.2301C11.1531 15.6619 11.8477 15.6619 12.2754 15.2301L18.8446 8.59766C19.2723 8.16587 19.2723 7.46463 18.8446 7.03283C18.4169 6.60104 17.7224 6.60104 17.2947 7.03283L11.4987 12.8845L5.70272 7.03629C5.27504 6.60449 4.58048 6.60449 4.15279 7.03629C3.72511 7.46808 3.72511 8.16932 4.15279 8.60112L10.722 15.2335L10.7254 15.2301Z"
        fill="#5E06B9"
      />
    </svg>
  )
}

export default DropDownArrowIcon
