import React from 'react'

function ArrowRightIcon({ className }) {
  return (
    <svg
      className={className}
      width="14"
      height="27"
      viewBox="0 0 14 27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.8913 14.2732L2.48434 25.8357C2.05754 26.3099 1.32593 26.3484 0.851863 25.9216C0.351929 25.4684 0.361685 24.7371 0.766011 24.2869L10.4776 13.5L0.765867 2.71072C0.340945 2.23377 0.378523 1.50678 0.85114 1.07752C1.32593 0.651153 2.05725 0.689454 2.48434 1.16352L12.8906 12.661C13.2888 13.1676 13.2888 13.8324 12.8913 14.2732Z" />
    </svg>
  )
}

export default ArrowRightIcon
