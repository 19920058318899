import React from 'react'

function ServicesIcon({ className }) {
  return (
    <svg className={className} viewBox="-30 10 300 232" xmlns="http://www.w3.org/2000/svg">
      <path d="M68.875 50.75H163.125C163.896 50.7494 164.646 50.5032 165.267 50.0472C165.888 49.5912 166.348 48.9491 166.58 48.2141C166.811 47.4791 166.802 46.6895 166.555 45.9598C166.307 45.2301 165.833 44.5983 165.202 44.1561L130.5 19.8614V7.25H123.25V18.125H108.75V7.25H101.5V19.8614L66.7978 44.1489C66.1635 44.59 65.6868 45.2222 65.437 45.9533C65.1873 46.6843 65.1777 47.4761 65.4096 48.2131C65.6415 48.95 66.1027 49.5936 66.7261 50.05C67.3495 50.5063 68.1024 50.7516 68.875 50.75ZM106.267 25.375H125.733L151.626 43.5H80.3735L106.267 25.375Z" />
      <path d="M54.375 148.625H47.125C44.2407 148.625 41.4746 149.771 39.4352 151.81C37.3957 153.85 36.25 156.616 36.25 159.5V163.125C36.25 166.009 37.3957 168.775 39.4352 170.815C41.4746 172.854 44.2407 174 47.125 174H54.375C57.2592 174 60.0253 172.854 62.0647 170.815C64.1042 168.775 65.25 166.009 65.25 163.125V159.5C65.25 156.616 64.1042 153.85 62.0647 151.81C60.0253 149.771 57.2592 148.625 54.375 148.625ZM58 163.125C58 164.086 57.618 165.008 56.9382 165.688C56.2584 166.368 55.3364 166.75 54.375 166.75H47.125C46.1636 166.75 45.2415 166.368 44.5617 165.688C43.8819 165.008 43.5 164.086 43.5 163.125V159.5C43.5 158.539 43.8819 157.617 44.5617 156.937C45.2415 156.257 46.1636 155.875 47.125 155.875H54.375C55.3364 155.875 56.2584 156.257 56.9382 156.937C57.618 157.617 58 158.539 58 159.5V163.125Z" />
      <path d="M184.875 148.625H177.625C174.741 148.625 171.975 149.771 169.935 151.81C167.896 153.85 166.75 156.616 166.75 159.5V163.125C166.75 166.009 167.896 168.775 169.935 170.815C171.975 172.854 174.741 174 177.625 174H184.875C187.759 174 190.525 172.854 192.565 170.815C194.604 168.775 195.75 166.009 195.75 163.125V159.5C195.75 156.616 194.604 153.85 192.565 151.81C190.525 149.771 187.759 148.625 184.875 148.625ZM188.5 163.125C188.5 164.086 188.118 165.008 187.438 165.688C186.758 166.368 185.836 166.75 184.875 166.75H177.625C176.664 166.75 175.742 166.368 175.062 165.688C174.382 165.008 174 164.086 174 163.125V159.5C174 158.539 174.382 157.617 175.062 156.937C175.742 156.257 176.664 155.875 177.625 155.875H184.875C185.836 155.875 186.758 156.257 187.438 156.937C188.118 157.617 188.5 158.539 188.5 159.5V163.125Z" />
      <path d="M148.625 148.625H83.375C80.4907 148.625 77.7246 149.771 75.6852 151.81C73.6457 153.85 72.5 156.616 72.5 159.5V163.125C72.5 166.009 73.6457 168.775 75.6852 170.815C77.7246 172.854 80.4907 174 83.375 174H148.625C151.509 174 154.275 172.854 156.315 170.815C158.354 168.775 159.5 166.009 159.5 163.125V159.5C159.5 156.616 158.354 153.85 156.315 151.81C154.275 149.771 151.509 148.625 148.625 148.625ZM152.25 163.125C152.25 164.086 151.868 165.008 151.188 165.688C150.508 166.368 149.586 166.75 148.625 166.75H83.375C82.4136 166.75 81.4915 166.368 80.8117 165.688C80.1319 165.008 79.75 164.086 79.75 163.125V159.5C79.75 158.539 80.1319 157.617 80.8117 156.937C81.4915 156.257 82.4136 155.875 83.375 155.875H148.625C149.586 155.875 150.508 156.257 151.188 156.937C151.868 157.617 152.25 158.539 152.25 159.5V163.125Z" />
      <path d="M181.25 224.75H203C204.923 224.75 206.767 223.986 208.127 222.627C209.486 221.267 210.25 219.423 210.25 217.5V154.817C210.261 151.191 209.18 147.646 207.147 144.643C205.114 141.641 202.224 139.321 198.853 137.986L190.816 134.77L166.775 101.714C165.09 99.4052 162.885 97.5262 160.338 96.2293C157.791 94.9325 154.974 94.2544 152.116 94.25H79.8841C77.0259 94.2544 74.209 94.9325 71.662 96.2293C69.115 97.5262 66.9095 99.4052 65.2246 101.714L41.1836 134.77L33.147 137.986C29.776 139.321 26.8856 141.641 24.8526 144.643C22.8196 147.646 21.7384 151.191 21.75 154.817V217.5C21.75 219.423 22.5138 221.267 23.8734 222.627C25.2331 223.986 27.0771 224.75 29 224.75H50.75C52.6728 224.75 54.5168 223.986 55.8765 222.627C57.2361 221.267 58 219.423 58 217.5V203H174V217.5C174 219.423 174.764 221.267 176.123 222.627C177.483 223.986 179.327 224.75 181.25 224.75ZM203 217.5H181.25V203H199.375C200.06 202.996 200.744 202.927 201.416 202.793C201.633 202.754 201.836 202.681 202.05 202.627C202.366 202.547 202.695 202.489 203 202.38V217.5ZM71.0862 105.981C72.0972 104.595 73.4208 103.467 74.9494 102.688C76.478 101.91 78.1687 101.503 79.8841 101.5H152.116C153.831 101.503 155.522 101.91 157.051 102.688C158.579 103.467 159.903 104.595 160.914 105.981L181.38 134.125H50.6195L71.0862 105.981ZM29 154.817C28.9924 152.641 29.6408 150.515 30.8604 148.714C32.08 146.913 33.8142 145.521 35.8367 144.721L44.196 141.375H187.804L196.163 144.721C198.186 145.521 199.92 146.913 201.14 148.714C202.359 150.515 203.008 152.641 203 154.817V181.25H29V154.817ZM50.75 217.5H29V202.38C29.3045 202.489 29.6343 202.547 29.9533 202.627C30.1636 202.681 30.3666 202.754 30.5841 202.793C31.2563 202.927 31.9397 202.996 32.625 203H50.75V217.5ZM54.375 195.75H32.625C31.6636 195.75 30.7415 195.368 30.0617 194.688C29.3819 194.008 29 193.086 29 192.125V188.5H203V192.125C203 193.086 202.618 194.008 201.938 194.688C201.258 195.368 200.336 195.75 199.375 195.75H54.375Z" />
      <path d="M119.625 58H112.375V68.875H119.625V58Z" />
      <path d="M119.625 76.125H112.375V87H119.625V76.125Z" />
      <path d="M76.9498 55.7027L70.0585 64.1156L75.6671 68.7098L82.5584 60.2969L76.9498 55.7027Z" />
      <path d="M65.4643 69.7275L58.5714 78.139L64.1791 82.7343L71.072 74.3227L65.4643 69.7275Z" />
      <path d="M53.9797 83.7491L47.0869 92.1606L52.6945 96.7559L59.5874 88.3443L53.9797 83.7491Z" />
      <path d="M42.4938 97.7678L35.5986 106.182L41.2063 110.777L48.1015 102.363L42.4938 97.7678Z" />
      <path d="M94.3948 56.9847L91.3469 67.4238L98.3064 69.4557L101.354 59.0166L94.3948 56.9847Z" />
      <path d="M89.3162 74.3809L86.2634 84.8186L93.2219 86.8538L96.2747 76.4161L89.3162 74.3809Z" />
      <path d="M56.3774 54.5929L48.3831 61.9657L53.2958 67.2925L61.2901 59.9198L56.3774 54.5929Z" />
      <path d="M43.0546 66.8798L35.0604 74.2526L39.9731 79.5794L47.9673 72.2066L43.0546 66.8798Z" />
      <path d="M29.7343 79.173L21.7401 86.5458L26.6528 91.8726L34.647 84.4999L29.7343 79.173Z" />
      <path d="M16.4142 91.461L8.41998 98.8337L13.3327 104.161L21.327 96.7878L16.4142 91.461Z" />
      <path d="M155.054 56.4514L149.447 61.0417L156.336 69.4565L161.943 64.8662L155.054 56.4514Z" />
      <path d="M166.54 70.4719L160.931 75.0659L167.824 83.4817L173.433 78.8876L166.54 70.4719Z" />
      <path d="M178.021 84.4942L172.413 89.0889L179.305 97.5011L184.913 92.9065L178.021 84.4942Z" />
      <path d="M189.505 98.5134L183.897 103.108L190.789 111.52L196.397 106.926L189.505 98.5134Z" />
      <path d="M137.598 57.7737L130.638 59.8037L133.683 70.2437L140.643 68.2137L137.598 57.7737Z" />
      <path d="M142.682 75.1334L135.723 77.1677L138.775 87.6058L145.734 85.5715L142.682 75.1334Z" />
      <path d="M175.622 55.3389L170.709 60.6652L178.702 68.0389L183.615 62.7127L175.622 55.3389Z" />
      <path d="M188.947 67.6296L184.033 72.9552L192.025 80.3299L196.939 75.0043L188.947 67.6296Z" />
      <path d="M202.267 79.9181L197.353 85.2438L205.346 92.6185L210.26 87.2928L202.267 79.9181Z" />
      <path d="M215.589 92.2081L210.676 97.5343L218.669 104.908L223.582 99.5818L215.589 92.2081Z" />
    </svg>
  )
}

export default ServicesIcon
