import React from 'react'

function RobotErrorIcon({ className, id }) {
  return (
    <svg className={className} viewBox="5 0 310 283" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16100_139635)">
        <path
          d="M296.258 151.572C296.258 151.572 294.706 205.403 254.586 241.279C214.467 277.154 158.889 279.727 113.887 283.137C68.8856 286.546 34.3202 258.004 29.1121 214.87C23.904 171.736 58.2896 159.077 76.9442 109.975C95.5989 60.8734 93.9405 44.7299 133.414 14.5319C172.888 -15.6662 234.646 2.87933 267.007 54.6144C299.368 106.35 296.258 151.572 296.258 151.572Z"
          fill={`url(#paint0_linear_16100_139635-${id})`}
          id={id}
        />
        <path
          opacity="0.7"
          d="M296.258 151.572C296.258 151.572 294.706 205.403 254.586 241.279C214.467 277.154 158.889 279.727 113.887 283.137C68.8856 286.546 34.3202 258.004 29.1121 214.87C23.904 171.736 58.2896 159.077 76.9442 109.975C95.5989 60.8734 93.9405 44.7299 133.414 14.5319C172.888 -15.6662 234.646 2.87933 267.007 54.6144C299.368 106.35 296.258 151.572 296.258 151.572Z"
          fill="white"
        />
        <path
          d="M292.127 176.237H28.5977"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M291.074 210.65C290.542 210.652 290.017 210.501 289.551 210.212C289.084 209.923 288.691 209.506 288.409 209C288.126 208.494 287.963 207.915 287.936 207.319C287.908 206.723 288.016 206.129 288.251 205.592C288.858 204.231 288.977 202.66 288.585 201.203C288.193 199.745 287.318 198.511 286.139 197.752C281.437 194.38 270.502 193.843 266.672 193.91H266.619C265.785 193.919 264.983 193.557 264.387 192.903C263.792 192.249 263.453 191.356 263.445 190.423C263.438 189.489 263.761 188.59 264.345 187.923C264.929 187.256 265.725 186.877 266.559 186.868C268.164 186.831 282.383 186.704 289.523 191.821C296.562 196.872 295.47 205.033 293.905 208.651C293.648 209.249 293.247 209.753 292.746 210.107C292.246 210.46 291.666 210.649 291.074 210.65Z"
          fill="#263238"
        />
        <path
          d="M305.567 229.226L304.874 231.859L294.384 212.538L294.111 208.368L305.567 229.226Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M304.874 231.859L308.151 230.046L308.737 227.532L305.567 229.226L304.874 231.859Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M292.732 206.204L304.874 205.734L308.736 227.532L305.566 229.226L292.732 206.204Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M291.074 207.129L303.215 230.002L299.219 232.165L281.277 215.783L291.074 207.129Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M299.22 232.165L298.667 234.485L280.592 218.409L281.278 215.783L299.22 232.165Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M299.22 232.165L303.216 230.002L302.67 232.322L298.667 234.485L299.22 232.165Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M297.81 215.378C300.213 212.686 300.213 208.322 297.81 205.63C295.407 202.938 291.511 202.938 289.107 205.63C286.704 208.322 286.704 212.686 289.107 215.378C291.511 218.07 295.407 218.07 297.81 215.378Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M163.769 229.226L164.461 231.859L174.944 212.538L175.224 208.368L163.769 229.226Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M164.461 231.859L161.185 230.046L160.599 227.532L163.769 229.226L164.461 231.859Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M176.603 206.204L164.461 205.734L160.599 227.532L163.769 229.226L176.603 206.204Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M178.261 207.129L166.113 230.002L170.116 232.165L188.051 215.783L178.261 207.129Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M170.116 232.165L170.669 234.485L188.744 218.409L188.052 215.783L170.116 232.165Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M170.116 232.165L166.113 230.002L166.666 232.322L170.669 234.485L170.116 232.165Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M176.27 212.993C175.62 212.996 174.985 212.773 174.452 212.355C173.92 211.937 173.516 211.344 173.297 210.658C173.078 209.973 173.054 209.228 173.229 208.526C173.403 207.825 173.768 207.201 174.272 206.741C199.26 183.966 194.172 150.001 194.119 149.657C193.994 148.749 194.192 147.822 194.669 147.075C195.146 146.328 195.865 145.819 196.673 145.657C197.48 145.496 198.312 145.694 198.991 146.21C199.67 146.726 200.142 147.519 200.306 148.419C200.552 149.978 206.073 186.838 178.241 212.202C177.685 212.711 176.989 212.991 176.27 212.993Z"
          fill="#263238"
        />
        <path
          d="M212.774 142.154C213.857 134.638 209.295 127.561 202.585 126.347C195.874 125.134 189.556 130.244 188.473 137.76C187.39 145.277 191.952 152.354 198.662 153.567C205.373 154.781 211.691 149.671 212.774 142.154Z"
          fill="#ADADAD"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M180.223 215.375C182.626 212.684 182.626 208.319 180.223 205.627C177.82 202.935 173.924 202.935 171.52 205.627C169.117 208.319 169.117 212.684 171.52 215.375C173.924 218.067 177.82 218.067 180.223 215.375Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M233.579 196.73C244.367 196.73 253.112 187.592 253.112 176.319C253.112 165.047 244.367 155.909 233.579 155.909C222.79 155.909 214.045 165.047 214.045 176.319C214.045 187.592 222.79 196.73 233.579 196.73Z"
          fill="#ADADAD"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M223.402 167.599L225.227 175.573C225.766 177.547 226.909 179.244 228.455 180.367C230.001 181.49 231.85 181.967 233.679 181.713C235.505 181.457 237.192 180.488 238.443 178.976C239.694 177.464 240.429 175.506 240.519 173.447L240.878 165.405L223.402 167.599Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M261.911 127.471C260.068 127.501 258.231 127.22 256.463 126.636L256.969 124.972C257.056 124.972 265.8 128.158 274.705 120.862L275.63 122.287C271.675 125.624 266.863 127.442 261.911 127.471Z"
          fill="#263238"
        />
        <path
          d="M271.727 140.705C264.521 128.911 256.322 129.799 256.242 129.806L256.069 128.061C256.442 128.016 265.293 127.054 273.019 139.713L271.727 140.705Z"
          fill="#263238"
        />
        <path
          d="M269.902 149.038C260.578 145.823 254.977 131.918 254.737 131.328L256.176 130.582C256.229 130.724 261.73 144.346 270.368 147.322L269.902 149.038Z"
          fill="#263238"
        />
        <path
          d="M274.751 122.048L277.268 120.168"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M271.674 138.646L273.352 142.399"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M268.737 147.569C270.064 147.947 271.425 148.157 272.793 148.195"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M198.041 124.883L200.365 143.093L258.207 136.468L257.994 117.55L198.041 124.883Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M200.365 143.093L210.708 172.41L255.25 167.442L258.207 136.468L200.365 143.093Z"
          fill="#E5E5E5"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M214.318 150.754L218.38 164.182C218.67 165.151 219.25 165.977 220.023 166.521C220.795 167.065 221.711 167.293 222.616 167.166L240.458 164.988C241.359 164.865 242.195 164.399 242.825 163.669C243.456 162.938 243.843 161.987 243.922 160.974L245.027 146.852C245.064 146.39 245.009 145.924 244.866 145.489C244.722 145.053 244.494 144.659 244.197 144.334C243.901 144.01 243.543 143.764 243.151 143.614C242.759 143.463 242.342 143.413 241.93 143.466L216.669 146.629C216.252 146.68 215.851 146.835 215.495 147.083C215.139 147.331 214.837 147.665 214.611 148.061C214.386 148.457 214.242 148.904 214.192 149.37C214.141 149.836 214.184 150.309 214.318 150.754Z"
          fill="#263238"
        />
        <path
          d="M214.318 158.132L221.684 157.401L224.022 150.292L229.523 161.37L232.32 153.111L233.719 155.73L247.066 153.947"
          stroke="white"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M214.318 150.754L218.38 164.182C218.67 165.151 219.25 165.977 220.023 166.521C220.795 167.065 221.711 167.293 222.616 167.166L240.458 164.988C241.359 164.865 242.195 164.399 242.825 163.669C243.456 162.938 243.843 161.987 243.922 160.974L245.027 146.852C245.064 146.39 245.009 145.924 244.866 145.489C244.722 145.053 244.494 144.659 244.197 144.334C243.901 144.01 243.543 143.764 243.151 143.614C242.759 143.463 242.342 143.413 241.93 143.466L216.669 146.629C216.252 146.68 215.851 146.835 215.495 147.083C215.139 147.331 214.837 147.665 214.611 148.061C214.386 148.457 214.242 148.904 214.192 149.37C214.141 149.836 214.184 150.309 214.318 150.754V150.754Z"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M251.495 148.65C253.282 148.65 254.731 147.027 254.731 145.025C254.731 143.022 253.282 141.399 251.495 141.399C249.707 141.399 248.258 143.022 248.258 145.025C248.258 147.027 249.707 148.65 251.495 148.65Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M254.132 156.364C254.132 157.037 253.953 157.694 253.62 158.254C253.286 158.813 252.812 159.249 252.257 159.507C251.702 159.764 251.091 159.832 250.502 159.7C249.913 159.569 249.372 159.245 248.947 158.769C248.522 158.294 248.233 157.687 248.116 157.028C247.999 156.368 248.059 155.684 248.289 155.062C248.519 154.441 248.908 153.909 249.407 153.535C249.907 153.162 250.494 152.962 251.095 152.962C251.9 152.962 252.672 153.321 253.242 153.959C253.812 154.596 254.132 155.462 254.132 156.364Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M212.087 148.964C212.272 149.65 212.27 150.382 212.083 151.068C211.897 151.754 211.532 152.362 211.037 152.816C210.542 153.27 209.937 153.549 209.3 153.618C208.663 153.687 208.022 153.543 207.458 153.205C206.894 152.866 206.432 152.347 206.131 151.715C205.83 151.082 205.703 150.363 205.767 149.65C205.83 148.937 206.081 148.26 206.488 147.707C206.894 147.153 207.438 146.747 208.051 146.539C208.458 146.4 208.886 146.353 209.309 146.4C209.733 146.446 210.144 146.586 210.519 146.812C210.894 147.037 211.225 147.343 211.495 147.712C211.764 148.082 211.965 148.507 212.087 148.964Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M214.07 162.864C214.984 161.288 214.584 159.181 213.177 158.158C211.771 157.135 209.89 157.582 208.976 159.158C208.063 160.734 208.463 162.841 209.869 163.864C211.276 164.887 213.157 164.439 214.07 162.864Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M221.844 200.124C226.596 200.124 230.449 195.963 230.449 190.829C230.449 185.696 226.596 181.534 221.844 181.534C217.092 181.534 213.239 185.696 213.239 190.829C213.239 195.963 217.092 200.124 221.844 200.124Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M209.189 211.687L217.068 191.874C217.406 191.022 218.011 190.34 218.769 189.956C219.527 189.573 220.384 189.514 221.177 189.792C221.996 190.073 222.689 190.691 223.118 191.522C223.547 192.352 223.679 193.333 223.488 194.268L218.3 220.319L209.189 211.687Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M206.559 181.34L223.762 226.689L203.116 229.867C203.116 229.867 190.768 215.805 182.47 189.054L206.559 181.34Z"
          fill="#ADADAD"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M206.56 181.303L207.365 176.998L224.575 223.25L223.762 226.652L206.56 181.303Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M207.365 176.998L183.888 184.928L182.47 189.016L206.559 181.303L207.365 176.998Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M184.088 193.321L207.771 185.16"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M185.906 198.08L209.796 190.829"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M187.731 203.071L211.821 195.82"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M190.361 209.196L213.844 201.482"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M192.853 214.06L216.276 207.152"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.424 218.506L218.301 212.366"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M198.387 222.78L219.712 217.133"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M200.485 226.272L221.671 222.348"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M244.76 200.124C249.512 200.124 253.365 195.963 253.365 190.829C253.365 185.696 249.512 181.534 244.76 181.534C240.008 181.534 236.155 185.696 236.155 190.829C236.155 195.963 240.008 200.124 244.76 200.124Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M257.408 211.687L249.53 191.874C249.192 191.022 248.587 190.34 247.829 189.956C247.071 189.573 246.214 189.514 245.42 189.792C244.602 190.073 243.909 190.692 243.482 191.522C243.054 192.353 242.923 193.334 243.116 194.268L248.304 220.319L257.408 211.687Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M260.045 181.34L242.836 226.689L263.482 229.867C263.482 229.867 275.83 215.805 284.128 189.054L260.045 181.34Z"
          fill="#ADADAD"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M260.045 181.303L259.233 176.998L242.023 223.25L242.836 226.652L260.045 181.303Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M259.233 176.998L282.717 184.928L284.135 189.016L260.046 181.303L259.233 176.998Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M282.51 193.321L258.827 185.16"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M280.691 198.08L256.802 190.829"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M278.867 203.071L254.777 195.82"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M276.236 209.196L252.753 201.482"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M273.745 214.06L250.328 207.152"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M271.174 218.506L248.304 212.366"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M268.218 222.78L246.886 217.133"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M266.113 226.272L244.928 222.348"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M275.73 142.555L277.968 143.652"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M274.331 143.495L276.569 147.412"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M273.072 143.652L272.652 146.159"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M278.527 127.367H283.975"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M274.331 127.68L282.157 130.187"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M277.269 124.398L282.297 123.615"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M259.233 143.809L260.632 148.352"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M261.051 143.495L264.96 150.702"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M262.449 139.273L266.219 140.683"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M283.276 136.923L282.017 138.46L282.93 140.28L281.231 139.407L279.973 140.944L280.186 138.87L278.494 137.997L280.319 137.587L280.539 135.513L281.451 137.333L283.276 136.923Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="0.540812"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M285.234 116.252L283.969 117.788L284.888 119.609L283.189 118.736L281.931 120.272L282.144 118.199L280.452 117.326L282.277 116.915L282.49 114.842L283.409 116.662L285.234 116.252Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="0.540812"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M264.821 152.425L263.562 153.962L264.475 155.782L262.783 154.909L261.518 156.446L261.731 154.372L260.039 153.499L261.864 153.089L262.084 151.015L262.996 152.835L264.821 152.425Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="0.540812"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M204.481 118.266C209.671 118.266 213.879 113.606 213.879 107.859C213.879 102.112 209.671 97.4523 204.481 97.4523C199.291 97.4523 195.084 102.112 195.084 107.859C195.084 113.606 199.291 118.266 204.481 118.266Z"
          fill="#ADADAD"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M245.433 115.423C250.623 115.423 254.831 110.764 254.831 105.017C254.831 99.2693 250.623 94.61 245.433 94.61C240.243 94.61 236.036 99.2693 236.036 105.017C236.036 110.764 240.243 115.423 245.433 115.423Z"
          fill="#ADADAD"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M226.538 139.542C233.067 139.542 238.36 133.877 238.36 126.889C238.36 119.902 233.067 114.237 226.538 114.237C220.009 114.237 214.717 119.902 214.717 126.889C214.717 133.877 220.009 139.542 226.538 139.542Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M217.888 120.392L219.519 128.419C220.007 130.408 221.106 132.141 222.623 133.311C224.141 134.48 225.978 135.012 227.811 134.812C229.643 134.613 231.354 133.697 232.643 132.224C233.931 130.752 234.716 128.818 234.857 126.763L235.41 118.736L217.888 120.392Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M207.118 86.5756L202.689 106.442L246.805 102.421L240.265 83.5021L207.118 86.5756Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M202.689 106.442L210.495 128.195L245.12 124.413L246.805 102.421L202.689 106.442Z"
          fill="#E5E5E5"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M222.53 115.312C222.411 118.4 220 120.832 217.116 121.078C214.232 121.324 211.408 119.303 210.789 116.244C210.123 112.738 212.461 109.291 216.157 109.03C219.853 108.769 222.657 111.79 222.53 115.312Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M220.325 115.483C220.289 116.459 219.926 117.385 219.31 118.076C218.693 118.767 217.866 119.173 216.995 119.213C216.11 119.32 215.221 119.06 214.497 118.481C213.772 117.902 213.263 117.045 213.065 116.072C212.739 113.954 214.224 111.962 216.395 111.798C216.872 111.738 217.353 111.787 217.812 111.942C218.271 112.097 218.697 112.355 219.064 112.699C219.431 113.044 219.732 113.468 219.949 113.946C220.166 114.425 220.294 114.948 220.325 115.483Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M242.157 113.185C242.044 116.274 239.633 118.706 236.749 118.952C233.865 119.198 231.035 117.177 230.422 114.111C229.723 110.612 232.094 107.165 235.79 106.897C239.486 106.628 242.29 109.664 242.157 113.185Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M239.953 113.357C239.918 114.334 239.556 115.26 238.939 115.952C238.322 116.643 237.495 117.048 236.623 117.087C235.737 117.192 234.848 116.93 234.124 116.35C233.4 115.769 232.891 114.912 232.693 113.939C232.367 111.82 233.852 109.836 236.023 109.672C236.5 109.61 236.982 109.658 237.442 109.812C237.901 109.966 238.328 110.223 238.696 110.568C239.063 110.913 239.364 111.338 239.581 111.817C239.797 112.297 239.923 112.821 239.953 113.357Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M222.743 124.278C222.337 124.283 221.946 124.112 221.65 123.8C221.355 123.489 221.178 123.062 221.157 122.608C221.137 122.154 221.274 121.708 221.539 121.365C221.805 121.021 222.179 120.806 222.583 120.765L231.028 119.817C231.443 119.771 231.858 119.911 232.181 120.207C232.504 120.503 232.708 120.93 232.75 121.395C232.791 121.86 232.666 122.325 232.402 122.686C232.138 123.048 231.756 123.277 231.341 123.324L222.93 124.271L222.743 124.278Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M207.931 96.3482C209.693 96.3482 211.121 94.7484 211.121 92.7749C211.121 90.8014 209.693 89.2016 207.931 89.2016C206.169 89.2016 204.741 90.8014 204.741 92.7749C204.741 94.7484 206.169 96.3482 207.931 96.3482Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M205.96 93.7447C207.465 93.7447 208.684 92.3787 208.684 90.6936C208.684 89.0085 207.465 87.6424 205.96 87.6424C204.456 87.6424 203.236 89.0085 203.236 90.6936C203.236 92.3787 204.456 93.7447 205.96 93.7447Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M205.434 87.4485C205.432 87.9232 205.305 88.3869 205.069 88.781C204.832 89.175 204.497 89.4817 204.105 89.6624C203.713 89.8431 203.282 89.8896 202.867 89.7961C202.451 89.7025 202.07 89.4732 201.77 89.1369C201.471 88.8007 201.267 88.3726 201.185 87.9069C201.103 87.4411 201.146 86.9585 201.308 86.52C201.471 86.0815 201.746 85.7068 202.098 85.4432C202.451 85.1796 202.865 85.0389 203.289 85.0389C203.571 85.0389 203.851 85.1013 204.111 85.2224C204.372 85.3436 204.609 85.5212 204.808 85.7451C205.007 85.9689 205.165 86.2346 205.272 86.5269C205.38 86.8192 205.434 87.1324 205.434 87.4485Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M202.77 86.6055L197.895 81.5327"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M239.259 93.879C241.021 93.879 242.45 92.2791 242.45 90.3056C242.45 88.3321 241.021 86.7323 239.259 86.7323C237.498 86.7323 236.069 88.3321 236.069 90.3056C236.069 92.2791 237.498 93.879 239.259 93.879Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M241.231 91.2754C242.735 91.2754 243.955 89.9094 243.955 88.2243C243.955 86.5392 242.735 85.1732 241.231 85.1732C239.726 85.1732 238.507 86.5392 238.507 88.2243C238.507 89.9094 239.726 91.2754 241.231 91.2754Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M241.757 84.9792C241.758 85.454 241.885 85.9176 242.122 86.3117C242.358 86.7057 242.693 87.0125 243.085 87.1931C243.477 87.3738 243.908 87.4203 244.324 87.3268C244.739 87.2332 245.121 87.0039 245.42 86.6676C245.719 86.3314 245.923 85.9034 246.005 85.4376C246.087 84.9719 246.045 84.4893 245.882 84.0508C245.72 83.6123 245.445 83.2375 245.092 82.9739C244.74 82.7103 244.325 82.5696 243.901 82.5696C243.619 82.5696 243.34 82.632 243.079 82.7532C242.819 82.8743 242.582 83.052 242.383 83.2758C242.183 83.4997 242.026 83.7653 241.918 84.0576C241.811 84.3499 241.756 84.6631 241.757 84.9792Z"
          fill="#7600ED"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M244.422 84.1288L249.297 79.0634"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M237.281 215.343C237.281 217.066 236.262 218.461 235.003 218.461C233.745 218.461 232.732 217.066 232.732 215.343C232.732 213.619 233.751 212.224 235.003 212.224C236.256 212.224 237.281 213.619 237.281 215.343Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M241.784 217.954H241.611L235.291 216.917C234.992 216.854 234.726 216.664 234.549 216.386C234.373 216.109 234.299 215.766 234.342 215.429C234.386 215.092 234.545 214.786 234.785 214.577C235.025 214.368 235.328 214.271 235.63 214.306L241.951 215.335C242.257 215.368 242.539 215.534 242.738 215.797C242.936 216.06 243.035 216.399 243.013 216.743C242.992 217.086 242.851 217.407 242.621 217.636C242.392 217.865 242.091 217.984 241.784 217.969V217.954Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M153.453 229.92C152.627 228.465 152.787 226.674 153.879 225.928C154.971 225.182 156.463 225.742 157.289 227.189C158.115 228.636 157.921 230.434 156.856 231.188C155.79 231.941 154.272 231.374 153.453 229.92Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M148.397 230.419C148.446 230.385 148.497 230.355 148.55 230.33L154.385 227.413C154.667 227.289 154.982 227.291 155.262 227.42C155.543 227.549 155.769 227.794 155.893 228.104C156.016 228.415 156.028 228.767 155.926 229.087C155.824 229.407 155.615 229.67 155.344 229.823L149.51 232.739C149.238 232.874 148.93 232.89 148.649 232.782C148.368 232.674 148.133 232.451 147.993 232.157C147.852 231.864 147.816 231.522 147.891 231.199C147.966 230.877 148.147 230.598 148.397 230.419Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M304.388 196.499C303.562 195.044 303.755 193.261 304.814 192.508C305.873 191.754 307.398 192.321 308.224 193.768C309.05 195.216 308.857 197.013 307.798 197.767C306.739 198.52 305.207 197.953 304.388 196.499Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M299.34 197.006L299.486 196.917L305.32 194C305.463 193.92 305.618 193.873 305.777 193.862C305.936 193.852 306.095 193.877 306.245 193.937C306.395 193.997 306.533 194.091 306.649 194.212C306.766 194.333 306.86 194.48 306.925 194.643C306.99 194.806 307.024 194.982 307.027 195.16C307.029 195.338 306.999 195.515 306.939 195.68C306.879 195.846 306.789 195.995 306.676 196.12C306.562 196.246 306.428 196.344 306.279 196.409L300.452 199.326C300.18 199.461 299.873 199.476 299.592 199.368C299.31 199.26 299.076 199.037 298.935 198.744C298.795 198.45 298.758 198.108 298.833 197.786C298.908 197.464 299.089 197.185 299.34 197.006Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M191.747 231.322C189.083 231.322 186.938 232.754 186.938 234.515C186.938 236.275 189.09 237.708 191.747 237.708C194.404 237.708 196.562 236.275 196.562 234.515C196.562 232.754 194.404 231.322 191.747 231.322ZM191.747 236.164C190.375 236.164 189.263 235.418 189.263 234.515C189.263 233.612 190.375 232.866 191.747 232.866C193.119 232.866 194.238 233.612 194.238 234.515C194.238 235.418 193.126 236.164 191.747 236.164Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          stroke-miterlimit="10"
        />
        <path
          d="M235.091 231.837L231.808 230.345L228.518 231.837L227.705 235.119L229.983 237.753H233.632L235.904 235.119L235.091 231.837ZM232.733 236.029H230.882L229.723 234.694L230.136 233.03L231.808 232.284L233.473 233.03L233.886 234.694L232.733 236.029Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M297.401 184.175L294.111 182.683L290.821 184.175L290.009 187.465L292.287 190.098H295.936L298.214 187.465L297.401 184.175ZM295.037 188.375H293.186L292.033 187.039L292.44 185.368L294.111 184.622L295.783 185.368L296.196 187.039L295.037 188.375Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M257.074 239.327C256.981 239.327 256.889 239.309 256.801 239.274C256.354 239.063 255.977 238.7 255.723 238.237C255.356 237.603 255.057 236.477 255.636 234.731C255.839 234.093 256.179 233.52 256.626 233.063C257.073 232.607 257.614 232.28 258.2 232.113C258.952 231.909 259.737 231.903 260.491 232.096C261.246 232.289 261.95 232.675 262.549 233.224C262.629 233.297 262.696 233.387 262.745 233.489C262.794 233.591 262.824 233.703 262.834 233.818C262.844 233.934 262.833 234.05 262.802 234.161C262.772 234.271 262.722 234.374 262.656 234.463C262.523 234.643 262.332 234.756 262.125 234.779C261.918 234.801 261.711 234.73 261.55 234.582C261.141 234.217 260.664 233.96 260.155 233.831C259.645 233.702 259.115 233.703 258.606 233.836C258.262 233.935 257.946 234.128 257.684 234.398C257.423 234.667 257.225 235.005 257.108 235.38C256.515 237.133 257.228 237.618 257.374 237.663C257.562 237.757 257.71 237.927 257.788 238.14C257.866 238.352 257.868 238.59 257.794 238.804C257.733 238.961 257.633 239.095 257.505 239.187C257.377 239.28 257.227 239.329 257.074 239.327Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M273.312 242.564C273.063 242.565 272.816 242.523 272.58 242.437L259.799 237.775C259.497 237.675 259.216 237.508 258.972 237.284C258.728 237.061 258.527 236.784 258.381 236.472C258.234 236.16 258.145 235.818 258.118 235.466C258.091 235.115 258.128 234.761 258.225 234.425C258.323 234.09 258.479 233.779 258.685 233.513C258.892 233.246 259.144 233.028 259.426 232.873C259.709 232.717 260.017 232.626 260.331 232.606C260.646 232.586 260.961 232.636 261.258 232.754L274.045 237.417C274.576 237.614 275.027 238.016 275.317 238.552C275.608 239.087 275.719 239.721 275.631 240.339C275.543 240.958 275.261 241.521 274.836 241.929C274.412 242.337 273.871 242.562 273.312 242.564Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M259.413 237.656C259.413 237.656 259.919 234.015 263.982 233.926"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M262.15 238.446C262.15 238.446 262.663 234.813 266.719 234.716"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M264.588 239.468C264.588 239.468 265.094 235.835 269.15 235.738"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M267.125 240.274C267.125 240.274 267.631 236.641 271.687 236.544"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M269.662 241.289C269.662 241.289 270.168 237.656 274.224 237.559"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M273.306 242.512C273.215 242.51 273.124 242.49 273.04 242.452C272.59 242.246 272.21 241.886 271.954 241.423C271.588 240.789 271.288 239.662 271.867 237.909C272.072 237.272 272.413 236.7 272.86 236.244C273.307 235.788 273.846 235.461 274.431 235.291C275.184 235.088 275.969 235.084 276.723 235.278C277.478 235.472 278.182 235.859 278.78 236.41C278.861 236.483 278.927 236.573 278.976 236.675C279.025 236.777 279.055 236.889 279.065 237.004C279.075 237.119 279.065 237.235 279.034 237.346C279.003 237.457 278.953 237.559 278.887 237.648C278.754 237.828 278.564 237.942 278.356 237.964C278.149 237.986 277.942 237.916 277.781 237.767C277.373 237.402 276.896 237.145 276.386 237.016C275.876 236.886 275.347 236.888 274.838 237.021C274.494 237.118 274.177 237.309 273.915 237.578C273.653 237.846 273.456 238.183 273.339 238.558C272.746 240.319 273.459 240.796 273.606 240.841C273.771 240.922 273.907 241.064 273.99 241.243C274.074 241.422 274.101 241.628 274.066 241.826C274.031 242.025 273.936 242.204 273.798 242.334C273.66 242.464 273.486 242.537 273.306 242.542V242.512Z"
          fill="white"
          stroke="#263238"
          strokeWidth="1.08162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.0351 84.728C85.5951 84.728 82.7471 87.528 82.7471 90.984C82.7471 94.456 85.5951 97.208 89.0351 97.208C92.4751 97.208 95.3231 94.456 95.3231 90.984C95.3231 87.528 92.4751 84.728 89.0351 84.728ZM89.0351 85.976C91.8031 85.976 93.9791 88.232 93.9791 90.968C93.9791 93.688 91.7711 95.96 89.0351 95.96C86.2991 95.96 84.0911 93.688 84.0911 90.968C84.0911 88.232 86.2671 85.976 89.0351 85.976Z"
          fill="#111827"
        />
        <path
          d="M102.832 84.728C99.3919 84.728 96.5439 87.528 96.5439 90.984C96.5439 94.456 99.3919 97.208 102.832 97.208C106.272 97.208 109.12 94.456 109.12 90.984C109.12 87.528 106.272 84.728 102.832 84.728ZM102.832 85.976C105.6 85.976 107.776 88.232 107.776 90.968C107.776 93.688 105.568 95.96 102.832 95.96C100.096 95.96 97.8879 93.688 97.8879 90.968C97.8879 88.232 100.064 85.976 102.832 85.976Z"
          fill="#111827"
        />
        <path
          d="M112.293 91.72H113.269C114.277 91.72 115.317 91.592 116.133 90.92C116.885 90.296 117.285 89.304 117.285 88.328C117.285 87.256 116.821 86.2 115.925 85.576C115.045 84.968 113.973 84.936 112.933 84.936H110.949V97H112.293V91.72ZM112.293 86.184H112.789C114.357 86.184 115.941 86.328 115.941 88.296C115.941 90.024 114.677 90.472 113.189 90.472H112.293V86.184Z"
          fill="#111827"
        />
        <path
          d="M125.54 86.6C124.9 85.416 123.764 84.728 122.42 84.728C120.628 84.728 119.044 85.928 119.044 87.8C119.044 89.528 120.26 90.248 121.668 90.888L122.404 91.208C123.524 91.72 124.628 92.2 124.628 93.608C124.628 94.968 123.444 95.96 122.148 95.96C120.852 95.96 119.908 94.952 119.732 93.72L118.42 94.088C118.804 95.96 120.244 97.208 122.18 97.208C124.276 97.208 125.972 95.592 125.972 93.48C125.972 91.56 124.644 90.776 123.076 90.088L122.276 89.736C121.46 89.368 120.388 88.888 120.388 87.848C120.388 86.744 121.316 85.976 122.388 85.976C123.412 85.976 123.988 86.456 124.468 87.288L125.54 86.6Z"
          fill="#111827"
        />
        <path
          d="M129.421 95.416C128.925 95.416 128.525 95.816 128.525 96.296C128.525 96.792 128.925 97.208 129.421 97.208C129.917 97.208 130.317 96.792 130.317 96.296C130.317 95.816 129.917 95.416 129.421 95.416ZM128.765 94.552H130.077V84.936H128.765V94.552Z"
          fill="#111827"
        />
        <path
          d="M70.2161 197.026H71.1921C72.2001 197.026 73.2401 196.898 74.0561 196.226C74.8081 195.602 75.2081 194.61 75.2081 193.634C75.2081 192.562 74.7441 191.506 73.8481 190.882C72.9681 190.274 71.8961 190.242 70.8561 190.242H68.8721V202.306H70.2161V197.026ZM70.2161 191.49H70.7121C72.2801 191.49 73.8641 191.634 73.8641 193.602C73.8641 195.33 72.6001 195.778 71.1121 195.778H70.2161V191.49Z"
          fill="#111827"
        />
        <path
          d="M82.5977 198.882L84.0217 202.306H85.5097L80.0857 189.666L74.5177 202.306H75.9897L77.4457 198.882H82.5977ZM82.0697 197.634H77.9897L80.0537 192.802L82.0697 197.634Z"
          fill="#111827"
        />
        <path
          d="M93.0172 196.242V197.49H96.6012C96.6012 199.73 94.3932 201.266 92.2972 201.266C91.0332 201.266 89.7532 200.61 88.8732 199.73C87.9772 198.818 87.4652 197.602 87.4652 196.322C87.4652 193.602 89.6412 191.282 92.3932 191.282C94.1692 191.282 95.5292 192.13 96.5372 193.554L97.5132 192.642C96.2332 190.978 94.5212 190.034 92.4092 190.034C88.9372 190.034 86.1212 192.85 86.1212 196.306C86.1212 199.698 88.9372 202.514 92.3292 202.514C94.0092 202.514 95.6892 201.778 96.7932 200.498C97.8972 199.218 98.0732 197.874 98.0412 196.242H93.0172Z"
          fill="#111827"
        />
        <path
          d="M99.9502 202.306H106.206V201.058H101.294V196.322H106.062V195.074H101.294V191.49H106.206V190.242H99.9502V202.306Z"
          fill="#111827"
        />
        <path
          d="M113.499 202.306H114.843V193.01L124.251 202.85V190.242H122.907V199.586L113.499 189.73V202.306Z"
          fill="#111827"
        />
        <path
          d="M132.443 190.034C129.003 190.034 126.155 192.834 126.155 196.29C126.155 199.762 129.003 202.514 132.443 202.514C135.883 202.514 138.731 199.762 138.731 196.29C138.731 192.834 135.883 190.034 132.443 190.034ZM132.443 191.282C135.211 191.282 137.387 193.538 137.387 196.274C137.387 198.994 135.179 201.266 132.443 201.266C129.707 201.266 127.499 198.994 127.499 196.274C127.499 193.538 129.675 191.282 132.443 191.282Z"
          fill="#111827"
        />
        <path
          d="M143.76 191.49H146.704V190.242H139.504V191.49H142.416V202.306H143.76V191.49Z"
          fill="#111827"
        />
        <path
          d="M85.6726 210.49V209.242H80.1846V221.306H81.5286V215.322H85.5446V214.074H81.5286V210.49H85.6726Z"
          fill="#111827"
        />
        <path
          d="M93.5052 209.034C90.0652 209.034 87.2172 211.834 87.2172 215.29C87.2172 218.762 90.0652 221.514 93.5052 221.514C96.9452 221.514 99.7932 218.762 99.7932 215.29C99.7932 211.834 96.9452 209.034 93.5052 209.034ZM93.5052 210.282C96.2732 210.282 98.4492 212.538 98.4492 215.274C98.4492 217.994 96.2412 220.266 93.5052 220.266C90.7692 220.266 88.5612 217.994 88.5612 215.274C88.5612 212.538 90.7372 210.282 93.5052 210.282Z"
          fill="#111827"
        />
        <path
          d="M102.934 209.242H101.59V216.794C101.59 218.17 101.718 219.274 102.774 220.282C103.59 221.082 104.758 221.514 105.894 221.514C107.11 221.514 108.31 221.034 109.142 220.138C110.102 219.146 110.198 218.106 110.198 216.794V209.242H108.854V216.426C108.854 217.37 108.902 218.426 108.262 219.194C107.702 219.866 106.758 220.266 105.894 220.266C105.014 220.266 103.99 219.85 103.462 219.114C102.902 218.346 102.934 217.322 102.934 216.426V209.242Z"
          fill="#111827"
        />
        <path
          d="M112.655 221.306H113.999V212.01L123.407 221.85V209.242H122.063V218.586L112.655 208.73V221.306Z"
          fill="#111827"
        />
        <path
          d="M125.919 221.306H128.159C130.047 221.306 131.487 221.066 132.927 219.786C134.255 218.602 134.895 217.05 134.895 215.29C134.895 213.498 134.271 211.882 132.895 210.698C131.471 209.482 129.999 209.242 128.191 209.242H125.919V221.306ZM127.263 220.058V210.49H128.239C129.663 210.49 130.879 210.682 131.983 211.658C133.023 212.57 133.551 213.898 133.551 215.274C133.551 216.618 133.039 217.882 132.047 218.81C130.943 219.85 129.711 220.058 128.239 220.058H127.263Z"
          fill="#111827"
        />
        <path
          d="M39.4717 154.251V145.11C39.4717 144.45 39.6621 143.777 40.043 143.091L56.5723 112.432C56.8262 111.924 57.207 111.671 57.7148 111.671H76.7578C77.0625 111.671 77.3164 111.772 77.5195 111.975C77.748 112.178 77.8623 112.42 77.8623 112.699V142.787H80.1855C80.4902 142.787 80.7568 142.901 80.9854 143.13C81.2393 143.333 81.3662 143.587 81.3662 143.891V154.974C81.3662 155.304 81.2393 155.584 80.9854 155.812C80.7568 156.015 80.4902 156.117 80.1855 156.117H77.8623V164.305C77.8623 164.61 77.748 164.889 77.5195 165.143C77.3164 165.372 77.0625 165.486 76.7578 165.486H62.9707C62.6406 165.486 62.3613 165.372 62.1328 165.143C61.9043 164.889 61.79 164.61 61.79 164.305V156.117H41.1855C40.6523 156.117 40.2334 155.952 39.9287 155.622C39.624 155.266 39.4717 154.809 39.4717 154.251ZM54.8584 142.787H61.79V127.514L54.8584 142.787Z"
          fill="#111827"
        />
        <path
          d="M88.9072 118.221C84.2607 123.35 81.9375 130.13 81.9375 138.559C81.9375 146.989 84.2607 153.781 88.9072 158.935C93.5537 164.09 99.6729 166.667 107.265 166.667C114.831 166.667 120.938 164.09 125.584 158.935C130.23 153.756 132.554 146.964 132.554 138.559C132.554 130.155 130.23 123.376 125.584 118.221C120.938 113.067 114.831 110.49 107.265 110.49C99.6729 110.49 93.5537 113.067 88.9072 118.221ZM100.866 148.766C99.2666 146.176 98.4668 142.774 98.4668 138.559C98.4668 134.344 99.2666 130.955 100.866 128.39C102.491 125.826 104.624 124.544 107.265 124.544C109.88 124.544 111.987 125.839 113.587 128.428C115.212 130.993 116.024 134.37 116.024 138.559C116.024 142.774 115.212 146.176 113.587 148.766C111.987 151.331 109.88 152.613 107.265 152.613C104.624 152.613 102.491 151.331 100.866 148.766Z"
          fill="#111827"
        />
        <path
          d="M134.306 154.251V145.11C134.306 144.45 134.496 143.777 134.877 143.091L151.406 112.432C151.66 111.924 152.041 111.671 152.549 111.671H171.592C171.896 111.671 172.15 111.772 172.354 111.975C172.582 112.178 172.696 112.42 172.696 112.699V142.787H175.02C175.324 142.787 175.591 142.901 175.819 143.13C176.073 143.333 176.2 143.587 176.2 143.891V154.974C176.2 155.304 176.073 155.584 175.819 155.812C175.591 156.015 175.324 156.117 175.02 156.117H172.696V164.305C172.696 164.61 172.582 164.889 172.354 165.143C172.15 165.372 171.896 165.486 171.592 165.486H157.805C157.475 165.486 157.195 165.372 156.967 165.143C156.738 164.889 156.624 164.61 156.624 164.305V156.117H136.02C135.486 156.117 135.067 155.952 134.763 155.622C134.458 155.266 134.306 154.809 134.306 154.251ZM149.692 142.787H156.624V127.514L149.692 142.787Z"
          fill="#111827"
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_16100_139635-${id}`}
          x1="162.46"
          y1="0"
          x2="162.46"
          y2="283.412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#52B4F9" />
          <stop offset="1" stopColor="#77FADB" />
        </linearGradient>
        <clipPath id="clip0_16100_139635">
          <rect width="333" height="450" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
RobotErrorIcon.defaultProps = {
  className: '',
  id: '',
}

export default RobotErrorIcon
