import React, { useState, useEffect, useMemo } from 'react'

import { ampli } from 'ampli'
import { API_TYPES, FORM, USER_ROLES } from 'config'
import { RootState } from 'App'
import { USER_STATUS } from 'config'
import { useSelector } from 'react-redux'
import { isMobileOnly } from 'mobile-device-detect'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'

import {
  AssignOwnerToAdvisorContainer,
  AssignOwnerToAdvisorMobileViewContainer,
  EditBusinessProfileContainer,
  EditUserProfileContainer,
} from 'pages/Admin/container/UserManagement'
import Button from 'components/Common/Button'
import ModalBox from 'components/Common/ModalBox'
import { replaceTerm } from 'utils/helper'
import {
  AdvisorAssignmentWrapper,
  CancelButtonWrapper,
  EditAccountWrapper,
  FooterWrapper,
  EditAccountFormWrapper,
  HeaderWrapper,
  HorizontalBreak,
  LabelWrapper,
  SaveButtonWrapper,
  EditAccountBgColorWrapper,
  BottomHrWrapper,
} from 'components/Admin/UserManagement/style'
import SelectField from 'components/Common/SelectField'
import ErrorMessage from 'components/Common/ErrorMessage'
import { SelectFieldWrapper } from 'components/Common/StyledComponents/SelectFieldWrapper'
import Dialog, { IDialogType } from 'components/Clients/Client/Details/EditClient/Dialog'

import Loader from 'components/Loaders'
import useFormContainer from 'hooks/useFormContainer'
import { profileSchema } from 'pages/Owner/validation'
import UserStatus from 'components/Admin/UserManagement/EditAccount/UserStatus'
import { useGetBusinessIndustryDetailsQuery } from '__generated__/api-types-and-hooks'
import hookForms from 'utils/hookForms'
import { cloneDeep, isEmpty } from 'lodash'
import { useFlashParams } from 'hooks/useFlashParams'
import { useErrors } from 'hooks/useErrors'

const EditAccount = ({
  error,
  userId,
  advisors,
  handleClose,
  isBusiness,
  userProfile,
  selectedUser,
  disableButton,
  resetErrorsAction,
  getClientsAction,
  isUserProfileFormSet,
  enableDisableUserAction,
  updateOwnerProfileAction,
  updateAdvisorProfileAction,
  openUserManagementEditModal,
  getUserProfileAndAdvisorsAction,
}) => {
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(profileSchema),
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    clearErrors,
  } = methods
  const formValues = hookForms.getForm(FORM.USER_MANAGEMENT_FORM)?.getValues()
  const [isIndustryChanged, setIndustryChanged] = useState(false)
  const [showIndustryModal, setShowIndustryModal] = useState(false)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const { errorMessages } = useErrors([
    API_TYPES.UPDATE_ADVISOR_PROFILE,
    API_TYPES.UPDATE_OWNER_PROFILE,
  ])

  const { flashParams } = useFlashParams<{ validationErrorMessage?: string }>()
  const submitData = () => {
    setShowIndustryModal(false)
    isBusiness ? updateOwnerProfileAction({ userId }) : updateAdvisorProfileAction({ userId })
  }
  const { data, isLoading } = useGetBusinessIndustryDetailsQuery({
    input: { foodSubIndustryOptionIds: formValues?.foodSubIndustryOptionIds, userId },
  })
  let isFoodSubIndustryChanged = data?.getBusinessIndustryDetails?.isSubIndustryUpdated

  const onSubmit = () => {
    resetErrorsAction([API_TYPES.UPDATE_ADVISOR_PROFILE, API_TYPES.UPDATE_OWNER_PROFILE])
    const isSelectedUserABusinessOwner = userProfile?.roles
      ? userProfile.roles.includes(USER_ROLES.BUSINESS_OWNER)
      : false
    if (isSelectedUserABusinessOwner && (isIndustryChanged || isFoodSubIndustryChanged)) {
      setShowIndustryModal(true)
      return
    }
    submitData()
  }

  const onToggleUserAccess = () => {
    const isCurrentlyEnabled = userProfile?.status === 'active'
    if (isCurrentlyEnabled) {
      ampli.accountDeactivated({ id: userId, email: userProfile?.email || 'No email found' })
    } else {
      ampli.accountActivated({ id: userId, email: userProfile?.email || 'No email found' })
    }

    enableDisableUserAction({
      status: isCurrentlyEnabled ? USER_STATUS.DISABLED : USER_STATUS.ACTIVE,
      userId,
      isBusiness,
      name: selectedUser?.name,
      userRole: selectedUser?.role ? selectedUser?.role?.toString() : USER_ROLES.BUSINESS_OWNER,
    })
  }

  useEffect(() => {
    getUserProfileAndAdvisorsAction({
      userId,
      setForms: [FORM.USER_PROFILE_FORM],
    })
  }, [getUserProfileAndAdvisorsAction, userId])

  useEffect(() => {
    isDirty && setIsFormDirty(true)
  }, [isDirty])

  useEffect(() => {
    getClientsAction({
      advisorId: userId,
      includeInvitedClients: true,
    })
  }, [getClientsAction, userId])

  useEffect(() => {
    if (!openUserManagementEditModal) {
      handleClose()
    }
  }, [openUserManagementEditModal, handleClose])

  useFormContainer(FORM.USER_PROFILE_FORM, methods)

  const bsoProfile = useSelector((state: RootState) => state.bso.bsoProfile)

  const advisorTerm = useMemo(() => {
    return replaceTerm('advisor', bsoProfile.terminology)
    // eslint-disable-next-line
  }, [bsoProfile?.terminology])

  const disabledButton = disableButton || !isValid || !isFormDirty

  return (
    <>
      <EditAccountWrapper>
        <EditAccountBgColorWrapper>
          <HeaderWrapper>
            <div>
              <LabelWrapper> User Profile</LabelWrapper>
            </div>
            <div className="xs:hidden sm:inline-block">
              <UserStatus userProfile={userProfile} onToggleUserAccess={onToggleUserAccess} />
            </div>
          </HeaderWrapper>
          <>
            <div>
              {!isUserProfileFormSet ? (
                <Loader loader={isBusiness ? 'EditUserProfile' : 'EditAdvisorUserProfile'} />
              ) : (
                <div className="xs:mb-7 sm:mb-0">
                  <SelectFieldWrapper>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <EditAccountFormWrapper isBusiness={isBusiness}>
                        <EditUserProfileContainer
                          register={register}
                          errors={cloneDeep(errors)}
                          control={control}
                          clearErrors={clearErrors}
                        />
                        <div className="col-span-12 sm:col-span-6 mt-7 sm:hidden">
                          <UserStatus
                            userProfile={userProfile}
                            onToggleUserAccess={onToggleUserAccess}
                          />
                        </div>
                        <BottomHrWrapper />
                        {isBusiness && (
                          <AdvisorAssignmentWrapper>
                            <Controller
                              name={'advisors'}
                              control={control}
                              render={({ field }) => (
                                <SelectField
                                  {...field}
                                  isMulti={true}
                                  label={`${advisorTerm} Assignment`}
                                  options={advisors}
                                  onChange={(value) => {
                                    field.onChange(value)
                                  }}
                                />
                              )}
                            />
                          </AdvisorAssignmentWrapper>
                        )}
                      </EditAccountFormWrapper>
                    </form>
                  </SelectFieldWrapper>
                </div>
              )}
              {isBusiness && (
                <EditBusinessProfileContainer
                  userId={userId}
                  onSubmit={onSubmit}
                  setIsFormValid={(isFormValid) => setIsFormDirty(!isFormValid)}
                  setIndustryChanged={setIndustryChanged}
                  isUserManagement={true}
                />
              )}
              {!isBusiness && (
                <AssignOwnerToAdvisor setIsFormDirty={setIsFormDirty} onSubmit={onSubmit} />
              )}
            </div>
          </>
          {(error ||
            errorMessages?.UPDATE_OWNER_PROFILE ||
            errorMessages?.UPDATE_ADVISOR_PROFILE ||
            !isEmpty(flashParams)) && (
            <div className="px-7">
              <ErrorMessage id="error">
                {error?.message ||
                  errorMessages.UPDATE_OWNER_PROFILE ||
                  errorMessages.UPDATE_ADVISOR_PROFILE ||
                  flashParams?.validationErrorMessage}
              </ErrorMessage>
            </div>
          )}
          <FooterWrapper>
            {isMobileOnly && <HorizontalBreak />}
            {isMobileOnly && (
              <SaveButtonWrapper>
                <Button
                  label="Save"
                  variant="primary"
                  className="w-full"
                  disabled={disabledButton || !isEmpty(flashParams)}
                  type="submit"
                  onClick={onSubmit}
                  fontSize="xxl"
                />
              </SaveButtonWrapper>
            )}
            <CancelButtonWrapper>
              <Button
                label="Cancel"
                type="button"
                variant={'secondary'}
                className={`${
                  userProfile?.status === USER_STATUS.ACTIVE ||
                  userProfile?.status === USER_STATUS.DISABLED
                    ? 'visible'
                    : 'invisible'
                } !outline-0 active:outline-0 w-full`}
                fontSize="xxl"
                onClick={handleClose}
              />
            </CancelButtonWrapper>
            {!isMobileOnly && (
              <SaveButtonWrapper>
                <Button
                  label="Save"
                  variant="primary"
                  disabled={disabledButton || isLoading || !isEmpty(flashParams)}
                  type="submit"
                  onClick={onSubmit}
                  className="w-full ml-2"
                  fontSize="xxl"
                />
              </SaveButtonWrapper>
            )}
          </FooterWrapper>
        </EditAccountBgColorWrapper>
      </EditAccountWrapper>
      {showIndustryModal && (
        <ModalBox onClose={() => setShowIndustryModal(false)} title="Save Industry Changes">
          <Dialog
            dialogType={IDialogType.UpdatedIndustry}
            onSubmit={(e) => submitData()}
            showModal={showIndustryModal}
            setShowModal={setShowIndustryModal}
            isIndustryChanged={isIndustryChanged}
            isFoodSubIndustryChanged={isFoodSubIndustryChanged}
          />
        </ModalBox>
      )}
    </>
  )
}

export default EditAccount

const AssignOwnerToAdvisor = ({ setIsFormDirty, onSubmit }) => {
  const methods = useForm({
    mode: 'all',
  })
  useFormContainer(FORM.OWNERS, methods)

  return (
    <>
      <div className="xs:hidden sm:block">
        <AssignOwnerToAdvisorContainer
          onSubmit={onSubmit}
          isChanged={setIsFormDirty}
          methods={methods}
        />
      </div>
      <div className="xs:block sm:hidden">
        <AssignOwnerToAdvisorMobileViewContainer
          onSubmit={onSubmit}
          isChanged={setIsFormDirty}
          methods={methods}
        />
      </div>
    </>
  )
}
