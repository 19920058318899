import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const PlayBookStatsLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="22" y="26" rx="0" ry="0" width="4" height="160" />
        <rect x="22" y="183" rx="0" ry="0" width="380" height="4" />
        <rect x="22" y="24" rx="0" ry="0" width="380" height="4" />
        <rect x="400" y="24" rx="0" ry="0" width="4" height="160" />

        <rect x="35" y="70" rx="0" ry="0" width="270" height="20" />
        <rect x="35" y="120" rx="0" ry="0" width="270" height="20" />

        <rect x="22" y="200" rx="0" ry="0" width="4" height="198" />
        <rect x="22" y="200" rx="0" ry="0" width="380" height="4" />
        <rect x="22" y="396" rx="0" ry="0" width="380" height="4" />
        <rect x="400" y="200" rx="0" ry="0" width="4" height="198" />

        <rect x="35" y="250" rx="0" ry="0" width="270" height="30" />
      </ContentLoader>
    </>
  )
}

export default PlayBookStatsLoader
