import React, { useEffect, useState } from 'react'

import { isMobileOnly } from 'mobile-device-detect'

import ButtonTabs from 'components/Common/ButtonTabs'
import Button from 'components/Common/Button'

import GrowingContainer from 'components/Common/GrowingContainer'
import DataTable from 'components/Common/DataTable'
import { Columns } from 'types'
import { getRoleFromPath, navigateTo } from 'utils/helper'

export interface IManagePlaysProps {
  // todo - type this
  getPlaysAction: any
  plays: any[]
  loaded: boolean
}

const tabsOptions = [{ label: 'Plays' }]

const columns: Columns = [
  {
    textDirection: 'left',
    colSpan: 'col-span-8',
    colFields: [
      {
        field: 'name',
        type: 'string',
        headerName: 'Name',
        sortable: false,
      },
    ],
    renderCell: (params: any) => {
      return (
        <td className="p-2 col-span-8">
          <span className="text-sm">{params?.title || '-'}</span>
        </td>
      )
    },
  },
  {
    textDirection: 'left',
    colSpan: 'col-span-4',
    colFields: [
      {
        field: 'recommendedBy',
        type: 'string',
        headerName: 'recommended by',
        sortable: false,
      },
    ],
    renderCell: (params: any) => {
      return (
        <td className="p-2 col-span-4">
          <span className="text-sm capitalize">{params?.recommendedBy || '-'}</span>
        </td>
      )
    },
  },
]

const ManagePlays = ({ getPlaysAction, plays, loaded }: IManagePlaysProps) => {
  const [selectedTab, setSelectedTab] = useState(tabsOptions[0])
  const [pageSize] = useState(150)

  const userRole = getRoleFromPath()

  useEffect(() => {
    getPlaysAction({ lastEvaluatedKey: null, pageSize })
  }, [getPlaysAction, pageSize])

  return (
    <GrowingContainer>
      <div className={`${isMobileOnly ? 'p-[8px]' : ''} grid-cols-12 w-full h-full`}>
        <div className="col-span-12 items-center justify-between">
          <ButtonTabs
            options={tabsOptions}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
        </div>
        <div className="col-span-12 relative z-[5] xs:-mt-2 md:-mt-3 lg:-mt-5">
          <div className="xs:bock bg-white grid-cols-12 pt-6 pb-2 lg:p-12 rounded-2xl mb-8 gap-12 shadow-lg  mdl:pb-6 lg:pb-3 xl:pb-10">
            {selectedTab.label === 'Plays' && (
              <div className="xs:col-span-12 lg:col-span-12 lg:block hideInIpadProView mdl:px-8 xl:px-0">
                <div className="w-full p-2 overflow-auto mdl:overflow-hidden flex justify-between">
                  <div className="text-3xl font-normal whitespace-nowrap pb-5">Manage Plays</div>
                  <div>
                    <Button
                      variant="secondary"
                      label="New Play"
                      className="px-2 w-full"
                      loading={false}
                      onClick={() => navigateTo(userRole, 'create-play')}
                    />
                  </div>
                </div>
                <div className="w-full min-w-[800px]">
                  <DataTable
                    rows={plays}
                    pagination={false}
                    columns={columns}
                    pageSize={pageSize}
                    // setPageSize={setPageSize}
                    gridColumns={12}
                    tableType={'play'}
                    isLoading={!loaded}
                    loaderType="ManagePlaysLoader"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </GrowingContainer>
  )
}

export default ManagePlays
