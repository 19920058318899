import React, { FC } from 'react'

interface ISectionFooter {
  source?: string
  lastUpdated: string
  getChartDataAction: () => void
}

const SectionFooter: FC<ISectionFooter> = ({ source, lastUpdated, getChartDataAction }) => {
  return (
    <div className="sm:justify-end mt-auto flex xs:gap-1 xxl:gap-2 items-center w-full flex-wrap font-normal font-primary">
      <div className="xs:text-xs xl:text-sm flex gap-1 leading-5">
        <span>Source(s)</span>
        <span>{source}</span>
      </div>
      <span className="text-grey-darkest">|</span>
      {lastUpdated && (
        <>
          <div className="xs:text-xs xl:text-sm leading-5">
            <span>Last Updated {lastUpdated}</span>
          </div>
          <span className="text-grey-darkest">|</span>
        </>
      )}
      <h6
        onClick={getChartDataAction}
        className="text-primary font-bold xs:text-xs xl:text-sm  leading-5 cursor-pointer"
      >
        Refresh Now
      </h6>
    </div>
  )
}

SectionFooter.defaultProps = {
  source: 'Square POS',
}

export default SectionFooter
