import React from 'react'

function MiniLogo(props) {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="23" cy="23" r="23" fill="#5BBAB9" />
    </svg>
  )
}

export default MiniLogo
