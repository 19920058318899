import React from 'react'
import styled from 'styled-components'

const StyledInputTextButton = styled.div`
  margin-top: 16px;
`

const InputTextButton = ({
  title,
  action,
  fontSize = '',
  fontWeight = '',
  className = '',
  position = '',
  margin = '',
}) => {
  return (
    <StyledInputTextButton className={`${position ? position : 'self-end'} relative`}>
      <span
        className={` ${fontSize ? fontSize : 'xs:text-base sm:text-xl'}  ${
          margin ? margin : ' mt-[6px]'
        } font-primary text-primary cursor-pointer ${
          className ? className : 'right-[0px] absolute'
        }  ${fontWeight ? fontWeight : 'font-[650]'} hover:text-primary-brand`}
        onClick={action}
      >
        {title}
      </span>
    </StyledInputTextButton>
  )
}

export default InputTextButton
