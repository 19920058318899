import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const MainWrapper = styled.div`
  ${tw`flex items-center gap-2 w-full`}
`

export const ProgressBarWrapper = styled.div<{ disabled: boolean }>`
  ${tw`relative w-full h-[6px] rounded-lg flex items-center`}

  ${({ disabled }) => (disabled ? tw`bg-app-grey-20` : tw`bg-app-primary-30`)}
`

export const ProgressBarFill = styled.div<{ disabled: boolean }>`
  ${tw`absolute top-0 left-0 h-full bg-app-primary-60 rounded-lg`}
  ${({ disabled }) => (disabled ? tw`bg-app-grey-40` : tw`bg-app-primary-60`)}
`

interface LabelProps {
  position: 'left' | 'right'
}

export const Label = styled.span<LabelProps>`
  ${tw`text-sm font-medium text-app-grey-70`}
  ${({ position }) =>
    position === 'left'
      ? css`
          ${tw`mr-2`}
        `
      : css`
          ${tw`ml-2`}
        `}
`
