import React, { FC } from 'react'
import { Control, FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'

import { FIELD_NAME, LABEL } from 'config'

import InputField from 'components/Common/InputField'
interface IBusinessEmployeesFields {
  errors: FieldErrors<FieldValues>
  register: UseFormRegister<FieldValues>
  control: Control<FieldValues>
}

const BusinessEmployeesFields: FC<IBusinessEmployeesFields> = ({ errors, register, control }) => {
  return (
    <>
      <div className="mt-3">
        <InputField
          errors={errors?.fullTimeEmployees}
          className="mt-3"
          type={'text'}
          label={LABEL.FULL_TIME_EMPLOYEES}
          defaultValue={''}
          {...register(FIELD_NAME.FULL_TIME_EMPLOYEES)}
        />
        <InputField
          errors={errors?.partTimeEmployees}
          className="mt-3"
          type={'text'}
          label={LABEL.PART_TIME_EMPLOYEES}
          defaultValue={''}
          {...register(FIELD_NAME.PART_TIME_EMPLOYEES)}
        />
        <InputField
          errors={errors?.contractors}
          className="mt-3"
          type={'text'}
          label={LABEL.CONTRACT_EMPLOYESS}
          defaultValue={''}
          {...register(FIELD_NAME.CONTRACTORS)}
        />
        <InputField
          errors={errors?.volunteers}
          className="mt-3"
          type={'text'}
          label={LABEL.VOLUNTEERS}
          defaultValue={''}
          {...register(FIELD_NAME.VOLUNTEERS)}
        />
      </div>
    </>
  )
}

export default BusinessEmployeesFields
