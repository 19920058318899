import React from 'react'
import { MainWrapper, ProgressBarWrapper, ProgressBarFill, Label } from './AppProgressBar.style'

interface ProgressBarProps {
  progress: number // Percentage value, e.g., 20 for 20%
  showLabelLeft?: boolean
  showLabelRight?: boolean
  disabled?: boolean
}

export const AppProgressBar: React.FC<ProgressBarProps> = ({
  progress = 0,
  showLabelLeft = false,
  showLabelRight = false,
  disabled = false,
}) => {
  // Clamp the progress value between 0 and 100
  const clampedProgress = Math.min(100, Math.max(0, progress))

  return (
    <MainWrapper>
      {showLabelLeft && <Label position="left">{clampedProgress}%</Label>}
      <ProgressBarWrapper disabled={disabled}>
        <ProgressBarFill disabled={disabled} style={{ width: `${clampedProgress}%` }} />
      </ProgressBarWrapper>
      {showLabelRight && <Label position="right">{clampedProgress}%</Label>}
    </MainWrapper>
  )
}
