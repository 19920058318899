import React from 'react'

function ChatUpdatedVersionIcon({ className }) {
  return (
    <svg
      width="34"
      className={className}
      height="27"
      viewBox="0 0 34 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1957_121)">
        <path d="M3.375 0C1.51348 0 0 1.51348 0 3.375V13.5C0 15.3615 1.51348 16.875 3.375 16.875H5.0625V19.4062C5.0625 19.7279 5.2418 20.018 5.52656 20.1604C5.81133 20.3027 6.1541 20.2711 6.4125 20.0812L10.6893 16.875H18.5625C20.424 16.875 21.9375 15.3615 21.9375 13.5V3.375C21.9375 1.51348 20.424 0 18.5625 0H3.375ZM18.5625 18.5625H13.5V20.25C13.5 22.1115 15.0135 23.625 16.875 23.625H23.0607L27.3375 26.8312C27.5906 27.0211 27.9334 27.0527 28.2234 26.9104C28.5135 26.768 28.6875 26.4779 28.6875 26.1562V23.625H30.375C32.2365 23.625 33.75 22.1115 33.75 20.25V10.125C33.75 8.26348 32.2365 6.75 30.375 6.75H23.625V13.5C23.625 16.2949 21.3574 18.5625 18.5625 18.5625Z" />
      </g>
      <defs>
        <clipPath id="clip0_1957_121">
          <rect width="33.75" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

ChatUpdatedVersionIcon.defaultProps = {
  className: '',
}
export default ChatUpdatedVersionIcon
