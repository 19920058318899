import React from 'react'

function AccountsCreatedIcon({ className }) {
  return (
    <svg
      className={className}
      width="107"
      height="79"
      viewBox="0 0 107 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.1528 34.3034C74.2143 35.6875 72.1192 36.8378 69.9109 37.7305C65.9947 39.361 64.4196 39.8532 64.4196 39.8532L65.5055 77.3233H93.9373C93.9373 77.3233 90.2918 53.8291 90.2918 45.9444C90.2918 38.0597 90.6179 36.5215 90.6179 36.5215L81.2657 34.1281L76.1528 34.3034Z"
        fill="#5BBAB9"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.0519 35.8816C72.1654 42.2456 71.6395 48.6547 71.4768 55.0781C71.3137 64.7041 73.1626 77.5356 73.1626 77.5356H52.3325C54.9228 72.6355 56.7753 67.3798 57.83 61.9384C59.3682 54.146 60.5987 40.9361 60.9248 40.773C61.2509 40.61 72.292 36.5215 73.0519 35.8816Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.592 77.5387C63.592 77.5387 61.4693 63.1813 61.577 59.8096C61.6846 56.4379 62.2199 45.0184 62.2199 45.0184"
        stroke="#616161"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.745 34.9033C84.745 34.9033 82.0809 47.138 81.8625 57.2008C81.6441 67.2636 80.9396 77.5941 80.9396 77.5941H97.7827C97.7827 77.5941 98.0011 68.4019 97.7827 65.2486C97.5643 62.0953 97.5643 62.7475 97.5643 62.7475L104.092 64.4334C104.092 64.4334 99.9023 44.2555 99.1424 42.0282C98.3826 39.8009 98.761 39.0903 98.2718 38.7088C97.7827 38.3273 84.745 34.9033 84.745 34.9033Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.3229 69.2202C54.762 69.8933 55.2782 70.5128 55.8611 71.066C56.6087 71.7059 58.2115 71.3737 59.0636 72.2474C59.9158 73.121 58.9836 75.7883 57.9069 76.7204C56.0611 78.3109 57.0394 73.4841 57.0394 73.4841C56.3885 73.9302 55.6695 74.2679 54.9105 74.4839C53.8646 74.7238 51.1727 73.9055 50.2714 73.8686C49.37 73.8317 48.1179 73.6594 47.638 72.6565C47.1581 71.6536 50.136 70.4446 51.4588 69.9647C52.3932 69.6439 53.3507 69.395 54.3229 69.2202Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.6255 70.6353C48.341 70.5594 48.0405 70.5685 47.7612 70.6614C47.4818 70.7544 47.2358 70.9271 47.0534 71.1582C46.4382 71.9673 45.2076 75.6221 45.5675 76.3265C45.9275 77.031 46.8596 76.0435 47.3026 74.8837C47.4915 74.3248 47.7434 73.7891 48.0532 73.2871C48.0532 73.2871 48.5393 73.524 48.8808 72.8902C49.2223 72.2565 49.5668 71.3059 48.6255 70.6353Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.3667 69.9986C50.0686 69.8271 49.7204 69.764 49.3811 69.82C49.0419 69.8761 48.7325 70.0479 48.5055 70.3062C47.641 71.2291 48.2348 75.1084 48.6931 76.2959C49.1515 77.4834 50.0191 77.3357 50.1483 76.6035C50.2775 75.8714 50.4251 72.2197 50.4713 71.3737C50.5094 70.9127 50.4741 70.4485 50.3667 69.9986Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.2125 69.5801C51.9184 69.3949 51.5665 69.3239 51.2235 69.3806C50.8806 69.4373 50.5703 69.6177 50.3513 69.8877C49.493 70.8629 50.1268 74.9668 50.5974 76.2281C51.0681 77.4894 51.9357 77.3418 52.0587 76.5573C52.1818 75.7728 52.2925 71.9181 52.3294 71.0198C52.3609 70.5368 52.3215 70.0517 52.2125 69.5801Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.9136 70.0785C54.9136 70.0785 54.1968 68.4757 53.0278 68.7403C51.8588 69.0049 51.4896 70.5861 51.705 72.9857C51.9203 75.3853 52.3694 77.2926 53.0216 77.3603C53.6738 77.428 54.3845 75.979 54.5137 74.1055C54.6429 72.232 54.8367 71.1122 54.8367 71.1122"
        fill="white"
      />
      <path
        d="M54.9136 70.0785C54.9136 70.0785 54.1968 68.4757 53.0278 68.7403C51.8588 69.0049 51.4896 70.5861 51.705 72.9857C51.9203 75.3853 52.3694 77.2926 53.0216 77.3603C53.6738 77.428 54.3845 75.979 54.5137 74.1055C54.6429 72.232 54.8367 71.1122 54.8367 71.1122"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.9447 53.3093H62.6937V64.4396H69.9447V53.3093Z"
        fill="#5BBAB9"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.8253 50.0667H65.4901V55.2165H66.8253V50.0667Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.5882 56.4256H64.4718V60.4956H67.5882V56.4256Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.9642 61.7046H68.225"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.3274 62.5938H68.7972"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.3929 32.7284C75.3929 32.7284 73.0518 34.3035 72.6242 35.5556C72.1966 36.8077 70.3938 42.1359 69.6863 44.1479C68.9787 46.1598 64.9548 47.901 65.4993 48.2825C66.0439 48.664 71.3198 49.15 71.3198 49.15C71.3198 49.15 72.5165 53.8292 72.7873 55.0259C73.058 56.2226 74.6915 59.865 74.6915 59.865C74.6915 59.865 79.5307 54.0445 80.2936 52.4818C80.8177 51.4249 81.4171 50.4071 82.0871 49.4362C82.0871 49.4362 84.1544 49.9253 85.4711 50.1437C86.7878 50.3621 90.3656 50.6852 90.2026 50.0883C90.0395 49.4915 88.246 46.8797 87.5384 44.3232C86.8309 41.7668 87.2646 36.1647 86.3971 33.9897C85.5296 31.8147 83.6284 31.4886 82.97 31.3256C82.3117 31.1625 76.3712 31.8024 75.3929 32.7284Z"
        fill="#5BBAB9"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.63 31.0425C74.63 31.0425 75.3375 35.011 75.2453 38.2197C75.153 41.4283 73.9932 48.6086 74.0486 52.3064C74.1037 54.7627 74.303 57.2136 74.6454 59.6466C74.6454 59.6466 79.9767 52.3064 81.9333 46.3228C83.8898 40.3393 84.4344 35.2295 84.4344 34.848C84.4344 34.4665 84.542 25.6035 84.542 25.6035C84.542 25.6035 78.8723 31.5317 74.63 31.0425Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.4836 29.3659C84.5051 27.3724 84.5267 25.6035 84.5267 25.6035C84.5267 25.6035 78.8723 31.5317 74.63 31.0425C74.63 31.0425 74.83 32.1593 74.9992 33.6882C75.554 34.0683 76.17 34.3503 76.8204 34.5219C78.7831 34.9157 80.9827 33.8174 82.4747 31.9316C83.0254 31.2363 83.7545 30.2888 84.4836 29.3659Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.3629 11.3538C91.3515 11.3538 93.7742 8.95582 93.7742 5.9978C93.7742 3.03979 91.3515 0.641846 88.3629 0.641846C85.3743 0.641846 82.9515 3.03979 82.9515 5.9978C82.9515 8.95582 85.3743 11.3538 88.3629 11.3538Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.45 15.3561C66.45 15.3561 63.5459 14.5685 63.066 12.9996C62.5861 11.4306 64.3242 5.92394 69.9786 3.10291C75.6329 0.281886 80.8197 1.84468 83.0962 2.47226C85.3727 3.09984 87.0247 4.28116 88.5168 8.44349C90.0088 12.6058 90.0857 15.8268 89.615 17.3957C89.3352 18.3339 88.994 19.2526 88.5937 20.146C88.5937 20.146 85.6896 20.4536 84.902 19.2815C84.1145 18.1094 84.4313 12.2059 81.0535 10.8C77.6756 9.39409 71.2368 10.1847 69.6709 11.5845C68.1051 12.9842 66.45 15.3561 66.45 15.3561Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.9792 14.3348C65.9792 14.3348 66.6868 18.8109 66.6868 21.2443C66.6868 23.6777 68.6495 27.0586 69.6709 28.0123C70.6922 28.966 74.0701 32.1746 76.0328 32.5684C77.9955 32.9622 80.1951 31.8608 81.6872 29.975C83.1792 28.0892 86.0864 24.3206 86.0864 24.3206C86.7346 24.3928 87.3877 24.2362 87.9326 23.8778C88.4775 23.5193 88.88 22.9817 89.0705 22.3579C89.858 20.0783 89.4642 17.488 88.1291 17.1742C86.794 16.8604 85.8526 18.4294 85.8526 18.4294C85.8526 18.4294 84.7512 14.1902 83.3392 12.0675L81.2965 8.9911L83.9022 5.73323C83.9306 5.69783 83.9515 5.65698 83.9636 5.61318C83.9756 5.56939 83.9786 5.52359 83.9722 5.47862C83.9659 5.43364 83.9504 5.39044 83.9267 5.35169C83.903 5.31294 83.8716 5.27946 83.8345 5.25331C83.7644 5.2031 83.6777 5.1818 83.5924 5.19383C83.507 5.20586 83.4296 5.2503 83.3761 5.31792L80.8258 8.6004C80.8258 8.6004 75.799 7.73594 71.3998 9.93555C67.0006 12.1351 65.9792 14.3348 65.9792 14.3348Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.1178 18.7555L81.4103 19.6999"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.4211 24.1514C72.4211 24.1514 72.578 24.6221 73.5994 24.859C74.0759 24.9698 74.5744 24.9399 75.0343 24.7727C75.4941 24.6056 75.8955 24.3085 76.1897 23.9176"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.6025 26.6771C74.571 26.8956 75.5796 26.8585 76.5294 26.5694C77.4792 26.2803 78.3375 25.7492 79.02 25.0282C79.02 25.0282 78.3155 28.0123 76.9004 28.5629C75.4853 29.1136 75.4084 27.6185 75.4084 27.6185L73.6025 26.6771Z"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.4266 20.6967C77.0774 20.084 77.8695 19.6415 78.7325 19.4085C79.5955 19.1756 80.5027 19.1595 81.3734 19.3616"
        stroke="#263238"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.3198 20.5397C71.3198 20.5397 69.5939 18.8108 67.785 19.7522"
        stroke="#263238"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.2374 19.3616L68.0988 20.226"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.876 16.4544C75.876 16.4544 78.2171 13.7564 81.6011 16.2975"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.163 16.3743C71.163 16.3743 69.0433 14.7254 66.9207 16.6112"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.2755 20.2752C78.2785 20.3519 78.2583 20.4277 78.2176 20.4927C78.1769 20.5578 78.1175 20.609 78.0472 20.6398C77.9769 20.6705 77.899 20.6794 77.8236 20.6651C77.7482 20.6509 77.6788 20.6143 77.6245 20.56C77.5703 20.5058 77.5337 20.4364 77.5194 20.361C77.5052 20.2856 77.5141 20.2077 77.5448 20.1374C77.5756 20.0671 77.6268 20.0077 77.6919 19.967C77.7569 19.9262 77.8327 19.9061 77.9094 19.9091C78.0053 19.9129 78.0962 19.9527 78.164 20.0205C78.2319 20.0884 78.2717 20.1793 78.2755 20.2752Z"
        fill="#263238"
      />
      <path
        d="M69.2955 19.946C69.2961 20.0216 69.2743 20.0957 69.2327 20.1588C69.1912 20.2219 69.1318 20.2713 69.0622 20.3006C68.9925 20.33 68.9157 20.338 68.8415 20.3236C68.7673 20.3092 68.6991 20.2732 68.6454 20.2199C68.5918 20.1667 68.5551 20.0987 68.5402 20.0247C68.5252 19.9506 68.5326 19.8737 68.5613 19.8038C68.5901 19.7339 68.639 19.6742 68.7018 19.6321C68.7646 19.5901 68.8385 19.5676 68.9141 19.5676C69.0147 19.5676 69.1113 19.6074 69.1827 19.6783C69.2542 19.7491 69.2947 19.8454 69.2955 19.946Z"
        fill="#263238"
      />
      <path
        d="M87.8553 12.2859C87.8553 12.2859 88.1261 15.8206 87.4739 18.4848L88.5075 18.8109C88.5075 18.8109 89.3228 14.7316 88.9413 12.4489L87.8553 12.2859Z"
        fill="#5BBAB9"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.8289 1.94617C81.9661 2.51363 82.9796 3.3009 83.8108 4.26247C84.6419 5.22403 85.2742 6.34081 85.6711 7.54823C86.9201 11.5721 86.757 12.8242 87.5169 13.258C88.2768 13.6917 89.7472 13.9655 89.7995 12.9873C89.8518 12.009 89.5134 7.6436 87.3385 4.9364C85.708 2.87523 82.1886 2.00154 80.8289 1.94617Z"
        fill="#5BBAB9"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.286 17.7064C86.7202 17.2265 85.0005 18.3217 84.4406 20.1398C83.9237 21.8226 84.5821 23.5392 85.9172 24.1729C84.937 26.6637 83.4791 28.9388 81.6257 30.8702C81.2649 30.8257 80.9006 30.9179 80.6043 31.1286C80.0444 31.507 79.7983 32.1253 80.0598 32.5129C80.3213 32.9006 80.9827 32.9067 81.5457 32.5314C82.1087 32.1561 82.3086 31.6085 82.1302 31.2332C84.0253 29.2551 85.5119 26.9229 86.5048 24.3698C87.9999 24.6774 89.5812 23.6069 90.1072 21.8749C90.6671 20.0537 89.8519 18.1863 88.286 17.7064Z"
        fill="#5BBAB9"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.615 21.2627C89.3504 22.4256 88.3014 23.1824 87.2708 22.9486C86.2402 22.7148 85.6188 21.5796 85.8833 20.4167C86.1479 19.2539 87.1969 18.4971 88.2275 18.7278C89.2581 18.9585 89.8795 20.0968 89.615 21.2627Z"
        fill="#5BBAB9"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.3135 40.45C89.3135 40.45 87.7907 36.6445 87.4676 35.9369C87.1446 35.2294 89.8057 31.0424 90.2364 30.4425C90.6671 29.8427 91.4854 28.5967 91.4854 28.3229C91.4854 28.0491 88.3875 25.0035 87.7322 24.5144C87.0769 24.0252 85.9941 25.1297 85.9941 25.1297C85.9941 25.1297 83.1669 28.8828 83.1669 29.3166C83.1669 29.7504 84.5236 31.1101 84.5236 31.1101C84.5236 31.1101 82.4039 34.1557 82.6777 35.3524C82.9515 36.5491 84.0375 37.5828 84.0375 37.5828L85.5603 43.4556L89.3135 40.45Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.5266 31.0947C84.5266 31.0947 86.3724 29.6273 86.2125 29.1935C86.1491 29.0647 86.0472 28.9589 85.921 28.8905C85.7948 28.8222 85.6505 28.7948 85.508 28.8121"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.8341 25.6035C85.9972 25.6035 90.7809 28.7568 90.6732 29.2952C90.5781 29.6926 90.4323 30.0762 90.2395 30.4365"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.3787 25.1144C86.3787 25.1144 90.8917 27.9416 90.9932 28.4307C91.0134 28.5914 90.9943 28.7546 90.9375 28.9062C90.8807 29.0579 90.7879 29.1935 90.6671 29.3013"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.8678 24.6251C86.8678 24.6251 91.5993 27.8338 91.4823 28.3168C91.3527 28.7127 91.1503 29.081 90.8855 29.4027"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.9657 40.0162L82.6777 44.2124C82.6777 44.2124 92.1406 66.7253 93.8819 69.7709C95.6231 72.8165 100.299 73.524 102.748 72.7088C105.197 71.8936 106.39 66.9991 105.575 64.9317C104.76 62.8644 98.884 53.8291 96.0599 49.313C93.2358 44.7969 89.9657 40.0162 89.9657 40.0162Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.114 51.9033C86.114 51.9033 94.1126 67.2852 96.1614 70.3615"
        stroke="#616161"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.624 41.8406L101.905 59.0682"
        stroke="#616161"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0035 77.511H66.8866V75.459H16.0035V77.511Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.18941 77.511H52.5383V75.459H8.18941V77.511Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33115 71.8075H49.7604L45.1859 39.4164H2.75659L7.33115 71.8075Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.57456 71.8075H48.0038L43.4293 39.4164H1L5.57456 71.8075Z"
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5753 56.7517L14.3176 77.511H25.2664L29.5241 56.7517H18.5753ZM26.0017 62.9044C25.9839 62.9916 25.9365 63.0698 25.8675 63.1259C25.7985 63.182 25.7122 63.2125 25.6233 63.2121L19.4213 63.1505C19.3633 63.1498 19.3061 63.136 19.2541 63.1102C19.202 63.0844 19.1565 63.0473 19.1207 63.0015C19.085 62.9557 19.06 62.9025 19.0476 62.8458C19.0352 62.789 19.0357 62.7302 19.0491 62.6737L19.609 60.3449C19.6268 60.2578 19.6742 60.1795 19.7432 60.1234C19.8122 60.0673 19.8985 60.0369 19.9874 60.0373L26.1924 60.0957C26.2502 60.0969 26.307 60.111 26.3587 60.137C26.4103 60.163 26.4555 60.2002 26.4909 60.246C26.5263 60.2917 26.551 60.3447 26.5632 60.4013C26.5755 60.4578 26.5749 60.5163 26.5616 60.5726L26.0017 62.9044Z"
        fill="white"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AccountsCreatedIcon
