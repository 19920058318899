import React from 'react'

export const AppBackArrow = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.75 16.25L2.5 10M2.5 10L8.75 3.75M2.5 10H17.5"
        stroke="#5E5E5E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
