import { useMemo } from 'react'
import { useTenantId } from 'utils/useTenantId'

import {
  useGetAdvisorDashboardQuery,
  useGetClientsSummaryQuery,
} from '__generated__/api-types-and-hooks'

const useClientStatsCards = () => {
  const tenantId = useTenantId()
  let summary

  const { data, isFetching } = useGetClientsSummaryQuery(
    { tenantId: tenantId || '' },
    { staleTime: 60000 }
  )
  if (data) {
    summary = JSON.parse(data?.getClientsSummary?.data || '{}')
  }

  let response
  const {
    data: advisorData,
    isFetched,
    isError,
  } = useGetAdvisorDashboardQuery({ tenantId }, { staleTime: 60000 })
  if (advisorData) {
    response = JSON.parse(advisorData?.getAdvisorDashboard?.data || '')
  }

  const showNoActiveClientsMessage = useMemo(() => {
    return response?.accounts?.length === 0 && isFetched && !isError
  }, [response?.accounts, isFetched, isError])

  const total = summary?.totalUsers

  return { showNoActiveClientsMessage, isFetching, summary, total }
}

export default useClientStatsCards
