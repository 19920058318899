import { isArray } from 'lodash'
import {
  GET_USER_FILES,
  INPUT_FIELD_BG_COLOR,
  RESET_IMAGE_URL,
  RESET_PLAY,
  SAVE_USER_FILE,
  TOTAL_UPCOMING_MEETINGS,
  UPLOAD_FILE,
} from 'store/types'

const initialState = {
  uploadedImage: '',
  uploadFileLoading: false,
  userFiles: [],
  uploadFileErrors: '',
  totalUpComingMeeting: 0,
  inputFieldBgColor: 'bg-grey-light',
  uploadedFile: [],
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILE.STARTED:
      return {
        ...state,
        // uploadedImage: '',
        uploadFileErrors: '',
        uploadFileLoading: true,
      }
    case UPLOAD_FILE.FULLFILLED:
      return {
        ...state,
        uploadedFile: isArray(action.payload?.fileData)
          ? [...state.uploadedFile, ...action.payload?.fileData]
          : action.payload?.fileData,
        uploadFileLoading: false,
      }
    case UPLOAD_FILE.REJECTED:
    case UPLOAD_FILE.RESET:
      return {
        ...state,
        uploadedFile: [],
        uploadFileErrors: '',
        uploadFileLoading: false,
      }

    case GET_USER_FILES.STARTED:
      return {
        ...state,
        userFiles: [],
      }
    case GET_USER_FILES.FULLFILLED:
      return {
        ...state,
        userFiles: action.payload,
      }
    case TOTAL_UPCOMING_MEETINGS:
      return {
        ...state,
        totalUpComingMeeting: action.payload,
      }
    case RESET_IMAGE_URL:
      return {
        ...state,
        filesData: {},
      }
    case INPUT_FIELD_BG_COLOR:
      return {
        ...state,
        inputFieldBgColor: action.payload,
      }
    case RESET_PLAY:
      return {
        ...state,
        filesData: {},
      }

    case SAVE_USER_FILE.FULLFILLED:
    case SAVE_USER_FILE.REJECTED:
      return {
        ...state,
        uploadedFile: [],
      }
    default:
  }
  return state
}

export default reducer
