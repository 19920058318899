import React from 'react'

function WhiteCheck(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="white" />
      <path d="M5 9.41026L8.38983 13L15 6" stroke="#5BBAB9" strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}

export default WhiteCheck
