/**
 * A class which can be given a react setState hook to furnish a fresh copy of itself
 * when one of it's properties changes. This behavior allows the use of regular OOP
 * patterns in classes that inherit from it while still triggering rerenders
 * */
export class Model {
  parent: Model | null = null
  setState: null | ((copy: any) => void) = null

  constructor(parent: null | Model = null) {
    this.parent = parent
  }

  set<M extends this, K extends keyof M>(this: M, attribute: K, value: M[K]) {
    this[attribute] = value
    // @ts-ignore
    // console.log(`Setting ${String(attribute)} to ${value}`)
    this.callSetState()
  }

  duplicate<T extends this>(this: T) {
    const model = Object.getPrototypeOf(this).constructor
    const attrs = { ...this }
    return this.parent !== null ? new model(this.parent, attrs) : new model(attrs)
  }

  /**
   * Calls the set state function, or tries to call its' parent's setter if it
   * has no setter of its own. This lets the caller decide which entity to
   * track state against.
   *
   * @example
   * const [parent, setParent] = useState(new Parent())
   * parent.setState = setParent
   * parent.set("propertyName", value) // triggers rerender of parent
   * parent.child.set("childProperty", value) // child has no setter, so parent will rerender
   */
  callSetState() {
    if (this.setState) {
      const copy = this.duplicate()
      this.setState(copy)
    } else {
      this?.parent?.callSetState()
    }
  }

  get isModelInstance() {
    return true
  }

  /** Recursively reduces a JSON string composed of the non-computed properties of each related entity. */
  get json() {
    let copy = this.duplicate()
    /* remove computed getters/setters */
    copy = { ...copy }
    delete copy.parent
    delete copy.setState

    const keys: string[] = []
    Object.entries(copy).forEach(([_key, value]) => {
      /* Remove "_" from private properties */
      let key = _key
      if (key.charAt(0) === '_') {
        key = key.replace('_', '')
        delete copy[_key]
        Object.assign(copy, { [key]: value })
      }
      // @ts-ignore
      if (value?.isModelInstance) {
        copy[key] = (value as Model).json
        keys.push(key)
      } else if (Array.isArray(value)) {
        copy[key] = []
        let arrHasModels = false
        value.forEach((item) => {
          copy[key].push(item?.isModelInstance ? item.json : item)
          arrHasModels = true
        })
        if (arrHasModels) {
          keys.push(key)
        }
      }
    })

    return JSON.stringify(
      copy,
      (key, value) => {
        if (keys.includes(key)) {
          return Array.isArray(value) ? value.map((item) => JSON.parse(item)) : JSON.parse(value)
        } else {
          return value
        }
      },
      4
    )
  }
}

export abstract class Step extends Model {
  id = ''
  stepData!: Record<string, any>[]
}

export abstract class Option extends Model {
  id = '<INSESRT OPTION ID HERE?>'
}

export class Action extends Model {
  next = { default: '' }
  constructor(parent: Option, { parent: oldParent, ...action }: Partial<Action>) {
    super(parent)
    Object.assign(this, action)
  }
}

export default class Play extends Model {
  id = '<INSERT A PLAY ID>'
  _guide = {} // legacy property
  _use = {} // legacy property
  _recommendedBy = ''
  title = '' // this appears on Plays.tsx
  description = ''
  playTabs!: [Guide, Setup] | [Guide, Setup, Use]
  meta: Meta
  readonly type = 'play'

  constructor({ meta, playTabs, ...play }: Partial<Play> = {}) {
    // console.log("creating new Play")
    super()
    Object.assign(this, play)
    this.meta = new Meta(this, meta || {})
    this.playTabs = [new Guide(this, playTabs?.[0] || {}), new Setup(this, playTabs?.[1] || {})]
    if (playTabs?.[2]) {
      // @ts-ignore
      this.playTabs.push(new Use(this, playTabs?.[2]))
    }
  }

  deleteUse() {
    if (this.playTabs.length === 2) {
      throw new Error("Can't delete use tab again!")
    }
    const newTabs = [...this.playTabs]
    newTabs.splice(2, 1)
    this.set('playTabs', newTabs as [Guide, Setup])
  }

  addUse() {
    if (this.playTabs.length === 3) {
      throw new Error("Can't add use tab again!")
    }
    this.set('playTabs', [...this.playTabs, new Use(this)])
  }

  get guide() {
    return this.playTabs[0]
  }

  get setup() {
    return this.playTabs[1]
  }

  get use() {
    return this.playTabs?.[2] || null
  }

  /** Include this in the payload for creating new plays */
  get recommendedBy() {
    return this.meta.category
  }

  get json() {
    this._recommendedBy = this.recommendedBy
    return super.json
  }
}

export class Meta extends Model {
  banner = ''
  category = ''
  playType = ''
  playImage = ''
  toolName?: string
  url = ''
  playBookTitle = '' // this appears on PlayCards only
  constructor(play: Play, { parent, ...attributes }: Partial<Meta> = {}) {
    super(play)
    Object.assign(this, attributes)
  }
}

export abstract class PlayTab extends Model {
  tabName: string = ''
  tabData!: Record<string, any>

  get playType() {
    return (this.parent as Play).meta.playType
  }
}

export class Guide extends PlayTab {
  tabName = 'Guide'
  tabData: GuideTabData
  constructor(play: Play, { parent, ...attributes }: Partial<Guide> = {}) {
    super(play)
    Object.assign(this, attributes)
    this.tabData = new GuideTabData(this, attributes?.tabData || {})
  }

  get step() {
    return this.tabData.steps[0]
  }

  get guideDescription() {
    return this.step.description
  }

  set guideDescription(value: string) {
    this.step.set('description', value)
  }

  set headingDescription(value: string) {
    this.step.set('headingDescription', value)
  }

  set actionButtonLabel(value: string) {
    this.step.set('actionButtonLabel', value)
  }

  get actionButtonLabel() {
    return this.step.actionButtonLabel
  }
}

class GuideTabData extends Model {
  image: string = ''
  steps: [GuideStep]
  pricing = ''
  constructor(guide: Guide, { parent, steps, ...attributes }: Partial<GuideTabData> = {}) {
    super(guide)
    Object.assign(this, attributes)
    this.steps = [new GuideStep(this, steps?.[0] || {})]
  }
}

export class GuideStep extends Step {
  stepIcon = ''
  stepTitle = ''
  stepData: [GuideStepData]
  constructor(
    guideTabData: GuideTabData,
    { parent, stepData, ...attributes }: Partial<GuideStep> = {}
  ) {
    super(guideTabData)
    Object.assign(this, attributes)
    this.stepData = [new GuideStepData(this, stepData?.[0] || {})]
  }

  get guideStepData() {
    return this.stepData[0]
  }

  set headingDescription(value: string) {
    this.guideStepData.set('stepHeading', { title: '', description: value })
  }

  get headingDescription() {
    return this.guideStepData.stepHeading.description
  }

  set description(value: string) {
    this.guideStepData.set('description', value)
  }

  get description() {
    return this.guideStepData.description
  }

  set actionButtonLabel(value: string) {
    this.guideStepData.set('actionButtonLabel', value)
  }

  get actionButtonLabel() {
    return this.guideStepData.actionButtonLabel
  }
}

export class GuideStepData extends Model {
  stepType = 'question'
  stepHeading = { title: '', description: '' } // title is always empty string, description is used a few times
  stepBody: [GuideStepDataBodyItem]
  constructor(guideStep: GuideStep, { parent, stepBody, ...attributes }: Partial<GuideStepData>) {
    super(guideStep)
    Object.assign(this, attributes)
    this.stepBody = [new GuideStepDataBodyItem(this, stepBody?.[0] || {})]
  }

  get bodyItem() {
    return this.stepBody[0]
  }

  set description(value: string) {
    this.bodyItem.set('description', value)
  }

  get description() {
    return this.bodyItem.description
  }

  set actionButtonLabel(value: string) {
    this.bodyItem.set('actionButtonLabel', value)
  }

  get actionButtonLabel() {
    return this.bodyItem.actionButtonLabel
  }
}

export class GuideStepDataBodyItem extends Model {
  id = '<INSERT ID HERE?>'
  assessmentType = '<INSERT ASSESSMENT TYPE ID HERE?>'
  content = { owner: { description: '', title: '' } }
  optionType = 'button'
  options: [GuideOption]
  constructor(
    guideStepData: GuideStepData,
    { parent, options, ...attributes }: Partial<GuideStepDataBodyItem>
  ) {
    super(guideStepData)
    Object.assign(this, attributes)
    this.options = [new GuideOption(this, options?.[0] || {})]
  }

  get description() {
    return this.content.owner.description
  }

  set description(value: string) {
    this.set('content', { owner: { description: value, title: '' } })
  }

  get option() {
    return this.options[0]
  }

  set actionButtonLabel(value: string) {
    this.option.set('value', value)
  }

  get actionButtonLabel() {
    return this.option.value
  }
}

export class GuideOption extends Option {
  value = ''
  action: Action
  constructor(
    parent: GuideStepDataBodyItem,
    { parent: oldParent, action, ...attributes }: Partial<GuideOption>
  ) {
    super(parent)
    Object.assign(this, attributes)
    this.action = new Action(this, action || {})
  }
}

export class Setup extends PlayTab {
  steps: SetupStep[] = []
  tabName = 'Setup'
  pricing = ''
  constructor(play: Play, { parent, ...setup }: Partial<Setup> = {}) {
    super(play)
    Object.assign(this, setup)
    if (!this.steps.length) {
      this.steps = [new SetupStep(this), new SetupStep(this), new SetupStep(this)]
    }
  }

  addStep() {
    this.set('steps', [...this.steps, new SetupStep(this)])
  }

  deleteStep(idx: number) {
    const newSteps = [...this.steps]
    newSteps.splice(idx, 1)
    this.set('steps', newSteps)
  }
}

export class SetupStep extends Step {
  stepIcon = ''
  stepTitle = ''
  stepData: Record<string, any>[] = []
  constructor(parent: Setup, { parent: oldParent, ...step }: Partial<Step> = {}) {
    super(parent)
    Object.assign(this, step)
  }
}

export class Use extends PlayTab {
  // steps: Step[] = []
  tabName = 'Use'
  pricing = ''
  constructor(play: Play, { parent, ...guide }: Partial<Guide> = {}) {
    super(play)
    Object.assign(this, guide)
  }
}
