import React from 'react'

const ArrowHead = ({ color, className, down = false }) => {
  return (
    <svg
      className={className}
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={down ? 'rotate(180)' : ''}
    >
      <path d="M8.5 0L16.2942 13.5H0.705771L8.5 0Z" fill={color} />
    </svg>
  )
}

ArrowHead.defaultProps = {
  color: '#059669',
  className: '',
}

export default ArrowHead
