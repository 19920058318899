import { SortOrder } from '__generated__/api-types-and-hooks'
import React from 'react'

function SortIcon({ className, sortingOrder = '' }) {
  return (
    <svg
      className={className}
      width="13"
      height="17"
      viewBox="0 0 88 92"
      // fill="#181818"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.7147 26.2851C43.5555 26.2851 44.3962 25.9643 45.0374 25.3223C46.3209 24.0388 46.3209 21.9585 45.0374 20.6771L25.3226 0.962621C24.0391 -0.320874 21.9588 -0.320874 20.6774 0.962621L0.962633 20.6771C-0.320878 21.9606 -0.320878 24.0409 0.962633 25.3223C2.24614 26.6038 4.32646 26.6058 5.60792 25.3223L19.7142 11.2178V88.7143C19.7142 90.5214 21.1928 92 23 92C24.8072 92 26.2858 90.5304 26.2858 88.7143V11.2178L40.3921 25.324C41.0308 25.9565 41.8727 26.2851 42.7147 26.2851Z"
        className={
          sortingOrder === SortOrder.Ascending
            ? 'fill-primary stroke-primary'
            : 'fill-[#181818] stroke-[#181818]'
        }
      />
      <path
        d="M45.2853 65.7149C44.4445 65.7149 43.6038 66.0357 42.9626 66.6777C41.6791 67.9612 41.6791 70.0415 42.9626 71.3229L62.6774 91.0374C63.9609 92.3209 66.0412 92.3209 67.3226 91.0374L87.0374 71.3229C88.3209 70.0394 88.3209 67.9591 87.0374 66.6777C85.7539 65.3962 83.6735 65.3942 82.3921 66.6777L68.2858 80.7822L68.2858 3.28575C68.2858 1.47859 66.8072 6.14516e-06 65 5.98717e-06C63.1928 5.82918e-06 61.7142 1.46956 61.7142 3.28575L61.7142 80.7822L47.6079 66.676C46.9692 66.0435 46.1273 65.7149 45.2853 65.7149Z"
        className={
          sortingOrder === SortOrder.Descending
            ? 'fill-primary stroke-primary'
            : 'fill-[#181818] stroke-[#181818]'
        }
      />
    </svg>
  )
}
export default SortIcon
