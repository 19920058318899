import React from 'react'

function MeetingCreationSuccessIcon() {
  return (
    <div>
      <svg
        width="66"
        height="66"
        viewBox="0 0 66 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 10C0 4.47715 4.47715 0 10 0H56C61.5229 0 66 4.47715 66 10V56C66 61.5229 61.5228 66 56 66H10C4.47715 66 0 61.5228 0 56V10Z"
          fill="#E2F0EC"
        />
        <circle cx="33" cy="33" r="19" fill="#059669" />
        <path
          d="M43 28.5772L28.6 42L22 35.8479L23.692 34.2707L28.6 38.8345L41.308 27L43 28.5772Z"
          fill="#E2F0EC"
        />
      </svg>
    </div>
  )
}

MeetingCreationSuccessIcon.defaultProps = {
  className: '',
}

export default MeetingCreationSuccessIcon
