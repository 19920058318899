export enum FORM {
  USER_TASK_FORM = 'USER_TASK_FORM',
}
export enum OTHERTASKS {
  MY_OTHER_TASKS = 'Miscellaneous Plays',
  MY_ALL_PLAYS = 'All Plays',
}
export enum GOALIDS {
  MY_OTHER_TASKS = 'other-tasks',
  MY_ALL_PLAYS = 'all-plays',
}
