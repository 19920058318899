import styled from 'styled-components'

export const PlaysWrapper = styled.div`
width: 100%;
background-color: #fff;
padding: 0px !important;
box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1);

  .imgIcon{
    padding: 20px 40px 20px;
    img{
      margin: 0 auto;
      width: 100%;
      max-width: 200px;
    }
  }
.tabMainWrapper{
  background-color:white;
}
  .playTabs{
    display:flex;
    align-items:center;
    li{
      width: 100%;
      padding:18px 12px;
      text-align: center;
      font-size: 18px;
      a {
        font-size: 14px;
        line-height: 20px;
        font-weight:600;
      }
      &.active{
        border-bottom:4px solid #5E06B9;
        a{
          color:#5E06B9;
        }
        color: #5E06B9;
      }
    }

  }
  .tabInnerContent{
    padding: 40px;
    .guideTabContent{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 30px;
    img{
      border-radius: 50px;
      border:2px solid #27272A;
    }
    button{
      flex-shrink: 0;
      background: #5BBAB9;
      color: #fff;
      padding: 10px 20px;
      border-radius: 50px;
      font-size: 16px;
      font-weight: 600;
      border:1px solid transparent;
      margin-left: auto;
      &:hover{
        background: transparent;
        border:1px solid #5BBAB9;
        color: #5BBAB9;
      }
    }
}
  }
    @media only screen and (min-width: 992px) {
      font-size: 16px !important;
      padding: 15px;
      .imgIcon{
        padding: 40px 40px 20px;
        img{
          max-width: 320px;
        }
      }
    }
  }

  @media only screen and (min-width: 992px) {
    .playTabs{
      li{
        /* padding:18px 30px; */
        font-size: 20px;

    }

  }
`
export const TabHeading = styled.div`
  background-color:white;
  padding: 0px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  position: fixed;

  top: 0;
  width: 100%;
  z-index: 1024;
  border-bottom: 1px solid #D4D4D8;
  gap:10px;
  font-size:14px;

  @media only screen and (min-width: 1200px) {
    background-color:transparent;
    padding:0;
    position:relative;
    border-bottom: 1px solid transparent;
    font-size:16px;
  }
  @media only screen and (max-width: 500px) {
   height: 60px;
   left: 0px;
  }
  @media only screen and (min-width: 500px) and (max-width: 850px) {
   height: 72px;
   left: 0px;
  }
  @media only screen and (min-width: 851px) and (max-width: 1100px) {
   height: 125px;
   left: 0px;
  }
}
`

export const SetUpTabContent = styled.div`
  .perMonth {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    display: flex;
    background: #5e06b9;
    flex-direction: column;
    color: #fafafa;
    align-items: center;
    justify-content: center;
  }
  .amount {
    font-weight: 800;
    font-size: 16px;
    line-height: 28px;
    padding: 0px 8px;
  }
  .month {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
  }
  .decimal {
    position: absolute;
    background: #5e06b9;
    right: -6px;
    top: 20px;
    border-radius: 50px;
    font-size: 14px;
  }
  img {
    // margin: 20px auto 0;
    width: 100%;
    max-width: 150px;
    max-height: 100px;
  }
  @media only screen and (min-width: 1200px) {
    .squarePaymentWrap {
      padding: 30px;
    }
  }
`
export const PlayStepsPanelWrapper = styled.div`
  margin-bottom: 30px;
  .tabStyle {
    border-radius: 8px 8px 0px 0px;
    z-index: 10;
    font-size: 16px;
    justify-content: center;
    display: inline-block;
  }
  .stepsInnerContent {
    border: 1px solid #d4d4d8;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: #ffffff;
    padding: 30px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
  }
  .stepsMidLine {
    position: absolute;
    content: '';
    width: 2px;
    height: 100%;
    left: 25px;
    margin-left: -1px;
    background: #27272a;
    z-index: -1;
  }
`
export const StepsLargeHeading = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 2.25rem;
  color: #3f3f46;
  margin-bottom: 1px;
  font-family: 'Futura Std';
`

export const AccordianWrapper = styled.div`
  .accordainInner {
    background: #ffffff;
    box-shadow: -1px 1px 8px #0000002b;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 15px;
    flex-direction: column;
    margin-bottom: 30px;
    &.open {
      .accordianOpenContent {
        width: 100%;
        border-top: 1px solid #d4d4d8;
        padding-top: 30px;
      }
      .arrowIcon {
        SVG {
          transform: rotate(0);
        }
      }
    }
    &.close {
      .accordianOpenContent {
        display: none;
      }
      .arrowIcon {
        SVG {
          transform: rotate(90deg);
        }
      }
    }
  }

  .accordainContentWrap {
    display: flex;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .signInMainWrapper {
    border-radius: 10px;
    border: 1px solid #d4d4d8;
    padding: 20px 30px 30px 30px;
  }

  @media only screen and (min-width: 992px) {
    .accordainInner {
      padding: 20px 30px;
    }
  }
`

export const CustomRadio = styled.div`
  display: flex;
  flex-direction: column;
  input[type='radio'] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline: none;
    border: 2px solid #5bbab9;
    flex-shrink: 0;
  }
  input[type='radio']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }

  input[type='radio']:checked:before {
    background: #5bbab9;
  }

  input[type='radio']:checked {
    border-color: #5bbab9;
  }
`

export const CardWrapper = styled.main.attrs({
  className:
    'bg-white rounded-xl	border border-primary-disabled px-7 py-12 h-[450px] flex flex-col justify-center items-center',
})``
