import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  AppGoalCenterWrapper,
  AppGoalCenterHeading,
  AppGoalCenterSubHeading,
  AppCountCardWrapper,
  AppCard,
  AppContent,
  AppCountWrapper,
  AppCountBubble,
  AppCount,
  Divider,
  AppContentLabel,
} from 'components/AppGoalCenter/AppGoalCenter.style'
import GradientCircleNotchIcon from 'components/Common/SvgIcons/GradientCircleNotchIcon'

import {
  BusinessGoalsFilterData,
  UserTaskItemStatus,
  useGetBusinessGoalsQuery,
} from '__generated__/api-types-and-hooks'
import { getTenantId } from 'utils/getTenantId'

import { BusinessGoal } from '__generated__/api-types-and-hooks'
import { RootState } from 'App'
import AppSidebar from './AppSidebar/AppSidebar.component'
import { AppGoals } from './AppGoals/AppGoals.component'
import { myOtherTasksGoal } from 'appConfig/data'

const AppGoalCenter: React.FC = () => {
  const clientId = useSelector((state: RootState) => state.clients.client.profile.id)
  const tenantId = getTenantId()
  const [selectedFilters, setSelectedFilters] = useState<BusinessGoalsFilterData>({
    isArchive: false,
  })
  const [viewArchive, setViewArchive] = useState<boolean>(false)

  const { data, isLoading, refetch } = useGetBusinessGoalsQuery(
    {
      input: { userId: clientId, tenantId, filter: selectedFilters },
    },
    { refetchOnWindowFocus: false }
  )

  const updatedGoals = data?.getBusinessGoals
    ? [...myOtherTasksGoal, ...data.getBusinessGoals]
    : [myOtherTasksGoal]

  const { data: allGoals, refetch: refetchBusinessGoals } = useGetBusinessGoalsQuery(
    {
      input: { userId: clientId, tenantId },
    },
    { refetchOnWindowFocus: false }
  )

  const completedGoals = useMemo(
    () => allGoals?.getBusinessGoals?.filter((goal) => goal.status === UserTaskItemStatus.Done),
    [allGoals]
  )
  const completedTouchdowns = useMemo(() => {
    let touchdowns = 0
    allGoals?.getBusinessGoals?.forEach((goal) => {
      goal.plan?.milestones?.forEach((milestone) => {
        if (milestone.status === UserTaskItemStatus.Done) {
          touchdowns++
        }
      })
    })
    return touchdowns
  }, [allGoals])

  const handleRefetchBusinessGoals = ({ hasFilter }: { hasFilter?: boolean }) => {
    hasFilter ? refetch() : refetchBusinessGoals()
  }
  return (
    <div className="flex flex-col sm:flex-row gap-y-4 w-full">
      {!isLoading && (
        <AppSidebar setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} />
      )}
      <div className="flex-1 flex flex-col gap-8 py-7 xs:px-5 sm:px-6 md:px-12 lg:px-[6.25rem] ">
        {!isLoading && (
          <>
            <AppGoalCenterWrapper>
              <AppGoalCenterHeading>Welcome To Your Goal Center!</AppGoalCenterHeading>
              <AppGoalCenterSubHeading>
                Below you will find your goals. Click on each to work towards it.
              </AppGoalCenterSubHeading>
            </AppGoalCenterWrapper>

            <AppCountCardWrapper>
              <AppCard>
                <AppContent>
                  <AppContentLabel>Touchdowns Completed</AppContentLabel>
                </AppContent>
                <AppCountWrapper>
                  <AppCountBubble>
                    <AppCount>{completedTouchdowns}</AppCount>
                  </AppCountBubble>
                </AppCountWrapper>
              </AppCard>

              <AppCard>
                <AppContent>
                  <AppContentLabel>Goals Completed</AppContentLabel>
                </AppContent>
                <AppCountWrapper>
                  <AppCountBubble>
                    <AppCount>{completedGoals ? completedGoals.length : 0}</AppCount>
                  </AppCountBubble>
                </AppCountWrapper>
              </AppCard>
            </AppCountCardWrapper>

            <Divider />
          </>
        )}

        <div>
          {isLoading ? (
            <div className="flex justify-center items-center">
              <GradientCircleNotchIcon width="100" height="100" />
            </div>
          ) : (
            <AppGoals
              goals={updatedGoals as BusinessGoal[]}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              refetchGoals={handleRefetchBusinessGoals}
              viewArchive={viewArchive}
              setViewArchive={setViewArchive}
              allPlays={allGoals?.getBusinessGoals as BusinessGoal[]}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AppGoalCenter
