import React from 'react'

const RadioButtonField = ({ onChange, value, options, id = '', error = '' }) => {
  return options.map((option, index) => (
    <div
      className="flex flex-row gap-3 items-center"
      key={index}
      onClick={() => onChange(option.value)}
    >
      <input
        id={id}
        type="radio"
        checked={value === option.value}
        className={`h-6 w-6 ${
          value === option.value
            ? 'border-[3px] border-cream-dark bg-primary ring-1 ring-primary'
            : error
            ? 'border-alert ring-1 ring-alert bg-cream-dark'
            : 'bg-cream-dark ring-1 ring-primary'
        } rounded-full appearance-none cursor-pointer `}
      />
      <label className="font-primary  text-base	text-black-light font-[650]">{option.label}</label>
    </div>
  ))
}

export default RadioButtonField
