import React from 'react'

function FullTimeIcon({ className }) {
  return (
    <svg
      width="50"
      height="50"
      className={className}
      viewBox="0 0 50 50"
      fill="#71717A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="25" fill="" />
    </svg>
  )
}

FullTimeIcon.defaultProps = {
  className: '',
}
export default FullTimeIcon
