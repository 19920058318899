import React, { Fragment, useEffect } from 'react'

import isEmpty from 'lodash/isEmpty'
import Loader from 'components/Loaders'
import CardView from 'components/Common/CardView'

import ActiveClientCard from 'components/Clients/ActiveClientCard'
import {
  TopActiveClientsWrapper,
  TopActiveClientsDesktopWrapper,
  TopActiveClientsMobileWrapper,
  NoClientWrapper,
} from 'components/Clients/style'

export default function TopActiveClients({ topActiveClients, getTopActiveClientsAction }) {
  useEffect(() => {
    getTopActiveClientsAction()
  }, [getTopActiveClientsAction])

  return (
    <>
      <TopActiveClientsWrapper>
        {topActiveClients &&
          topActiveClients.map((client, index) => {
            return (
              <Fragment key={index}>
                <TopActiveClientsDesktopWrapper>
                  <CardView
                    padding="xl:px-[10px]"
                    margin=" md:mb-0 sm:mb-[10px]"
                    classes="rounded-[15px] bg-white"
                  >
                    {!isEmpty(client) ? (
                      <ActiveClientCard client={client} index={index} />
                    ) : (
                      <Loader loader="TopActiveClients" />
                    )}
                  </CardView>
                </TopActiveClientsDesktopWrapper>
                <TopActiveClientsMobileWrapper>
                  <CardView
                    padding="xl:px-[10px]"
                    margin=" md:mb-0 sm:mb-[10px]"
                    classes="rounded-[15px] bg-white"
                  >
                    {!isEmpty(client) ? (
                      <ActiveClientCard isMobile={true} client={client} index={index} />
                    ) : (
                      <Loader loader="TopActiveClients" isMobile={true} />
                    )}
                  </CardView>
                </TopActiveClientsMobileWrapper>
              </Fragment>
            )
          })}
        {topActiveClients?.length === 0 && (
          <NoClientWrapper>You have no active clients.</NoClientWrapper>
        )}
      </TopActiveClientsWrapper>
    </>
  )
}
