import React, { useMemo } from 'react'
import moment from 'moment'
import { CircleIcon } from 'components/Common/SvgIcons'

const ClientActivityLog = ({ date, logDetail }) => {
  const label = useMemo(() => {
    return moment().format('MMMM').toUpperCase() === date.toUpperCase() ? 'THIS MONTH' : date
  }, [date])

  return (
    <div className="pb-[30px]">
      <div className="text-sm uppercase pl-1"> {label} </div>
      <div className="w-full relative p-4">
        <div className="flex flex-col">
          {logDetail.map((log, idx) => (
            <div id={`log-item-${idx + 1}`} key={`log-item-${idx + 1}`} className="relative flex">
              <div className="flex space-x-3">
                <div id={`circle-icon-and-vertical-line-${idx + 1}`} className="relative">
                  {idx !== logDetail.length - 1 && (
                    <ProgressConnectorLine id={`progress-connector-line-${idx + 1}`} />
                  )}
                  <ProgressCircleIcon id={`progress-circle-icon-${idx + 1}`} />
                </div>
                <div className="flex-1 flex flex-col justify-between pb-3">
                  <p
                    className={`${
                      log?.logStatus === 'Completed' ? 'font-medium text-primary' : 'font-medium'
                    }`}
                  >
                    <b> {log?.action} </b>
                  </p>
                  <p> {log?.createdAt}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const ProgressConnectorLine = ({ id }) => (
  <div
    id={id}
    className="absolute top-2 left-1/2 -translate-x-1/2 h-full w-[1px] bg-primary -mt-1 z-0"
    aria-hidden="true"
  />
)

const ProgressCircleIcon = ({ id }) => {
  return (
    <span
      id={id}
      className={'w-4 mt-1 rounded-full flex items-center justify-center z-10 relative'}
    >
      <CircleIcon className=" bg-white z-idx-10 w-10 text-white" aria-hidden="true" />
    </span>
  )
}

export default ClientActivityLog
