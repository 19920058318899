import React from 'react'

function ChevronRightIcon({ className }) {
  return (
    <svg
      className={className}
      width="32"
      height="62"
      viewBox="0 0 32 62"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M30.6543 32.8809L5.34008 61.0059C4.30192 62.1594 2.52231 62.2529 1.36919 61.2147C0.15313 60.1124 0.176861 58.3337 1.16036 57.2385L24.7832 31L1.16001 4.75587C0.126412 3.59572 0.217818 1.82736 1.36743 0.783219C2.52231 -0.253891 4.30122 -0.160726 5.34008 0.992399L30.6526 28.9592C31.6211 30.1914 31.6211 31.8086 30.6543 32.8809Z" />
    </svg>
  )
}

export default ChevronRightIcon

ChevronRightIcon.defaultProps = {
  className: 'fill-black',
}
