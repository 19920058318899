import React from 'react'

export default function RecommendedPlaysIcon() {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="1" width="35" height="31" rx="2" fill="#18181B" />
      <rect x="0.5" y="0.5" width="33" height="30" rx="1.5" fill="white" stroke="#18181B" />
      <path
        d="M9 17.5C9 13.36 12.36 10 16.5 10H19V6L26 12L19 18V14H16.5C14.57 14 13 15.57 13 17.5V25H9V17.5Z"
        fill="black"
      />
    </svg>
  )
}
