import React from 'react'

function CalenderIcon({ className }) {
  return (
    <svg
      className={className}
      width={'35.55px'}
      height={'39.9px'}
      viewBox="0 0 448 512"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'middle', overflow: 'hidden' }}
    >
      <path d="M112 0C120.8 0 128 7.164 128 16V64H320V16C320 7.164 327.2 0 336 0C344.8 0 352 7.164 352 16V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H96V16C96 7.164 103.2 0 112 0zM416 192H312V264H416V192zM416 296H312V376H416V296zM416 408H312V480H384C401.7 480 416 465.7 416 448V408zM280 376V296H168V376H280zM168 480H280V408H168V480zM136 376V296H32V376H136zM32 408V448C32 465.7 46.33 480 64 480H136V408H32zM32 264H136V192H32V264zM168 264H280V192H168V264zM384 96H64C46.33 96 32 110.3 32 128V160H416V128C416 110.3 401.7 96 384 96z" />{' '}
    </svg>
  )
}
export default CalenderIcon

export const CalenderHollowIcon = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'middle', overflow: 'hidden' }}
    >
      <path
        d="M17 1.5H14.9933V1C14.9933 0.734784 14.888 0.48043 14.7004 0.292893C14.5129 0.105357 14.2585 0 13.9933 0C13.7281 0 13.4738 0.105357 13.2862 0.292893C13.0987 0.48043 12.9933 0.734784 12.9933 1V1.5H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V1.5H3C2.20463 1.50092 1.4421 1.81728 0.879693 2.37969C0.317283 2.9421 0.000917502 3.70463 0 4.5V17C0.000917502 17.7954 0.317283 18.5579 0.879693 19.1203C1.4421 19.6827 2.20463 19.9991 3 20H17C17.7954 19.9991 18.5579 19.6827 19.1203 19.1203C19.6827 18.5579 19.9991 17.7954 20 17V4.5C19.9991 3.70463 19.6827 2.9421 19.1203 2.37969C18.5579 1.81728 17.7954 1.50092 17 1.5ZM2 4.5C2.00028 4.23487 2.10573 3.98068 2.29321 3.79321C2.48068 3.60573 2.73487 3.50028 3 3.5H5V4C5 4.26522 5.10536 4.51957 5.29289 4.70711C5.48043 4.89464 5.73478 5 6 5C6.26522 5 6.51957 4.89464 6.70711 4.70711C6.89464 4.51957 7 4.26522 7 4V3.5H12.9933V4C12.9933 4.26522 13.0987 4.51957 13.2862 4.70711C13.4738 4.89464 13.7281 5 13.9933 5C14.2585 5 14.5129 4.89464 14.7004 4.70711C14.888 4.51957 14.9933 4.26522 14.9933 4V3.5H17C17.2651 3.50028 17.5193 3.60573 17.7068 3.79321C17.8943 3.98068 17.9997 4.23487 18 4.5V6H2V4.5ZM18 17C17.9997 17.2651 17.8943 17.5193 17.7068 17.7068C17.5193 17.8943 17.2651 17.9997 17 18H3C2.73487 17.9997 2.48068 17.8943 2.29321 17.7068C2.10573 17.5193 2.00028 17.2651 2 17V8H18V17Z"
        fill="#5BBAB9"
      />
    </svg>
  )
}
