import React from 'react'

import ActionCard from 'components/Common/ActionCard'
import useNavigateTo from 'hooks/useNavigateTo'
import { AppFC, IUpcomingEvent } from 'types'

import { getRoleFromPath } from 'utils/helper'
import { ROLES } from 'config'
import SectionTitleComponent from '../../Advisor/SectionTitle/SectionTitle.component'
import EventCard from 'components/Dashboard/Advisor/EventCard/EventCard.component'

export interface IUpcomingEventsProps {
  events: IUpcomingEvent[]
  noEventsMessage: string
  loading?: boolean
}

/** Displays event cards featuring details about a few most recent events for the user */
const UpcomingEvents: AppFC<IUpcomingEventsProps> = ({
  events = [],
  className = '',
  noEventsMessage = '',
  loading = false,
}) => {
  const navigate = useNavigateTo('calendar')
  const userRole = getRoleFromPath()

  const noEventsScheduled = !loading && events.length === 0

  return (
    <section
      id="admin-upcoming-events-section"
      className={`flex flex-col gap-5 max-w-[334px] lg:max-w-[350px] lg:min-w-[350px] ${className}`}
    >
      <div className="flex justify-between items-center gap-4">
        <SectionTitleComponent>Upcoming Events</SectionTitleComponent>
        {noEventsScheduled ? null : (
          <p
            onClick={navigate}
            className="text-primary hover:text-primary-brand hover:cursor-pointer text-base font-semibold whitespace-nowrap"
          >
            See All
          </p>
        )}
      </div>
      {noEventsScheduled && (
        <p className="italic text-black-light text-lg sm:text-xl -mt-2">
          No {userRole === ROLES.BUSINESS_OWNER ? 'events yet!' : 'meetings today'}
        </p>
      )}
      <div className="flex flex-col gap-4.5">
        {noEventsScheduled ? (
          <ActionCard onClick={navigate}>
            <div>
              <p className="leading-6 font-semibold text-lg">{noEventsMessage}</p>
            </div>
          </ActionCard>
        ) : (
          events.map((event, idx) => {
            return (
              <EventCard
                key={`event-card-${idx + 1}`}
                description={event.description}
                date={event.date}
                eventId={event.eventId}
              />
            )
          })
        )}
      </div>
    </section>
  )
}

export default UpcomingEvents
