import { takeLatest, put } from 'redux-saga/effects'

////////////// Handlers ///////////////////////

function* setError(action) {
  if (typeof action.type === 'string' && action.payload) {
    const actionType = action.type.replace('_REJECTED', '')
    const newAction = {
      type: `${actionType}_SET_ERROR`,
      payload: action.payload,
      state: actionType,
    }
    yield put(newAction)
  }
}

////////////// Watchers ///////////////////////

export function* watcherError() {
  yield takeLatest((action) => /REJECTED$/.test(action.type), setError)
}
