import styled from 'styled-components'
import tw from 'twin.macro'

export const Wrapper = styled.div.attrs({
  className: `xs:p-[8px] md:p-0 grid-cols-12 w-full h-full`,
})``

export const TabHeaderWrapper = styled.div.attrs({
  className: `col-span-12 items-center justify-between`,
})``

export const TabBodyWrapper = styled.div.attrs({
  className: `col-span-12 relative z-[5] xs:-mt-2 md:-mt-3 lg:-mt-5`,
})``

export const TabBodyContainer = styled.div.attrs({
  className: `xs:bock bg-white grid-cols-12 xs:p-4 pt-6 pb-2 lg:p-12  rounded-2xl mb-8 gap-12 shadow-lg  mdl:pb-6 lg:pb-3 xl:pb-10`,
})``

export const TitleWrapper = styled.h3`
  ${tw`font-light text-4xl leading-7 text-black mt-2.5 sm:mt-0`}
`

export const PageWrapper = styled.div`
  ${tw`w-full h-full p-3 sm:p-0`}
`
export const ReportHistoryWrapper = styled.div`
  ${tw`flex flex-col bg-white mt-[50px] rounded-t-lg`}
`
export const ReportHistoryHeadingWrapper = styled.div`
  ${tw`flex justify-between items-center p-4 sm:p-5 bg-cream-dark`}
`
export const ReportHistoryHeading = styled.div`
  ${tw`font-bold text-sm sm:text-base`}
`
export const NoReportHistoryHeading = styled.div`
  ${tw`p-4 sm:p-5 font-primary text-black-light italic text-lg`}
`
export const ReportHistoryGridRow = styled.div`
  ${tw`flex justify-between p-5 border-b border-grey-lightest`}
`
export const ReportHistoryGridRowWrapper = styled.div`
  ${tw`flex justify-between p-4 sm:p-5 border-b border-grey-lightest`}
`
export const EllipseWrapper = styled.div`
  ${tw`flex gap-1.5 sm:gap-2 items-center relative`}
`
export const DateWrapper = styled.p`
  ${tw`text-xs sm:text-base`}
`
export const ButtonWrapper = styled.p`
  ${tw`flex items-center gap-2 sm:gap-4`}
`
export const NoReportText = styled.p`
  ${tw`text-primary-text font-primary italic`}
`

export const ReportButtonWrapper = styled.button`
  ${tw`xs:flex-col sm:flex-row inline-flex justify-between gap-4 pl-4 xs:pr-2 sm:pr-0`}
`

export const ClientDataCardWrapper = styled.div`
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  ${tw`p-6 lg:p-4`}
`

export const ClientDataCardTitle = styled.div`
  ${tw`text-2xl not-italic font-black text-black-light font-secondary`}
`

export const ClientDataCardDescription = styled.div`
  ${tw`text-sm not-italic font-normal text-black-light font-primary mt-2`}
`

export const ClientDataCardHeader = styled.div`
  ${tw`mb-10`}
`

export const ClientDataCardBody = styled.div`
  ${tw`mx-auto mdl:mx-0 col-span-1`}
`
export const ButtonTabsWrapper = styled.div`
  ${tw`w-full flex overflow-auto`}
`
export const DataReportsWrapper = styled.div`
  ${tw`xs:p-2 sm:p-0 w-full h-full`}
`
export const DataReportsComponentWrapper = styled.div`
  ${tw`bg-white grid-cols-12 p-4 sm:p-8 mb-8 gap-12 shadow-lg mdl:pb-6 lg:pb-3 xl:pb-10 `}
`
export const IndustryReportWrapper = styled.div`
  ${tw`py-10 px-1 md:p-10 `}
`
export const HeadingWrapper = styled.div`
  ${tw`flex flex-col md:flex-row gap-3 md:items-center`}
`
export const BodyWrapper = styled.div`
  ${tw`grid grid-cols-12 pt-6`}
`
export const BodyContentWrapper = styled.div`
  ${tw`col-span-12 mdl:col-span-5`}
`
export const ContentWrapper = styled.p`
  ${tw`text-primary-text text-sm`}
`
export const BodyTextWrapper = styled.p`
  ${tw`text-primary-text pt-20 pb-7 font-medium	text-xl`}
`
export const AlertWrapper = styled.div`
  ${tw`flex xs:flex-col sm:flex-row gap-10 justify-evenly`}
`
export const FieldWrapper = styled.div`
  ${tw`flex items-center xs:flex-col sm:flex-row w-full gap-4 md:gap-3 mt-8`}
`

export const COLORS = [
  '#03337C',
  '#470C84',
  '#77FADB',
  '#52B4F9',
  '#7600ED',
  '#BB80F6',
  '#FF7E59',
  '#FFC900',
]
