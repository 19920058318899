import React from 'react'

function TickMarkIcon({ className }) {
  return (
    <svg
      className={className}
      width="26"
      height="18"
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.2325 0.368359C25.5911 0.731641 25.5911 1.3293 25.2325 1.69258L9.75033 17.6301C9.39743 17.9992 8.81685 17.9992 8.46395 17.6301L0.266725 9.19258C-0.0889085 8.8293 -0.0889085 8.23164 0.266725 7.86836C0.622132 7.49922 1.19873 7.49922 1.55448 7.86836L9.10714 15.6437L23.9461 0.368359C24.299 0.0015625 24.8796 0.0015625 25.2325 0.368359Z"
        fill="#059669"
      />
    </svg>
  )
}
export default TickMarkIcon
