import React, { MutableRefObject, useEffect } from 'react'
import Button from 'components/Common/Button'
import { useForm, FormProvider } from 'react-hook-form'
import { AppFC } from 'types'
import { isEmpty } from 'lodash'
import {
  CreateAccountEmailWrapper,
  CreateAccountErrorWrapper,
  CreateAccountHeadingWrapper,
  CreateAccountParagraphWrapper,
} from 'components/Common/CreateAccount/style'
import { useFlashParams } from 'hooks/useFlashParams'

interface ICreateAccount {
  userEmail: string
  disableCreateButton: boolean
  handleOnSubmits: (data) => void
  isError?: MutableRefObject<boolean>
  apiError?: string
}

const CreateAccount: AppFC<ICreateAccount> = ({
  userEmail,
  disableCreateButton,
  handleOnSubmits,
  isError,
  apiError,
}) => {
  const { flashParams } = useFlashParams<{ validationErrorMessage?: string }>()
  useEffect(() => {
    if (isError && flashParams.validationErrorMessage) {
      isError.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flashParams])
  const methods = useForm({
    mode: 'onBlur',
  })
  const { handleSubmit } = methods
  return (
    <>
      <CreateAccountHeadingWrapper>Create Account</CreateAccountHeadingWrapper>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleOnSubmits)}>
          <div className="grid grid-cols-1 gap-12 mt-8">
            <CreateAccountParagraphWrapper>
              The email address that will be used to create the account:
            </CreateAccountParagraphWrapper>
            <CreateAccountEmailWrapper>{userEmail}</CreateAccountEmailWrapper>

            {flashParams?.validationErrorMessage && (
              <CreateAccountErrorWrapper>
                {flashParams?.validationErrorMessage}
              </CreateAccountErrorWrapper>
            )}
            {apiError && apiError?.includes('mobileContactNumber') && (
              <CreateAccountErrorWrapper>Invalid mobile number</CreateAccountErrorWrapper>
            )}
            <Button
              type="submit"
              label={disableCreateButton ? '...' : 'CREATE ACCOUNT'}
              disabled={disableCreateButton || !isEmpty(flashParams?.validationErrorMessage)}
              testId="createAccountButton"
              variant="primary"
              className="w-full"
            />
          </div>
        </form>
      </FormProvider>
    </>
  )
}
export default CreateAccount
