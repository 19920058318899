import React from 'react'

const SelectInfo = ({ selectInfo, allowMultiSelect }) => {
  return (
    <div id="select-info" className="flex mt-6">
      <div className=" text-left italic sm:w-[100%] md:w-[100%] lg:w-[100%]">
        {selectInfo ? selectInfo : allowMultiSelect ? 'Select Multiple' : 'Select one.'}
      </div>
    </div>
  )
}

export default SelectInfo
