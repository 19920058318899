import { USER_STATUS } from 'config'
import { createSelector } from 'reselect'
import { sortList } from 'utils/helper'

const getAdvisors = (state) => state.userManagement.advisors
const getBusinessDetails = (state) => state.userManagement.businesses

export const getAdvisorsList = createSelector([getAdvisors], (advisors) => {
  try {
    if (advisors && advisors?.list) {
      let list: any = []

      if (advisors && advisors?.length === 0) {
        return []
      }

      advisors?.list?.forEach((obj) => {
        if (obj.status === USER_STATUS.ACTIVE) {
          let row = {
            label: obj.firstName + ' ' + obj.lastName || '',
            value: obj.username,
          }
          list.push(row)
        }
      })
      sortList(list)

      return list
    } else {
      return []
    }
  } catch (err: any) {
    console.log(err.message)
    return []
  }
})
export const getBusinessesList = createSelector([getBusinessDetails], (business) => {
  try {
    if (business && business?.list) {
      let list: any = []

      if (business && business?.length === 0) {
        return []
      }

      business?.list?.forEach((obj) => {
        if (obj.status === USER_STATUS.ACTIVE) {
          let row = {
            label: obj.name + ' ',
            value: obj.userName,
          }
          list.push(row)
        }
      })
      return list
    } else {
      return []
    }
  } catch (err: any) {
    console.log(err.message)
    return []
  }
})
