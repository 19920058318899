import React from 'react'
import { AppFC } from 'types'

const GrowingContainer: AppFC = ({ children, className, ...rest }) => {
  return (
    <div {...rest} className={`w-full flex-grow-1 ${className}`}>
      {children}
    </div>
  )
}

GrowingContainer.defaultProps = {
  className: '',
}

export default GrowingContainer
