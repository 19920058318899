import {
  CHANGE_PASSWORD,
  GET_USER_FILES,
  SAVE_USER_FILE,
  UPLOAD_FILE,
  TOTAL_UPCOMING_MEETINGS,
  RESET_IMAGE_URL,
  INPUT_FIELD_BG_COLOR,
  RESET_ERROR,
  SET_TOOL_CONNECTION_STATUS,
} from 'store/types'

export const changePasswordAction = {
  STARTED: (payload: any) => ({ type: CHANGE_PASSWORD.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: CHANGE_PASSWORD.FULLFILLED, payload }),
  REJECTED: (payload: string) => ({ type: CHANGE_PASSWORD.REJECTED, payload }),
  RESET: () => ({ type: CHANGE_PASSWORD.RESET }),
}

export const uploadFileAction = {
  STARTED: (payload: any) => ({ type: UPLOAD_FILE.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: UPLOAD_FILE.FULLFILLED, payload }),
  REJECTED: () => ({ type: UPLOAD_FILE.REJECTED }),
  RESET: () => ({ type: UPLOAD_FILE.RESET }),
}
export const saveUserFileAction = {
  STARTED: (payload: any) => ({ type: SAVE_USER_FILE.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: SAVE_USER_FILE.FULLFILLED, payload }),
  REJECTED: () => ({ type: SAVE_USER_FILE.REJECTED }),
}
export const getUserFilesAction = {
  STARTED: (payload: any) => ({ type: GET_USER_FILES.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_USER_FILES.FULLFILLED, payload }),
  REJECTED: () => ({ type: GET_USER_FILES.REJECTED }),
}

export const totalUpComingMeetingsAction = (payload) => ({
  type: TOTAL_UPCOMING_MEETINGS,
  payload,
})
export const resetImageUrlAction = () => ({
  type: RESET_IMAGE_URL,
})
export const inputFieldbgColorAction = (payload) => ({
  type: INPUT_FIELD_BG_COLOR,
  payload,
})

export const resetErrorAction = () => ({
  type: RESET_ERROR,
})

export const setToolConnectionStatus = (payload: { toolName: string; isConnected: boolean }) => ({
  type: SET_TOOL_CONNECTION_STATUS,
  payload,
})
