import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const UserManagementLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 200"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="0" y="10" rx="0" ry="0" width="450" height="1" />
        <rect x="4" y="26" rx="0" ry="0" width="27" height="5" />
        <rect x="114" y="29" rx="0" ry="0" width="55" height="6" />
        <rect x="114" y="39" rx="0" ry="0" width="55" height="6" />
        <rect x="322" y="26" rx="0" ry="0" width="66" height="15" />
        <rect x="393" y="26" rx="0" ry="0" width="32" height="16" />

        <rect x="0" y="49" rx="0" ry="0" width="450" height="1" />
        <rect x="4" y="55" rx="0" ry="0" width="27" height="5" />
        <rect x="114" y="57" rx="0" ry="0" width="55" height="6" />
        <rect x="114" y="69" rx="0" ry="0" width="55" height="6" />
        <rect x="322" y="54" rx="0" ry="0" width="66" height="15" />
        <rect x="393" y="54" rx="0" ry="0" width="32" height="16" />

        <rect x="0" y="80" rx="0" ry="0" width="450" height="1" />
        <rect x="4" y="90" rx="0" ry="0" width="27" height="5" />
        <rect x="114" y="87" rx="0" ry="0" width="55" height="6" />
        <rect x="114" y="100" rx="0" ry="0" width="55" height="6" />
        <rect x="322" y="87" rx="0" ry="0" width="66" height="18" />
        <rect x="393" y="87" rx="0" ry="0" width="32" height="16" />

        <rect x="0" y="112" rx="0" ry="0" width="450" height="1" />
        <rect x="4" y="120" rx="0" ry="0" width="27" height="5" />
        <rect x="114" y="120" rx="0" ry="0" width="55" height="6" />
        <rect x="114" y="132" rx="0" ry="0" width="55" height="6" />
        <rect x="322" y="118" rx="0" ry="0" width="66" height="18" />
        <rect x="393" y="118" rx="0" ry="0" width="32" height="16" />

        <rect x="0" y="142" rx="0" ry="0" width="450" height="1" />
      </ContentLoader>
    </>
  )
}

export default UserManagementLoader
