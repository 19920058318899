import React from 'react'

import { AppAlertIcon } from 'components/Common/AppSvgIcons/AppAlertIcon'
import {
  AppFieldWrapper,
  AppErrorWrapper,
  AppFieldParentWrapper,
  AppPrefixDividerWrapper,
  AppPrefixWrapper,
  AppErrorMessageWrapper,
  AppLabelWrapper,
  AppInputWrapper,
} from '../AppCommonStyles/AppFormField.style'
import appColors from 'appColors'

interface IconProps {
  color?: string
}

export interface AppTextFieldProps {
  mask?: string
  value?: string | number
  error?: string
  label?: string
  disabled?: boolean
  placeholder?: string
  prefix?: string
  size: 'lg' | 'md' | 'sm'
  type?: 'text' | 'password' | 'email' | 'date' | 'number'
  onChange?: (value) => void
  Icon?: React.ComponentType<IconProps>
  onBlur?: (value) => void
  name?: string
}

export const AppTextField: React.FC<AppTextFieldProps> = ({
  size,
  mask,
  Icon,
  value,
  label,
  disabled,
  onChange,
  onBlur,
  error = '',
  placeholder,
  type = 'text',
  prefix,
  name,
}) => {
  return (
    <div className="w-full">
      {label && <AppLabelWrapper>{label}</AppLabelWrapper>}
      <AppFieldParentWrapper>
        <AppFieldWrapper prefix={prefix} size={size} error={!!error} disabled={!!disabled}>
          {Icon && <Icon />}
          {prefix && (
            <>
              <AppPrefixWrapper size={size}>{prefix}</AppPrefixWrapper>
              <AppPrefixDividerWrapper size={size}>
                <div className="self-stretch bg-app-grey-40 relative w-px" />
              </AppPrefixDividerWrapper>
            </>
          )}
          <AppInputWrapper
            type={type}
            mask={mask}
            value={value}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            prefix={prefix}
            placeholder={placeholder || 'Placeholder'}
            data-testid={name}
          />
        </AppFieldWrapper>
        {error && <AppErrorMessage error={error} />}
      </AppFieldParentWrapper>
    </div>
  )
}

export const AppErrorMessage = ({ error }) => {
  return (
    <AppErrorWrapper>
      <AppAlertIcon color={appColors['app-red']['70']} />
      <AppErrorMessageWrapper>{error}</AppErrorMessageWrapper>
    </AppErrorWrapper>
  )
}
