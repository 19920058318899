import { ampli } from 'ampli'
import { PathNames } from 'config'
import history from 'utils/history'
import { getTabName } from 'utils/helper'

/** "item" is a nav item defined in config data - todo: type it */
export default function navigateTo(item: any) {
  if (item?.path) {
    if (item.route === PathNames.Dashboard) {
      ampli.dashboardViewed({ previousPath: getTabName(), triggeredFrom: 'Navbar' })
    }
    ampli.topNavViewed({ tabName: item.label })
    history.push(item.path)
  }
}
