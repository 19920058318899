import React, { Fragment } from 'react'

// import AlphabetList from 'react-alphabet-list'
import { isMobileOnly, isTablet } from 'mobile-device-detect'

import { DropDownIcon } from 'components/Common/SvgIcons'
import { DictionaryWrapper } from 'components/Clients/style'
import Loader from 'components/Loaders'
import DictionaryList from 'components/Common/DictionaryList'
import TackleAvatarLogo from 'components/Common/TackleAvatarLogo'

export default function DirectoryList({ alphabetListData, filteredList, clientList }) {
  return (
    <DictionaryWrapper>
      <div className="grid grid-cols-12 xl:h-[100vh]">
        <div
          id="client-directory"
          className="xs:col-span-12 md:col-span-11 border-b border-t border-secondary-text sm:h-[112vh] h-[100vh] md:h-[100vh] mdl:h-[78.3vh] xl:h-[100vh] xxl:h-[100vh overflow-y-scroll"
        >
          {
            filteredList?.length > 0 &&
              alphabetListData.map((item, index) => {
                const client = filteredList?.find(
                  ({ firstName, id }) => `${firstName || ''}${id}` === item
                )
                const logo = client?.logo?.url || ''
                return (
                  <Fragment key={item + index}>
                    <DictionaryList client={client}>
                      <div className="inline-flex">
                        <div className="relative h-[51px] w-[51px]">
                          <TackleAvatarLogo
                            logo={logo}
                            className="bg-blue-lighter1x h-[51px] w-[50.57px] icon-color  group-hover:bg-blue-lighter1x"
                            firstName={client.firstName}
                            lastName={client.lastName}
                          />
                        </div>
                        <div className="flex-col ml-4 inline-flex cursor-pointer">
                          <div>
                            <p className="text-zinc font-primary text-lg font-semibold">
                              {`${client?.firstName || ''} ${client?.lastName || ''}`}
                            </p>
                          </div>
                          <div>
                            <p className="text-sm font-semibold text-zinc font-primary">
                              {client?.businessName || ''}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${
                          isMobileOnly ? 'mr-10' : isTablet ? 'mr-6' : ''
                        } xxl:mr-4 flex items-center`}
                      >
                        <DropDownIcon className="h-[25px] w-[20px] fill-primary rotate-[270deg]" />
                      </div>
                    </DictionaryList>
                  </Fragment>
                )
              })
            // <AlphabetList
            //   className="alpha-list"
            //   data={alphabetListData}
            //   generateFn={(item, index) => {
            //     const client = filteredList?.find(
            //       ({ firstName, id }) => `${firstName}${id}` === item
            //     )
            //     return (
            //       <Fragment key={item + index}>
            //         <DictionaryList client={client}>
            //           <div className="inline-flex">
            //             <div className="relative">
            //               <TackleAvatarLogo className="bg-primary h-[51px] w-[50.57px] icon-color  group-hover:bg-primary-brand" />
            //             </div>
            //             <div className="flex-col ml-4 inline-flex cursor-pointer">
            //               <div>
            //                 <p className="text-zinc font-primary text-lg font-semibold">
            //                   {`${client?.firstName || ''} ${client?.lastName || ''}`}
            //                 </p>
            //               </div>
            //               <div>
            //                 <p className="text-sm font-semibold text-zinc font-primary">
            //                   {client?.businessName || ''}
            //                 </p>
            //               </div>
            //             </div>
            //           </div>
            //           <div
            //             className={`${
            //               isMobileOnly ? 'mr-10' : isTablet ? 'mr-6' : ''
            //             } xxl:mr-4 flex items-center`}
            //           >
            //             <DropDownIcon className="h-[25px] w-[20px] fill-primary rotate-[270deg]" />
            //           </div>
            //         </DictionaryList>
            //       </Fragment>
            //     )
            //   }}
            // />
          }
          {!filteredList && <Loader loader="DirectoryList" />}
          {filteredList?.length === 0 && clientList?.length === 0 && (
            <p className="text-base font-semibold text-xl p-4">You have no active clients.</p>
          )}
          {filteredList?.length === 0 && clientList?.length > 0 && (
            <p className="text-base font-semibold text-xl p-4">0 results found.</p>
          )}
        </div>

        <div className="xs:cols-span-0 md:col-span-1"></div>
      </div>
    </DictionaryWrapper>
  )
}
