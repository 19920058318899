import { ApiException } from './api-exception'
import { ApiResponseContext, ApiResponseError } from './api-response-error'
import { errorHandlers } from './error-handlers'
import * as Sentry from '@sentry/browser'

export function handleErrors(errors: ApiResponseError[], context: ApiResponseContext) {
  for (const error of errors) {
    const errorHandler = errorHandlers[error.errorType]
    if (errorHandler) {
      errorHandler(error)
      return
    }
  }
  const messages = errors.map(({ message }) => message).join(', ')
  try {
    throw new ApiException(messages, context.apiName)
  } catch (err: any) {
    Sentry.setExtra('context', context)
    Sentry.captureException(err, {
      fingerprint: [err.message],
    })
    throw err
  }
}
