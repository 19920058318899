import React from 'react'
import { TickIcon, EllipseIcon } from 'components/Common/SvgIcons'
import {
  UPPER_CASE_REGEX,
  NUMBER_REGEX,
  SPECIAL_CHARACTER_REGEX,
  MINIMUM_8_CHARACTERS_REGEX,
} from 'config'
import { PasswordWrapper } from 'components/Auth/ResetPassword/style'
import { AppFC } from 'types'

const PasswordRules = ({ passwordCheck }) => {
  const checkMatch = (regex) => passwordCheck && passwordCheck.match(regex)
  return (
    <PasswordWrapper>
      <p className="xs:text-base sm:text-xl italic inline font-primary pb-1 font-normal text-black-light">
        Password Rules:
      </p>
      <Rule
        rule="At least 8 characters"
        passwordMatchesRule={checkMatch(MINIMUM_8_CHARACTERS_REGEX)}
      />
      <Rule rule="At least one number" passwordMatchesRule={checkMatch(NUMBER_REGEX)} />
      <Rule
        rule="At least one upper case letter"
        passwordMatchesRule={checkMatch(UPPER_CASE_REGEX)}
      />
      <Rule
        rule="At least one symbol: ! $ @ # % &"
        passwordMatchesRule={checkMatch(SPECIAL_CHARACTER_REGEX)}
      />
    </PasswordWrapper>
  )
}

interface IRuleProps {
  rule: string
  passwordMatchesRule: boolean
}

const Rule: AppFC<IRuleProps> = ({ rule, passwordMatchesRule }) => {
  return (
    <div className="flex flex-column gap-2 relative">
      <EllipseIcon
        className={`${
          passwordMatchesRule ? 'fill-tertiary' : 'fill-primary-disabled'
        }  h-6 w-6 inline`}
      />
      <TickIcon className={`stroke-[12px] stroke-white w-4 h-4  absolute top-[6px] left-1`} />
      <div>
        <p className="xs:text-base sm:text-xl font-primary font-medium text-black-light">{rule}</p>
      </div>
    </div>
  )
}

export default PasswordRules
