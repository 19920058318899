import React, { useState, useEffect } from 'react'
import { parse, stringify } from 'querystring'
import decodeUriComponent from 'decode-uri-component'
import history from '../history'
import { isEmpty, forEach } from 'lodash'
import { FlashParamsContext } from 'hooks/useFlashParams'

export const FlashParamsProvider: React.FC = ({ children }) => {
  const [flashParams, setFlashParams] = useState<Record<string, string>>({})

  useEffect(() => {
    const parsedParams = parseFlashParams()
    if (parsedParams) {
      setFlashParams(parsedParams)
    }
    removeFlashParams()
    // eslint-disable-next-line
  }, [])

  const updateFlashParams = (): void => {
    const parsedParams = parseFlashParams()
    if (parsedParams) {
      setFlashParams(parsedParams)
      removeFlashParams()
    }
  }

  useEffect(() => {
    updateFlashParams() // Initial parse

    // Listen for history changes
    const unlisten = history.listen(() => {
      updateFlashParams() // Parse when history changes
    })

    return () => {
      unlisten() // Cleanup listener
    }
    // eslint-disable-next-line
  }, [])

  const parseFlashParams = (): Record<string, string> | null => {
    try {
      const queryString = window.location.search.slice(1)
      const queryParams = parse(queryString.slice(0))
      const flashParams = queryParams.flash
      if (flashParams) {
        const decodedParams = Array.isArray(flashParams)
          ? flashParams.map((param) => decodeUriComponent(param))
          : decodeUriComponent(flashParams)
        const pairs = decodedParams.split('&')
        const result: Record<string, string> = {}
        for (const pair of pairs) {
          const [key, value] = pair.split('=')
          result[key] = value
        }
        return result
      }
      return null
    } catch (error) {
      console.error('Failed to parse flash params:', error)
      return null
    }
  }

  const removeFlashParams = (fieldKey?: string | undefined): void => {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    params.forEach((_, key) => {
      if (key.startsWith('flash')) {
        params.delete(key)
      }
    })
    let updatedParams: Record<string, string> = {}
    if (fieldKey) {
      forEach(Object.keys(flashParams || {}), (key) => {
        if (key !== fieldKey) updatedParams[key] = flashParams[key]
      })
    }

    const newUrl = params.toString()
      ? `${url.pathname}?${params.toString()}${url.hash}`
      : url.pathname
    window.history.replaceState({}, '', newUrl)

    if (!isEmpty(updatedParams)) setFlashUrlParams(updatedParams)
  }

  const setFlashUrlParams = (flashParams: Record<string, string>): void => {
    try {
      const url = new URL(window.location.href)
      const inputParams = { ...flashParams, ...flashParams }
      const encodedParams = encodeURIComponent(stringify(inputParams))
      const urlSearchParams = new URLSearchParams(window.location.search)
      const hasQueryParams = urlSearchParams.toString().length > 0
      const pathName = url.pathname !== '/' ? url.pathname : ''
      const newUrl = `${pathName}${window.location.search ?? ''}${
        hasQueryParams ? '&' : '?'
      }flash=${encodedParams}`
      history.push(newUrl)
    } catch (err) {
      console.error('Failed to set flash params:', err)
    }
  }

  return (
    <FlashParamsContext.Provider value={{ flashParams, setFlashParams }}>
      {children}
    </FlashParamsContext.Provider>
  )
}
