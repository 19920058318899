import moment from 'moment-timezone'
import {
  AccountingCharts,
  CashBalanceByMonthReport,
  ChartStatus,
  QueryAccountingReportInput,
} from '../../../../../__generated__/api-types-and-hooks'
import React, { useEffect } from 'react'
import BarChart from 'components/Common/BarChart'
import Loader from 'components/Loaders'
import SectionFooter from 'components/Owner/Data/SectionFooter'
import DatePicker, { useDatePickerHandler } from 'components/Common/DatePicker'
import { chartRangeChanged, getMonthName, getRoleFromPath } from 'utils/helper'
import withChartWrapper, { WithChartWrapperProps } from '../../ChartHOC'
import OwnerApi from 'api/ownerData'
import { useFetchChartData } from 'hooks/useFetchChartData'
import { OperationName } from 'types'
import { IDataTabs, ROLES } from 'config'
import { RootState } from 'App'
import { useSelector } from 'react-redux'

type CashBalanceByMonthData = { data?: CashBalanceByMonthReport[]; status: ChartStatus }

const onFetchDataMethod = async (
  params: QueryAccountingReportInput
): Promise<CashBalanceByMonthData | undefined> => {
  const data = await OwnerApi.getOwnerAccountingData<CashBalanceByMonthReport[]>(params)
  return data?.getAccountingCharts
}

const ACCOUNTING_CHARTS_QUERY_KEY: OperationName = 'getAccountingCharts'

interface ICashBalanceByMonthChartProps
  extends WithChartWrapperProps<QueryAccountingReportInput, CashBalanceByMonthData> {}

const CashBalanceByMonthTestChart = withChartWrapper(
  onFetchDataMethod,
  ({ onFetchData, chartData }: ICashBalanceByMonthChartProps) => {
    const { selectedDate, handleClick, formattedDate, handleNextDate, handlePreviousDate } =
      useDatePickerHandler()
    const section = IDataTabs.OVERVIEW
    const chartName = [AccountingCharts.CashBalanceByMonth]

    const handleNext = () => {
      const { startDate, endDate } = handleNextDate()
      chartRangeChanged({ section, chartName, endDate, startDate })
    }

    const handlePrevious = () => {
      const { startDate, endDate } = handlePreviousDate()
      chartRangeChanged({ section, chartName, endDate, startDate })
    }

    const { mutate: fetchData, isLoading } = useFetchChartData(
      onFetchData,
      ACCOUNTING_CHARTS_QUERY_KEY
    )

    const userId =
      getRoleFromPath() === ROLES.BUSINESS_OWNER
        ? useSelector((state: RootState) => state.user.user.id)
        : useSelector((state: RootState) => state.clients.client.profile.id)

    useEffect(() => {
      fetchData({
        chartType: AccountingCharts.CashBalanceByMonth,
        year: moment(selectedDate.startDate).year(),
        userId,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate.startDate])

    const graphData =
      chartData?.data?.map((data, index) => ({
        value: data.cashBalance,
        name: getMonthName(index)?.toUpperCase(),
      })) ?? []

    const source = chartData?.data?.length ? chartData?.data[0]?.source : 'QuickBooks'

    return (
      <div className="grow flex flex-col gap-12 p-6">
        <div>
          <div className="flex flex-col sm:flex-row">
            <h5 className="mb-6 font-semibold text-lg">WHAT IS MY CASH BALANCE?</h5>
            <div className="flex mb-6 sm:ml-auto">
              <DatePicker
                handleClick={handleClick}
                value={formattedDate}
                onNextDate={handleNext}
                onPreviousDate={handlePrevious}
                showFullCalendar={false}
              />
            </div>
          </div>
          <div className="flex flex-col">
            {isLoading && <Loader loader="ChartLoader" />}
            <div className={`h-[300px] relative ${isLoading && 'hidden'} `}>
              <BarChart data={graphData} xDataKey="name" yDataKey="value" />
            </div>
          </div>
        </div>
        <div></div>
        <div>
          <SectionFooter
            source={source ?? ''}
            lastUpdated={moment().format('YYYY-MM-DD')}
            getChartDataAction={() =>
              fetchData({
                chartType: AccountingCharts.CashBalanceByMonth,
                year: moment(selectedDate.startDate).year(),
                userId,
              })
            }
          />
        </div>
      </div>
    )
  }
)

export default CashBalanceByMonthTestChart
