import React, { ReactNode } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

export interface IAppAlertBannerProps {
  type: 'success' | 'error' | 'info' | 'warn'
  title?: string
  children?: ReactNode | undefined
}

const AppAlertBannerWrapper = styled.div<IAppAlertBannerProps>`
  ${(props) => props.type === 'success' && tw`border-green text-green`}
  ${(props) => props.type === 'error' && tw`border-gerberared text-gerberared`}
  ${tw`border-l-4 p-4`}
`

export const AppAlertBanner = ({ type, title, children }: IAppAlertBannerProps) => {
  return (
    <AppAlertBannerWrapper type={type} role="alert">
      {title && <p className="font-bold">{title}</p>}
      {children}
    </AppAlertBannerWrapper>
  )
}
