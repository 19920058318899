import React from 'react'
import {
  AppFieldWrapper,
  AppLabelWrapper,
  AppValueWrapper,
} from 'components/AppAccount/AppAccount.style'

export const AppBusinessProfileField = ({ label, value }) => {
  return (
    <AppFieldWrapper>
      <AppLabelWrapper>{label}</AppLabelWrapper>
      <AppValueWrapper>{value}</AppValueWrapper>
    </AppFieldWrapper>
  )
}
