import API from 'api'

import * as api from '__generated__/api-types-and-hooks'

class TOOL_API {
  getTools = (): Promise<any> => {
    return API.fetch(api['GetToolsDocument'], {})
  }
}

// eslint-disable-next-line
export default new TOOL_API()
