import styled from 'styled-components'

export const SelectFieldWrapper = styled.div`
  .select__multi-value__remove {
    :hover {
      background: none;
    }
  }
  .select__clear-indicator {
    display: none;
  }
`
