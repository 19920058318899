import React, { Dispatch, FC, SetStateAction } from 'react'

import OverlayPage from 'components/Common/OverlayPage'
import Heading from 'components/Common/Heading'
import { AppFC } from 'types'

export const DISCLAIMER_TEST_ID = 'DISCLAIMER_TEST_ID'
export const LAST_REVISED_TEST_ID = 'LAST_REVISED_TEST_ID'

export interface IPolicyOverlayPageProps {
  title: string
  bannerImageSrc: string
  /** @example "December 27, 2022" */
  lastRevised: string
  user: Object
  setShow: Dispatch<SetStateAction<boolean>>
}

const PolicyOverlayPage: AppFC<IPolicyOverlayPageProps> = ({
  bannerImageSrc,
  lastRevised,
  children,
  title,
  user = {},
  setShow,
}) => {
  return (
    <OverlayPage bannerImageSrc={bannerImageSrc} user={user} setShow={setShow}>
      <article className={'flex flex-col gap-6 max-w-[804px] m-auto mb-5 xs:mb-10'}>
        <Heading>{title}</Heading>
        <LastRevised data-testid={DISCLAIMER_TEST_ID} lastRevised={lastRevised} />
        {children}
      </article>
    </OverlayPage>
  )
}

const LastRevised: FC<{ lastRevised: string }> = ({ lastRevised }) => (
  <span data-testid={LAST_REVISED_TEST_ID} className="text-xs md:text-base">
    Last Revised on {lastRevised}
  </span>
)
PolicyOverlayPage.defaultProps = {
  user: {},
  setShow: () => {},
}

export default PolicyOverlayPage
