import { createSelector } from 'reselect'
import moment from 'moment-timezone'
import { isEmpty, sortBy, isFinite } from 'lodash'
import { getOptions } from 'utils/helper'
import { IGetAssessmentOptions } from 'components/MonthlyAttestation/utils'
import { USER_ROLES } from 'config'

export const getAttestationSummaryData = (state) => state.attestationSummary
export const getAssessmentOptions = (state) => state.owner.assessmentOptions
export const getUserData = (state) => state.user?.user

export interface IReportHistoryList {
  signedDocumentUrl: string
  date: string
}
export const getAttestationSummarySelector = createSelector(
  [getAttestationSummaryData, getUserData],
  (attestationSummary, user) => {
    const year = moment().format('YYYY')
    const month = moment().format('MMMM')

    let timeZone = moment.tz.guess()
    const userTimeZone = user?.timezone || timeZone
    const currentDate = moment(new Date()).tz(userTimeZone)?.format('YYYY-MM-DD')

    let variant: 'success' | 'info' | 'warning' | 'alert' | 'none' | undefined = 'success'
    const fileName = attestationSummary?.attestationSummary?.newAggregatedReport?.fileName

    const submissionStartDate = moment(user?.submissionStartDay, 'DD').format('YYYY-MM-DD')

    const submissionReminderDate = moment(user?.submissionReminderDay, 'DD').format('YYYY-MM-DD')

    const submissionEndDate = moment(user?.submissionEndDay, 'DD').format('YYYY-MM-DD')

    const submissionReportDate = moment(user?.submissionReportDay, 'DD').format('YYYY-MM-DD')

    const nextMonthStartDate = moment(submissionStartDate)
      .set('date', user?.submissionStartDay)
      .add(1, 'month')
      .format('MMMM D, YYYY')

    const currentMonthStartDate = moment(submissionStartDate)
      .set('date', user?.submissionStartDay)
      .format('MMMM D, YYYY')

    const submissionEndDay: string = moment(submissionEndDate).format('D')
    const submissionReportDay: string = moment(submissionReportDate).format('D')

    let date: string = ''
    let message: string = ''
    let infoText: string = ''
    let showCountCard: boolean = true
    let showReportButton: boolean = false
    let reportHistoryList: IReportHistoryList[] = []

    let signedDocumentUrl: string =
      attestationSummary?.attestationSummary?.newAggregatedReport?.signedDocumentUrl
    let error = attestationSummary?.attestationSummary?.newAggregatedReport?.error
    if (typeof error === 'string') {
      error = JSON.parse(error)
      error = error.message || error.data
    }
    let downloadUrl: string =
      attestationSummary?.attestationSummary?.newAggregatedReport?.downloadUrl
    let showViewDetailsButton: boolean = true
    const unsignedCount = attestationSummary?.attestationSummary?.unsignedCount
    const signedCount = attestationSummary?.attestationSummary?.signedCount

    let isClientAssigned =
      (user?.roles.includes(USER_ROLES.BSO_ADMIN) && currentDate > submissionReportDate) ||
      (user?.roles.includes(USER_ROLES.BSO_ADVISOR) &&
        ((unsignedCount === 0 && signedCount === 0) || !isFinite(unsignedCount)))
        ? false
        : true

    if (unsignedCount === 0 && signedCount > 0 && currentDate <= submissionEndDate) {
      showViewDetailsButton = false
      variant = 'success'
      infoText = `All clients have successfully signed their monthly impact statements. A report will be generated on `
      date = `${month} ${submissionReportDay}, ${year}.`
    } else if (currentDate > submissionStartDate && currentDate < submissionReminderDate) {
      variant = 'info'
      infoText = `A monthly impact statement is being sent to your clients. This collection of statements is due on `
      date = `${month} ${submissionEndDay}, ${year}`
    } else if (currentDate >= submissionReminderDate && currentDate < submissionEndDate) {
      variant = 'warning'
      infoText = `A reminder to complete the monthly impact statement has been sent to your clients who have not yet signed. This collection of statements is due on `
      date = `${month} ${submissionEndDay}, ${year}.`
    } else if (currentDate === submissionEndDate) {
      variant = 'success'
      infoText = `Today is the last day for Clients to sign the monthly impact statement. A full report will be generated `
      date = `${month} ${submissionReportDay}, ${year}.`
    } else if (currentDate === submissionReportDate) {
      variant =
        isEmpty(signedDocumentUrl) && isEmpty(error)
          ? 'info'
          : !isEmpty(error)
          ? 'alert'
          : 'success'

      infoText =
        isEmpty(signedDocumentUrl) && isEmpty(error)
          ? 'We are working on processing your monthly impact report. You will receive a notification when it is ready.'
          : !isEmpty(error)
          ? error
          : 'A monthly impact report has been generated.'
      showReportButton = isEmpty(error)
      signedDocumentUrl = isEmpty(signedDocumentUrl) ? '' : signedDocumentUrl
      downloadUrl = isEmpty(downloadUrl) ? '' : downloadUrl
    } else if (currentDate < submissionStartDate) {
      variant = 'none'
      infoText = `Your current monthly impact report collection deadline is `
      message = `A reminder will be sent to your clients on ${currentMonthStartDate}.`
      date = `${moment(submissionEndDate)
        .set('date', user?.submissionEndDay)
        .format('MMMM D, YYYY')}.`
      showCountCard = false
    } else if (currentDate === submissionStartDate) {
      if (!isFinite) {
        variant = 'info'
        infoText = `We are working on processing your monthly impact report. You will receive a notification when it is ready.`
        showCountCard = false
      } else {
        variant = 'info'
        infoText = `A monthly impact statement is being sent to your clients. This collection of statements is due on `
        date = `${month} ${submissionEndDay}, ${year}`
      }
    } else {
      variant = 'none'
      infoText = `Your next monthly impact report collection deadline is `
      message = `A reminder will be sent to your clients on ${nextMonthStartDate}.`
      date = `${moment(submissionEndDate)
        .set('date', user?.submissionEndDay)
        .add(1, 'month')
        .format('MMMM D, YYYY')}.`
      showCountCard = false
    }

    reportHistoryList = attestationSummary?.attestationSummary?.aggregationReportHistory?.map(
      (data) => ({
        signedDocumentUrl: data?.signedDocumentUrl,
        date: moment(data.createdAt).subtract(1, 'months').format('MMMM YYYY'),
        downloadUrl: data?.downloadUrl,
      })
    )
    reportHistoryList = sortBy(reportHistoryList, (item) => {
      const [month, year] = item.date.split(' ')
      return new Date(`${month} 1, ${year}`)
    }).reverse()

    return {
      fileName,
      variant,
      infoText,
      message,
      date,
      showReportButton,
      showCountCard,
      signedDocumentUrl,
      reportHistoryList,
      showViewDetailsButton,
      downloadUrl,
      isClientAssigned,
      submissionReportDate,
      currentDate,
    }
  }
)

export interface IToolTipData {
  [key: string]: string
}

export const getToolTipTextSelector = createSelector(
  [getAssessmentOptions],
  (assessmentOptions: IGetAssessmentOptions[]) => {
    let toolTipData: IToolTipData = {}
    assessmentOptions = assessmentOptions.filter((option) => !isEmpty(option.toolTipText))

    assessmentOptions?.forEach((opt) => {
      toolTipData[opt.type] = opt.toolTipText
    })
    return toolTipData
  }
)
export const getAttestationOptionsSelector = createSelector(
  [getAssessmentOptions],
  (assessmentOptions: IGetAssessmentOptions[]) => {
    assessmentOptions = assessmentOptions = assessmentOptions.filter((option) =>
      option?.dependencies?.includes('attestation')
    )
    return getOptions(assessmentOptions)
  }
)
