import React, { useState } from 'react'

import Toast from 'components/Common/Toast'

import { ContentBoxWrapper, HeaderWrapper, SubheaderWrapper, ParagraphWrapper } from './styles'

/** A toast that invites users to take some plays, along with helpful info. */
const TakePlaysToast = () => {
  const [hidden, setHidden] = useState(false)
  return hidden ? (
    <div />
  ) : (
    <Toast variant={'overlay'} onClose={() => setHidden(true)}>
      <ContentBoxWrapper>
        <HeaderWrapper>Let's tackle some plays!</HeaderWrapper>
        <SubheaderWrapper>What is a play?</SubheaderWrapper>
        <ParagraphWrapper>
          Plays are part of how GoTackle helps you reach your business goals. Running GoTackle plays
          will help you go step-by-step through business processes, setting up tech tools, and
          learning how to use tech tools in running your business.
        </ParagraphWrapper>
      </ContentBoxWrapper>
    </Toast>
  )
}

export default TakePlaysToast
