import {
  useGetUserNotificationsQuery,
  useUpdateUserNotificationsMutation,
} from '__generated__/api-types-and-hooks'
import { useTenantId } from 'utils/useTenantId'

const useNotificationList = () => {
  const tenantId = useTenantId()

  const { data } = useGetUserNotificationsQuery(
    {
      input: { tenantId, pageSize: 20, isPaginated: true },
    },
    { staleTime: 60000 }
  )

  const { mutate: updateUserNotications } = useUpdateUserNotificationsMutation({})

  let notificationList = (data?.getUserNotifications?.data?.notifications || []) as any[]

  return { updateUserNotications, notificationList }
}

export default useNotificationList
