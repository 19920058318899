import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const AllToolsLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="0" y="1" rx="0" ry="0" width="1" height="165" />
        <rect x="0" y="165" rx="0" ry="0" width="172" height="1" />
        <rect x="0" y="1" rx="0" ry="0" width="172" height="1" />
        <rect x="172" y="1" rx="0" ry="0" width="1" height="165" />

        <rect x="20" y="20" rx="2" ry="2" width="130" height="70" />
        <rect x="40" y="102" rx="2" ry="2" width="90" height="10" />

        <rect x="197" y="1" rx="0" ry="0" width="1" height="165" />
        <rect x="197" y="165" rx="0" ry="0" width="173" height="1" />
        <rect x="197" y="1" rx="0" ry="0" width="173" height="1" />
        <rect x="370" y="1" rx="0" ry="0" width="1" height="165" />

        <rect x="219" y="20" rx="2" ry="2" width="130" height="70" />
        <rect x="239" y="102" rx="2" ry="2" width="90" height="10" />

        <rect x="0" y="187" rx="0" ry="0" width="1" height="165" />
        <rect x="0" y="351" rx="0" ry="0" width="172" height="1" />
        <rect x="0" y="187" rx="0" ry="0" width="172" height="1" />
        <rect x="172" y="187" rx="0" ry="0" width="1" height="165" />

        <rect x="20" y="207" rx="2" ry="2" width="130" height="70" />
        <rect x="40" y="289" rx="2" ry="2" width="90" height="10" />

        <rect x="197" y="187" rx="0" ry="0" width="1" height="165" />
        <rect x="197" y="351" rx="0" ry="0" width="173" height="1" />
        <rect x="197" y="187" rx="0" ry="0" width="173" height="1" />
        <rect x="370" y="187" rx="0" ry="0" width="1" height="165" />

        <rect x="219" y="207" rx="2" ry="2" width="130" height="70" />
        <rect x="239" y="289" rx="2" ry="2" width="90" height="10" />
      </ContentLoader>
    </>
  )
}

export default AllToolsLoader
