import * as yup from 'yup'
import Lazy from 'yup/lib/Lazy'
import { isEmpty, isNil } from 'lodash'
import { appExitPlanIds, registeredBusinessStructureV2 } from 'components/MonthlyAttestation/utils'
import moment from 'moment-timezone'
import { isValidPhoneNumber } from 'libphonenumber-js'
type Schema = Lazy<any, unknown> | any

/**
 * A set of common validations.
 * Don't add to this class unless a given validation is needed more than once.
 * For one-time validations use the "custom" method of ValidationSchemaBuilder
 */

export const isValid = (registerBusinessStructureOptionId, value) => {
  if (
    !isEmpty(registerBusinessStructureOptionId) &&
    registerBusinessStructureOptionId !== registeredBusinessStructureV2.noBusinessOptionId
  ) {
    return !value ? false : true
  }
  return true
}

export abstract class CommonValidations {
  abstract firstName()
  abstract lastName()
  abstract email()
  abstract mobileContactNumber()
  // abstract ein()
  // abstract yearsInBusiness()
  abstract businessName()
}

export const isPhoneNumberValid = (phoneNumber: string | null | undefined) => {
  if (!phoneNumber) return true
  return isValidPhoneNumber(phoneNumber)
}
/** Responsible for defining validation rules with the "yup" library */
export class ValidationsProvider {
  firstName() {
    return yup.string().trim().required('Please enter first name')
  }

  lastName() {
    return yup.string().trim().required('Please enter last name')
  }

  email() {
    return yup
      .string()
      .email('Please enter a valid email address')
      .required('Please enter a valid email address')
  }
  businessEmail() {
    return yup.string().email('Please enter a valid business email address').nullable()
  }

  isSmsEnabled() {
    return yup.boolean().required('Agreement is required to subscribe.')
  }

  mobileContactNumber() {
    return yup
      .string()
      .required('Phone number is required')
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .test('isValidPhoneNumber', 'Please enter valid phone number', isPhoneNumberValid)
      .test('contactNumberLength', 'All 10 numbers required', (value) => {
        if (!value || value.length > 10) {
          return true
        }
        return false
      })
  }

  imageData() {
    return yup.mixed().nullable()
  }
  avatar() {
    return yup.string().required('Please Select Avatar')
  }

  businessAddress() {
    return yup.object().shape({
      addressLine1: yup
        .string()
        .transform((value, originalValue) => (value === null ? '' : value))
        .test('required-address-line1', 'Business Address is required', function (value) {
          this.parent.hasBusinessAddress = this.parent?.hasBusinessAddress === false ? false : true
          const { hasBusinessAddress } = this.parent
          if (hasBusinessAddress && !value) {
            return false
          }
          return true
        }),
    })
  }

  ein() {
    return yup
      .string()
      .trim()
      .matches(/^[0-9]{2}-[0-9]{7}$/i, 'Please enter a valid EIN number')
      .required('Please enter a valid ein number')
  }

  city() {
    return yup
      .string()
      .trim()
      .transform((value, originalValue) => (value === null ? '' : value))
      .required('City is required')
  }

  state() {
    return yup
      .string()
      .trim()
      .transform((value, originalValue) => (value === null ? '' : value))
      .required('State is required')
  }

  businessName() {
    return yup
      .string()
      .trim()
      .transform((value, originalValue) => (value === null ? '' : value))
      .required('Business name is required')
  }

  registeredBusinessStructure() {
    return yup
      .string()
      .transform((value, originalValue) => (value === null ? '' : value))
      .trim()
      .test('has-business-structure-or-not', 'Please select Business Structure', function (value) {
        const { hasRegisterBusinessStructure } = this.parent
        if (!hasRegisterBusinessStructure) {
          return true
        }
        return yup.string().required('Please select Business Structure').isValidSync(value)
      })
      .nullable()
  }

  domainName() {
    return yup
      .string()
      .trim()
      .transform((value, originalValue) => (value === null ? '' : value))
      .test('valid-url-or-none', 'Please enter a valid Business Website', function (value) {
        const { hasWebsite } = this.parent
        if (!hasWebsite) {
          return true
        }
        return yup
          .string()
          .matches(
            /^(https?:\/\/)?([\w\d-]+\.)+[\w-]+(\/[\w- ;,./?%&=]*)?$/i,
            'Please enter a valid Business Website'
          )
          .required('Please Eenter Website URL')
          .isValidSync(value)
      })
      .nullable()
  }
  facebook() {
    return yup
      .string()
      .transform((value, originalValue) => (value === null ? '' : value))
      .test('valid-facebook-or-none', 'Please enter a valid Facebook link', function (value) {
        const { hasFacebook } = this.parent
        if (!hasFacebook) {
          return true
        }
        return yup.string().required('Please enter a Facebook link').isValidSync(value)
      })
      .nullable()
  }
  instagram() {
    return yup
      .string()
      .transform((value, originalValue) => (value === null ? '' : value))
      .test('valid-instagram-or-none', 'Please enter a valid Instagram link', function (value) {
        const { hasInstagram } = this.parent
        if (!hasInstagram) {
          return true
        }
        return yup.string().required('Please enter an Instagram link').isValidSync(value)
      })
      .nullable()
  }
  linkedin() {
    return yup
      .string()
      .transform((value, originalValue) => (value === null ? '' : value))
      .test('valid-linkedin-or-none', 'Please enter a valid LinkedIn link', function (value) {
        const { hasLinkedin } = this.parent
        if (!hasLinkedin) {
          return true
        }
        return yup.string().required('Please enter a LinkedIn link').isValidSync(value)
      })
      .nullable()
  }
  tiktok() {
    return yup
      .string()
      .trim()
      .transform((value, originalValue) => (value === null ? '' : value))
      .test('valid-tiktok-or-none', 'Please enter a valid TikTok link', function (value) {
        const { hasTikTok } = this.parent
        if (!hasTikTok) {
          return true
        }
        return yup
          .string()
          .matches(/^@[\w.]+$/, 'Please enter a valid TikTok link')
          .required('Please enter a TikTok link')
          .isValidSync(value)
      })
      .nullable()
  }
  twitter() {
    return yup
      .string()
      .transform((value, originalValue) => (value === null ? '' : value))
      .test('valid-twitter-or-none', 'Please enter a valid Twitter link', function (value) {
        const { hasTwitter } = this.parent
        if (!hasTwitter) {
          return true
        }
        return yup.string().required('Please enter a Twitter link').isValidSync(value)
      })
      .nullable()
  }
  youtube() {
    return yup
      .string()
      .trim()
      .transform((value, originalValue) => (value === null ? '' : value))
      .test('valid-youtube-or-none', 'Please enter a valid YouTube link', function (value) {
        const { hasYoutube } = this.parent
        if (!hasYoutube) {
          return true
        }
        return yup
          .string()
          .matches(/^@[\w.]+$/, 'Please enter a valid YouTube link')
          .required('Please enter a YouTube link')
          .isValidSync(value)
      })
      .nullable()
  }
  yearEstablished() {
    return yup
      .string()
      .transform((value, originalValue) => (value === null ? '' : value))
      .test('valid-yearEstablished-or-none', 'Please enter valid date', function (value) {
        const { registerBusinessStructureOptionId, hasRegisterBusinessStructure } = this.parent
        if (
          (registerBusinessStructureOptionId &&
            registerBusinessStructureOptionId ===
              registeredBusinessStructureV2.noBusinessOptionId) ||
          !hasRegisterBusinessStructure
        ) {
          return true
        }
        return yup
          .string()
          .matches(/^(0[1-9]|1[0-2])\/\d{4}$/, 'Must be in the format MM/YYYY')
          .test('is-valid-date', 'Date must not be in the future', (value) => {
            if (!value) return true
            const [month, year] = value.split('/').map(Number)
            const currentDate = new Date()
            const inputDate = new Date(year, month - 1)

            return inputDate <= currentDate
          })
          .required('Please enter Date')
          .isValidSync(value)
      })
      .test('yearEstablished', 'Registration must not be in the future.', function (value) {
        const { yearEstablished } = this.parent
        const { isAfter } = require('date-fns')
        const currentDate = new Date()
        const inputDate = new Date(yearEstablished)
        if (isAfter(inputDate, currentDate)) {
          return false
        } else {
          return true
        }
      })
      .nullable()
  }

  businessEINCode() {
    return yup
      .string()
      .trim()
      .transform((value, originalValue) => (value === null ? '' : value))
      .test('hasEINOrNot', 'Enter a valid ein number', function (value) {
        const { registerBusinessStructureOptionId, hasRegisterBusinessStructure, hasEIN } =
          this.parent
        if (
          (registerBusinessStructureOptionId &&
            registerBusinessStructureOptionId ===
              registeredBusinessStructureV2.noBusinessOptionId) ||
          !hasRegisterBusinessStructure ||
          !hasEIN
        ) {
          return true
        }
        return yup
          .string()
          .matches(/^[0-9]{2}-[0-9]{7}$/i, 'Please enter a valid EIN number')
          .required('Please enter a valid ein number')
          .isValidSync(value)
      })
      .nullable()
  }

  otherRegisteredBusinessStructure() {
    return yup
      .string()
      .trim()
      .transform((value, originalValue) => (value === null ? '' : value))
      .test(
        'checkOtherRegisteredBusinessStructure',
        'Enter other business structure',
        function (value) {
          const { registerBusinessStructureOptionId } = this.parent
          if (
            registerBusinessStructureOptionId ===
            registeredBusinessStructureV2.otherBusinessOptionId
          ) {
            return yup.string().required('Enter other business structure').isValidSync(value)
          }
          return true
        }
      )
      .nullable()
  }

  hasWebsite() {
    return yup
      .boolean()
      .transform((value, originalValue) => (value === null || value === '' ? false : value))
      .required()
  }

  hasAvatar() {
    return yup
      .boolean()
      .transform((value, originalValue) => (value === null ? false : value))
      .required()
  }
  hasBoolean() {
    return yup
      .boolean()
      .transform((value, originalValue) => (value === null ? true : value))
      .required()
  }

  hasLogo() {
    return yup.boolean().required()
  }

  hasEIN() {
    return yup
      .boolean()
      .transform((value, originalValue) => (value === null || value === '' ? false : value))
      .required()
  }

  hasRegisterBusinessStructure() {
    return yup
      .boolean()
      .transform((value, originalValue) => (value === null || value === '' ? false : value))
      .required()
  }

  yearsInBusiness() {
    return yup
      .string()
      .transform((value, originalValue) => (value === null ? '' : value))
      .matches(/^(?!00)[0-9]{1,4}$/i, 'Year must be between 0 to 1000')
      .nullable()
  }

  logo() {
    return yup.object().nullable()
  }

  name() {
    return yup.string().trim().required('Please enter play name')
  }

  assigneeId() {
    return yup.string().nullable()
  }
  estimatedEffortInMinutes() {
    return yup
      .string()
      .transform((value, originalValue) => (value === null ? 0 : value))
      .required('Estimation hours is required field')
      .nullable()
  }

  order() {
    return yup.number().nullable().min(0, 'Order cannot be negative number')
  }

  taskPriority() {
    return yup.string().trim().nullable()
  }

  status() {
    return yup.string().trim().nullable()
  }

  category() {
    return yup.string().trim().nullable()
  }

  instructionForTask() {
    return yup.string().trim().nullable()
  }

  resource() {
    return yup.string().trim().nullable()
  }

  startDate() {
    return yup.date().typeError('Please select start data').required('Start date is required')
  }

  expectedEndDate() {
    return yup
      .date()
      .typeError('Please select due data')
      .required('Expected end date is required')
      .test('is-after-start', 'Due date must be after start date', function (value) {
        const { startDate } = this.parent
        if (isNil(value)) {
          return false
        }
        return !isNil(startDate) && moment(value).isSameOrAfter(moment(startDate), 'day')
      })
  }

  milestoneId() {
    return yup.string().trim().nullable()
  }

  howToLinks() {
    return yup
      .array()
      .of(
        yup.object({
          howToId: yup.string().required(),
          title: yup.string().nullable(),
          url: yup.string().nullable(),
        })
      )
      .nullable()
  }
  dba() {
    return yup
      .string()
      .transform((value, originalValue) => (value === null ? '' : value))
      .nullable()
  }
  totalEmployees() {
    return yup
      .string()
      .transform((value, originalValue) => (value === null ? '' : value))
      .required('Toal Employees is Required')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'must be a number')
  }
  naicsCode() {
    return yup
      .string()
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Please enter a valid NAICS number')
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .nullable()
  }
  fullTimeEmployees() {
    return yup
      .string()
      .transform((value, originalValue) => (value === null ? '' : value))
      .required('Full Time Employees is Required')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'must be a number')
  }
  partTimeEmployees() {
    return yup
      .string()
      .transform((value, originalValue) => (value === null ? '' : value))
      .required('Part Time Employees is Required')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'must be a number')
  }
  minorityOwned() {
    return yup.string().required('Minority Owned is Required')
  }
  sbaCertified() {
    return yup.string().required('SBA Certified  is Required')
  }
  veteranOwned() {
    return yup.string().required('Veteran Owned  is Required')
  }
  clientDetailsPartTimeEmployees() {
    return yup
      .string()
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
      .nullable()
  }
  clientDetailsFullTimeEmployees() {
    return yup
      .string()
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
      .nullable()
  }

  totalEmployeesUpdate() {
    return yup
      .string()
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
      .nullable()
  }

  clientDetailsContractors() {
    return yup
      .string()
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
      .nullable()
  }
  clientDetailsVolunteers() {
    return yup
      .string()
      .transform((value, originalValue) => (originalValue?.trim() === '' ? null : value))
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
      .nullable()
  }
  businessAddressUpdate() {
    return yup.object().shape({
      addressLine1: yup
        .string()
        .transform((value, originalValue) => (value === null ? '' : value))
        .nullable(),
    })
  }
  domainNameUpdate() {
    return yup
      .string()
      .transform((value, originalValue) => (value === null ? '' : value))
      .matches(
        /^(https?:\/\/)?([\w\d-]+\.)+[\w-]+(\/[\w- ;,./?%&=]*)?$|^$/i,
        'Please enter a valid Business Website'
      )
      .nullable()
  }
  businessEINCodeUpdate() {
    return yup
      .string()
      .trim()
      .transform((value, originalValue) => (value === null ? '' : value))
      .nullable()
  }
  yearEstablishedUpdate() {
    return yup
      .string()
      .transform((value, originalValue) => (value === null ? '' : value))
      .test('valid-yearEstablished-or-none', 'Please enter valid date', function (value) {
        const { registerBusinessStructureOptionId } = this.parent
        if (
          registerBusinessStructureOptionId &&
          registerBusinessStructureOptionId === registeredBusinessStructureV2.noBusinessOptionId
        ) {
          return true
        }
        return yup
          .string()
          .matches(/^(0[1-9]|1[0-2])\/\d{4}$/, 'Must be in the format MM/YYYY')
          .test('is-valid-date', 'Date must not be in the future', (value) => {
            if (!value) return true
            const [month, year] = value.split('/').map(Number)
            const currentDate = new Date()
            const inputDate = new Date(year, month - 1)

            return inputDate <= currentDate
          })
          .required('Please enter Date')
          .isValidSync(value)
      })
      .test('yearEstablished', 'Registration must not be in the future.', function (value) {
        const { yearEstablished } = this.parent
        const { isAfter } = require('date-fns')
        const currentDate = new Date()
        const inputDate = new Date(yearEstablished)
        if (isAfter(inputDate, currentDate)) {
          return false
        } else {
          return true
        }
      })
      .nullable()
  }
  otherExitPlan() {
    return yup
      .string()
      .trim()
      .transform((value, originalValue) => (value === null ? '' : value))
      .test('checkOtherExitPlan', 'Enter other exit plan', function (value) {
        const { exitPlanOptionId } = this.parent
        if (exitPlanOptionId === appExitPlanIds.other) {
          return yup.string().required('Enter other exit plan').isValidSync(value)
        }
        return true
      })
      .nullable()
  }
  launchDate() {
    return yup
      .string()
      .transform((value, originalValue) => (value === null ? '' : value))
      .required('Enter launch date')
  }
  lastUpdatedBusinessPlan() {
    return yup
      .string()
      .transform((value, originalValue) => (value === null ? '' : value))
      .test('is-valid-date', 'Date must not be in the future', (value) => {
        if (!value) return true
        const inputDate = moment(value)
        const currentDate = moment()
        if (inputDate.isAfter(currentDate, 'day')) {
          return false
        }
        return true
      })
      .nullable()
  }
  hoursSpentPerWeek() {
    return yup
      .number()
      .required('Please enter hours spent per week')
      .typeError('Please enter hours spent per week')
      .min(0, 'Hours spent per week cannot be negative number')
  }
}

/** Builder class for creating yup schemas */
class ValidationSchemaBuilder extends CommonValidations {
  provider = new ValidationsProvider()
  validations: { [key: string]: any } = {}
  _schema: Schema | null = null

  updateSchema() {
    this._schema = yup.object(this.validations) as Schema
  }

  /** Returns the finished schema and clears itself to make another schema */
  getSchema() {
    const schema = this._schema
    this.validations = {}
    this._schema = null
    return schema as Schema
  }

  /** Add custom, one-off properties to the schema */
  custom(obj: { [key: string]: any }) {
    Object.assign(this.validations, obj)
    this.updateSchema()
    return this
  }

  firstName() {
    this.validations.firstName = this.provider.firstName()
    this.updateSchema()
    return this
  }

  lastName() {
    this.validations.lastName = this.provider.lastName()
    this.updateSchema()
    return this
  }

  email() {
    this.validations.email = this.provider.email()
    this.updateSchema()
    return this
  }

  mobileContactNumber() {
    this.validations.mobileContactNumber = this.provider.mobileContactNumber()
    this.updateSchema()
    return this
  }
  yearEstablished() {
    this.validations.yearEstablished = this.provider.yearEstablished()
    this.updateSchema()
    return this
  }

  isSmsEnabled() {
    this.validations.isSmsEnabled = this.provider.isSmsEnabled()
    this.updateSchema()
    return this
  }

  businessName() {
    this.validations.businessName = this.provider.businessName()
    this.updateSchema()
    return this
  }

  city() {
    this.validations.city = this.provider.city()
    this.updateSchema()
    return this
  }

  state() {
    this.validations.state = this.provider.state()
    this.updateSchema()
    return this
  }

  registeredBusinessStructure() {
    this.validations.registerBusinessStructureOptionId = this.provider.registeredBusinessStructure()
    this.updateSchema()
    return this
  }

  domainName() {
    this.validations.domainName = this.provider.domainName()
    this.updateSchema()
    return this
  }

  businessEINCode() {
    this.validations.EIN = this.provider.businessEINCode()
    this.updateSchema()
    return this
  }

  otherRegisteredBusinessStructure() {
    this.validations.otherRegisteredBusinessStructure =
      this.provider.otherRegisteredBusinessStructure()
    this.updateSchema()
    return this
  }

  hasWebsite() {
    this.validations.hasWebsite = this.provider.hasBoolean()
    this.updateSchema()
    return this
  }

  hasLogo() {
    this.validations.hasLogo = this.provider.hasLogo()
    this.updateSchema()
    return this
  }

  hasEIN() {
    this.validations.hasEIN = this.provider.hasBoolean()
    this.updateSchema()
    return this
  }

  hasRegisterBusinessStructure() {
    this.validations.hasRegisterBusinessStructure = this.provider.hasBoolean()
    this.updateSchema()
    return this
  }

  businessEmail() {
    this.validations.businessEmail = this.provider.businessEmail()
    this.updateSchema()
    return this
  }
  facebook() {
    this.validations.facebook = this.provider.facebook()
    this.updateSchema()
    return this
  }
  instagram() {
    this.validations.instagram = this.provider.instagram()
    this.updateSchema()
    return this
  }
  linkedin() {
    this.validations.linkedin = this.provider.linkedin()
    this.updateSchema()
    return this
  }
  tiktok() {
    this.validations.tiktok = this.provider.tiktok()
    this.updateSchema()
    return this
  }
  twitter() {
    this.validations.twitter = this.provider.twitter()
    this.updateSchema()
    return this
  }
  youtube() {
    this.validations.youtube = this.provider.youtube()
    this.updateSchema()
    return this
  }

  hasAvatar() {
    this.validations.hasAvatar = this.provider.hasAvatar()
    this.updateSchema()
    return this
  }

  imagaData() {
    this.validations.imageData = this.provider.imageData()
    this.updateSchema()
    return this
  }
  avatar() {
    this.validations.avatar = this.provider.avatar()
    this.updateSchema()
    return this
  }

  businessAddress() {
    this.validations.businessAddress = this.provider.businessAddress()
    this.updateSchema()
    return this
  }

  yearsInBusiness() {
    this.validations.yearsInBusiness = this.provider.yearsInBusiness()
    this.updateSchema()
    return this
  }

  logo() {
    this.validations.logo = this.provider.logo()
    this.updateSchema()
    return this
  }

  name() {
    this.validations.name = this.provider.name()
    this.updateSchema()
    return this
  }

  assigneeId() {
    this.validations.assigneeId = this.provider.assigneeId()
    this.updateSchema()
    return this
  }

  estimatedEffortInMinutes() {
    this.validations.estimatedEffortInMinutes = this.provider.estimatedEffortInMinutes()
    this.updateSchema()
    return this
  }

  order() {
    this.validations.order = this.provider.order()
    this.updateSchema()
    return this
  }

  taskPriority() {
    this.validations.taskPriority = this.provider.taskPriority()
    this.updateSchema()
    return this
  }

  status() {
    this.validations.status = this.provider.status()
    this.updateSchema()
    return this
  }

  category() {
    this.validations.category = this.provider.category()
    this.updateSchema()
    return this
  }

  instructionForTask() {
    this.validations.instructionForTask = this.provider.instructionForTask()
    this.updateSchema()
    return this
  }

  resource() {
    this.validations.resource = this.provider.resource()
    this.updateSchema()
    return this
  }

  startDate() {
    this.validations.startDate = this.provider.startDate()
    this.updateSchema()
    return this
  }

  expectedEndDate() {
    this.validations.expectedEndDate = this.provider.expectedEndDate()
    this.updateSchema()
    return this
  }

  milestoneId() {
    this.validations.milestoneId = this.provider.milestoneId()
    this.updateSchema()
    return this
  }

  howToLinks() {
    this.validations.howToLinks = this.provider.howToLinks()
    this.updateSchema()
    return this
  }
  naicsCode() {
    this.validations.naicsCode = this.provider.naicsCode()
    this.updateSchema()
    return this
  }
  dba() {
    this.validations.dba = this.provider.dba()
    this.updateSchema()
    return this
  }
  totalEmployees() {
    this.validations.totalEmployees = this.provider.totalEmployees()
    this.updateSchema()
    return this
  }
  fullTimeEmployees() {
    this.validations.fullTimeEmployees = this.provider.fullTimeEmployees()
    this.updateSchema()
    return this
  }
  partTimeEmployees() {
    this.validations.partTimeEmployees = this.provider.partTimeEmployees()
    this.updateSchema()
    return this
  }
  minorityOwned() {
    this.validations.minorityOwned = this.provider.minorityOwned()
    this.updateSchema()
    return this
  }
  sbaCertified() {
    this.validations.sbaCertified = this.provider.sbaCertified()
    this.updateSchema()
    return this
  }
  veteranOwned() {
    this.validations.veteranOwned = this.provider.veteranOwned()
    this.updateSchema()
    return this
  }
  businessWebsite() {
    this.validations.domainName = this.provider.domainNameUpdate()
    this.updateSchema()
    return this
  }
  businessAddressUpdate() {
    this.validations.businessAddress = this.provider.businessAddressUpdate()
    this.updateSchema()
    return this
  }

  clientDetailsPartTimeEmployees() {
    this.validations.partTimeEmployees = this.provider.clientDetailsPartTimeEmployees()
    this.updateSchema()
    return this
  }
  clientDetailsFullTimeEmployees() {
    this.validations.fullTimeEmployees = this.provider.clientDetailsFullTimeEmployees()
    this.updateSchema()
    return this
  }
  totalEmployeesUpdate() {
    this.validations.totalEmployees = this.provider.totalEmployeesUpdate()
    this.updateSchema()
    return this
  }
  clientDetailsContractors() {
    this.validations.contractors = this.provider.clientDetailsContractors()
    this.updateSchema()
    return this
  }
  clientDetailsVolunteers() {
    this.validations.volunteers = this.provider.clientDetailsVolunteers()
    this.updateSchema()
    return this
  }

  businessEINCodeUpdate() {
    this.validations.EIN = this.provider.businessEINCodeUpdate()
    this.updateSchema()
    return this
  }
  yearEstablishedUpdate() {
    this.validations.yearEstablished = this.provider.yearEstablishedUpdate()
    this.updateSchema()
    return this
  }
  otherExitPlan() {
    this.validations.otherExitPlan = this.provider.otherExitPlan()
    this.updateSchema()
    return this
  }
  launchDate() {
    this.validations.launchDate = this.provider.launchDate()
    this.updateSchema()
    return this
  }
  lastUpdatedBusinessPlan() {
    this.validations.lastUpdatedBusinessPlan = this.provider.lastUpdatedBusinessPlan()
    this.updateSchema()
    return this
  }
  hasFacebook() {
    this.validations.hasFacebook = this.provider.hasBoolean()
    this.updateSchema()
    return this
  }
  hasInstagram() {
    this.validations.hasInstagram = this.provider.hasBoolean()
    this.updateSchema()
    return this
  }
  hasTikTok() {
    this.validations.hasTikTok = this.provider.hasBoolean()
    this.updateSchema()
    return this
  }
  hasLinkedin() {
    this.validations.hasLinkedin = this.provider.hasBoolean()
    this.updateSchema()
    return this
  }
  hasYoutube() {
    this.validations.hasYoutube = this.provider.hasBoolean()
    this.updateSchema()
    return this
  }
  hasTwitter() {
    this.validations.hasTwitter = this.provider.hasBoolean()
    this.updateSchema()
    return this
  }
  hoursSpentPerWeek() {
    this.validations.hoursSpentPerWeek = this.provider.hoursSpentPerWeek()
    this.updateSchema()
    return this
  }
}

export default ValidationSchemaBuilder
