import PropTypes from 'prop-types'
import React from 'react'

interface AppChevronDownIconProps {
  color?: string
}

export const AppChevronDownIcon: React.FC<AppChevronDownIconProps> = ({ color }) => {
  return (
    <svg
      className={`chevron-down-16`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path bg-app-red-10"
        d="M4 6L8 10L12 6"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33333"
      />
    </svg>
  )
}

AppChevronDownIcon.propTypes = {
  color: PropTypes.string,
}
