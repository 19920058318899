import React, { FC } from 'react'

export interface ISelectFieldCancelIconProps {
  className?: string
}
const SelectFieldCancelIcon: FC<ISelectFieldCancelIconProps> = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="25"
      viewBox="0 0 16 25"
      className={className}
    >
      <path d="M13.8081 18.8095C13.5541 19.0635 13.1431 19.0635 12.8889 18.8095L7.49989 13.4187L2.10971 18.8095C1.85572 19.0635 1.44477 19.0635 1.19049 18.8095C0.936502 18.5555 0.936502 18.1445 1.19049 17.8902L6.5819 12.5006L1.19131 7.10983C0.937314 6.85581 0.937314 6.44482 1.19131 6.19052C1.4453 5.93649 1.85625 5.93649 2.11052 6.19052L7.49989 11.5825L12.8901 6.19173C13.1441 5.93771 13.555 5.93771 13.8093 6.19173C14.0633 6.44575 14.0633 6.85675 13.8093 7.11105L8.41789 12.5006L13.8081 17.8914C14.064 18.1433 14.064 18.5576 13.8081 18.8095Z" />
    </svg>
  )
}
SelectFieldCancelIcon.defaultProps = {
  className: 'fill-black-light',
}
export default SelectFieldCancelIcon
