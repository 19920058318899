import { call, put, takeLatest, select } from 'redux-saga/effects'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import UserApi from 'api/user'
import history from 'utils/history'
import { IUSER_NOTIFICATION_STATUS } from 'components/NotificationPanel/types'
import CobrowseIO from 'cobrowse-sdk-js'

import {
  getChatUsersAction,
  getUserBusinessProfileAction,
  getUserProfileAction,
  updateUserBusinessProfileAction,
  updateUserProfileAction,
  chatUnreadMessageCountAction,
  getUserNoticationsAction,
  updateUserNotificationsAction,
  deleteUserNotificationsAction,
  getClientsSummaryAction,
  resendUserInviteAction,
  publishAnnouncementAction,
  setAnnouncementActiveStepAction,
} from 'store/actions/user'
import {
  CHANGE_PASSWORD,
  GET_CHAT_USERS,
  GET_USER_BUSINESS_PROFILE,
  GET_USER_PROFILE,
  LOG_USER_ACTIVITY,
  UPDATE_USER_BUSINESS_PROFILE,
  UPDATE_USER_PROFILE,
  CHAT_UNREAD_MESSAGE_COUNT,
  GET_UNREAD_NOTIFICATIONS,
  GET_USER_NOTIFICATIONS,
  UPDATE_USER_NOTIFICATIONS,
  DELETE_USER_NOTIFICATIONS,
  CLIENTS_SUMMARY,
  RESEND_USER_INVITE,
  PUBLISH_ANNOUNCEMENT_ACTION,
} from 'store/types'

import { getIsAllowCookies, saveUser } from 'utils/user'
import { showMyProfileAction, showBusinessProfileEditModeAction } from 'store/actions/owner/account'
import { setActiveStepAction } from 'store/actions/common/stepper'
import pickBy from 'lodash/pickBy'
import filter from 'lodash/filter'
import { USER_ROLES, formKeys } from 'config'
import { changePasswordAction } from 'store/actions/common'
import { setFormData, setProfileAction } from 'store/actions/form'
import CalendarApi from 'api/calendar'
import ClientsApi from 'api/clients'
import user from 'api/user'
import { GetUserNotificationsResponse } from '__generated__/api-types-and-hooks'
import { ampli } from 'ampli'
import { isEmpty } from 'lodash'
import { getRoleFromPath } from 'utils/helper'
import moment from 'moment'
import { getClientProfileAction } from 'store/actions/clients'
// import { initializeUsetiful } from 'lib/usetiful'

interface IGetUserNotifications {
  getUserNotifications: GetUserNotificationsResponse
}
function* getUserProfile(action) {
  const res = yield call(UserApi.getUserProfile, action?.payload?.userId)
  const user = res?.getMyProfile
  const userProfile = res?.getMyProfile

  yield put(
    getUserProfileAction.FULLFILLED(action.payload === undefined ? { user } : { userProfile })
  )

  if (action.payload?.setForms?.length > 0) {
    const { setForms } = action.payload
    const payload = { forms: setForms, profile: res?.getMyProfile }
    yield put(setProfileAction(payload))
  }

  CobrowseIO.customData = {
    user_id: user.id,
    user_name: `${user?.firstName} ${user?.lastName}`,
    user_email: user.email,
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // initializeUsetiful(user.id)
}

function* updateUserProfile(action) {
  const pathname = history.location.pathname
  const tenantId = yield select((state) => state.user.tenantId)
  const steps = yield select((state) => state.stepper.steps)
  const activeStep = yield select((state) => state.stepper.activeStep)
  try {
    const { nextStep } = action.payload
    delete action.payload.nextStep
    const response = yield call(UserApi.updateUserProfile, { ...action.payload, tenantId })
    if (!response) return
    const userProfile = yield call(UserApi.getUserProfile)
    if (nextStep === 'updateStepper') {
      const indexofActiveStep = steps.findIndex((step) => step?.value === activeStep?.value)
      history.replace(`/advisor/${tenantId}/onboarding/${steps[indexofActiveStep + 1].value}`)
      yield put(setActiveStepAction(steps[indexofActiveStep + 1]))
    } else if (pathname === `/${getRoleFromPath()}/${tenantId}/account`) {
      yield put(getUserProfileAction.STARTED(action.payload.id))
      yield put(showMyProfileAction(false))
    } else {
      const isAllow = getIsAllowCookies()
      if (isAllow) {
        saveUser(userProfile)
      }
    }
    yield put(updateUserProfileAction.FULLFILLED(userProfile?.getMyProfile))
  } catch (error: any) {
    console.log('error: ', error)

    if (error.message.includes('mobileContactNumber')) {
      const mobileContactError = { message: 'Please enter valid phone number' }
      yield put(updateUserProfileAction.REJECTED({ mobileContactError }))
    } else {
      const errors = error.messages
      yield put(updateUserProfileAction.REJECTED({ error: errors }))
    }
  }
}

function* getUserBusinessProfile(action) {
  const res = yield call(UserApi.getBusinessProfile, action.payload.userId)
  if (action.payload?.setForms?.length > 0) {
    const { setForms } = action.payload
    const businessProfile = pickBy(res.getBusinessProfile, (value, key) =>
      formKeys[setForms].includes(key)
    )
    yield put(
      setFormData({
        form: setForms[0],
        data: businessProfile,
      })
    )
  }
  yield put(getUserBusinessProfileAction.FULLFILLED(res?.getBusinessProfile))
}

function* updateUserBusinessProfile(action) {
  try {
    const tenantId = yield select((state) => state.user.tenantId)
    const payload = { ...action.payload, tenantId }
    const response = yield call(UserApi.updateBusinessProfile, payload)
    if (response) {
      yield put(getUserBusinessProfileAction.STARTED({ userId: action.payload.id }))
      yield put(showBusinessProfileEditModeAction(false))
    }
    yield put(updateUserBusinessProfileAction.FULLFILLED())
  } catch (error: any) {
    console.log('error :', error)
    yield put(updateUserBusinessProfileAction.REJECTED(error.message))
  }
}

function* changePassword(action) {
  const response = yield call(UserApi.updatePassword, action.payload)
  if (isEmpty(response)) {
    yield put(changePasswordAction.REJECTED('Unable to reset password'))
  } else {
    yield put(changePasswordAction.FULLFILLED(false))
  }
}

function* getChatUsers(action) {
  try {
    const userProfile = yield select((state) => state.user.user)
    const tenantId = yield select((state) => state.user.tenantId)
    const role = userProfile.roles[0]

    let payload: any = {}
    payload.tenantId = tenantId

    let users: any = []
    if (role === USER_ROLES.BUSINESS_OWNER) {
      let advisors = yield call(CalendarApi.getBoAdvisors)
      advisors = get(advisors, 'getBoAdvisors.data', [])

      if (advisors.length > 0) {
        users.push(...advisors)
      }
    } else if (role === USER_ROLES.BSO_ADVISOR) {
      payload.advisorId = userProfile.id
      let res = yield call(ClientsApi.getClients, payload)
      res = get(res, 'getClients.data')

      if (res) {
        users = [...res]
      }
    }

    let usersPayload = {
      glRole: role,
      users,
    }

    yield put(getChatUsersAction.FULLFILLED(usersPayload))
  } catch (error) {
    yield put(changePasswordAction.REJECTED('Unable to reset password'))
    console.log(' user password update error  : ', error)
  }
}

function* logUserActivity(action) {
  const sessionToken = localStorage.getItem('sessionToken')

  yield call(UserApi.logActivity, {
    action: get(action, 'payload.action', ''),
    logStatus: get(action, 'payload.logStatus', ''),
    accessToken: sessionToken,
    tenantId: get(action, 'payload.tenantId', ''),
    showClientActivity: get(action, 'payload.showClientActivity', false),
  })
}

// chat unread message count
function* chatUnreadMessageCount(action) {
  yield put(chatUnreadMessageCountAction.FULLFILLED(action.payload))
}

// chat unread message count
function* getUnreadNotifications() {
  const tenantId = yield select((state) => state.user.tenantId)

  const payload = {
    tenantId,
    isPaginated: false,
    filter: IUSER_NOTIFICATION_STATUS.UNREAD,
    countOnly: true,
  }

  let data = yield call(UserApi.getUserNotifications, payload)

  if (get(data, 'getUserNotifications.data')) {
    const totalUnread = get(data, 'getUserNotifications.data.totalCount')
    yield put(getUserNoticationsAction.FULLFILLED({ totalUnread }))
  }
}

function* getUserNotifications(payloadData?: any) {
  const user = yield select((state) => ({
    tenantId: state.user.tenantId,
    data: state.user.notifications.data,
    lastEvaluatedKey: payloadData?.getNotifications
      ? undefined
      : state.user.notifications.lastEvaluatedKey,
  }))
  const payload = {
    tenantId: user.tenantId,
    pageSize: 20,
    isPaginated: true,
    lastEvaluatedKey: user.lastEvaluatedKey || undefined,
  }

  let response = (yield call(UserApi.getUserNotifications, payload)) as IGetUserNotifications
  if (response) {
    const notifications = response?.getUserNotifications?.data?.notifications || []
    const lastEvaluatedKey = response?.getUserNotifications?.data?.lastEvaluatedKey || ''

    if (payloadData?.payload?.viewMore) {
      yield put(
        getUserNoticationsAction.FULLFILLED({
          data: [
            ...get(user, 'data', []),
            ...(response?.getUserNotifications?.data?.notifications || []),
          ],
          lastEvaluatedKey,
        })
      )
    } else {
      const totalCount = response?.getUserNotifications?.data?.totalCount
      yield put(
        getUserNoticationsAction.FULLFILLED({ data: notifications, lastEvaluatedKey, totalCount })
      )
    }
  } else {
    yield put(getUserNoticationsAction.FULLFILLED({ data: [], lastEvaluatedKey: '' }))
  }
}
function* updateUserNotifications(action) {
  const tenantId = yield select((state) => state.user.tenantId)

  const apiData = {
    id: action.payload?.id,
    status: action.payload?.status || 'read',
    updateAll: isNil(action.payload?.id),
    tenantId,
  }
  yield call(UserApi.updateUserNotifications, apiData)
  yield put(updateUserNotificationsAction.FULLFILLED())
  yield call(getUnreadNotifications)
  if (action.payload?.getNotifications) {
    yield call(getUserNotifications, { getNotifications: action.payload.getNotifications })
  }
}

function* deleteUserNotifications(payload) {
  const user = yield select((state) => ({
    tenantId: state.user.tenantId,
    data: state.user.notifications.data,
    totalCount: state.user.notifications.totalCount,
  }))

  const apiData = {
    id: payload?.payload?.id,
    deleteAll: isNil(payload?.payload?.id),
    tenantId: user.tenantId,
  }
  if (apiData.id) {
    const payload = {
      data: filter(user.data, (data) => data.id !== apiData.id),
      totalCount: user.totalCount - 1,
    }
    yield put(deleteUserNotificationsAction.FULLFILLED(payload))
    yield call(UserApi.deleteUserNotifications, apiData)
    return
  }
  yield call(UserApi.deleteUserNotifications, apiData)
  yield call(getUserNotifications)
}

function* getClientsSummary() {
  const tenantId = yield select((state) => state.user.tenantId)

  let data = yield call(UserApi.getClientsSummary, { tenantId })
  if (get(data, 'getClientsSummary.data')) {
    yield put(getClientsSummaryAction.FULLFILLED(JSON.parse(get(data, 'getClientsSummary.data'))))
  }
}
function* resendUserInvite(action) {
  try {
    const { name, email, userId } = action.payload
    const organizationName = yield select((state) => state.bso?.bsoProfile?.name) || ''

    yield call(user.resendUserInvite, { email, userId })
    yield put(resendUserInviteAction.FULLFILLED())

    ampli.accountInvitationResent({
      name: name,
      email: email,
      organizationName,
    })

    const res = yield call(UserApi.getUserProfile, userId)
    const userProfile = res?.getMyProfile

    const currentDate = moment().startOf('day')
    const totalDays = userProfile?.firstSigninDate
      ? currentDate.diff(moment(userProfile?.firstSigninDate).startOf('day'), 'days')
      : 0

    const clientProfile = {
      ...userProfile,
      totalDays,
    }

    yield put(getClientProfileAction.FULLFILLED(clientProfile))
  } catch (error) {
    console.log('resend invitation error : ', error)
    yield put(resendUserInviteAction.REJECTED(error))
  }
}
function* publishAnnouncement(action) {
  const userState = yield select((state) => state.user)
  try {
    const tenantId = yield select((state) => state.user.tenantId)
    const response = yield call(user.makeAnnouncementInput, { ...action?.payload, tenantId })
    if (response?.makeAnnouncement) {
      yield put(setAnnouncementActiveStepAction(userState.announcementSteps[1]))
      yield put(publishAnnouncementAction.FULLFILLED())
    } else {
      yield put(publishAnnouncementAction.REJECTED('Unable To Make The Announcement'))
    }
  } catch (error) {
    console.log('publish announcement error : ', error)
    yield put(publishAnnouncementAction.REJECTED('Unable To Make The Announcement'))
  }
}

/// /////////// Watchers ///////////////////////
export function* watcherUser() {
  yield takeLatest(GET_USER_PROFILE.STARTED, getUserProfile)
  yield takeLatest(UPDATE_USER_PROFILE.STARTED, updateUserProfile)
  yield takeLatest(GET_USER_BUSINESS_PROFILE.STARTED, getUserBusinessProfile)
  yield takeLatest(UPDATE_USER_BUSINESS_PROFILE.STARTED, updateUserBusinessProfile)
  yield takeLatest(CHANGE_PASSWORD.STARTED, changePassword)
  yield takeLatest(GET_CHAT_USERS.STARTED, getChatUsers)
  yield takeLatest(LOG_USER_ACTIVITY, logUserActivity)
  yield takeLatest(CHAT_UNREAD_MESSAGE_COUNT.STARTED, chatUnreadMessageCount)
  yield takeLatest(GET_UNREAD_NOTIFICATIONS.STARTED, getUnreadNotifications)
  yield takeLatest(GET_USER_NOTIFICATIONS.STARTED, getUserNotifications)
  yield takeLatest(UPDATE_USER_NOTIFICATIONS.STARTED, updateUserNotifications)
  yield takeLatest(DELETE_USER_NOTIFICATIONS.STARTED, deleteUserNotifications)
  yield takeLatest(CLIENTS_SUMMARY.STARTED, getClientsSummary)
  yield takeLatest(RESEND_USER_INVITE.STARTED, resendUserInvite)
  yield takeLatest(PUBLISH_ANNOUNCEMENT_ACTION.STARTED, publishAnnouncement)
}
