import React from 'react'

function ChevronGreenIcon({ className = '', ownerIds, id }) {
  return (
    <svg
      className={`fill-primary inline  ${ownerIds?.includes(id) ? 'rotate-90' : ''} ${className}`}
      width="11"
      height="20"
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 10L0.500002 19.5263L0.500002 0.473721L11 10Z" />
    </svg>
  )
}

export default ChevronGreenIcon
