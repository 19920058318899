import React from 'react'

export const AppPlusIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="plus">
        <path
          id="Shape"
          d="M10 3.75V16.25M16.25 10L3.75 10"
          stroke="white"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  )
}
