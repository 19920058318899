import React, { FC, useCallback, useEffect } from 'react'

import {
  LicenseLimitModalBoxContainer,
  UserManagementLayoutContainer,
} from 'pages/Common/container'
import { replaceTerm } from 'utils/helper'
import { BSOProfile } from 'store/reducers/bso'
import {
  columns,
  defaultPaginationValues,
  sortFields,
} from 'components/Admin/UserManagement/config/data/index'
import { useFeatureFlag } from 'hooks/userFeatureFlag'

export interface BusinessesKeys {
  name: string
  email: string
  userId: string
  status: string
  createdAt: Date | null
  created: string
  updated: string
  advisors?: string
  activated: string
  activatedAt: string
  businessName: string
  activatedSort: string
}

interface IBusinessesProps {
  userId: string
  businesses: BusinessesKeys[]
  bsoProfile: BSOProfile
  loading: boolean
  getAdvisorsAction: ({ isPaginated }) => {}
  getBusinessesAction: ({ allStatuses }) => {}
  setCurrentPageAction: (value: number) => {}
  setPerPageAction: (value: number) => {}
  setFiltersAction: (value: any[]) => {}
}

const Businesses: FC<IBusinessesProps> = ({
  userId,
  loading,
  businesses,
  bsoProfile,
  getAdvisorsAction,
  getBusinessesAction,
  setCurrentPageAction,
  setPerPageAction,
  setFiltersAction,
}) => {
  const isV2FeatureFlagOrgEnabled = useFeatureFlag(userId, 'release-v2-org')
  const isV2FeatureFlagUserEnabled = useFeatureFlag(userId, 'release-v2-user')
  const isV2FlagEnabled = isV2FeatureFlagOrgEnabled && isV2FeatureFlagUserEnabled
  useEffect(() => {
    setCurrentPageAction(1)
    setPerPageAction(5)
    setFiltersAction([])

    return () => {
      setCurrentPageAction(defaultPaginationValues.currentPage)
      setPerPageAction(defaultPaginationValues.noOfItemsPerPage)
      setFiltersAction([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = useCallback(() => {
    getAdvisorsAction({ isPaginated: false })
    getBusinessesAction({ allStatuses: true })
    // eslint-disable-next-line
  }, [getAdvisorsAction, getBusinessesAction])

  columns[2].colFields[0].headerName = replaceTerm('advisor', bsoProfile.terminology)

  return (
    <>
      <LicenseLimitModalBoxContainer isOwner={false} />
      <UserManagementLayoutContainer
        getData={getData}
        loading={loading}
        showAdvisorFilter
        tableColumns={columns}
        sortFields={sortFields}
        userAccounts={businesses}
        userAccountType="Business"
        searchByProperties={['name', 'businessName']}
        createAccountUrlPath="create-business-owner?returnTo=user-management/businesses"
        searchInputPlaceholder="Search by name or business"
        isV2FlagOn={!!isV2FlagEnabled}
      />
    </>
  )
}

export default Businesses
