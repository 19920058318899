import React from 'react'

import { useForm } from 'react-hook-form'
import { FORM } from 'config'

import Button from 'components/Common/Button'
import {
  Heading,
  SkipButton,
  SkipButtonWrapper,
  AssessmentListWrapper,
  ContinueButtonWrapper,
} from 'components/Admin/BusinessOwnerStepper/style'
import hookForms from 'utils/hookForms'

import { AdditionalBusinessProfileContainer } from 'pages/Admin/container'
import useFormContainer from 'hooks/useFormContainer'

export default function AdditionalBusinessProfileForm({ handleOnSubmit }) {
  const onSubmit = () => {
    const formValues = hookForms.getForm(FORM.BUSINESS_ASSESSMENT_FORM)?.getValues()
    handleOnSubmit(formValues)
  }

  const methods = useForm({
    mode: 'onBlur',
  })

  const {
    control,
    unregister,
    setValue,
    formState: { isDirty },
  } = methods

  useFormContainer(FORM.BUSINESS_ASSESSMENT_FORM, methods)
  const formValues = hookForms.getForm(FORM.BUSINESS_ASSESSMENT_FORM)?.getValues()

  return (
    <div>
      <Heading>Additional Details</Heading>
      <form>
        <AssessmentListWrapper>
          <AdditionalBusinessProfileContainer
            setValue={setValue}
            control={control}
            formValues={formValues}
            unregister={unregister}
            isDirty={isDirty}
            isOwnerCreation={true}
          />
        </AssessmentListWrapper>
      </form>
      <ContinueButtonWrapper>
        <Button
          type="submit"
          testId="submit"
          label="CONTINUE"
          variant="primary"
          className="w-full"
          onClick={onSubmit}
        />
      </ContinueButtonWrapper>
      <SkipButtonWrapper>
        <SkipButton>
          <Button
            testId="additionalBusinessProfileSkipButton"
            label="SKIP"
            variant="text"
            type="button"
            onClick={() => handleOnSubmit({})}
          />
        </SkipButton>
      </SkipButtonWrapper>
    </div>
  )
}
