import React from 'react'
import { Control, Controller, FieldErrors, FieldValues, UseFormSetValue } from 'react-hook-form'

import { AppCheckBox } from 'components/Common/AppFormFields/AppCheckBox/AppCheckBox.component'
import { AppTextField } from 'components/Common/AppFormFields/AppTextField/AppTextField.component'

interface IFormValues {
  businessAddress: {
    zipCode: string
    state: string
    city: string
    addressLine1: string
    addressLine2?: string
  }
}
interface IAppEditBusinessAddressProps {
  control: Control<FieldValues>
  errors: FieldErrors<IFormValues>
  watch: (name: string) => FieldValues[keyof FieldValues]
  clearErrors: (name?: keyof FieldValues) => void
  setValue: UseFormSetValue<FieldValues>
}
export const AppEditBusinessAddress: React.FC<IAppEditBusinessAddressProps> = ({
  control,
  errors,
  watch,
  setValue,
  clearErrors,
}) => {
  const clearFields = (fields) => {
    fields.forEach((field) => setValue(field, ''))
  }
  const hasBusinessAddress = watch('businessAddress.hasBusinessAddress')

  return (
    <div className="mt-4">
      <div className="mb-2">
        <Controller
          name="businessAddress.addressLine1"
          control={control}
          render={({ field }) => (
            <AppTextField
              size="md"
              type="text"
              placeholder="123 Memory Lane, Columbus, OH 44227"
              label={`Registered Business Address${hasBusinessAddress ? ' *' : ''}`}
              error={errors.businessAddress?.addressLine1?.message as string}
              disabled={hasBusinessAddress === false}
              {...field}
            />
          )}
        />
      </div>

      <div className="mb-6 pl-1 mt-2">
        <Controller
          name="businessAddress.hasBusinessAddress"
          control={control}
          render={({ field }) => (
            <AppCheckBox
              {...field}
              id="hasBusinessAddress"
              label={'I don’t have a Business Address'}
              value={field.value === false}
              onChange={(e) => {
                field.onChange(!e.target.checked)
                clearErrors('businessAddress')
                clearFields([
                  'businessAddress.zipCode',
                  'businessAddress.addressLine1',
                  'businessAddress.addressLine2',
                  'businessAddress.city',
                  'businessAddress.state',
                ])
              }}
            />
          )}
        />
      </div>
    </div>
  )
}
