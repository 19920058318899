import React, { FC } from 'react'

export interface ICrossIconProps {
  className?: string
  id?: string
  onClick?: (...args: any[]) => any
}
const CrossIcon: FC<ICrossIconProps> = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="x-mark">
        <path
          id="Shape"
          d="M5 15L15 5M5 5L15 15"
          stroke="#5E5E5E"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  )
}
export default CrossIcon
