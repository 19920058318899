import React from 'react'

function FaceViewFinder({ className }) {
  return (
    <svg
      className={className}
      width="32px"
      height="32px"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M144 480h-96C39.17 480 32 472.8 32 464v-96C32 359.2 24.84 352 16 352S0 359.2 0 368v96C0 490.5 21.53 512 48 512h96C152.8 512 160 504.8 160 496S152.8 480 144 480zM16 160C24.84 160 32 152.8 32 144v-96C32 39.19 39.17 32 48 32h96C152.8 32 160 24.84 160 16S152.8 0 144 0h-96C21.53 0 0 21.53 0 48v96C0 152.8 7.156 160 16 160zM496 352c-8.844 0-16 7.156-16 16v96c0 8.812-7.172 16-16 16h-96c-8.844 0-16 7.156-16 16s7.156 16 16 16h96c26.47 0 48-21.53 48-48v-96C512 359.2 504.8 352 496 352zM464 0h-96C359.2 0 352 7.156 352 16S359.2 32 368 32h96C472.8 32 480 39.19 480 48v96C480 152.8 487.2 160 496 160S512 152.8 512 144v-96C512 21.53 490.5 0 464 0zM256 96C167.6 96 96 167.6 96 256s71.63 160 160 160s160-71.63 160-160S344.4 96 256 96zM256 384c-70.58 0-128-57.42-128-128s57.42-128 128-128c70.58 0 128 57.42 128 128S326.6 384 256 384zM296 240C309.3 240 320 229.3 320 216S309.3 192 296 192S272 202.7 272 216S282.7 240 296 240zM216 240c13.25 0 24-10.75 24-24S229.3 192 216 192S192 202.7 192 216S202.7 240 216 240zM328 277.2c-7.625-4.422-17.44-1.875-21.88 5.828C295.8 300.9 276.6 312 256 312S216.2 300.9 205.8 283C201.4 275.3 191.6 272.8 183.1 277.2C176.3 281.6 173.7 291.4 178.2 299.1C194.2 326.8 224 344 256 344s61.81-17.22 77.84-44.92C338.3 291.4 335.7 281.6 328 277.2z" />
    </svg>
  )
}

export default FaceViewFinder
