import styled from 'styled-components'
import tw from 'twin.macro'

export const ErrorWrapper = styled.div.attrs({
  className: 'w-full',
})`
  & {
    .ErrorBody {
      ${tw`xs:items-center flex justify-center sm:gap-10 lg:gap-14 xs:flex-col sm:flex-row xs:my-0 sm:my-12 md:my-0 sm:px-14 md:px-0 mdl:mt-24`}
    }
    .ErrorBodyIconWrapper {
      ${tw`xs:block sm:hidden items-center`}
    }
    .ErrorBodyIcon {
      ${tw`xs:w-[340px] xs:h-[283px] sm:h-[310px] mdl:w-[380px] mdl:h-[450px] lg:h-[395px] lg:w-[400px] xs:my-9 sm:my-0`}
    }
  }
`

export const ErrorBoundaryWrapper = styled.div.attrs({
  className: 'w-full ',
})`
  & {
    .ErrorBody {
      ${tw`xs:items-center flex justify-center sm:gap-10 lg:gap-14 xs:flex-col sm:flex-row xs:my-0 sm:my-12 md:my-0 sm:px-14 md:px-0 mdl:mt-24`}
    }
    .ErrorBodyIconWrapper {
      ${tw`xs:block sm:hidden items-center`}
    }
    .ErrorBodyIcon {
      ${tw`xs:w-[340px] xs:h-[283px] sm:h-[310px] mdl:w-[380px] mdl:h-[450px] lg:h-[395px] lg:w-[400px] xs:my-9 sm:my-0`}
    }
  }
`

export const ErrorTextWrapper = styled.div.attrs({
  className: 'w-[345px] text-center items-center',
})`
  & {
    .ErrorText {
      ${tw`font-primary text-base`}
    }
    .ErrorBodyIconWrapper {
      ${tw`xs:block sm:hidden items-center`}
    }
    .ErrorBodyIcon {
      ${tw`xs:w-[340px] xs:h-[283px] mdl:w-[425px] mdl:h-[450px] lg:h-[400px] lg:w-[400px] my-9`}
    }
  }
`

export const ErrorBoundaryTextWrapper = styled.div.attrs({
  className: 'w-[315px]  items-center',
})`
  & {
    .ErrorText {
      ${tw`font-primary text-base`}
    }
    .ErrorBodyIconWrapper {
      ${tw`xs:block sm:hidden items-center`}
    }
    .ErrorBodyIcon {
      ${tw`xs:w-[340px] xs:h-[283px] mdl:w-[425px] mdl:h-[450px] lg:h-[400px] lg:w-[400px] my-9`}
    }
  }
`
