import React, { FC } from 'react'
import { Tooltip, PlacesType } from 'react-tooltip'
import { AppToolTipWrapper } from './AppToolTip.style'

export interface IAppToolTip {
  name: string
  text: string
  place?: PlacesType
  children: React.ReactChild | React.ReactNode
  variant: 'primary' | 'clear'
}

export const AppToolTip: FC<IAppToolTip> = ({ name, text, children, variant }) => {
  return (
    <span
      data-tooltip-id={name}
      data-testid={name}
      data-tooltip-content={text}
      className=" text-primary-disabledTextLight text-center"
    >
      <AppToolTipWrapper variant={variant}>
        <Tooltip
          id={name}
          style={{
            zIndex: 1000,
            backgroundColor: variant === 'primary' ? '#705AF8' : '#FFF',
            color: variant === 'primary' ? '#ECECEC' : '#121212',
            maxWidth: 320,
          }}
          opacity={100}
          noArrow
        />
      </AppToolTipWrapper>
      {children}
    </span>
  )
}
