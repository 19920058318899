import React from 'react'

import flag from 'assets/svgs/flag.svg'

export default function Timeline({ stepProgressInfo }) {
  return (
    <div className="text-center  mb-28">
      <div className=" max-w-[340px] my-0 mx-[auto] bg-secondary p-10">
        <div className="ml-[35px] ">
          <div className=" relative border-l border-primary">
            <ol className=" flex flex-col gap-16 -ml-2.5">
              <li className="flex items-center gap-4 -ml-[25px]">
                <div className="w-[74px] h-[74px] bg-primary rounded-full flex items-center justify-center border-rad">
                  <img src={flag} alt={'flag'} width={'37px'} />
                </div>
                <span className=" text-basefont-primary font-bold">Sales Assessment</span>
              </li>

              {stepProgressInfo?.map((step, index) => (
                <li className="flex items-center gap-4 activeLink cursor-pointer ">
                  <div className="w-5 h-5 bg-secondary rounded-full outline outline-offset-0 outline-secondary outline-4">
                    <div
                      className={`w-5 h-5 outline ${
                        step.isAnswered ? 'bg-primary' : 'bg-secondary'
                      } outline-offset-4 outline-[3px] outline-primary rounded-full`}
                    ></div>
                  </div>
                  <span className=" text-basefont-primary font-normal">{step.value}</span>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}
