import React from 'react'

const AddNewClientIcon = () => {
  return (
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="29" cy="29" r="29" fill="#5E06B9" />
      <path
        d="M31.75 31.0833C28.4125 31.0833 21.75 32.8012 21.75 36.25V38.8333H41.75V36.25C41.75 32.8012 35.0875 31.0833 31.75 31.0833ZM20.5 25.9166V22.0416H18V25.9166H14.25V28.5H18V32.375H20.5V28.5H24.25V25.9166M31.75 28.5C33.0761 28.5 34.3479 27.9556 35.2855 26.9867C36.2232 26.0177 36.75 24.7036 36.75 23.3333C36.75 21.963 36.2232 20.6488 35.2855 19.6799C34.3479 18.711 33.0761 18.1666 31.75 18.1666C30.4239 18.1666 29.1521 18.711 28.2145 19.6799C27.2768 20.6488 26.75 21.963 26.75 23.3333C26.75 24.7036 27.2768 26.0177 28.2145 26.9867C29.1521 27.9556 30.4239 28.5 31.75 28.5Z"
        fill="white"
      />
    </svg>
  )
}

export default AddNewClientIcon
