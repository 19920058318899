import { RootState } from 'App'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

const dashboardProps = (state: RootState) => {
  return {
    bsoProfile: state.bso.bsoProfile,
    user: state.user?.user,
  }
}

function dashboardDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const advisorDashboardConnector = connect(dashboardProps, dashboardDispatch)
