import React, { FC, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import DataTable from 'components/Common/DataTable'

import {
  signedColumn,
  unSignedColumn,
} from 'components/DataReports/ClientReportManagement/ClientReportColumnData'
import {
  CLIENT_STATUS,
  ExtendedITabOption,
} from 'components/DataReports/ClientReportManagement/utils'
import { SortOrder } from '__generated__/api-types-and-hooks'
import { getOrder } from 'utils/helper'

interface IClientReportTableProps {
  filter: ExtendedITabOption | undefined
  lastEvaluatedKey: ''
  savedCurrentPage: number
  clientReportList: []
  getClientAttestationReportAction: (filter) => void
  resetSavedCurrentPageAction: () => void
  resetClientAttestationReportAction: () => void
  isClientAttestationReportLoading: boolean
  totalPages: number
  totalRecords: number
  noOfItemsPerPage: number
}
const ClientReportTable: FC<IClientReportTableProps> = ({
  filter,
  clientReportList,
  lastEvaluatedKey,
  savedCurrentPage,
  getClientAttestationReportAction,
  isClientAttestationReportLoading,
  resetClientAttestationReportAction,
  resetSavedCurrentPageAction,
  totalPages,
  totalRecords,
  noOfItemsPerPage,
}) => {
  const [pageSize, setPageSize] = useState<number>(5)
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    getClientAttestationReportAction({
      filter: filter?.value?.toUpperCase(),
      sort: SortOrder.Ascending,
      pageSize,
    })
    return () => resetClientAttestationReportAction()
    // eslint-disable-next-line
  }, [filter])

  const getRecords = (
    pageSize: number = 5,
    sortOrder: string,
    currentPage?: number,
    clearLastEvalKey: boolean = false,
    paginationDirection?: string
  ) => {
    const lastEvalKey = clearLastEvalKey ? '' : lastEvaluatedKey
    getClientAttestationReportAction({
      filter: filter?.value?.toUpperCase(),
      viewMore: !isEmpty(lastEvalKey),
      sort:
        sortOrder === SortOrder.Ascending
          ? SortOrder.Ascending
          : sortOrder === SortOrder.Descending
          ? SortOrder.Descending
          : getOrder(filter),
      pageSize,
      deleteLastEvaluatedKey: sortOrder ? true : false,
      currentPage,
      paginationDirection,
    })
  }

  return (
    <div className="pt-4">
      <DataTable
        pageSize={pageSize}
        setPageSize={setPageSize}
        columns={filter?.label === CLIENT_STATUS.SIGNED ? signedColumn : unSignedColumn}
        rows={clientReportList}
        pagination={true}
        isApiDataPaginated={true}
        getRecords={getRecords}
        hasMoreData={isEmpty(lastEvaluatedKey) ? false : true}
        isLoading={isClientAttestationReportLoading}
        savedCurrentPage={savedCurrentPage}
        resetSavedCurrentPage={resetSavedCurrentPageAction}
        totalRecordsApi={totalRecords}
        totalPagesApi={totalPages}
        noOfItemsPerPageApi={noOfItemsPerPage}
        currentPageApi={currentPage}
        setCurrentPageApi={setCurrentPage}
      />
    </div>
  )
}

export default ClientReportTable
