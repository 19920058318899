import React, { useEffect, useState, useMemo } from 'react'
import TableHeader from 'components/Common/TableHeader'
import TableBody from 'components/Common/TableBody'
import TablePagination from 'components/Common/TablePagination'
import Loader from 'components/Loaders'
import { checkIsLoading, sortHandlerHelper } from 'utils/helper'
import { Columns } from 'types'
import TablePaginationV2 from '../TablePaginationV2'
import { noOfItemsPerPageOptions } from 'components/Admin/UserManagement/config/data'
import { PaginationDirection } from '__generated__/api-types-and-hooks'
let emptyFunction = (obj) => {}
export interface IDataTableProps {
  columns: Columns
  rows: any[]
  /** todo: remove "pagination" prop. Instead, infer that pagination should be used if "pageSize" and/or "setPageSize" props are defined. */
  pagination: boolean
  setPageSize?: (value: any) => void
  getRecords?: (...args: any[]) => void
  pageSize: number
  ExpandedComponent: any
  /** The number of equally spaced grid columns used in conjuction with "display: grid" to style the table. */
  gridColumns?: number
  /** The name of the business concept / data type that populates each table row. (e.g. "user", "play", etc.) */
  tableType?: string
  hasMoreData?: boolean
  isApiDataPaginated?: boolean
  isLoading?: boolean
  defaultSort: { [key: string]: string }
  loaderType?: string
  savedCurrentPage?: number
  resetSavedCurrentPage?: () => void
  totalRecordsApi?: number
  totalPagesApi?: number
  noOfItemsPerPageApi?: number
  currentPageApi?: number
  setCurrentPageApi?: (value: number) => void
}
const DataTable = ({
  columns,
  rows,
  pagination,
  setPageSize = emptyFunction,
  getRecords = emptyFunction,
  pageSize,
  ExpandedComponent,
  gridColumns,
  tableType,
  hasMoreData = false,
  isApiDataPaginated,
  defaultSort,
  isLoading,
  loaderType,
  savedCurrentPage,
  resetSavedCurrentPage,
  totalRecordsApi,
  totalPagesApi,
  noOfItemsPerPageApi,
  currentPageApi,
  setCurrentPageApi,
}: IDataTableProps) => {
  const size = useMemo(
    () => (rows?.length < pageSize && rows?.length > 0 ? rows?.length : pageSize),
    [pageSize, rows]
  )
  const [noOfItemsPerPage, setNoOfItemsPerPage] = useState(size)
  const [isInitialRender, setIsInitialRender] = useState(false)
  const [isMoveToNextButton, setIsMoveToNextButton] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [startIndex, setStartIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(0)
  const [tableData, setTableData] = useState<any[]>([])

  useEffect(() => {
    if (rows.length > 0 && savedCurrentPage) {
      setCurrentPage(savedCurrentPage!)
      resetSavedCurrentPage!()
    }
    // eslint-disable-next-line
  }, [savedCurrentPage, rows])
  useEffect(() => {
    if (rows?.length > 0 && !isInitialRender) {
      setNoOfItemsPerPage(size)
      setIsInitialRender(true)
    }

    if (
      currentPage < totalPages &&
      rows.length !== totalItems &&
      isApiDataPaginated &&
      isMoveToNextButton &&
      !savedCurrentPage
    ) {
      setCurrentPage(currentPage + 1)
    } else if (!isMoveToNextButton && rows.length !== totalItems && isInitialRender) {
      setNoOfItemsPerPage(noOfItemsPerPage)
      setPageSize(noOfItemsPerPage + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  useEffect(() => {
    getRecords()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (Array.isArray(rows)) {
      setTotalItems(rows.length)
      setTableData(rows)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // return () => {
    //   setCurrentPage(1)
    // }
  }, [rows])

  const moveNextHandler = () => {
    let difference = totalItems - currentPage * noOfItemsPerPage
    if (isApiDataPaginated && difference < noOfItemsPerPage && hasMoreData) {
      getRecords(noOfItemsPerPage)
      setIsMoveToNextButton(true)
      setCurrentPage(currentPage + 1)
    } else if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  const movePreviousHandler = () => {
    if (currentPage - 1 > 0) {
      setCurrentPage(currentPage - 1)
    }
  }
  const updateNoOfItemPerPageHandler = (itemPerPage) => {
    if (isApiDataPaginated && hasMoreData && itemPerPage > rows.length) {
      getRecords(itemPerPage)
      setIsMoveToNextButton(false)
    }
    if (itemPerPage >= 1 && itemPerPage <= rows.length) {
      setPageSize(itemPerPage)
    } else if (itemPerPage > rows.length) {
      itemPerPage = rows.length
    } else {
      itemPerPage = 1
    }
    setNoOfItemsPerPage(itemPerPage)
  }
  const sortHandler = ({ sortField, sortOrder, fieldType }) => {
    let sortedTableData = sortHandlerHelper({
      sortField,
      sortOrder,
      fieldType,
      data: tableData,
    })
    setTableData(sortedTableData)
  }
  const data = useMemo(() => {
    let computedData = tableData
    // Commenting it out as it is not getting used
    // if (startIndex +1 === rows.length) {
    //   let updatedCurrentPage = currentPage - 1
    //   if (currentPage > 1) {
    //     // startIndex & endIndex
    //     let start = (updatedCurrentPage - 1) * noOfItemsPerPage
    //     let end = (updatedCurrentPage - 1) * noOfItemsPerPage + noOfItemsPerPage
    //     setStartIndex(start)
    //     if (rows.length < end) {
    //       setEndIndex(rows.length)
    //     } else {
    //       setEndIndex(end)
    //     }
    //     // updateTotalPages
    //     let pages = Math.ceil(rows.length / noOfItemsPerPage)
    //     setCurrentPage(updatedCurrentPage)
    //     setTotalPages(pages)
    //     return computedData.slice(start, end)
    //   } else {
    //     // note: for one record
    //     setStartIndex(0)
    //     setEndIndex(1)
    //     setCurrentPage(1)
    //     setTotalPages(1)
    //     return computedData
    //   }
    // } else {
    // // startIndex & endIndex
    let start = (currentPage - 1) * noOfItemsPerPage
    let end = (currentPage - 1) * noOfItemsPerPage + noOfItemsPerPage
    setStartIndex(start)
    if (rows.length < end) {
      setEndIndex(rows.length)
    } else {
      setEndIndex(end)
    }
    // updateTotalPages
    let pages = Math.ceil(rows.length / noOfItemsPerPage)
    setTotalPages(pages)
    return computedData.slice(start, end)
    // }
    // eslint-disable-next-line
  }, [startIndex, tableData, rows.length, currentPage, noOfItemsPerPage])

  const updateNoOfItemPerPageApiHandler = (value: string) => {
    const updatedValue = Number(value)
    setPageSize(updatedValue)
    getRecords(updatedValue, '', 1, true)
    setCurrentPageApi!(1)
  }

  const moveNextApiHandler = (value: number) => {
    getRecords(noOfItemsPerPageApi, '', value, false)
    setCurrentPageApi!(value)
  }

  const movePreviousApiHandler = (value: number) => {
    if (value > 0) {
      getRecords(noOfItemsPerPageApi, '', value, false, PaginationDirection.Backward)
      setCurrentPageApi!(value)
    }
  }

  return (
    <div>
      <div className="overflow-hidden xs:w-full md:w-full lg:w-full overflow-x-auto">
        <table className="w-full">
          <TableHeader
            setCurrentPage={setCurrentPage}
            gridColumns={gridColumns}
            columns={columns}
            sortHandler={sortHandler}
            defaultSort={defaultSort}
            isApiDataPaginated={isApiDataPaginated}
            getRecords={getRecords}
            totalItems={isApiDataPaginated ? noOfItemsPerPageApi : totalItems}
            currentPage={isApiDataPaginated ? currentPageApi : currentPage}
          />
          {!isApiDataPaginated ? (
            <>
              {checkIsLoading(isLoading, data, hasMoreData) ? (
                <Loader loader={loaderType} />
              ) : (
                <TableBody
                  gridColumns={gridColumns}
                  columns={columns}
                  rows={data}
                  ExpandedComponent={ExpandedComponent}
                />
              )}
            </>
          ) : (
            <>
              {checkIsLoading(isLoading, rows, hasMoreData) ? (
                <Loader loader={loaderType} />
              ) : (
                <TableBody
                  gridColumns={gridColumns}
                  columns={columns}
                  rows={rows}
                  ExpandedComponent={ExpandedComponent}
                />
              )}
            </>
          )}
        </table>
        {!isApiDataPaginated && pagination && tableData.length > 0 && (
          <TablePagination
            noOfItemsPerPage={noOfItemsPerPage}
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems}
            startIndex={startIndex}
            endIndex={endIndex}
            hasMoreData={hasMoreData}
            moveNextHandler={moveNextHandler}
            movePreviousHandler={movePreviousHandler}
            updateNoOfItemPerPageHandler={updateNoOfItemPerPageHandler}
            type={tableType || columns[0].tableType || 'record'}
          />
        )}
        {isApiDataPaginated && pagination && tableData.length > 0 && (
          <TablePaginationV2
            noOfItemsPerPage={noOfItemsPerPageApi!}
            currentPage={currentPageApi!}
            totalPages={totalRecordsApi!}
            moveNextHandler={moveNextApiHandler}
            movePreviousHandler={movePreviousApiHandler}
            updateNoOfItemPerPageHandler={updateNoOfItemPerPageApiHandler}
            options={noOfItemsPerPageOptions}
          />
        )}
      </div>
    </div>
  )
}
DataTable.defaultProps = {
  ExpandedComponent: React.Fragment,
  getRecords: () => {},
  isApiDataPaginated: false,
  defaultSort: {},
  loaderType: 'UserManagementLoader',
}
export default DataTable
