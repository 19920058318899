import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const ClientAssignmentLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="20" y="50" rx="2" ry="2" width="170" height="13" />
        <rect x="230" y="50" rx="2" ry="2" width="170" height="13" />

        <rect x="20" y="80" rx="14" ry="14" width="180" height="28" />
        <rect x="230" y="80" rx="14" ry="14" width="180" height="28" />

        <rect x="0" y="140" rx="0" ry="0" width="1" height="140" />
        <rect x="0" y="280" rx="0" ry="0" width="205" height="1" />
        <rect x="0" y="140" rx="0" ry="0" width="205" height="1" />
        <rect x="205" y="140" rx="0" ry="0" width="1" height="140" />

        <rect x="14" y="160" rx="14" ry="14" width="180" height="25" />
        <rect x="14" y="200" rx="14" ry="14" width="180" height="25" />
        <rect x="14" y="240" rx="14" ry="14" width="180" height="25" />

        <rect x="230" y="140" rx="0" ry="0" width="1" height="140" />
        <rect x="230" y="280" rx="0" ry="0" width="205" height="1" />
        <rect x="230" y="140" rx="0" ry="0" width="205" height="1" />
        <rect x="435" y="140" rx="0" ry="0" width="1" height="140" />

        <rect x="245" y="160" rx="14" ry="14" width="180" height="25" />
        <rect x="245" y="200" rx="14" ry="14" width="180" height="25" />
        <rect x="245" y="240" rx="14" ry="14" width="180" height="25" />
      </ContentLoader>
    </>
  )
}

export default ClientAssignmentLoader
