import React, { FC, useEffect } from 'react'
import { ConnectedProps } from 'react-redux'

import { launchUsetifulIfNeeded } from 'utils/helper'
import { EventStatsWrapper } from './AdvisorDashboard.style'
import GetUserEvents from './FutureEvents/FutureEvents.component'
import NavigationCards from './NavigationCard/NavigationCard.component'
import { advisorDashboardConnector } from './AdvisorDashboard.connector'
import NotificationList from './NotificationList/NotificationList.component'
import ClientStatsCards from './ClientStatsCards/ClientStatsCards.component'
import UserTimeZone from 'components/Common/UserTimeZone/UserTimeZone.component'
import ProfileSection from '../Common/ProfileSection/ProfileSection.component'

interface IAdvisorDashboardProps extends ConnectedProps<typeof advisorDashboardConnector> {}

const AdvisorDashboard = advisorDashboardConnector<FC<IAdvisorDashboardProps>>(
  ({ user, bsoProfile }) => {
    useEffect(() => {
      launchUsetifulIfNeeded()
    }, [user])

    return (
      <div className="w-full max-w-full">
        <NotificationList />
        <ProfileSection
          firstName={user.firstName}
          lastName={user.lastName}
          profileImgSrc={bsoProfile.logoUrl}
          bsoName={bsoProfile.name}
          isBSO={true}
        />
        <NavigationCards />
        <EventStatsWrapper>
          <ClientStatsCards />
          <GetUserEvents
            calendarEventViewPreference={user.calendarEventViewPreference}
            role={user.roles}
          />
        </EventStatsWrapper>
        <UserTimeZone />
      </div>
    )
  }
)

export default AdvisorDashboard
