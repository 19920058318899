import React from 'react'
import { FieldWrapper, LabelWrapper, ValueWrapper } from 'components/Clients/Client/Details/style'

const Field = ({ label, value }) => {
  return (
    <FieldWrapper>
      <LabelWrapper>{label}</LabelWrapper>
      <ValueWrapper>{value}</ValueWrapper>
    </FieldWrapper>
  )
}

export default Field
