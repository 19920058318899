import React, { FC } from 'react'

interface IAssessmentImageProps {
  imageUrl: string
  margin?: string
}

const AssessmentImage: FC<IAssessmentImageProps> = ({ imageUrl, margin }) => {
  return (
    <img
      id="active-question-image"
      src={imageUrl}
      alt="cool"
      className={`inline ${margin}`}
      placeholder="Cool"
    />
  )
}
AssessmentImage.defaultProps = {
  margin: 'mt-6',
}

export default AssessmentImage
