import { getInitials } from 'components/Chat/helper'
import React from 'react'

export interface ChatWithNameInitialsLogoProps {
  className?: string
  padding?: string
  name: { firstName: string; lastName: string }
  logoUrl?: string
}

const ChatWithNameInitialsLogo: React.FC<ChatWithNameInitialsLogoProps> = ({
  className,
  name,
  logoUrl,
}) => {
  return (
    <div>
      {logoUrl ? (
        <span
          className={`rounded-full inline-flex justify-center items-center ${
            logoUrl ? '!bg-white' : ''
          }  ${className}`}
        >
          <img
            src={logoUrl}
            className="object-cover bg-center w-full h-full rounded-full"
            alt="profile-pic"
          />
        </span>
      ) : (
        <span
          className={`rounded-full inline-flex justify-center items-center text-white font-semibold uppercase ${className}`}
        >
          {getInitials(name)}
        </span>
      )}
    </div>
  )
}

ChatWithNameInitialsLogo.defaultProps = {
  padding: 'p-[6px]',
  className: 'bg-tertiary h-[50px] w-[50px]',
}
export default ChatWithNameInitialsLogo
