import { CUSTOM_MESSAGE_TYPE } from 'components/Chat/constants/messageTypes'
import React, { useState } from 'react'
import get from 'lodash/get'
import {
  MessageDeleted as DefaultMessageDeleted,
  MessageRepliesCountButton as DefaultMessageRepliesCountButton,
  MessageStatus as DefaultMessageStatus,
  MessageText,
  areMessageUIPropsEqual,
  messageHasAttachments,
  messageHasReactions,
  EditMessageForm as DefaultEditMessageForm,
  MessageInput,
  MML,
  Modal,
  useComponentContext,
  MessageContextValue,
  useMessageContext,
  MessageUIComponentProps,
  MessageTimestamp,
  useChatContext,
} from 'stream-chat-react'
import {
  ReactionsList as DefaultReactionList,
  ReactionSelector as DefaultReactionSelector,
} from '../Reactions'

import type { DefaultStreamChatGenerics } from '../../types'
import { MessageOptions as DefaultMessageOptions } from 'components/Chat/components/CustomMessage/MessageOptions'
import ExclamationMarkIcon from 'components/Common/SvgIcons/ExclamationMarkIcon'
import { ampli } from 'ampli'
import { useUserInfo } from 'hooks/useUserInfo'

type MessageSimpleWithContextProps<
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
> = MessageContextValue<StreamChatGenerics>

const MessageSimpleWithContext = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: MessageSimpleWithContextProps<StreamChatGenerics>
) => {
  const {
    additionalMessageInputProps,
    clearEditingState,
    editing,
    endOfGroup,
    firstOfGroup,
    groupedByUser,
    handleAction,
    handleOpenThread,
    handleRetry,
    highlighted,
    isMyMessage,
    isReactionEnabled,
    message,
    reactionSelectorRef,
    showDetailedReactions,
    threadList,
  } = props

  const {
    Attachment,
    EditMessageInput = DefaultEditMessageForm,
    MessageDeleted = DefaultMessageDeleted,
    MessageOptions = DefaultMessageOptions,
    MessageRepliesCountButton = DefaultMessageRepliesCountButton,
    MessageStatus = DefaultMessageStatus,
    ReactionSelector = DefaultReactionSelector,
    ReactionsList = DefaultReactionList,
  } = useComponentContext<StreamChatGenerics>('MessageSimple')
  const { channel } = useChatContext<StreamChatGenerics>()
  const { userName, roles, organizationName } = useUserInfo()

  const hasAttachment = messageHasAttachments(message)
  const hasReactions = messageHasReactions(message)

  const [showEmoji, setShowEmoji]: any = useState(false)
  const messageClasses = isMyMessage()
    ? 'str-chat__message str-chat__message--me str-chat__message-simple str-chat__message-simple--me'
    : 'str-chat__message str-chat__message-simple'

  if (message.customType === CUSTOM_MESSAGE_TYPE.date) {
    return null
  }

  if (message.deleted_at || message.type === 'deleted') {
    return <MessageDeleted message={message} />
  }
  let messageDetails = message
  const isFrozen = get(channel, 'data.frozen') && message.type === 'error'
  if (isFrozen) {
    messageDetails = {
      ...message,
      text: 'Sorry, this channel has been frozen by the admin',
      type: '',
    }
    console.log({ message })
  }
  const messageEditedLog = (message) => {
    if (message.updated_at !== message.created_at) {
      ampli.messageEdited({
        editorName: userName,
        editorRole: roles,
        organizationName,
      })
    }
  }
  return (
    <>
      {editing && (
        <Modal onClose={clearEditingState} open={editing}>
          <MessageInput
            clearEditingState={() => {
              clearEditingState()
              messageEditedLog(message)
            }}
            Input={EditMessageInput}
            message={message}
            {...additionalMessageInputProps}
          />
        </Modal>
      )}
      {
        <div
          className={`
						${messageClasses}
						str-chat__message--${message.type}
						str-chat__message--${message.status}
						${message.text ? 'str-chat__message--has-text' : 'has-no-text'}
						${hasAttachment ? 'str-chat__message--has-attachment' : ''}
            ${hasReactions && isReactionEnabled ? 'str-chat__message--with-reactions' : ''}
            ${highlighted ? 'str-chat__message--highlighted' : ''}
            ${message.pinned ? 'pinned-message' : ''}
            ${groupedByUser ? 'str-chat__virtual-message__wrapper--group' : ''}
            ${firstOfGroup ? 'str-chat__virtual-message__wrapper--first' : ''}
            ${endOfGroup ? 'str-chat__virtual-message__wrapper--end' : ''}
					`.trim()}
          key={message.id}
        >
          <MessageStatus />
          <div
            className="str-chat__message-inner mt-6"
            data-testid="message-inner"
            onClick={
              message.status === 'failed' && message.errorStatusCode !== 403
                ? () => handleRetry(message)
                : undefined
            }
            onKeyPress={
              message.status === 'failed' && message.errorStatusCode !== 403
                ? () => handleRetry(message)
                : undefined
            }
          >
            <>
              <MessageOptions setShowEmoji={setShowEmoji} showEmoji={showEmoji} />
              {hasReactions && !showDetailedReactions && isReactionEnabled && (
                <ReactionsList reverse />
              )}
              {showEmoji && isReactionEnabled && (
                <ReactionSelector ref={reactionSelectorRef} setShowEmoji={setShowEmoji} />
              )}
            </>
            {message.attachments?.length && !message.quoted_message ? (
              <Attachment actionHandler={handleAction} attachments={message.attachments} />
            ) : null}
            <MessageText message={messageDetails} />
            {message.mml && (
              <MML
                actionHandler={handleAction}
                align={isMyMessage() ? 'right' : 'left'}
                source={message.mml}
              />
            )}
            {!threadList && !!message.reply_count && (
              <div className="str-chat__message-simple-reply-button">
                <MessageRepliesCountButton
                  onClick={handleOpenThread}
                  reply_count={message.reply_count}
                />
              </div>
            )}

            <div className="pt-2 pb-1 text-xs font-primary text-silver-darker font-medium text-right">
              {isFrozen && (
                <span className="relative text-primary-error inline-block items-center mr-2 font-bold text-14">
                  <ExclamationMarkIcon className="absolute fill-primary-error hover:fill-primary-error h-[14px] w-[14px] mr-1 inline-block left-[-18px] top-[2px]" />
                  Unsent
                </span>
              )}
              <MessageTimestamp
                customClass="str-chat__message-simple-timestamp"
                format="hh:mm A MMMM D"
              />
            </div>
          </div>
        </div>
      }
    </>
  )
}

const MemoizedMessageSimple = React.memo(
  MessageSimpleWithContext,
  areMessageUIPropsEqual
) as typeof MessageSimpleWithContext

/**
 * The default UI component that renders a message and receives functionality and logic from the MessageContext.
 */
export const MessageSimple = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: MessageUIComponentProps<StreamChatGenerics>
) => {
  const messageContext = useMessageContext<StreamChatGenerics>('MessageSimple')

  return <MemoizedMessageSimple {...messageContext} {...props} />
}
