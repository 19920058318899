import React, { useEffect, useRef, useState } from 'react'
import { useChannelStateContext, useChatContext } from 'stream-chat-react'
import './MessagingChannelHeader.css'

import type { StreamChatGenerics } from '../../types'
// import { NotePadIcon, NotePadSimpleIcon } from 'components/Common/SvgIcons'
import ArrowLeftIcon from 'components/Common/SvgIcons/ArrowLeftIcon'
import { isMobileOnly } from 'mobile-device-detect'
import { getChannelName, getBusinessName } from 'components/Chat/helper'
import ModalBox from 'components/Common/ModalBox'
import UserProfile from '../UserProfile'
import GroupChatLogo from 'components/Common/GroupChatLogo'
import ChatWithNameInitialsLogo from 'components/Common/ChatWithNameInitialsLogo'
import { UserFile } from '__generated__/api-types-and-hooks'

type Props = {
  theme: string
  usersAssessmentResponseByQuestion: any
  setShowChannelPreview: (showChannelPreview) => void
  setShowDesktopUserProfile: (showChannelPreview) => void
  showDesktopUserProfile: boolean
  showChannelPreview: boolean
}

type UserName = {
  firstName: string
  lastName: string
}
type File = {
  logo: UserFile
}

const MessagingChannelHeader = (props: Props) => {
  const {
    theme,
    setShowChannelPreview,
    showChannelPreview,
    // setShowDesktopUserProfile,
    // showDesktopUserProfile,
  } = props
  const { client } = useChatContext<StreamChatGenerics>()
  const { channel } = useChannelStateContext<StreamChatGenerics>()
  const [channelName, setChannelName] = useState(channel.data?.name || '')
  const [isEditing, setIsEditing] = useState(false)
  const [, setTitle] = useState('')
  const [showUserProfile, setShowUserProfile] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const members = Object.values(channel.state.members || {}).filter(
    (member) => member.user?.id !== client?.user?.id
  )

  const updateChannel = async () => {
    if (channelName && channelName !== channel.data?.name) {
      await channel.update(
        { name: channelName },
        { text: `Channel name changed to ${channelName}` }
      )
    }

    setIsEditing(false)
  }

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef?.current.focus()
    }
  }, [isEditing])

  useEffect(() => {
    if (!channelName) {
      setTitle(members.map((member) => member.user?.name).join(', '))
    }
  }, [channelName, members])

  const EditHeader = () => (
    <form
      style={{ flex: 1 }}
      onSubmit={(event) => {
        event.preventDefault()
        inputRef?.current?.blur()
      }}
    >
      <input
        autoFocus
        className="channel-header__edit-input"
        onBlur={updateChannel}
        onChange={(event) => setChannelName(event.target.value)}
        placeholder="Type a new name for the chat"
        ref={inputRef}
        value={channelName}
      />
    </form>
  )
  const closemodal = () => {
    setShowChannelPreview(false)
  }

  // Commenting icon code may be we need this later
  // let icon: any = null
  // if (members.length === 1) {
  //   const member: any = members[0]
  //   icon = assessmentIcons.find(
  //     (icons) => icons.iconLabel === props.usersAssessmentResponseByQuestion[member?.user?.id]?.icon
  //   )
  // }
  return (
    <>
      <div className="py-5 xs:px-2 xl:pl-3 mdl:pr-6 xl:px-6 messaging__channel-header">
        <div className="xs:hidden sm:hidden md:hidden mdl:flex pb-3 items-center gap-2">
          {members.length > 1 ? (
            <GroupChatLogo />
          ) : (
            <ChatWithNameInitialsLogo
              name={{
                firstName: (members[0]?.user as unknown as UserName)?.firstName,
                lastName: (members[0]?.user as unknown as UserName)?.lastName,
              }}
              logoUrl={(members[0]?.user as unknown as File)?.logo?.url || ''}
            />
          )}

          {!isEditing ? (
            <div className="channel-header__name flex flex-col">
              <span className="!font-primary text-primary-text font-semibold">
                {getChannelName(
                  Object.values(members).filter(({ user }) => user?.id !== client.userID)
                )}
              </span>

              <span className="text-sm !font-primary text-primary-text">
                {getBusinessName(members)}
              </span>
            </div>
          ) : (
            <EditHeader />
          )}
          {/* <div className="inline-flex items-center">
            <span className="xl:hidden" onClick={() => setShowUserProfile(true)}>
              {!showUserProfile ? (
                <NotePadSimpleIcon className="cursor-pointer" />
              ) : (
                <NotePadIcon className="cursor-pointer" />
              )}
            </span>
            <span
              className="hidden xl:block"
              onClick={() => setShowDesktopUserProfile(!showDesktopUserProfile)}
            >
              {!showDesktopUserProfile ? (
                <NotePadSimpleIcon className="cursor-pointer" />
              ) : (
                <NotePadIcon className="cursor-pointer" />
              )}
            </span>
          </div> */}
        </div>

        <div className="mdl:hidden mobile-header-only">
          <div
            className={`${theme} flex justify-between items-center xs:pt-3 md:pt-10 md:mt-7 px-3`}
          >
            <div onClick={() => setShowChannelPreview(!showChannelPreview)}>
              <ArrowLeftIcon className={'fill-silver'} />
            </div>
            {/* <div>
              <div className="bg-grey h-[44px] w-[44px] rounded-3xl"></div>
            </div>
            <div className="inline-flex items-center">
              <span onClick={() => setShowUserProfile(true)}>
                <NotePadSimpleIcon className="cursor-pointer" />
              </span>
            </div> */}
          </div>
          <div className="flex flex-col justify-center items-center">
            <span className="font-primary text-primary-text font-semibold pt-2">
              {getChannelName(
                Object.values(members).filter(({ user }) => user?.id !== client.userID)
              )}
            </span>
            <span className="text-sm font-primary text-primary-text">BSO Name</span>
          </div>
          {showUserProfile && (
            <div className="xl:hidden">
              <ModalBox
                onClose={closemodal}
                width={`xs:w-full sm:!w-[420px] md:!!w-[420px] mdl:!w-[420px] `}
                title={isMobileOnly ? 'Files Received' : 'Files Received'}
              >
                <UserProfile
                  usersAssessmentResponseByQuestion={props.usersAssessmentResponseByQuestion}
                  setShowUserProfile={setShowUserProfile}
                />
              </ModalBox>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default React.memo(MessagingChannelHeader)
