import {
  OptionTypes,
  useGetBusinessSummaryQuery,
} from '../../../../__generated__/api-types-and-hooks'
import {
  COLORS,
  ClientDataCardBody,
  ClientDataCardDescription,
  ClientDataCardHeader,
  ClientDataCardTitle,
  ClientDataCardWrapper,
} from '../../../../pages/DataReports/styles'
import React from 'react'
import { ClientDataDonutChart } from './ClientDataDonutChart'
import { ClientDataItemCircle } from './ClientDataItemCircle'
import Loader from '../../../Loaders'
export interface IClientDataCardProps {
  title?: string
  description?: string
  useDonutChart: boolean
  total: number
  tenantId: string
  optionType?: OptionTypes
}

export const ClientDataCard = ({
  title,
  description,
  useDonutChart,
  total,
  tenantId,
  optionType,
}: IClientDataCardProps) => {
  const input = {
    input: {
      tenantId,
      optionType,
    },
  }
  const { data: response, isLoading, isError } = useGetBusinessSummaryQuery(input)
  const tableData = response?.getBusinessSummary?.data?.filter(
    (data) => data.optionType === optionType
  )[0]?.data

  const sortedTableData = tableData
    ?.filter(({ summary }) => summary && summary > 0)
    .sort((a, b) => {
      const bSummary = b.summary ?? 0
      const aSummary = a.summary ?? 0
      return bSummary - aSummary
    })

  const data = sortedTableData?.map((item) => {
    return {
      name: item.optionName,
      value: item.summary,
    }
  })

  return !isLoading && !isError && data?.length ? (
    <ClientDataCardWrapper>
      <ClientDataCardHeader>
        <ClientDataCardTitle>{title}</ClientDataCardTitle>
        <ClientDataCardDescription>{description}</ClientDataCardDescription>
      </ClientDataCardHeader>
      <div className={useDonutChart ? `grid grid-cols-1 mdl:grid-cols-3` : 'grid grid-cols-1'}>
        {useDonutChart ? (
          <ClientDataCardBody>
            <ClientDataDonutChart data={data} total={total} />
          </ClientDataCardBody>
        ) : (
          <></>
        )}
        <div
          className={`flex flex-col w-full mdl:w-3/4 ${
            useDonutChart ? 'col-span-2 ml-auto mt-10 mdl:mt-0 mdl:ml-8' : 'mx-auto'
          }`}
        >
          {sortedTableData?.map((item, index) => {
            return (
              <div key={item.optionName}>
                <div className="flex mt-2">
                  {useDonutChart ? (
                    <div className="mr-2 my-auto">
                      <ClientDataItemCircle color={COLORS[index % COLORS.length]} />
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="text-xs lg:text-base">{item.optionName}</div>
                  <div className="ml-auto text-sm lg:text-base">{item.summary}</div>
                </div>
                <div className="h-[0.1rem] w-full bg-silver-lighter"></div>
              </div>
            )
          })}
        </div>
      </div>
    </ClientDataCardWrapper>
  ) : (
    <div className="flex items-center h-48 md:h-72 md:w-72">
      <Loader loader="TableLoader" />
    </div>
  )
}
