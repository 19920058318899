import React from 'react'

function TruckIcon({ className }) {
  return (
    <svg
      className={className}
      width="32"
      height="25"
      viewBox="0 0 32 25"
      fill="#71717A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: 'multiply' }}>
        <path d="M12.5 20.3125C12.5 22.9004 10.4004 25 7.8125 25C5.18066 25 3.125 22.9004 3.125 20.3125C1.39893 20.3125 0 18.9111 0 17.1875V3.125C0 1.39893 1.39893 0 3.125 0H17.1875C18.9111 0 20.3125 1.39893 20.3125 3.125V4.6875H22.3975C23.125 4.6875 23.8184 4.97559 24.3311 5.48828L28.8867 10.0439C29.3994 10.5566 29.6875 11.25 29.6875 11.9775V17.9688H30.0781C30.7275 17.9688 31.25 18.4912 31.25 19.1406C31.25 19.79 30.7275 20.3125 30.0781 20.3125H28.125C28.125 22.9004 26.0254 25 23.4375 25C20.8057 25 18.75 22.9004 18.75 20.3125H12.5ZM17.1875 2.34375H3.125C2.69336 2.34375 2.34375 2.69336 2.34375 3.125V17.1875C2.34375 17.6172 2.69336 17.9688 3.125 17.9688H3.75195C4.5625 16.5674 6.0791 15.625 7.8125 15.625C9.5459 15.625 11.0645 16.5674 11.875 17.9688H17.1875C17.6172 17.9688 17.9688 17.6172 17.9688 17.1875V3.125C17.9688 2.69336 17.6172 2.34375 17.1875 2.34375ZM22.6758 7.14355C22.6025 7.07031 22.5049 7.03125 22.3975 7.03125H20.3125V11.7188H27.2461C27.2412 11.7139 27.2363 11.7041 27.2314 11.6992L22.6758 7.14355ZM7.8125 17.9688C6.51855 17.9688 5.46875 19.0186 5.46875 20.3125C5.46875 21.6064 6.51855 22.6562 7.8125 22.6562C9.10645 22.6562 10.1562 21.6064 10.1562 20.3125C10.1562 19.0186 9.10645 17.9688 7.8125 17.9688ZM23.4375 22.6562C24.7314 22.6562 25.7812 21.6064 25.7812 20.3125C25.7812 19.0186 24.7314 17.9688 23.4375 17.9688C22.1436 17.9688 21.0938 19.0186 21.0938 20.3125C21.0938 21.6064 22.1436 22.6562 23.4375 22.6562Z" />
      </g>
    </svg>
  )
}

TruckIcon.defaultProps = {
  className: '',
}
export default TruckIcon
