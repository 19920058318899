import React, { useEffect, useState } from 'react'
import {
  AssessmentList,
  TransformedRecommendation,
} from '../../../../../../common/src/types/common-types'
import { isEmpty, map } from 'lodash'
import Loader from 'components/Loaders'
import { getAssessmentsAndPlaysSelector } from 'store/selectors/assessmentsList'
import { ReviewAssessmentResponsesContainer } from 'pages/Assessment/containers'
import { ampli } from 'ampli'
import { ASSESSMENTS } from 'config'
import AssessmentIcon from 'components/Common/SvgIcons/AssessmentIcon'
import RecommendedPlaysIcon from 'components/Common/SvgIcons/RecommendedPlaysIcon'
import RecommendationProgressBubble, {
  RecommendableStatus,
} from 'components/Owner/PlayBooksCategoryDetails/RecommendationProgressBubble'
import PlayItem from 'components/Clients/Client/ClientPlaybook/PlayItem'
import ExpandItemsAccordion from 'components/Clients/Client/ClientPlaybook/ExpandItemsAccordion'

export interface IAssessmentsAndPlaysProps {
  clientId: string
  clientAssessmentRecommendations: TransformedRecommendation[]
  assessmentList: AssessmentList[]
  isLoadingRecommendations: boolean
  getClientRecommendedPlaysAssessments: (payload: { clientId?: string }) => void
  resetClientRecommendedPlaysAssessments: () => void
  businessName: string
  clientAssessmentLoading: boolean
  getAssessmentListAction: (payload: { userId: string }) => void
}

const AssessmentsAndPlays: React.FC<IAssessmentsAndPlaysProps> = ({
  clientId,
  isLoadingRecommendations,
  getClientRecommendedPlaysAssessments,
  resetClientRecommendedPlaysAssessments,
  businessName,
  clientAssessmentLoading,
  getAssessmentListAction,
  clientAssessmentRecommendations,
  assessmentList,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [assessmentAndPlays, setAssessmentAndPlays] = useState<
    Array<{
      category: string
      assessments: AssessmentList[]
      plays: TransformedRecommendation[]
    }>
  >([])
  const [activeAssessment, setActiveAssessment] = useState('')
  const [activeRecommendedPlay, setActiveRecommendedPlay] = useState('')

  useEffect(() => {
    if (clientAssessmentLoading || isLoadingRecommendations) {
      setIsLoading(true)
    } else {
      const assessmentAndPlays = getAssessmentsAndPlaysSelector(
        assessmentList,
        clientAssessmentRecommendations
      )
      setAssessmentAndPlays(assessmentAndPlays)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRecommendations, clientAssessmentLoading])

  useEffect(() => {
    if (!isEmpty(clientId)) {
      getClientRecommendedPlaysAssessments({})
      getAssessmentListAction({ userId: clientId })
    }
    return () => resetClientRecommendedPlaysAssessments()
    // eslint-disable-next-line
  }, [getClientRecommendedPlaysAssessments, getAssessmentListAction, clientId])

  const handleOpenAssessments = (assessment: AssessmentList) => {
    const type = assessment.type
    ampli.assessmentReviewed({
      id: type,
      name: ASSESSMENTS[assessment.type]?.title,
      userId: clientId,
    })
    setActiveAssessment(type)
  }

  const handleOpenRecommendedPlays = (category: string) => {
    setActiveRecommendedPlay(category)
  }

  return (
    <div className="col-span-12 p-4 sm:p-0 sm:pr-20 sm:col-span-7">
      <h2 className="font-light text-3xl text-zinc font-primary mb-12">Assessments and Plays</h2>
      {isLoading ? (
        <Loader loader={'AdvisorPlayBookAndTools'} />
      ) : (
        <>
          {assessmentAndPlays.map((ap, index) => {
            let assessmentStatus: string
            if (
              ap.assessments[0]?.isLocked ||
              (ap.assessments[0]?.isLocked === undefined &&
                ap.assessments[0]?.isCompleted === undefined)
            )
              assessmentStatus = RecommendableStatus.LOCKED
            else if (ap.assessments[0]?.inProgress)
              assessmentStatus = RecommendableStatus.IN_PROGRESS
            else if (ap.assessments[0]?.isCompleted)
              assessmentStatus = RecommendableStatus.COMPLETED
            else {
              assessmentStatus = RecommendableStatus.NOT_STARTED
            }
            return (
              <div className="border-b border-[#D4D4D8] pb-12 mb-12 space-y-4" key={index}>
                <h3 className="text-2xl font-semibold">{ap.category}</h3>
                <div className="space-y-12">
                  {/* Assessments */}
                  <div className="space-y-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <AssessmentIcon />
                        <span className="text-xl font-semibold">Assessment</span>
                      </div>
                      <div>
                        <RecommendationProgressBubble
                          status={assessmentStatus as RecommendableStatus}
                        />
                      </div>
                    </div>
                    {!ap.assessments[0]?.isLocked && (
                      <div>
                        {ap.assessments.length > 0 &&
                          map(ap.assessments, (assessment, key) => (
                            <ExpandItemsAccordion
                              key={key}
                              title="View"
                              open={activeAssessment === assessment.type}
                              onOpen={() => handleOpenAssessments(assessment)}
                            >
                              {activeAssessment === assessment.type && (
                                <ReviewAssessmentResponsesContainer
                                  userId={clientId}
                                  businessName={businessName}
                                  assessmentType={assessment?.type}
                                />
                              )}
                            </ExpandItemsAccordion>
                          ))}
                      </div>
                    )}
                  </div>
                  {/* Recommended Plays */}
                  {ap.plays.length > 0 && (
                    <div className="space-y-4">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <RecommendedPlaysIcon />
                          <span className="text-xl font-semibold">
                            Recommended Plays ({ap.plays[0].plays.length})
                          </span>
                        </div>
                        <div>
                          <RecommendationProgressBubble
                            status={ap.plays[0].status as RecommendableStatus}
                          />
                        </div>
                      </div>
                      <div>
                        <ExpandItemsAccordion
                          title="View"
                          open={activeRecommendedPlay === ap.plays[0].category}
                          onOpen={() => handleOpenRecommendedPlays(ap.plays[0].category)}
                        >
                          {ap.plays[0].plays.map((play, index) => (
                            <div
                              key={index}
                              className={`py-6 ${
                                index === ap.plays[0].plays.length - 1
                                  ? ''
                                  : 'border-b border-[#D4D4D8]'
                              }`}
                            >
                              <PlayItem play={play} />
                            </div>
                          ))}
                        </ExpandItemsAccordion>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}

export default AssessmentsAndPlays
