import { GET_BSO_PROFILE, SEARCH_INDUSTRY_REPORT } from 'store/types'
import {
  BusinessSupportOrganization,
  Scalars,
  IndustryReport,
} from '__generated__/api-types-and-hooks'

type Term = {
  term: string
  replacement: string
}

export interface BSOProfile extends BusinessSupportOrganization {
  logoUrl: Scalars['String']
  terminology: Array<Term>
  licenseCount: number
  licensesUsed: number
}

export interface BSOState {
  bsoProfile: BSOProfile
  industryReport: IndustryReport
  searchIndustryReportError: boolean
}

const initialState: BSOState = {
  bsoProfile: {
    id: '',
    adminId: '',
    name: '',
    logoUrl: '',
    terminology: [],
    licenseCount: 0,
    licensesUsed: 0,
  },
  industryReport: {
    summaryText: '',
    searchedKeyword: '',
    results: [],
  },
  searchIndustryReportError: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_BSO_PROFILE.STARTED:
    case GET_BSO_PROFILE.RESET:
      return {
        ...state,
        bsoProfile: { ...initialState.bsoProfile },
      }
    case GET_BSO_PROFILE.FULLFILLED:
      return {
        ...state,
        bsoProfile: {
          ...action.payload,
        },
      }
    case SEARCH_INDUSTRY_REPORT.STARTED:
      return {
        ...state,
        industryReport: { ...initialState.industryReport },
        searchIndustryReportError: false,
      }
    case SEARCH_INDUSTRY_REPORT.FULLFILLED:
      return {
        ...state,
        industryReport: {
          ...action.payload,
        },
        searchIndustryReportError: false,
      }
    case SEARCH_INDUSTRY_REPORT.REJECTED:
      return {
        ...state,
        industryReport: { ...initialState.industryReport },
        searchIndustryReportError: true,
      }
    default:
  }
  return state as BSOState
}

export default reducer
