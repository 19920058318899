import React from 'react'
import get from 'lodash/get'
import moment from 'moment'
import { isEmpty } from 'lodash'

const PlatformInfo = ({ profile }) => {
  return (
    <>
      {!isEmpty(profile.firstSigninDate) ? (
        <div>
          <div className="text-sm font-normal"> Platform start date </div>
          <div className="flex items-center">
            <div className="text-2xl font-normal">
              {profile?.firstSigninDate
                ? moment(profile?.firstSigninDate).format('MMM Do, YYYY')
                : ''}
            </div>
            <span className="ml-3 text-xs pt-[14px] text-primary-text font-primary">
              Total:
              <span> {get(profile, 'totalDays', 0)} </span>
              <span className="ml-[1px]">day{get(profile, 'totalDays', 0) > 1 ? 's' : ''} </span>
            </span>
          </div>
        </div>
      ) : (
        <>
          <div className="text-sm font-normal "> Invited date </div>
          <div className=" text-2xl font-normal">
            {profile?.createdAt ? moment(profile?.createdAt).format('MMM D, YYYY') : ''}
          </div>
        </>
      )}
    </>
  )
}

export default PlatformInfo
