import { useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'

import { useDispatch, useSelector } from 'react-redux'
import { resetFormData } from 'store/actions/form'

import hookForms from 'utils/hookForms'

import { FORM } from 'config'

const triggerOnReset = [FORM.USER_PROFILE_FORM]

export default function useFormContainer(name, methods) {
  const formData = useSelector((state: any) => state.form[name])
  const dispatch = useDispatch()

  methods.watch()

  hookForms.addForm(name, methods)

  useEffect(() => {
    methods.reset(formData)
    if (!isEmpty(formData) && triggerOnReset.includes(name)) {
      methods.trigger()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, methods])

  useEffect(() => {
    return () => {
      dispatch(resetFormData({ form: name }))
    }
  }, [dispatch, name])
}
