import React, { FC, useEffect } from 'react'
import { IOptions } from 'types'
import { FIELD_NAME, LABEL, industry } from 'config'
import SelectField from 'components/Common/SelectField'
import { Control, Controller, FieldValues, UseFormReturn } from 'react-hook-form'
import { FormValuesObject, getBusinessLocationOptions } from 'utils/helper'
import { SelectFieldWrapper } from 'components/Admin/BusinessOwnerStepper/style'
import { RootState } from 'App'
import { useFeatureFlag } from 'hooks/userFeatureFlag'
import { useSelector } from 'react-redux'
interface IBusinessClassificationFields {
  options: IOptions
  formValues: FormValuesObject
  control: Control<FieldValues>
  setValue?: UseFormReturn['setValue']
}
const BusinessClassificationFields: FC<IBusinessClassificationFields> = ({
  control,
  options,
  formValues,
  setValue = () => {},
}) => {
  const userId = useSelector((state: RootState) => state.user.user.id)
  const isV2FeatureFlagOrgEnabled = useFeatureFlag(userId, 'release-v2-org')
  const isV2FeatureFlagUserEnabled = useFeatureFlag(userId, 'release-v2-user')
  const isV2FlagEnabled = isV2FeatureFlagOrgEnabled && isV2FeatureFlagUserEnabled
  useEffect(() => {
    if (isV2FlagEnabled) {
      setValue('businessClassification', 'Professional Services')
    }
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <SelectFieldWrapper>
        <Controller
          name={FIELD_NAME.BUSINESS_STAGE_OPTION_ID}
          control={control}
          render={({ field }) => (
            <SelectField
              {...field}
              classes={'mt-4'}
              label={LABEL.BUSINESS_STAGE}
              options={options[FIELD_NAME.BUSINESS_STAGE]}
              placeholder={`Select ${LABEL.BUSINESS_STAGE}`}
            />
          )}
        />
      </SelectFieldWrapper>
      {!isV2FlagEnabled && (
        <>
          <SelectFieldWrapper>
            <Controller
              name={FIELD_NAME.BUSINESS_CLASSIFICATION_OPTION_ID}
              control={control}
              render={({ field }) => (
                <SelectField
                  {...field}
                  classes={'mt-4'}
                  label={LABEL.INDUSTRY}
                  options={options[FIELD_NAME.BUSINESS_CLASSIFICATION]}
                  placeholder={`Select ${LABEL.INDUSTRY}`}
                />
              )}
            />
          </SelectFieldWrapper>
          {formValues?.businessClassificationOptionId?.trim() === industry.foodId && (
            <SelectFieldWrapper>
              <Controller
                name={FIELD_NAME.FOOD_SUB_INDUSTRY_OPTION_IDS}
                control={control}
                render={({ field }) => (
                  <SelectField
                    isMulti={true}
                    {...field}
                    classes="mt-4"
                    label={LABEL.FOOD_INDUSTRY}
                    options={options[FIELD_NAME.FOOD_SUB_INDUSTRY]}
                    placeholder="Select Food Industry Type"
                  />
                )}
              />
            </SelectFieldWrapper>
          )}
          {formValues?.businessClassificationOptionId?.trim() === industry.servicesId && (
            <SelectFieldWrapper>
              <Controller
                name={FIELD_NAME.SERVICES_SUB_INDUSTRY_OPTION_ID}
                control={control}
                render={({ field }) => (
                  <SelectField
                    isMulti={false}
                    {...field}
                    classes="mt-4"
                    label={LABEL.SERVICES_INDUSTRY}
                    options={options[FIELD_NAME.SERVICES_SUB_INDUSTRY]}
                    placeholder="Select Services Industry Type"
                  />
                )}
              />
            </SelectFieldWrapper>
          )}
        </>
      )}
      {formValues?.businessClassificationOptionId &&
        formValues?.businessClassificationOptionId?.trim() !== industry.foodId && (
          <SelectFieldWrapper>
            <Controller
              name={FIELD_NAME.BUSINESS_LOCATIONS_OPTION_IDS}
              control={control}
              render={({ field }) => (
                <SelectField
                  {...field}
                  isMulti={true}
                  classes="mt-4"
                  label={LABEL.BUSINESS_LOCATION}
                  options={getBusinessLocationOptions(formValues, options)}
                  placeholder={`Select ${LABEL.BUSINESS_LOCATION}`}
                />
              )}
            />
          </SelectFieldWrapper>
        )}
    </>
  )
}
export default BusinessClassificationFields
