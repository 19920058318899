import { SET_ACTIVE_STEP, INITIALIZE_STEPPER } from 'store/types'

const initialState = {
  steps: [],
  activeStep: {},
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action?.payload,
      }
    case INITIALIZE_STEPPER:
      return {
        ...state,
        steps: action?.payload,
      }
    default:
  }
  return state
}

export default reducer
