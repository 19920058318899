import * as yup from 'yup'
import ValidationSchemaBuilder from 'utils/ValidationSchemaBuilder'
import { isValidEmailAddress } from '../../../utils/strings'

const schemaBuilder = new ValidationSchemaBuilder()

export const meetingsSchema = schemaBuilder
  .custom({
    attendees: yup
      .string()
      .test('valid-email-list', 'Please enter a valid email address', (value) => {
        const emailList = value?.split(',')?.filter((email) => email !== '')
        if (emailList?.length) {
          return emailList.every((email) => isValidEmailAddress(email.trim()))
        }
        return true
      }),
  })
  .getSchema()
