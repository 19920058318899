import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import Chat from 'components/Chat'
import InputField from 'components/Common/InputField'
import Progressbar from 'components/Common/ProgressBar'
import {
  totalStepsProgressbarAction,
  updateProgressbarAction,
} from 'store/actions/common/progressbar'
import SettingPanel from 'layout/AppLayout/SettingPanel'
import IdleTracking from 'components/Common/IdleTracking'
import NotificationPanel from 'components/NotificationPanel'
import ChangePassword from 'components/Common/ChangePassword'
import TableActions from 'components/Admin/UserManagement/TableActions'
import ConfirmationModal from 'components/Admin/UserManagement/ConfirmationModal'
import ResetPasswordConfirmationModal from 'components/Admin/UserManagement/ResetPasswordConfirmationModal'
import { getUsersAssessmentResponseByQuestionAction } from 'store/actions/owner/initialAssessment'
import {
  getChatUsersAction,
  resendUserInviteAction,
  updateUserProfileAction,
  getClientsSummaryAction,
  getUserNoticationsAction,
  openConfirmationModalAction,
  getUnreadNotificationsAction,
  chatUnreadMessageCountAction,
  updateUserNotificationsAction,
  deleteUserNotificationsAction,
  setAnnouncementActiveStepAction,
} from 'store/actions/user'
import NavigationTabs from 'layout/Layout/LayoutNavbar/NavigationTabs'
import NavbarActionIconButtons from 'layout/Layout/LayoutNavbar/NavbarActionIconButtons'
import { RootState } from 'App'
import { getClientsAction } from 'store/actions/clients'
import { getUserCalendarEventsAction } from 'store/actions/calendar'
import ChangeTimezone from 'components/Common/ChangeTimezone'
import { forgotPasswordAction, logoutAction } from 'store/actions/auth'
import { changePasswordAction, saveUserFileAction } from 'store/actions/common'
import { getParsedNotificationSelector } from 'store/selectors/notification'
import { getBSOProfileAction, searchIndustryReportAction } from 'store/actions/bso'
import UserManagementLayout from 'components/Admin/UserManagement/UserManagementLayout'
import { getAdvisorsListSelector, getBusinessesUM } from 'store/selectors/userManagement'
import EditAccountDetails from 'components/Admin/UserManagement/UserManagementList/EditAccountDetails'
import { resetAdvisorAndBusinessFormsAction } from 'store/actions/admin'
import LicenseLimitModalBox from 'components/Admin/UserManagement/TableActions/LicenseLimitModalBox'
import {
  getBusinessesUMAction,
  getAdvisorsAction,
  setCurrentPageAction,
  setPerPageAction,
  setFiltersAction,
  setSortValueAction,
} from 'store/actions/userManagement'
import Businesses from 'components/Admin/UserManagement/Businesses'
import Reports from 'pages/DataReports/Reports'
import IndustryReport from 'pages/DataReports/IndustryReport'
import { getAttestationSummaryAction } from 'store/actions/attestationSummary'
import { getAttestationSummarySelector } from 'store/selectors/attestationSummary'
import DataReports from 'pages/DataReports'
import { getNotificationListSelector } from 'store/selectors/dashboard'
import Data from '../../DataReports/Data'
import AdvisorDashboard from 'components/Dashboard/Advisor/AdvisorDashboard.component'
import { getCalendarEvents } from 'components/Dashboard/Advisor/FutureEvents/FutureEvents.helper'
import NotificationSettings from 'components/Common/NotificationSettings'
import { resetErrorsAction } from 'store/actions/errors'

/* --------------------------- Data and Reports  --------------------------- */
const dataReportsProps = (state: RootState) => {
  const { id } = state.user.user
  return {
    userId: id,
  }
}
function dataReportsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getAttestationSummaryAction: getAttestationSummaryAction.STARTED,
    },
    dispatch
  )
}
export const DataReportsContainer = connect(dataReportsProps, dataReportsDispatch)(DataReports)

/* --------------------------- Reports  --------------------------- */
const reportsProps = (state: RootState) => {
  const { user } = state.user
  const { organizationName } = user
  return {
    organizationName,
    userName: user.firstName + ' ' + user.lastName,
    summaryData: getAttestationSummarySelector(state),
    attestationSummary: state.attestationSummary.attestationSummary,
    isAttestationSummaryLoading: state.loadingStatus.isAttestationSummaryLoading,
    timezone: user?.timezone ?? 'UTC',
  }
}
function reportsDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const ReportsContainer = connect(reportsProps, reportsDispatch)(Reports)

/* --------------------------- Industry Report  --------------------------- */
const industryReportProps = (state: RootState) => {
  const { industryReport, searchIndustryReportError, bsoProfile } = state.bso
  const { isIndustryReportLoading } = state.loadingStatus
  const { user } = state.user
  return {
    industryReport,
    isIndustryReportLoading,
    searchIndustryReportError,
    bsoOrgName: bsoProfile.name,
    userId: user.id,
  }
}

function industryReportDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      searchIndustryReportAction: searchIndustryReportAction.STARTED,
    },
    dispatch
  )
}

export const IndustryReportContainer = connect(
  industryReportProps,
  industryReportDispatch
)(IndustryReport)

/* --------------------------- Businesses  --------------------------- */
const businessesProps = (state: RootState) => {
  return {
    loading: state.loadingStatus.isOwnerDataLoadingUM,
    businesses: getBusinessesUM(state),
    bsoProfile: state.bso.bsoProfile,
    userId: state.user.user.id,
  }
}
function businessesDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getBusinessesAction: getBusinessesUMAction.STARTED,
      getAdvisorsAction: getAdvisorsAction.STARTED,
      setCurrentPageAction,
      setPerPageAction,
      setFiltersAction,
    },
    dispatch
  )
}
export const BusinessesContainer = connect(businessesProps, businessesDispatch)(Businesses)

/* --------------------------- License Limit Modal Box  --------------------------- */

const licenseLimitModalBoxProps = () => {}

function licenseLimitModalBoxDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetAdvisorAndBusinessFormsAction: resetAdvisorAndBusinessFormsAction,
    },
    dispatch
  )
}
export const LicenseLimitModalBoxContainer = connect(
  licenseLimitModalBoxProps,
  licenseLimitModalBoxDispatch
)(LicenseLimitModalBox)

/* -------------------------- Edit Account Details--------------------------- */
const editAccountDetailsProps = () => {}

function editAccountDetailsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getBSOProfileAction: getBSOProfileAction.STARTED,
      resetErrorsAction: resetErrorsAction,
    },
    dispatch
  )
}

export const EditAccountDetailsContainer = connect(
  editAccountDetailsProps,
  editAccountDetailsDispatch
)(EditAccountDetails)

/* -------------------------- User Management Layout--------------------------- */
const userManagementLayoutProps = (state) => {
  const {
    isUserAddedToGroup,
    isDisableUserInProgress,
    currentPage,
    noOfItemsPerPage,
    totalPages,
    filters,
    sortValue,
  } = state.userManagement
  return {
    advisors: getAdvisorsListSelector(state),
    bso: state.bso,
    tenantId: state.user.tenantId,
    isUserAddedToGroup,
    isDisableUserInProgress,
    currentPage,
    noOfItemsPerPage,
    totalPages,
    filters,
    sortValue,
  }
}

function userManagementLayoutDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setCurrentPageAction,
      setPerPageAction,
      setFiltersAction,
      setSortValueAction,
    },
    dispatch
  )
}

export const UserManagementLayoutContainer = connect(
  userManagementLayoutProps,
  userManagementLayoutDispatch
)(UserManagementLayout)

/* --------------------------- Dashboard --------------------------- */
const dashboardProps = (state: RootState) => {
  return {
    isLoading: state.loadingStatus.clientsSummary,
    isUpdateProfileLoading: state?.user?.isUpdateProfileLoading,
    summary: state.user?.summary,
    user: state.user?.user,
    chatUnreadMessageCount: state?.user?.chatUnreadMessageCount,
    events: getCalendarEvents(state.calendar.calendarEvents, state.common.totalUpComingMeeting)
      .futureMeetings,
    notificationList: getNotificationListSelector(state),
  }
}

function dashboardDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getClientsSummaryAction: getClientsSummaryAction.STARTED,
      getUserCalendarEventsAction: getUserCalendarEventsAction.STARTED,
      getClientsAction: getClientsAction.STARTED,
      updateUserProfileAction: updateUserProfileAction.STARTED,
      getUserNoticationsAction: getUserNoticationsAction.STARTED,
      updateUserNoticationsAction: updateUserNotificationsAction.STARTED,
    },
    dispatch
  )
}
export const DashboardContainer = connect(dashboardProps, dashboardDispatch)(AdvisorDashboard)

/* --------------------------- Progressbar (Index) --------------------------- */

const progressbarProps = (state: RootState) => {
  const { width } = state.progressbar
  return {
    width: width,
  }
}

function progressbarDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateProgressbarAction: updateProgressbarAction,
      totalStepsProgressbarAction: totalStepsProgressbarAction,
    },
    dispatch
  )
}
export const ProgressbarContainer = connect(progressbarProps, progressbarDispatch)(Progressbar)

/* --------------------------- InputField (Index) --------------------------- */

const inputFieldProps = (state: RootState) => {
  const { inputFieldBgColor } = state.common
  return {
    inputFieldBgColor,
  }
}

export const InputFieldContainer = connect(inputFieldProps, null)(InputField)

/* --------------------------- Idle Tracking (Index) --------------------------- */

const idleTrackingProps = (state: RootState) => {
  return {}
}

function idleTrackingDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      logoutAction: logoutAction,
    },
    dispatch
  )
}

export const IdleTrackingContainer = connect(idleTrackingProps, idleTrackingDispatch)(IdleTracking)

/* --------------------------- App layout  --------------------------- */
/* --------------------------- Setting Panel --------------------------- */

const settingPanelProps = (state: RootState) => {
  return {}
}

function settingPanelDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      logoutAction: logoutAction,
      resetChangePasswordStatusAction: changePasswordAction.RESET,
    },
    dispatch
  )
}

export const SettingPanelContainer = connect(settingPanelProps, settingPanelDispatch)(SettingPanel)

/* --------------------------- Change Password (Index) --------------------------- */

const changePasswordProps = (state: RootState) => {
  const { error } = state.auth
  const { status, errorMessage } = state.changePassword
  return {
    errorMessage: error || errorMessage,
    status,
  }
}

function changePasswordDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      changePasswordAction: changePasswordAction.STARTED,
    },
    dispatch
  )
}
export const ChangePasswordContainer = connect(
  changePasswordProps,
  changePasswordDispatch
)(ChangePassword)

/* --------------------------- Chat --------------------------- */

const chatProps = (state: RootState) => {
  let { user, chatUsers } = state.user
  let { usersAssessmentResponseByQuestion } = state.owner
  return {
    user,
    chatUsers,
    token: user?.streamAccessToken,
    usersAssessmentResponseByQuestion,
    chatUnreadMessageCount: state.user.chatUnreadMessageCount,
  }
}

function chatDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      saveUserFileAction: saveUserFileAction.STARTED,
      getUsersAssessmentResponseByQuestionAction:
        getUsersAssessmentResponseByQuestionAction.STARTED,
      getChatUsersAction: getChatUsersAction.STARTED,
      chatUnreadMessageCountAction: chatUnreadMessageCountAction.STARTED,
    },
    dispatch
  )
}
export const ChatContainer = connect(chatProps, chatDispatch)(Chat)

/* ------------------- NAVBAR ----------------------- */

const navigationTabsProps = (state: RootState) => {
  let { user } = state.user
  return {
    user: user,
  }
}

function navigationTabsDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const NavigationTabsContainer = connect(
  navigationTabsProps,
  navigationTabsDispatch
)(NavigationTabs)

/* ------------------- HEADER ----------------------- */

const navbarActionIconButtonsProps = (state: RootState) => {
  let { user } = state
  return {
    user: user.user,
    chatUnreadMessageCount: user.chatUnreadMessageCount,
    chatUnreadNotificationsCount: user.notifications.totalUnread,
  }
}

function navbarActionIconButtonsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getUnreadNotificationsAction: getUnreadNotificationsAction.STARTED,
      chatUnreadMessageCountAction: chatUnreadMessageCountAction.STARTED,
      resetUserNoticationsAction: getUserNoticationsAction.RESET,
    },
    dispatch
  )
}
export const NavbarActionIconButtonsContainer = connect(
  navbarActionIconButtonsProps,
  navbarActionIconButtonsDispatch
)(NavbarActionIconButtons)

/* ------------------- Notifications ----------------------- */

const notificationProps = (state: RootState) => {
  let { user } = state
  return {
    notifications: getParsedNotificationSelector(state),
    totalCount: user?.notifications?.totalCount ?? 0,
    isLoading: user?.notifications?.isLoading ?? false,
    lastEvaluatedKey: user.notifications?.lastEvaluatedKey,
    activeStep: user.activeStep,
    name: user.user.firstName + ' ' + user.user.lastName,
    businessName: user?.businessProfile?.businessName,
  }
}

const notificationDispatch = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setAnnouncementActiveStepAction: setAnnouncementActiveStepAction,
      getUnreadNotificationsAction: getUnreadNotificationsAction.STARTED,
      getUserNoticationsAction: getUserNoticationsAction.STARTED,
      resetUserNotifications: getUserNoticationsAction.RESET,
      updateUserNoticationsAction: updateUserNotificationsAction.STARTED,
      deleteUserNoticationsAction: deleteUserNotificationsAction.STARTED,
    },
    dispatch
  )
}
export const NotificationPanelContainer = connect(
  notificationProps,
  notificationDispatch
)(NotificationPanel)

/* --------------------------- ResetPasswordConfirmationModal --------------------------- */

const resetPasswordConfirmationProps = (state: RootState) => {
  const { disableLoginSubmit, error, status } = state.auth
  return {
    status,
    errorMessage: error,
    disableLoginSubmit: disableLoginSubmit,
  }
}

function resetPasswordConfirmationDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      forgotPasswordAction: forgotPasswordAction.STARTED,
    },
    dispatch
  )
}

const ResetPasswordConfirmationModalContainer = connect(
  resetPasswordConfirmationProps,
  resetPasswordConfirmationDispatch
)(ResetPasswordConfirmationModal)

export default ResetPasswordConfirmationModalContainer

/* --------------------------- ConfirmationModal --------------------------- */

const confirmationProps = (state: RootState) => {
  const { error, status, isSuccess } = state.user
  return {
    status,
    isSuccess,
    errorMessage: error,
  }
}

function confirmationDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resendUserInviteAction: resendUserInviteAction.STARTED,
    },
    dispatch
  )
}

export const ConfirmationModalContainer = connect(
  confirmationProps,
  confirmationDispatch
)(ConfirmationModal)

/* --------------------------- TableActions --------------------------- */

const tableActionsProps = (state: RootState) => {
  const { openConfirmationModal, email, name } = state.user
  const { error: userManagementError } = state.userManagement
  return {
    name,
    email,
    userManagementError,
    openConfirmationModal,
  }
}

function tableActionsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetErrorsAction: resetErrorsAction,
      getBSOProfileAction: getBSOProfileAction.STARTED,
      resendUserInviteAction: resendUserInviteAction.STARTED,
      openConfirmationModalAction: openConfirmationModalAction,
    },
    dispatch
  )
}

export const TableActionsContainer = connect(tableActionsProps, tableActionsDispatch)(TableActions)

/* --------------------------- Change Time Zone (Index) --------------------------- */

const changeTimezoneProps = (state: RootState) => {
  const { user } = state.user
  return {
    user,
  }
}

function changeTimezoneDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateUserProfileAction: updateUserProfileAction.STARTED,
    },
    dispatch
  )
}
export const ChangeTimezoneContainer = connect(
  changeTimezoneProps,
  changeTimezoneDispatch
)(ChangeTimezone)

/* --------------------------- Notification Settings (Index) --------------------------- */

const notificationSettingsProps = (state: RootState) => {
  const { user } = state.user
  return {
    user,
  }
}

function notificationSettingsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateUserProfileAction: updateUserProfileAction.STARTED,
    },
    dispatch
  )
}
export const NotificationSettingsContainer = connect(
  notificationSettingsProps,
  notificationSettingsDispatch
)(NotificationSettings)

/* --------------------------- Data (Index) --------------------------- */

export const DataContainer = connect()(Data)
