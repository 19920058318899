import React from 'react'

function PartTimeIcon({ className }) {
  return (
    <svg
      width="51"
      height="50"
      className={className}
      viewBox="0 0 51 50"
      fill="#71717A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M25.5 25C25.5 38.8071 25.5 50 25.5 50C11.6929 50 0.5 38.8071 0.5 25C0.5 11.1929 11.6929 0 25.5 0C25.5 0 25.5 11.1929 25.5 25Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5 4.38049C39.1849 6.24821 46.5 14.7698 46.5 25C46.5 35.2302 39.1849 43.7518 29.5 45.6195V4.38049ZM29.5 49.6817C41.4057 47.7672 50.5 37.4456 50.5 25C50.5 12.5544 41.4057 2.23284 29.5 0.318279C28.1976 0.108835 26.8615 0 25.5 0V50C26.8615 50 28.1976 49.8912 29.5 49.6817Z"
      />
    </svg>
  )
}

PartTimeIcon.defaultProps = {
  className: '',
}
export default PartTimeIcon
