import React from 'react'

function CartIcon({ className }) {
  return (
    <svg
      className={className}
      width="57"
      height="50"
      viewBox="0 0 57 50"
      fill="#71717A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.84375 0C1.54492 0 0.5 1.04492 0.5 2.34375C0.5 3.64258 1.54492 4.6875 2.84375 4.6875H7.28711C7.6582 4.6875 7.98047 4.95117 8.05859 5.32227L13.0977 31.7871C13.7324 35.1074 16.6328 37.5 20.002 37.5H48.1562C49.4551 37.5 50.5 36.4551 50.5 35.1562C50.5 33.8574 49.4551 32.8125 48.1562 32.8125H20.002C18.8789 32.8125 17.9121 32.0117 17.6973 30.9082L17.1699 28.125H45.3438C48.5273 28.125 51.3105 25.9961 52.1309 22.9199L56.1348 8.04688C56.8086 5.56641 54.9336 3.125 52.3652 3.125H12.2285C11.3398 1.25 9.44531 0 7.28711 0H2.84375ZM13.3027 7.8125H51.3496L47.6094 21.6992C47.3359 22.7246 46.4082 23.4375 45.3438 23.4375H16.2813L13.3027 7.8125ZM17.6875 50C18.9307 50 20.123 49.5061 21.0021 48.6271C21.8811 47.748 22.375 46.5557 22.375 45.3125C22.375 44.0693 21.8811 42.877 21.0021 41.9979C20.123 41.1189 18.9307 40.625 17.6875 40.625C16.4443 40.625 15.252 41.1189 14.3729 41.9979C13.4939 42.877 13 44.0693 13 45.3125C13 46.5557 13.4939 47.748 14.3729 48.6271C15.252 49.5061 16.4443 50 17.6875 50ZM50.5 45.3125C50.5 44.0693 50.0061 42.877 49.1271 41.9979C48.248 41.1189 47.0557 40.625 45.8125 40.625C44.5693 40.625 43.377 41.1189 42.4979 41.9979C41.6189 42.877 41.125 44.0693 41.125 45.3125C41.125 46.5557 41.6189 47.748 42.4979 48.6271C43.377 49.5061 44.5693 50 45.8125 50C47.0557 50 48.248 49.5061 49.1271 48.6271C50.0061 47.748 50.5 46.5557 50.5 45.3125Z" />
    </svg>
  )
}

CartIcon.defaultProps = {
  className: '',
}
export default CartIcon
