import styled from 'styled-components'
import tw from 'twin.macro'

export const BusinessProfileWrapper = styled.div`
  ${tw`xs:col-span-12 xs:p-4 sm:p-0 col-span-3 sm:col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-3 2xl:col-span-3 border-r border-grey-darker !pr-5`}
`
export const FormWrapper = styled.div`
  ${tw`grid xs:grid-cols-12  gap-3`}
`
export const AdditionalBusinessProfileWrapper = styled.div`
  ${tw`col-span-12`}
`
