import React, { useMemo } from 'react'
import { AppFC } from 'types'

export enum PillColorTheme {
  BLUE = 'BLUE',
  LIME = 'LIME',
  ERROR = 'ERROR',
  ORANGE = 'ORANGE',
  PURPLE = 'PURPLE',
  YELLOW = 'YELLOW',
  SUCCESS = 'SUCCESS',
  RED = 'RED',
  GREEN = 'GREEN',
  DISABLE = 'DISABLE',
  INVITED = 'INVITED',
  SKY = 'SKY',
  BLUE_DEFAULT = 'BLUE_DEFAULT',
  BLUE_DARK = 'BLUE_DARK',
}

export interface IPillTextBoxProps {
  colorTheme: PillColorTheme
  size?: 'small' | 'base' | 'large'
  textSize?: 'small' | 'normal' | 'large'
}

const PillTextBox: AppFC<IPillTextBoxProps> = ({
  children,
  colorTheme,
  size = 'base',
  className,
  textSize = 'small',
}) => {
  const pillBackgroundColor = useMemo((): string => {
    return {
      [PillColorTheme.BLUE]: 'bg-sky',
      [PillColorTheme.YELLOW]: 'bg-yellow',
      [PillColorTheme.LIME]: 'bg-sky-light',
      [PillColorTheme.PURPLE]: 'bg-sky-dark',
      [PillColorTheme.ERROR]: 'bg-brown-light',
      [PillColorTheme.ORANGE]: 'bg-sky-darker',
      [PillColorTheme.SUCCESS]: 'bg-green-light',
      [PillColorTheme.RED]: 'bg-primary-error',
      [PillColorTheme.GREEN]: 'bg-green',
      [PillColorTheme.DISABLE]: 'bg-zinc-light',
      [PillColorTheme.INVITED]: 'bg-yellow-lighter2x',
      [PillColorTheme.SKY]: 'bg-sky',
    }[colorTheme]
  }, [colorTheme])

  const textColor = useMemo(() => {
    return {
      [PillColorTheme.LIME]: 'text-green',
      [PillColorTheme.SUCCESS]: 'text-green',
      [PillColorTheme.ORANGE]: 'text-brown-dark',
      [PillColorTheme.ERROR]: 'text-primary-error',
      [PillColorTheme.BLUE]: 'text-tertiary-light',
      [PillColorTheme.YELLOW]: 'text-yellow-darkest',
      [PillColorTheme.PURPLE]: 'text-purple-light',
      [PillColorTheme.RED]: 'text-white',
      [PillColorTheme.GREEN]: 'text-white',
      [PillColorTheme.DISABLE]: 'text-grey-darkest1x',
      [PillColorTheme.INVITED]: 'text-yellow-dark',
      [PillColorTheme.SKY]: 'text-black',
    }[colorTheme]
  }, [colorTheme])

  const sizeStyles = useMemo(() => {
    return {
      small: 'py-0.5 px-3',
      base: 'py-[4px] px-5',
      large: 'py-[6px] px-5',
    }[size]
  }, [size])

  const textStyles = useMemo(() => {
    return {
      small: 'text-xs',
      normal: 'text-base',
      large: 'text-lg',
    }[textSize]
  }, [textSize])

  return (
    <div
      className={`${pillBackgroundColor} ${sizeStyles} rounded-full flex-grow-0 self-start ${
        className ? className : 'text-center'
      } `}
    >
      <span className={`${textColor} font-bold ${textStyles}`}>{children}</span>
    </div>
  )
}

PillTextBox.defaultProps = {
  className: undefined,
}
export default PillTextBox
