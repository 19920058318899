import React, { FC, useState } from 'react'
import { ChartStatus } from '../../../__generated__/api-types-and-hooks'
import redirectTo from 'utils/redirectTo'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { USER_ROLES } from 'config'

type ChartData = {
  status: ChartStatus
}

export type WithChartWrapperProps<InputFetchData, OutputData extends ChartData> = {
  chartData?: OutputData
  onFetchData: (params: InputFetchData) => Promise<OutputData | undefined>
}

const withChartWrapper =
  <
    InputFetchData,
    OutputData extends ChartData,
    T extends WithChartWrapperProps<InputFetchData, OutputData>,
    P extends Omit<T, 'onFetchData'>
  >(
    onFetchData: (params: InputFetchData) => Promise<OutputData | undefined>,
    WrappedComponent: FC<T>
  ) =>
  (props: P) => {
    const [data, setData] = useState<OutputData | undefined>()

    const onFetchDataMethod = async (params: InputFetchData) => {
      const response = await onFetchData(params)
      setData(response)
      return response
    }
    const roles = useSelector((state: RootState) => state.user.user.roles)
    const properties = { ...props, onFetchData: onFetchDataMethod, chartData: data }
    if (data?.status === ChartStatus.DisconnectedSource) {
      return (
        <div className="relative w-full h-full min-h-[400px]">
          <WrappedComponent {...(properties as unknown as T)} />
          <div className="absolute inset-0 bg-grey-darker bg-opacity-50 flex items-center justify-center font-bold">
            {roles.includes(USER_ROLES.BUSINESS_OWNER) ? (
              <p className="p-4">
                When you complete assessments and plays, and connect tools, your data will display
                here.{' '}
                <span
                  className="text-primary hover:cursor-pointer"
                  onClick={() => redirectTo(window.location.pathname.replace(/data/g, 'playbooks'))}
                >
                  Go to Playbooks
                </span>
              </p>
            ) : (
              <p className="p-4">
                When Bo complete assessments and plays, and connect tools, data will display here.
              </p>
            )}
          </div>
        </div>
      )
    }
    return <WrappedComponent {...(properties as unknown as T)} />
  }

export default withChartWrapper
