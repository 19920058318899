import { PRODUCT_NAME } from 'config'
import React, { useEffect } from 'react'
import { AppFC } from 'types'

export interface IPageProps {
  title: string
}

const Page: AppFC<IPageProps> = ({ title, children }) => {
  useEffect(() => {
    document.title = `${PRODUCT_NAME} | ${title}`
  }, [title])

  return <>{children}</>
}

export default Page
