import React, { ComponentProps, Suspense, useState } from 'react'
import clsx from 'clsx'

import { useEmojiContext } from 'stream-chat-react'

import type { NimbleEmojiProps } from 'emoji-mart'
import type { ReactionResponse } from 'stream-chat'

import type { ReactEventHandler } from 'stream-chat-react'

import type { DefaultStreamChatGenerics } from '../../types'
import { ReactionEmoji } from 'components/Chat/components/Channel/emojiData'
import { useProcessReactions } from './hooks/useProcessReactions'

export type ReactionsListProps<
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
> = {
  additionalEmojiProps?: Partial<NimbleEmojiProps>
  onClick?: ReactEventHandler
  own_reactions?: ReactionResponse<StreamChatGenerics>[]
  reaction_counts?: { [key: string]: number }
  reactionOptions?: ReactionEmoji[]
  reactions?: ReactionResponse<StreamChatGenerics>[]
  reverse?: boolean
}

const ButtonWithTooltip = ({
  children,
  onMouseEnter,
  onMouseLeave,
  ...rest
}: Omit<ComponentProps<'button'>, 'ref'>) => {
  const [, setReferenceElement] = useState<HTMLButtonElement | null>(null)

  return (
    <>
      <button ref={setReferenceElement} {...rest}>
        {children}
      </button>
    </>
  )
}

const UnMemoizedReactionsList = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: ReactionsListProps<StreamChatGenerics>
) => {
  const { onClick, reverse = false, ...rest } = props

  const { Emoji, emojiConfig } = useEmojiContext('ReactionsList')

  const {
    additionalEmojiProps,
    aggregatedUserNamesByType,
    emojiData,
    getEmojiByReactionType,
    iHaveReactedWithReaction,
    latestReactions,
    latestReactionTypes,
    supportedReactionsArePresent,
    totalReactionCount,
  } = useProcessReactions({ emojiConfig, ...rest })

  if (!latestReactions.length) return null

  if (!supportedReactionsArePresent) return null

  return (
    <div
      aria-label="Reaction list"
      className={clsx('str-chat__reaction-list str-chat__message-reactions-container', {
        'str-chat__reaction-list--reverse': reverse,
      })}
      data-testid="reaction-list"
      onClick={onClick}
      onKeyUp={onClick}
      role="figure"
    >
      <ul className="str-chat__message-reactions">
        {latestReactionTypes.map((reactionType) => {
          const emojiObject = getEmojiByReactionType(reactionType)
          const isOwnReaction = iHaveReactedWithReaction(reactionType)
          return emojiObject ? (
            <li
              className={clsx('str-chat__message-reaction', {
                'str-chat__message-reaction-own': isOwnReaction,
              })}
              key={emojiObject.id}
            >
              <ButtonWithTooltip
                aria-label={`Reactions: ${reactionType}`}
                title={aggregatedUserNamesByType[reactionType].join(', ')}
                type="button"
              >
                {
                  <Suspense fallback={null}>
                    <span className="str-chat__message-reaction-emoji">
                      <Emoji
                        data={emojiData}
                        emoji={emojiObject}
                        size={16}
                        {...additionalEmojiProps}
                      />
                    </span>
                  </Suspense>
                }
                &nbsp;
              </ButtonWithTooltip>
            </li>
          ) : null
        })}
        <li>
          <span className="str-chat__reaction-list--counter">{totalReactionCount}</span>
        </li>
      </ul>
    </div>
  )
}

export const ReactionsList = React.memo(UnMemoizedReactionsList) as typeof UnMemoizedReactionsList
