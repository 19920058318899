import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { getFileIcon, getExt } from 'components/Common/Dropzone/utils'
import { EllipseIcon } from 'components/Common/SvgIcons'

export interface IFilesCardProps {
  file: IFileProps
}

export interface IFileProps {
  name: string
  size: number
  createdAt: string
  url: string
}

const FilesCard = ({ file }: IFilesCardProps) => {
  const ext = getExt(file?.name)
  const [fileSizeDisplay, setFileSizeDisplay] = useState<string>('')

  const icon = useMemo(() => {
    return getFileIcon(file?.name)
  }, [file])

  const getDate = (date) => {
    const dateTime = moment(new Date(date)).format('MMMM DD hh:mm A')
    return dateTime
  }

  useEffect(() => {
    if (file.size < 1000) {
      setFileSizeDisplay(`${file.size} B`)
    } else if (file.size >= 1000 && file.size < 1_000_000) {
      setFileSizeDisplay(`${Math.round(file.size / 1000)} KB`)
    } else if (file.size >= 1_000_000 && file.size < 1_000_000_000) {
      setFileSizeDisplay(`${Math.round(file.size / 1_000_000)} MB`)
    } else if (file.size >= 1_000_000_000 && file.size < 1_000_000_000_000) {
      setFileSizeDisplay(`${Math.round(file.size / 1_000_000_000)} GB`)
    }
  }, [file])

  return (
    <div className="grid grid-cols-1 mdl:grid-cols-1">
      <div className="flex items-center mt-2 mdl:bg-secondary bg-white border-[1px] border-primary-outline py-4 rounded-[10px]">
        <span className="mx-3 relative">
          <EllipseIcon className={'fill-primary-outline h-[40px] w-[40px]'} />
          {icon && <icon.value className={'absolute top-2 left-3 w-[19px] h-[25px]'} />}
        </span>
        <span className="flex flex-col text-ellipsis overflow-hidden pr-2">
          <a
            href={file?.url || ''}
            target="_blank"
            className="text-primary hover:text-primary-brand active:text-primary-brand text-base text-ellipsis overflow-hidden"
            rel="noreferrer"
          >
            {file.name}
          </a>
          <span className="text-silver-darker text-base">
            {ext + ' '}
            <span className="font-bold text-gray-700 rounded-full  inline-block font-mono w-[5px] h-[5px] mb-[3px] mr-2 bg-zinc ml-2"></span>

            {file.size && (
              <>
                <span>{fileSizeDisplay}</span>
                <span className="font-bold text-gray-700 rounded-full inline-block font-mono w-[5px] h-[5px] mb-[3px] mr-2 bg-zinc ml-2"></span>
              </>
            )}

            {getDate(file?.createdAt)}
          </span>
        </span>
      </div>
    </div>
  )
}

export default FilesCard
