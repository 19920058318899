import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { resetProgressbarAction, updateProgressbarAction } from 'store/actions/common/progressbar'
import {
  resetAdvisorAndBusinessFormsAction,
  setBusinessOwnerActiveStepAction,
  setAdvisorActiveStepAction,
  resetAccountErrorsAction,
  addBusinessAccountAction,
  getUsersAction,
  addAdvisorAccountAction,
} from 'store/actions/admin'
import { getPlaysAction } from 'store/actions/managePlays'

import {
  getAssessmentQuestionAction,
  getAssessmentOptionsAction,
} from 'store/actions/owner/initialAssessment'
import { getAdvisorsAction, getBusinessesAction } from 'store/actions/userManagement'
import { getAdvisorsList, getBusinessesList } from 'store/selectors/admin'
import { getAssessmentQuestionSelector, getAssessmentOptionsSelector } from 'store/selectors/owner'

import { RootState } from 'App'

import AccountCreated from 'components/Common/AccountCreated'
import AdditionalDetailsForm from 'components/Admin/BusinessOwnerStepper/AdditionalDetailsForm'
import AdvisorStepper from 'components/Admin/AdvisorStepper'
import AssignAdvisorOrClientForm from 'components/Common/AssignAdvisorOrClientForm'
import BusinessOwnerStepper from 'components/Admin/BusinessOwnerStepper'
import CreateAccount from 'components/Common/CreateAccount'
import CreatePlay from 'components/Admin/CreatePlay/index'
import ManagePlays from 'components/Admin/ManagePlays/index'
import UserAssignment from 'components/Admin/UserAssignment'
import AdditionalBusinessProfile from 'components/Admin/BusinessOwnerStepper/AdditionalBusinessProfile'
import BusinessProfileMultiSelectFields from 'components/Clients/Client/Details/EditClient/BusinessProfileMultiSelectFields'
import { getUserProfileAction } from 'store/actions/user'
import { getClientsAction } from 'store/actions/clients'

/* --------------------------- CreateBusinessAccount (Index) --------------------------- */

const businessOwnerStepperProps = (state: RootState) => ({
  user: state.user.user,
  steps: state.admin.businessOwnerStepper.steps,
  activeStep: state.admin.businessOwnerStepper.activeStep,
})

function businessOwnerStepperDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setBusinessOwnerActiveStepAction: setBusinessOwnerActiveStepAction,
      updateProgressbarAction: updateProgressbarAction,
      resetProgressbarAction: resetProgressbarAction,
      resetAccountErrorsAction: resetAccountErrorsAction,
      getUsersAction: getUsersAction.STARTED,
      addBusinessAccountAction: addBusinessAccountAction.STARTED,
      resetAdvisorAndBusinessFormsAction: resetAdvisorAndBusinessFormsAction,
    },
    dispatch
  )
}
export const BusinessOwnerStepperContainer = connect(
  businessOwnerStepperProps,
  businessOwnerStepperDispatch
)(BusinessOwnerStepper)

/* --------------------------- CreateAdvisorAccount (Index) --------------------------- */

const advisorStepperProps = (state: RootState) => ({
  steps: state.admin.advisorStepper.steps,
  activeStep: state.admin.advisorStepper.activeStep,
})

function advisorStepperDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getUsersAction: getUsersAction.STARTED,
      resetProgressbarAction: resetProgressbarAction,
      updateProgressbarAction: updateProgressbarAction,
      resetAccountErrorsAction: resetAccountErrorsAction,
      setAdvisorActiveStepAction: setAdvisorActiveStepAction,
      addAdvisorAccountAction: addAdvisorAccountAction.STARTED,
      resetAdvisorAndBusinessFormsAction: resetAdvisorAndBusinessFormsAction,
      resetGetUserProfileAction: getUserProfileAction.RESET,
      resetGetClientsAction: getClientsAction.RESET,
      resetBusinessesError: getBusinessesAction.RESET,
    },
    dispatch
  )
}
export const AdvisorStepperContainer = connect(
  advisorStepperProps,
  advisorStepperDispatch
)(AdvisorStepper)

/* --------------------------- AssignAdvisorOrClientForm (Index) --------------------------- */

const assignAdvisorOrClientFormProps = (state: RootState) => {
  const { user } = state.user
  return {
    advisors: getAdvisorsList(state),
    user,
  }
}

function assignAdvisorOrClientFormDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getAdvisorsAction: getAdvisorsAction.STARTED,
    },
    dispatch
  )
}
export const AssignAdvisorOrClientFormContainer = connect(
  assignAdvisorOrClientFormProps,
  assignAdvisorOrClientFormDispatch
)(AssignAdvisorOrClientForm)

/* --------------------------- Create Account (Index) --------------------------- */

const createAccountProps = (state: RootState) => ({
  disableCreateButton: state.admin?.disableCreateButton,
  // todo: fix bug - state.admin.error does not exist
  // @ts-ignore
  apiError: state.admin?.error,
})

function acreateAccountDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const CreateAccountContainer = connect(
  createAccountProps,
  acreateAccountDispatch
)(CreateAccount)

/* --------------------------- Additional Details Form (Index) --------------------------- */

const additionalDetailsProps = (state: any) => ({
  assessment: getAssessmentQuestionSelector(state),
  isAssessmentQuestionLoading: state.loadingStatus.isAssessmentQuestionLoading,
})

function additionalDetailsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getAssessmentQuestionAction: getAssessmentQuestionAction.STARTED,
    },
    dispatch
  )
}
export const AdditionalDetailsFormContainer = connect(
  additionalDetailsProps,
  additionalDetailsDispatch
)(AdditionalDetailsForm)

/* --------------------------- Additional Business Profile --------------------------- */

const additionalBusinessProfileProps = (state: RootState) => ({
  options: getAssessmentOptionsSelector(state),
  isAssessmentOptionsLoading: state.loadingStatus.isAssessmentOptionsLoading,
})

function additionalBusinessProfileDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getAssessmentOptionsAction: getAssessmentOptionsAction.STARTED,
    },
    dispatch
  )
}
export const AdditionalBusinessProfileContainer = connect(
  additionalBusinessProfileProps,
  additionalBusinessProfileDispatch
)(AdditionalBusinessProfile)

/* --------------------------- Business Profile Select Fields --------------------------- */

const businessProfileSelectFieldsProps = (state: RootState) => ({
  options: getAssessmentOptionsSelector(state),
})

function businessProfileSelectFieldsDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}

export const BusinessProfileMultiSelectFieldsContainer = connect(
  businessProfileSelectFieldsProps,
  businessProfileSelectFieldsDispatch
)(BusinessProfileMultiSelectFields)

/* --------------------------- Account Created  --------------------------- */

const accountCreatedProps = () => {}

function accountCreatedDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetAdvisorAndBusinessFormsAction: resetAdvisorAndBusinessFormsAction,
    },
    dispatch
  )
}

export const AccountCreatedContainer = connect(
  accountCreatedProps,
  accountCreatedDispatch
)(AccountCreated)

/* --------------------------- UserAssignment  --------------------------- */
const UserAssignmentProps = (state: RootState) => {
  let { isOwnerDataLoading } = state.loadingStatus
  let { businessesError } = state.userManagement

  return {
    businessesError,
    isOwnerDataLoading,
    firstName: 'Advisor',
    advisors: getAdvisorsList(state),
    owners: getBusinessesList(state),
    assignedOwnerList: [],
  }
}

function aUserAssignmentDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetBusinessesError: getBusinessesAction.RESET,
      getAdvisorsAction: getAdvisorsAction.STARTED,
      getBusinessessAccountAction: getBusinessesAction.STARTED,
    },
    dispatch
  )
}

export const UserAssignmentContainer = connect(
  UserAssignmentProps,
  aUserAssignmentDispatch
)(UserAssignment)

/* --------------------------- ManagePlays  --------------------------- */
const managePlaysProps = (state: RootState) => {
  return {
    plays: state.managePlays.plays,
    loaded: state.managePlays.loaded,
  }
}

function managePlaysDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getPlaysAction: getPlaysAction.STARTED,
    },
    dispatch
  )
}

export const ManagePlaysContainer = connect(managePlaysProps, managePlaysDispatch)(ManagePlays)

/* --------------------------- CreatePlay  --------------------------- */
const createPlayProps = (state: RootState) => {
  return {}
}

function createPlayDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}

export const CreatePlayContainer = connect(createPlayProps, createPlayDispatch)(CreatePlay)
