import React, { useEffect, useState } from 'react'
import { AppFC } from 'types'

import Button from 'components/Common/Button'
import AlertBanner from 'components/Common/AlertBanner'
import ModalBox from 'components/Common/ModalBox'

export interface IResetPasswordConfirmationProps {
  forgotPasswordAction: (email: string) => void
  resetPasswordInitiated?: boolean
  disableLoginSubmit: boolean
  onClose?: () => void
  errorMessage: string
  status?: string
  email: string
}

const ResetPasswordConfirmationModal: AppFC<IResetPasswordConfirmationProps> = ({
  resetPasswordInitiated,
  forgotPasswordAction,
  disableLoginSubmit,
  errorMessage,
  status = '',
  onClose,
  email,
}) => {
  const [resetInitiated, setResetInitiated] = useState<boolean>(resetPasswordInitiated || false)

  useEffect(() => {
    setResetInitiated(resetPasswordInitiated || false)
  }, [resetPasswordInitiated])

  const onConfirm = () => {
    forgotPasswordAction(email)
    setResetInitiated(true)
  }

  return (
    <ModalBox title="Reset Password Confirmation" onClose={onClose}>
      <div className="p-7">
        {resetInitiated && !disableLoginSubmit && (
          <>
            {!errorMessage && (
              <AlertBanner type="success" title="Password Reset Succeeded">
                <p>We have sent a reset password email to {email}.</p>
              </AlertBanner>
            )}
            {errorMessage && (
              <AlertBanner type="error" title="Error">
                <p>Your password could not be reset. Please close and try again later.</p>
                <p>(Error Message: {errorMessage})</p>
              </AlertBanner>
            )}
            <div className="flex mt-2">
              <div className="flex-auto">
                <Button variant="primary" label="Close" onClick={onClose} className="w-full" />
              </div>
            </div>
          </>
        )}
        {(!resetInitiated || (resetInitiated && disableLoginSubmit)) && (
          <>
            <p className="mb-8">
              Resetting the password will send the user an email with instructions to reset.
            </p>
            <div className="flex">
              <div className="flex-auto mr-2">
                <Button
                  variant="primary"
                  label="Confirm"
                  status={status}
                  onClick={onConfirm}
                  className="w-full"
                />
              </div>
              <div className="flex-auto ml-2">
                <Button variant="secondary" label="Cancel" onClick={onClose} className="w-full" />
              </div>
            </div>
          </>
        )}
      </div>
    </ModalBox>
  )
}

export default ResetPasswordConfirmationModal
