import React, { useCallback, useState, useMemo, useEffect } from 'react'
import size from 'lodash/size'
import { ChatAutoComplete, EmojiPicker, useMessageInputContext } from 'stream-chat-react'

import './MessagingInput.css'
import { isMobileOnly } from 'mobile-device-detect'
import { LightningBoltSmall } from '../../assets'
import type { StreamChatGenerics } from '../../types'
import { useGiphyContext } from '../../Giphy'
import ModalBox from 'components/Common/ModalBox'
import FileUploadIcon from 'components/Common/SvgIcons/FileUploadIcon'
import SendIcon from 'components/Common/SvgIcons/SendIcon'
import EmojiIcon from 'components/Common/SvgIcons/EmojiIcon'
import UploadFiles from 'components/Common/Upload/UploadFiles'

const GiphyIcon = () => (
  <div className="giphy-icon__wrapper">
    <LightningBoltSmall />
    <p className="giphy-icon__text">GIPHY</p>
  </div>
)

const MessagingInput = () => {
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const { giphyState, setGiphyState } = useGiphyContext()
  const [uploadedFiles, setUploadedFiles] = useState<Array<Object>>([])
  const messageInput = useMessageInputContext<StreamChatGenerics>()

  const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      const { value } = event.target

      const deletePressed =
        event.nativeEvent instanceof InputEvent &&
        event.nativeEvent.inputType === 'deleteContentBackward'

      if (messageInput.text.length === 1 && deletePressed) {
        setGiphyState(false)
      }

      if (!giphyState && messageInput.text.startsWith('/giphy') && !messageInput.numberOfUploads) {
        event.target.value = value.replace('/giphy', '')
        setGiphyState(true)
      }

      messageInput.handleChange(event)
    },
    [giphyState, messageInput.numberOfUploads, messageInput.text] // eslint-disable-line
  )

  const messageUploads = { ...messageInput.imageUploads, ...messageInput.fileUploads }
  const handleClose = (e) => {
    if (size(messageUploads)) {
      messageInput.handleSubmit(e)
    }
    setShowUploadModal(false)
  }

  const onSendMessage = (event: React.MouseEvent<HTMLElement>) => {
    messageInput.handleSubmit(event)
  }

  const files = useMemo(
    () => ({ ...messageInput?.fileUploads, ...messageInput?.imageUploads }),
    [messageInput]
  )

  useEffect(() => {
    let data = Object.values(files)
    if (data) {
      const uploadedFiles: Array<Object> = data.reduce((arr: Array<Object>, file, index) => {
        if (file.state !== 'uploading') arr[index] = file
        return arr
      }, [])
      setUploadedFiles(uploadedFiles)
    }
    if (Object.keys(files).length > 0) {
      return setIsFileUploading(Object.values(files).some((file) => file.state === 'uploading'))
    } else return setIsFileUploading(false)
    // eslint-disable-next-line
  }, [files])

  const getFiles = (files) => {
    messageInput.uploadNewFiles(files)
  }

  return (
    <>
      <div className="bg-secondary xs:fixed xs:left-0 xs:bottom-0 xs:w-full sm:static">
        {!isMobileOnly ? (
          <div className="hidden !bg-secondary md:flex">
            <div className="mt-4 ml-4 cursor-pointer" onClick={() => setShowUploadModal(true)}>
              <FileUploadIcon className={''} />
            </div>

            <div className="grow">
              <div className="">
                {giphyState && !messageInput.numberOfUploads && <GiphyIcon />}

                <ChatAutoComplete onChange={onChange} rows={1} placeholder="Write a message..." />
              </div>
            </div>
            <div
              className="emoji-button mr-2 mt-3"
              role="button"
              aria-roledescription="button"
              onClick={
                messageInput.emojiPickerIsOpen
                  ? messageInput.closeEmojiPicker
                  : messageInput.openEmojiPicker
              }
              ref={messageInput.emojiPickerRef}
            >
              <EmojiIcon />
            </div>
            <div className="relative inline-block">
              <EmojiPicker />
            </div>

            {/* send message */}
            <div
              className="mr-3 mt-3"
              role="button"
              aria-roledescription="button"
              onClick={onSendMessage}
            >
              <SendIcon />
            </div>
          </div>
        ) : (
          /* Mobile screen */
          <div className="!bg-secondary md:hidden">
            <div className="grow">
              <div>
                {giphyState && !messageInput.numberOfUploads && <GiphyIcon />}

                <ChatAutoComplete onChange={onChange} rows={1} placeholder="Write a message..." />
              </div>
            </div>
            <div className="flex items-center justify-between pb-3">
              <div className="flex items-center justify-center space-x-2">
                <div className="mt-4 ml-4 cursor-pointer" onClick={() => setShowUploadModal(true)}>
                  <FileUploadIcon className={''} />
                </div>
                <div
                  className="emoji-button mr-2 mt-3"
                  role="button"
                  aria-roledescription="button"
                  onClick={
                    messageInput.emojiPickerIsOpen
                      ? messageInput.closeEmojiPicker
                      : messageInput.openEmojiPicker
                  }
                  ref={messageInput.emojiPickerRef}
                >
                  <EmojiIcon />
                </div>
                <div className="absolute left-0 right-0">
                  <EmojiPicker />
                </div>
              </div>
              <div
                className="mr-3 mt-3"
                role="button"
                aria-roledescription="button"
                onClick={onSendMessage}
              >
                <SendIcon />
              </div>
            </div>
          </div>
        )}
      </div>
      {showUploadModal && (
        <ModalBox
          onClose={isFileUploading ? () => {} : handleClose}
          width={''}
          height="xs:h-full sm:h-auto"
          title="Upload Files"
        >
          <UploadFiles
            setShowUploadModal={(visible: boolean, e: React.MouseEvent<HTMLElement>) => {
              if (!visible) handleClose(e)
            }}
            showUploadModal={showUploadModal}
            uploadedFile={uploadedFiles}
            getFiles={getFiles}
            uploadFileErrors={undefined}
            disableButton={isFileUploading}
            uploadFileLoading={isFileUploading}
            saveFile={(e) => handleClose(e)}
          />
        </ModalBox>
      )}
    </>
  )
}

export default MessagingInput
