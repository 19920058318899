import { call, put, select, takeLatest } from 'redux-saga/effects'
import UserApi from 'api/user'

import {
  getUsersAction,
  addBusinessAccountAction,
  setAdvisorActiveStepAction,
  setBusinessOwnerActiveStepAction,
} from 'store/actions/admin'

import {
  ADD_ADVISOR_ACCOUNT,
  ADD_BUSINESS_ACCOUNT,
  GET_USERS,
  RESET_ADVISOR_BUSINESS_FORMS,
} from 'store/types'
import {
  resetProgressbarAction,
  totalStepsProgressbarAction,
  updateProgressbarAction,
} from 'store/actions/common/progressbar'
import {
  CREATE_ADVISOR_ACCOUNT_STEPS,
  CREATE_BUSINESS_ACCOUNT_STEPS,
  ERROR_MESSAGE_MAPPING,
  FORM,
  ROLES,
} from 'config'

import hookForms from 'utils/hookForms'
import { ampli } from 'ampli'

const getAdvisorOrOwnerList = (addUser) => {
  if (addUser && addUser.length > 0) {
    return JSON.stringify(addUser)
  } else {
    return JSON.stringify([])
  }
}

function* getUsers(action) {
  const users = {}
  const { roles } = action.payload
  for (let i = 0; i < roles.length; i++) {
    const data = yield call(UserApi.getCognitoUsers, { role: roles[i] })
    users[roles[i]] = data.listCognitoUsers
  }
  yield put(getUsersAction.FULLFILLED({ ...users }))
}

function* addBusinessAccount(action) {
  const tenantId = yield select((state) => state.user?.tenantId)

  try {
    let payload = action.payload

    const {
      email,
      firstName,
      lastName,
      businessName,
      mobileContactNumber,
      yearsInBusiness,
      businessProfile,
    } = action.payload
    let { addUser } = action.payload
    addUser = getAdvisorOrOwnerList(addUser)

    const userResponse = yield call(UserApi.createUser, {
      firstName,
      lastName,
      email,
      role: 'BUSINESS_OWNER',
      advisors: addUser,
      tenantId: tenantId,
      mobileContactNumber,
    })
    payload.userId = userResponse?.createUser.id

    yield call(UserApi.updateBusinessProfile, {
      id: payload.userId,
      tenantId,
      businessName,
      yearsInBusiness,
      isUserCreation: true,
      ...businessProfile,
    })

    yield put(addBusinessAccountAction.FULLFILLED())
    yield put(updateProgressbarAction())
    if (userResponse?.createUser.id) {
      yield put(setBusinessOwnerActiveStepAction('Account Created'))

      ampli.accountCreated({
        roles: ['BUSINESS_OWNER'],
        id: payload.userId,
        email: email,
        firstName: firstName,
        lastName: lastName,
        supportOrgIds: [tenantId],
      })
    }
  } catch (error: any) {
    const errorMessage = ERROR_MESSAGE_MAPPING[error.message.slice(0, -1)]
    if (errorMessage) error.message = errorMessage
    yield put(addBusinessAccountAction.REJECTED({ error }))
  }
}

function* addAdvisorAccount(action) {
  const tenantId = yield select((state) => state.user?.tenantId)
  try {
    let payload = action.payload

    const { email, adminAccess, firstName, lastName, mobileContactNumber } = action.payload
    let { addUser } = action.payload
    addUser = getAdvisorOrOwnerList(addUser)

    const userResponse = yield call(UserApi.createUser, {
      firstName,
      lastName,
      email,
      role: 'BSO_ADVISOR',
      owners: addUser,
      tenantId: tenantId,
      mobileContactNumber,
    })

    payload.userId = userResponse?.createUser.id

    if (adminAccess && userResponse?.createUser.id) {
      yield call(UserApi.addUserToGroup, {
        role: 'BSO_ADMIN',
        tenantId,
        userName: payload.userId,
      })
      yield put(setAdvisorActiveStepAction('Account Created'))
      ampli.accountCreated({
        roles: ['BSO_ADVISOR', 'BSO_ADMIN'],
        id: payload.userId,
        email: email,
        firstName: firstName,
        lastName: lastName,
        supportOrgIds: [tenantId],
      })
    } else {
      if (userResponse?.createUser.id) {
        yield put(setAdvisorActiveStepAction('Account Created'))
        ampli.accountCreated({
          roles: ['BSO_ADVISOR'],
          id: payload.userId,
          email: email,
          firstName: firstName,
          lastName: lastName,
          supportOrgIds: [tenantId],
        })
      }
    }
    yield put(addBusinessAccountAction.FULLFILLED())
    yield put(updateProgressbarAction())
  } catch (error: any) {
    const errorMessage = ERROR_MESSAGE_MAPPING[error.message.slice(0, -1)]

    if (errorMessage) error.message = errorMessage
    yield put(addBusinessAccountAction.REJECTED({ error }))
  }
}

function* resetAdvisorAndBusinessForms(action) {
  let totalSteps =
    Object.keys(
      action.payload?.accountCreation === ROLES.BSO_ADVISOR
        ? CREATE_ADVISOR_ACCOUNT_STEPS
        : CREATE_BUSINESS_ACCOUNT_STEPS
    ).length - 1

  yield put(resetProgressbarAction())
  yield put(setAdvisorActiveStepAction(CREATE_BUSINESS_ACCOUNT_STEPS.PROFILE_FORM))
  yield put(setBusinessOwnerActiveStepAction(CREATE_BUSINESS_ACCOUNT_STEPS.PROFILE_FORM))
  const profileForm = hookForms.getForm(FORM.PROFILE_FORM)
  const businessForm = hookForms.getForm(FORM.BUSINESS_PROFILE)
  const businessAssessmentForm = hookForms.getForm(FORM.BUSINESS_ASSESSMENT_FORM)
  const advisorForm = hookForms.getForm(FORM.ADVISOR_FORM)
  const adminAccessForm = hookForms.getForm(FORM.ADMIN_ACCESS)
  const assignOwnersForm = hookForms.getForm(FORM.OWNERS)
  businessAssessmentForm?.reset()
  profileForm?.reset()
  businessForm?.reset()
  advisorForm?.reset()
  adminAccessForm?.reset()
  assignOwnersForm?.reset()
  yield put(totalStepsProgressbarAction({ totalSteps, startingIndex: 0 }))
}

/// /////////// Watchers ///////////////////////
export function* watcherAdmin() {
  yield takeLatest(GET_USERS.STARTED, getUsers)
  yield takeLatest(ADD_BUSINESS_ACCOUNT.STARTED, addBusinessAccount)
  yield takeLatest(ADD_ADVISOR_ACCOUNT.STARTED, addAdvisorAccount)
  yield takeLatest(RESET_ADVISOR_BUSINESS_FORMS, resetAdvisorAndBusinessForms)
}
