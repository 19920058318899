import React from 'react'

function VideoIcon({ className }) {
  return (
    <svg
      className={className}
      width="35"
      height="46"
      viewBox="0 0 35 46"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3333 0V11.5H35L23.3333 0ZM20.4167 11.5V0H4.375C1.95872 0 0 1.93074 0 4.3125V41.6875C0 44.0684 1.95872 46 4.375 46H30.625C33.0413 46 35 44.0693 35 41.6875V14.375H23.4154C21.7201 14.375 20.4167 13.0902 20.4167 11.5ZM20.4167 34.5C20.4167 36.0875 19.1105 37.375 17.5 37.375H8.75C7.13945 37.375 5.83333 36.0875 5.83333 34.5V25.875C5.83333 24.2875 7.13945 23 8.75 23H17.5C19.1105 23 20.4167 24.2875 20.4167 25.875V34.5ZM29.1667 25.5965V34.7875C29.1667 35.9168 27.907 36.605 26.9354 36.0067L23.3333 33.7812V26.5938L26.9354 24.3746C27.9089 23.7727 29.1667 24.4645 29.1667 25.5965Z"
        fill="black"
      />
    </svg>
  )
}
export default VideoIcon
