import { useCallback, useMemo } from 'react'
import type { EmojiContextValue } from 'stream-chat-react'
import type { ReactionsListProps } from '../ReactionsList'
import { useMessageContext } from 'stream-chat-react'
import type { DefaultStreamChatGenerics } from '../../../types'
import { ReactionEmoji, getStrippedEmojiData } from 'components/Chat/components/Channel/emojiData'

type SharedReactionListProps =
  | 'additionalEmojiProps'
  | 'own_reactions'
  | 'reaction_counts'
  | 'reactionOptions'
  | 'reactions'

type UseProcessReactionsParams = Pick<ReactionsListProps, SharedReactionListProps> &
  Pick<EmojiContextValue, 'emojiConfig'>

export const useProcessReactions = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  params: UseProcessReactionsParams
) => {
  const {
    additionalEmojiProps = {},
    emojiConfig,
    own_reactions: propOwnReactions,
    reaction_counts: propReactionCounts,
    reactionOptions: propReactionOptions,
    reactions: propReactions,
  } = params
  const { message } = useMessageContext<StreamChatGenerics>('ReactionsList')
  const { defaultMinimalEmojis, emojiData: fullEmojiData, emojiSetDef } = emojiConfig || {}

  const latestReactions = useMemo(
    () => propReactions || message.latest_reactions || [],
    [propReactions, message.latest_reactions]
  )

  const ownReactions = useMemo(
    () => propOwnReactions || message?.own_reactions || [],
    [propOwnReactions, message?.own_reactions]
  )

  const reactionCounts = useMemo(
    () => propReactionCounts || message.reaction_counts || [],
    [propReactionCounts, message.reaction_counts]
  )
  const reactionOptions = propReactionOptions || defaultMinimalEmojis
  const reactionsAreCustom = !!propReactionOptions?.length

  const iHaveReactedWithReaction = useCallback(
    (reactionType: string) => ownReactions.find((reaction) => reaction.type === reactionType),
    [ownReactions]
  )

  const getEmojiByReactionType = useCallback(
    (type: string): ReactionEmoji | undefined =>
      reactionOptions.find((option: ReactionEmoji) => option.id === type),
    [reactionOptions]
  )

  const emojiData = useMemo(
    () => (reactionsAreCustom ? fullEmojiData : getStrippedEmojiData(fullEmojiData)),
    [fullEmojiData, reactionsAreCustom]
  )

  const latestReactionTypes = useMemo(
    () =>
      latestReactions.reduce((reactionTypes, { type }) => {
        if (reactionTypes.indexOf(type) === -1) {
          reactionTypes.push(type)
        }
        return reactionTypes
      }, [] as string[]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [latestReactions]
  )

  const supportedReactionMap = useMemo(
    () =>
      reactionOptions.reduce((acc, { id }) => {
        acc[id] = true
        return acc
      }, {} as Record<string, boolean>),
    [reactionOptions]
  )

  const supportedReactionsArePresent: any = useMemo(
    () => latestReactionTypes.some((type) => supportedReactionMap[type]),
    [latestReactionTypes, supportedReactionMap]
  )

  const totalReactionCount = useMemo(
    () =>
      supportedReactionsArePresent
        ? Object.values(reactionCounts).reduce((total, count) => total + count, 0)
        : 0,
    [reactionCounts, supportedReactionsArePresent]
  )

  const aggregatedUserNamesByType = useMemo(
    () =>
      latestReactions.reduce<Record<string, Array<string>>>((typeMap, { type, user }) => {
        typeMap[type] ??= []
        typeMap[type].push(user?.name || user!.id)
        return typeMap
      }, {}),
    // react-hooks/exhaustive-deps
    [latestReactions]
  )

  return {
    additionalEmojiProps: reactionsAreCustom ? additionalEmojiProps : emojiSetDef,
    aggregatedUserNamesByType,
    emojiData,
    getEmojiByReactionType,
    iHaveReactedWithReaction,
    latestReactions,
    latestReactionTypes,
    reactionCounts,
    supportedReactionsArePresent,
    totalReactionCount,
  }
}
