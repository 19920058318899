import React from 'react'

function PdfIcon({ className = '' }) {
  return (
    <svg
      id="pdf-svg"
      className={className}
      width="26"
      height="34"
      viewBox="0 0 26 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#pdf-svg)">
        <path
          d="M6.39591 20.4582H5.87508V17.3332H6.39591C7.2618 17.3332 7.95841 18.0298 7.95841 18.8957C7.95841 19.7616 7.2618 20.4582 6.39591 20.4582ZM13.1667 17.3332H13.6876C14.2605 17.3332 14.7292 17.8019 14.7292 18.3748V22.5415C14.7292 23.1144 14.2605 23.5832 13.6876 23.5832H13.1667V17.3332ZM15.2501 0.666504V8.99984C15.2501 10.1522 16.1811 11.0832 17.3334 11.0832H25.6667V29.8332C25.6667 32.1313 23.7983 33.9998 21.5001 33.9998H4.83341C2.53198 33.9998 0.666748 32.1313 0.666748 29.8332V4.83317C0.666748 2.53174 2.53198 0.666504 4.83341 0.666504H15.2501ZM4.83341 15.2498C4.25789 15.2498 3.79175 15.7186 3.79175 16.2915V24.6248C3.79175 25.1978 4.25789 25.6665 4.83341 25.6665C5.40894 25.6665 5.87508 25.1978 5.87508 24.6248V22.5415H6.39591C8.40763 22.5415 10.0417 20.9074 10.0417 18.8957C10.0417 16.884 8.40763 15.2498 6.39591 15.2498H4.83341ZM11.0834 24.6248C11.0834 25.1978 11.5522 25.6665 12.1251 25.6665H13.6876C15.4128 25.6665 16.8126 24.2668 16.8126 22.5415V18.3748C16.8126 16.6496 15.4128 15.2498 13.6876 15.2498H12.1251C11.5522 15.2498 11.0834 15.7186 11.0834 16.2915V24.6248ZM19.4167 15.2498C18.8438 15.2498 18.3751 15.7186 18.3751 16.2915V24.6248C18.3751 25.1978 18.8438 25.6665 19.4167 25.6665C19.9897 25.6665 20.4584 25.1978 20.4584 24.6248V21.4998H22.5417C23.1147 21.4998 23.5834 21.0311 23.5834 20.4582C23.5834 19.8853 23.1147 19.4165 22.5417 19.4165H20.4584V17.3332H22.5417C23.1147 17.3332 23.5834 16.8644 23.5834 16.2915C23.5834 15.7186 23.1147 15.2498 22.5417 15.2498H19.4167ZM17.3334 0.666504L25.6667 8.99984H17.3334V0.666504Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="pdf-svg">
          <rect width="25" height="33.3333" fill="white" transform="translate(0.666748 0.666504)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PdfIcon
