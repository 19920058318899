import React, { FC, useEffect } from 'react'
import map from 'lodash/map'
import ToolItemStatus from 'components/Clients/Client/ClientPlaybook/ToolItemStatus'
import Loader from 'components/Loaders'
import { isEmpty } from 'lodash'
import { UserIntegrations } from '../../../../__generated__/api-types-and-hooks'

interface IConnectedTool {
  [key: string]: UserIntegrations[]
}

export interface ITools {
  clientId: string
  connectedTools: IConnectedTool
  clientConnectedToolsLoading: boolean
  getConnectedToolsAction: () => void
}
const Tools: FC<ITools> = ({
  clientId,
  connectedTools,
  clientConnectedToolsLoading,
  getConnectedToolsAction,
}) => {
  useEffect(() => {
    if (!isEmpty(clientId)) {
      getConnectedToolsAction()
    }
  }, [getConnectedToolsAction, clientId])

  return (
    <div className="col-span-12 sm:ml-4 p-4 sm:p-0 sm:col-span-5">
      <h2 className="font-light text-3xl text-zinc font-primary mb-12">Tools</h2>
      <div className="">
        {clientConnectedToolsLoading ? (
          <Loader loader={'advisorConnectedTool'} />
        ) : (
          map(connectedTools, (values, key) => (
            <div key={key} className="mb-6">
              {map(values, (item, index) => (
                <ToolItemStatus key={index} data={item} />
              ))}
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default Tools
