import React from 'react'

export default function FilterMenuIcon({ className = '' }) {
  return (
    <svg
      className={className}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.75C0 1.05859 0.574554 0.5 1.28571 0.5H16.7143C17.4254 0.5 18 1.05859 18 1.75C18 2.44141 17.4254 3 16.7143 3H1.28571C0.574554 3 0 2.44141 0 1.75ZM2.57143 8C2.57143 7.30859 3.14598 6.75 3.85714 6.75H14.1429C14.854 6.75 15.4286 7.30859 15.4286 8C15.4286 8.69141 14.854 9.25 14.1429 9.25H3.85714C3.14598 9.25 2.57143 8.69141 2.57143 8ZM11.5714 14.25C11.5714 14.9414 10.9969 15.5 10.2857 15.5H7.71429C7.00312 15.5 6.42857 14.9414 6.42857 14.25C6.42857 13.5586 7.00312 13 7.71429 13H10.2857C10.9969 13 11.5714 13.5586 11.5714 14.25Z"
        fill="#5E06B9"
      />
    </svg>
  )
}
