import React from 'react'

const PadlockIconV2 = () => {
  return (
    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 7.61905C4.26522 7.61905 4.51957 7.51871 4.70711 7.3401C4.89464 7.1615 5 6.91925 5 6.66667C5 6.1381 4.55 5.71429 4 5.71429C3.73478 5.71429 3.48043 5.81463 3.29289 5.99323C3.10536 6.17184 3 6.41408 3 6.66667C3 6.91925 3.10536 7.1615 3.29289 7.3401C3.48043 7.51871 3.73478 7.61905 4 7.61905ZM7 3.33333C7.26522 3.33333 7.51957 3.43367 7.70711 3.61228C7.89464 3.79089 8 4.03313 8 4.28571V9.04762C8 9.30021 7.89464 9.54245 7.70711 9.72106C7.51957 9.89966 7.26522 10 7 10H1C0.734784 10 0.48043 9.89966 0.292893 9.72106C0.105357 9.54245 0 9.30021 0 9.04762V4.28571C0 3.75714 0.45 3.33333 1 3.33333H1.5V2.38095C1.5 1.74948 1.76339 1.14388 2.23223 0.697365C2.70107 0.25085 3.33696 0 4 0C4.3283 0 4.65339 0.0615852 4.95671 0.181239C5.26002 0.300893 5.53562 0.476273 5.76777 0.697365C5.99991 0.918457 6.18406 1.18093 6.3097 1.4698C6.43534 1.75867 6.5 2.06828 6.5 2.38095V3.33333H7ZM4 0.952381C3.60218 0.952381 3.22064 1.10289 2.93934 1.3708C2.65804 1.63871 2.5 2.00207 2.5 2.38095V3.33333H5.5V2.38095C5.5 2.00207 5.34197 1.63871 5.06066 1.3708C4.77936 1.10289 4.39782 0.952381 4 0.952381Z"
        fill="#818188"
      />
    </svg>
  )
}

export default PadlockIconV2
