import React from 'react'

function XIcon({ className }) {
  return (
    <svg
      className={className}
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6051 26.4819C26.0775 27.0071 25.2239 27.0071 24.6957 26.4819L13.5017 15.3372L2.3051 26.4819C1.77751 27.0071 0.923885 27.0071 0.395698 26.4819C-0.131899 25.9568 -0.131899 25.1071 0.395698 24.5814L11.5948 13.4392L0.397385 2.29443C-0.130212 1.76928 -0.130212 0.919608 0.397385 0.393866C0.924982 -0.131289 1.7786 -0.131289 2.30679 0.393866L13.5017 11.5411L24.6982 0.396385C25.2258 -0.128769 26.0794 -0.128769 26.6076 0.396385C27.1352 0.921539 27.1352 1.77121 26.6076 2.29695L15.4085 13.4392L26.6051 24.5839C27.1367 25.1046 27.1367 25.9612 26.6051 26.4819Z"
        fill="#3F3F46"
      />
    </svg>
  )
}

XIcon.defaultProps = {
  className: '',
}
export default XIcon
