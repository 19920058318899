import React, { useMemo } from 'react'

import { getCalendarEvents } from './FutureEvents.helper'
import {
  CalendarEventViewPreference,
  useGetUserCalendarEventsQuery,
} from '__generated__/api-types-and-hooks'
import { useTenantId } from 'utils/useTenantId'
import UpcomingEvents from 'components/Dashboard/Common/UpcomingEvents/UpcomingEvents.component'
import { IUpcomingEvent } from 'types'
import { USER_ROLES } from 'config'

interface IFutureEventsPayload {
  calendarEventViewPreference: string
  role: string[]
}

const FutureEvents = ({ calendarEventViewPreference, role }: IFutureEventsPayload) => {
  const tenantId = useTenantId()
  const { data, isFetching } = useGetUserCalendarEventsQuery(
    {
      calendarName:
        calendarEventViewPreference === CalendarEventViewPreference.Internal
          ? CalendarEventViewPreference.Internal
          : '',
      tenantId,
    },
    { staleTime: 60000 }
  )
  const { futureMeetings } = getCalendarEvents(data?.getUserCalendarEvents?.data)
  /** These are events parsed into the interface required by the UpcomingEvents component */
  const eventSummaries: IUpcomingEvent[] = useMemo(() => {
    return futureMeetings
      ? futureMeetings.map((event) => {
          const upcomingEvent: IUpcomingEvent = {
            description: event.title,
            date: event.start,
            eventId: event.eventId,
          }
          return upcomingEvent
        })
      : []
  }, [futureMeetings])

  const noEventMessage =
    role?.length && role.includes(USER_ROLES.BUSINESS_OWNER)
      ? 'Get started by scheduling a session with your advisor'
      : 'Get started on your Calendar'
  return (
    <UpcomingEvents
      className="lg:order-first"
      noEventsMessage={noEventMessage}
      events={eventSummaries}
      loading={isFetching}
    />
  )
}

export default FutureEvents
