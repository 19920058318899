import React, { memo, FC } from 'react'

interface IDeprecatedButtonProps {
  name: string
  onClick?: any
  type?: any
  className?: any
  disable?: any
  testId?: any
  variant?: any
  width?: any
  height?: any
  active?: any
  color?: any
  borderRadius?: any
}

/** Don't even think about this component for new features */

const DeprecatedButton: FC<IDeprecatedButtonProps> = ({
  name,
  onClick,
  type,
  className,
  disable,
  testId,
  variant,
  width,
  height,
  active,
  color,
}) => {
  const classes =
    variant === 'outlined'
      ? `${width ? `w-[${width}]` : ''} ${
          color ? `text-[${color}]` : '#52525B'
        } bg-transparent rounded-[10px] ${
          height ? `h-[${height}]` : ''
        }  outline outline-1 text-base`
      : variant === 'contained'
      ? `${color ? `text-[${color}]` : ''} bg-[#5BBAB9] rounded-[10px] ${
          height ? `h-[${height}]` : ''
        } ${width ? `w-[${width}]` : ''} px-4 py-2 leading-6 text-base`
      : variant === 'primary'
      ? `${
          color ? `bg-[${color}]` : 'bg-[#5BBAB9]'
        } rounded-[10px] group font-primary leading-6 relative w-full mt-2 mb-3 flex justify-center py-2 px-4 border border-transparent text-base leading-6 font-medium text-white ${
          disable ? 'opacity-50' : ''
        }`
      : variant === 'secondary'
      ? `h-[${height ? height : ''}]
  ${color ? `text-[${color}]` : 'text-[#5BBAB9]'}   float-right   ${active ? 'bg-[#5BBAB9]' : ''} ${
          active ? 'bg-[#5BBAB9]' : ''
        } ${active ? 'text-[#ffffff]' : ''}`
      : ''

  return (
    <>
      <button
        name={name}
        onClick={onClick}
        type={type}
        data-testid={testId}
        disabled={disable}
        style={{ width, height }}
        className={classes + ' ' + className}
      >
        {name}
      </button>
    </>
  )
}
export default memo(DeprecatedButton)
