import React from 'react'

const LockedIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <circle cx="21.5" cy="21.5" r="20.5" fill="#F4F4F5" stroke="#27272A" strokeWidth="2" />
      </g>
      <g opacity="0.5" clipPath="url(#clip0_4220_32184)">
        <path
          d="M14.9286 17.8011V14.5341C14.9286 10.9255 17.8703 8 21.5 8C25.1297 8 28.0714 10.9255 28.0714 14.5341V17.8011H28.8929C31.1621 17.8011 33 19.6286 33 21.8849V30.0526C33 32.3089 31.1621 34.1364 28.8929 34.1364H14.1071C11.839 34.1364 10 32.3089 10 30.0526V21.8849C10 19.6286 11.839 17.8011 14.1071 17.8011H14.9286ZM16.5714 17.8011H26.4286V14.5341C26.4286 11.8275 24.221 9.63352 21.5 9.63352C18.7328 9.63352 16.5714 11.8275 16.5714 14.5341V17.8011ZM11.6429 30.0526C11.6429 31.4053 12.7461 32.5028 14.1071 32.5028H28.8929C30.2533 32.5028 31.3571 31.4053 31.3571 30.0526V21.8849C31.3571 20.5322 30.2533 19.4347 28.8929 19.4347H14.1071C12.7461 19.4347 11.6429 20.5322 11.6429 21.8849V30.0526Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_4220_32184">
          <rect width="23" height="26.1364" fill="white" transform="translate(10 8)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LockedIcon
