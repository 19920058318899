import React, { memo } from 'react'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import { formatNumber } from 'utils/helper'

import ChartWrapper from 'components/Common/StyledComponents/ChartWrapper'
import {
  BarChart as RechartsBarChart,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
  Bar,
} from 'recharts'

const BarChart = ({ data, xDataKey, yDataKey, barName }) => {
  return (
    <ChartWrapper>
      <ResponsiveContainer width="100%" height="100%">
        <RechartsBarChart
          width={500}
          height={360}
          data={!isEmpty(data) ? data : ['']}
          margin={{
            top: 48,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey={xDataKey} />
          <YAxis
            axisLine={false}
            tickFormatter={(val) => {
              return `${formatNumber(val)}`
            }}
            label={{ value: 'Dollars', position: 'top', dy: -28 }}
          />
          <Tooltip
            formatter={(value) => {
              return isNumber(value) ? `$${formatNumber(value)}` : value || 0
            }}
          />
          <Bar dataKey={yDataKey}>
            {map(data, (key) => (
              <Cell fill="#52B4F9" key={key} />
            ))}
          </Bar>
        </RechartsBarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  )
}

BarChart.defaultProps = {
  xDataKey: 'name',
  yDataKey: 'value',
  barName: '',
}

export default memo(BarChart)
