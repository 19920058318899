import React, { ElementType } from 'react'
import GrowingContainer from 'components/Common/GrowingContainer'

interface IBanner {
  Icon?: ElementType
  Tooltip?: ElementType
  image?: string
  businessName?: string
  firstName?: string
  label?: string
  rightPanelContent?: React.ReactElement | boolean
  children?: React.ReactNode | string
}

const Banner: React.FC<IBanner> = ({
  Icon,
  Tooltip,
  image,
  businessName,
  firstName,
  label,
  rightPanelContent,
}) => {
  return (
    <GrowingContainer>
      <div className="grid grid-cols-12 gap-6">
        <div
          className={
            rightPanelContent ? 'xs:col-span-12 md:col-span-8' : 'xs:col-span-12 md:col-span-12'
          }
        >
          <div className="flex gap-10 items-center xs:flex-col md:flex-row">
            {Icon && <Icon />}
            {image && <img src={image} alt="bannerImage" className="max-w-96 max-h-64" />}
            <div className="font-black text-black xs:text-center md:text-left">
              <p className="text-4xl leading-10 font-primary font-normal">{firstName},</p>
              <h4 className="text-4xl leading-[43px] tracking-wider font-secondary">
                {label}
                {Tooltip && <Tooltip />}
              </h4>
            </div>
          </div>
        </div>
        {/* {rightPanelContent && (
          <div className="xs:col-span-12 md:col-span-4">{rightPanelContent}</div>
        )} */}
      </div>
    </GrowingContainer>
  )
}

Banner.defaultProps = {
  businessName: '',
  image: '',
  Icon: undefined,
  children: '',
  Tooltip: undefined,
  rightPanelContent: undefined,
}

export default Banner
