import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ROLES } from 'config'
import { getReturnToPath, navigateTo, redirectToPlayBookCard } from 'utils/helper'
import Button from 'components/Common/Button'
import { ReviewAssessmentHeader } from './ReviewAsssessmentHeader'
import { AppFC, IReviewAssessment } from 'types'
import Navbar from 'components/Common/Navbar'
import { CancelIcon } from 'components/Common/SvgIcons'
import { ReviewAssessmentResponsesContainer } from 'pages/Assessment/containers'

export const ReviewAssessment: AppFC<IReviewAssessment> = ({ userId, businessName }) => {
  const [completedAssessmentAt, setCompletedAssessmentAt] = useState('')
  const { assessmentType } = useParams()
  let queryParam = getReturnToPath()
  const redirectPath = queryParam?.returnTo || 'dashboard'

  return (
    <div className="bg-white">
      <Navbar
        className="h-auto"
        noCenterContent
        noRightContent
        leftContent={() => {
          return (
            <div
              className="flex gap-5 items-center "
              onClick={() => {
                navigateTo(
                  ROLES.BUSINESS_OWNER,
                  `playbooks-category/${redirectToPlayBookCard(assessmentType)}`
                )
              }}
            >
              <CancelIcon className="fill-primary cursor-pointer" />
              <div className="font-semibold">Review Assessment</div>
            </div>
          )
        }}
      />
      <div className="m-8">
        <ReviewAssessmentHeader
          assessmentType={assessmentType}
          completedAssessmentAt={completedAssessmentAt}
        />
        <div className="font-secondary font-extrabold text-3xl py-11 border-primary-outline border-t-2">
          Your responses:
        </div>
        <ReviewAssessmentResponsesContainer
          userId={userId}
          businessName={businessName}
          assessmentType={assessmentType}
          setCompletedAssessmentAt={setCompletedAssessmentAt}
        />
        <div className="pb-4">
          <Button
            label="Go to Playbooks"
            variant="primary"
            onClick={() => navigateTo(ROLES.BUSINESS_OWNER, `${redirectPath}`)}
          />
        </div>
      </div>
    </div>
  )
}
