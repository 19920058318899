import React from 'react'

function BackButtonArrowIcon({ className }) {
  return (
    <svg
      className={className}
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.457764 8.39624C-0.152588 9.00659 -0.152588 9.9978 0.457764 10.6082L8.27026 18.4207C8.88062 19.031 9.87183 19.031 10.4822 18.4207C11.0925 17.8103 11.0925 16.8191 10.4822 16.2087L5.33081 11.0623H20.3113C21.1755 11.0623 21.8738 10.364 21.8738 9.49976C21.8738 8.6355 21.1755 7.93726 20.3113 7.93726H5.33569L10.4773 2.79077C11.0876 2.18042 11.0876 1.18921 10.4773 0.578857C9.86694 -0.0314941 8.87573 -0.0314941 8.26538 0.578857L0.452881 8.39136L0.457764 8.39624Z"
        fill="#5E06B9"
      />
    </svg>
  )
}

BackButtonArrowIcon.defaultProps = {
  className: '',
}
export default BackButtonArrowIcon
