export const STATUS = {
  IN_PROGRESS: 'In Progress',
  TODO: 'To Do',
  DONE: 'Completed',
  PAUSED: 'PAUSED',
  DRAFT: 'Draft',
  ARCHIVED: 'ARCHIVED',
  BLOCKED: 'BLOCKED',
}
export enum BusinessGoalTimeFrames {
  ShortTerm = 'SHORT_TERM',
  MediumTerm = 'Medium_TERM',
  LongTerm = 'LONG_TERM',
}
