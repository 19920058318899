import React, { forwardRef, memo, ForwardRefRenderFunction } from 'react'
import CircleNotchIcon from 'components/Common/SvgIcons/CircleNotchIcon'

interface IUploadFileFieldProps {
  label?: string
  type: any
  value?: string
  onChange?: any
  onChangeText?: any
  disabled?: boolean
  hidden?: boolean
  id?: any
  className?: any
  multiple?: boolean
  isLoading?: boolean
}

const UploadFileField: ForwardRefRenderFunction<any, IUploadFileFieldProps> = (
  { label, type, onChange, value, disabled, hidden, id, className, multiple, isLoading },
  ref
) => {
  const getClasses = () => {
    let classes = `flex justify-center items-center bg-primary hover:bg-primary-brand active:bg-primary-active focus:bg-primary border-0 text-white py-2 px-4 gap-0 font-secondary font-black rounded-full py-4 px-8 gap-2 text-base cursor-pointer`
    classes += ` ${disabled ? '!bg-primary-disabled !cursor-not-allowed ' : ''} `
    return classes
  }

  return (
    <>
      <label
        className={`${
          className
            ? getClasses() + ' ' + className
            : 'questionLabel w-full text-center flex justify-center cursor-pointer'
        }`}
        title={label}
      >
        {isLoading && <CircleNotchIcon className="fill-white" />}
        {label}
        <input
          type={type}
          value={value}
          ref={ref}
          disabled={disabled}
          hidden={hidden}
          onChange={onChange}
          id={id}
          title={label}
          className={getClasses() + ' ' + className}
          multiple={multiple}
        />
      </label>
    </>
  )
}
export default memo(forwardRef(UploadFileField))
