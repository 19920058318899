import React, { useState, useEffect, ReactChild } from 'react'
import get from 'lodash/get'

import colors from 'colors'
import isUndefined from 'lodash/isUndefined'
import FilledArrowIcon from 'components/Common/SvgIcons/FilledArrowIcon'

interface IAssessmentAccordionProps {
  title: string
  open: boolean
  icon?: ReactChild
  outlined: boolean
  isLocked?: boolean
  onOpen: () => void
  onClose?: () => void
  children: any
}
const AssessmentAccordion = ({
  title,
  open,
  icon,
  outlined,
  onOpen,
  onClose = () => {},
  children,
  isLocked = false,
}: IAssessmentAccordionProps) => {
  const [isOpen, setOpen] = useState(open)

  const handleToggle = () => {
    setOpen(!isOpen)
    if (!isOpen) {
      onOpen()
    } else {
      if (onClose) onClose()
    }
  }

  useEffect(() => {
    if (!isUndefined(open)) {
      setOpen(open)
    }
  }, [open])

  return (
    <div className="mb-3 w-full">
      <div
        className={`flex w-full justify-between p-2 pt-3 pb-3 rounded ${
          isLocked
            ? 'bg-secondary border-primary-outline border'
            : outlined
            ? 'bg-secondary border-primary-outline border'
            : 'bg-primary'
        }`}
        onClick={handleToggle}
      >
        <div className="flex items-center ml-2">
          {icon}
          <div
            className={`${
              isLocked ? 'text-zinc' : outlined ? ' text-black' : 'text-white font-bold'
            } ml-2 capitalize font-primary`}
          >
            {title}
          </div>
        </div>
        <div className="flex items-center justify-center mr-4">
          <FilledArrowIcon
            className={isOpen ? 'rotate-90' : ''}
            color={isLocked ? `${get(colors, 'secondary.text', '#D4D4D8')}` : outlined && '#5E06B9'}
          />
        </div>
      </div>
      <div
        className={`${
          !isOpen ? 'hidden' : ''
        } bg-secondary border border-t-0 border-primary-outline shadow-3xl shadow-cyan-500/50 p-4 w-full`}
      >
        {children}
      </div>
    </div>
  )
}

AssessmentAccordion.defaultProps = {
  open: false,
  outlined: false,
}

export default AssessmentAccordion
