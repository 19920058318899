import React, { FC } from 'react'
import { InfoTextWrapper, InfoIconWrapper, InfoTextLabel } from 'components/Common/InfoText/style'

export interface IInfoTextProps {
  infoText: string
  index?: number
}

const InfoText: FC<IInfoTextProps> = ({ infoText, index = 0 }) => {
  return (
    <InfoTextWrapper index={index}>
      <InfoIconWrapper />
      <InfoTextLabel>{infoText}</InfoTextLabel>
    </InfoTextWrapper>
  )
}

export default InfoText
