import React from 'react'

const UserCreationContainer = ({ bgColor = 'bg-white', width = '', children }) => {
  return (
    <div className={`min-h-full w-full flex justify-center ${bgColor}`}>
      <div
        className={`xs:px-5 xs:py-4 md:py-6 md:px-0 w-full flex flex-col overflow-x-hidden pb-10 pt-6 sm:p-8 ${bgColor} ${width}`}
      >
        {children}
      </div>
    </div>
  )
}

export default UserCreationContainer
