import React from 'react'
import Button from 'components/Common/Button'
import AccountCreatedIcon from 'components/Common/SvgIcons/AccountCreatedIcon'
import { useSelector } from 'react-redux'
import { replaceTerm } from 'utils/helper'
import { RootState } from 'App'
import { ROLES } from 'config'

export default function AccountCreated({
  accountName,
  handleOnSubmits,
  resetAdvisorAndBusinessFormsAction,
  setFormData,
}) {
  const bsoProfile = useSelector((state: RootState) => state.bso.bsoProfile)
  const advisorTerm = replaceTerm('advisor', bsoProfile?.terminology)?.toLowerCase()

  return (
    <>
      <div className="flex justify-center">
        <AccountCreatedIcon />
      </div>
      <div>
        <p className="font-normal text-3xl mt-8  text-center font-primary text-purple-dark">
          Account Created!
        </p>
        <div className="max-w-full mb-2">
          <p className="font-normal h-[40px]  text-base mt-8 mb-8 text-purple-dark text-center">
            An invitation will be sent to the{' '}
            {`${accountName === 'advisor' ? advisorTerm : accountName}'s`} email.
          </p>
        </div>

        <Button
          variant="primary"
          label={'Done'}
          onClick={() => {
            handleOnSubmits()
          }}
          testId="submit"
          className="w-full"
        />
        <div className="py-2">
          <Button
            variant="secondary"
            testId="createAnother"
            label={`CREATE ANOTHER ${
              accountName === ROLES.BSO_ADVISOR ? advisorTerm.toUpperCase() : 'BUSINESS'
            } ACCOUNT`}
            onClick={() => {
              setFormData(null)
              resetAdvisorAndBusinessFormsAction({ accountCreation: ROLES.BSO_ADVISOR })
            }}
            className="w-full"
          />
        </div>
      </div>
    </>
  )
}
