import React from 'react'

function AppUpArrowIcon({ className }) {
  return (
    <svg
      className={className}
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 6L5 -4.37114e-07L10 6L0 6Z" fill="#5E5E5E" />
    </svg>
  )
}

AppUpArrowIcon.defaultProps = {
  className: '',
}
export default AppUpArrowIcon
