import styled from 'styled-components'
import tw from 'twin.macro'

export const TextLargeWrapper = styled.div`
  p {
    font-size: 18px;
  }
`

export const IpadViewPort = styled.div`
  @media (min-width: 821px) and (min-height: 1024px) {
    .hideInIpadView {
      display: none;
    }
  }

  @media (max-width: 1024px) and (max-height: 1365px) {
    .hideInIpadProView {
      display: none;
    }
    @media (max-width: 1366px) and (max-height: 1024px) {
      .hideInIpadProView {
        display: none;
      }
    }
  }
`

export const SelectFieldWrapper = styled.div`
  .select__indicator {
    display: none !important;
  }
  .select__indicator-separator {
    display: none !important;
  }
`

export const EditAccountWrapper = styled.div`
  ${tw`h-min w-full`}
`

export const HeaderWrapper = styled.div`
  ${tw`flex justify-between pt-8 pb-9 px-7`}
`
export const LabelWrapper = styled.label`
  ${tw`text-base font-bold leading-6 text-primary-text`}
`
export const StatusWrapper = styled.label`
  ${tw`text-base leading-6 text-black-light font-medium capitalize`}
`

export const AdvisorAssignmentWrapper = styled.div`
  ${tw`    grid grid-cols-1 col-span-12 gap-3 sm:pt-3 sm:pb-7`}
`

export const FooterWrapper = styled.div`
  ${tw`grid grid-cols-12 gap-1 px-7 pt-6 pb-6 bg-white`}
`

export const HorizontalBreak = styled.hr`
  ${tw`border-t-[1px] border-primary-outline col-span-12 pb-[23px]`}
`

export const SaveButtonWrapper = styled.div`
  ${tw`xs:col-span-12 sm:col-span-2	sm:col-start-11 xs:px-0 px-3`}
`
export const CancelButtonWrapper = styled.div`
  ${tw`xs:col-span-12 sm:col-span-2	sm:col-start-9`}
`

export const EditAccountFormWrapper = styled.div<{ isBusiness: boolean }>(({ isBusiness }) => {
  return [tw`grid xs:grid-cols-12 sm:grid-cols-12 mx-7 gap-3`, !isBusiness ? 'xs:pb-0 sm:pb-8' : '']
})

export const EditAccountBgColorWrapper = styled.div`
  ${tw`bg-white`}
`
export const BusinessProfileWrapper = styled.div`
  ${tw`pb-6 pt-[10px] px-7`}
`

export const FormWrapper = styled.div`
  ${tw`grid grid-cols-12 gap-3`}
`
export const YearsFieldWrapper = styled.div`
  ${tw`xs:col-span-12 sm:col-span-6 md:col-span-6 mdl:col-span-6 mt-7`}
`
export const BusinessNameWrapper = styled.div`
  ${tw`col-span-12`}
`
export const TopHrWrapper = styled.hr`
  ${tw`border-t-[3px] border-primary-disabled pb-[38px]`}
`

export const BottomHrWrapper = styled.hr`
  ${tw`border-t-[3px] border-primary-disabled pb-2 mt-6 col-span-12`}
`

export const DetailsWrapper = styled.div`
  ${tw`xs:mt-9 sm:my-0 xs:mb-1 flex gap-6 text-center xs:justify-center`}
`

export const DetailWrapper = styled.div`
  ${tw`px-4`}
`

export const ValueWrapper = styled.div`
  ${tw`text-zinc leading-7 font-bold text-xl`}
`

export const NameWrapper = styled.div`
  ${tw`whitespace-pre-line text-zinc leading-[18px] font-semibold text-[14px]`}
`
export const UserCountWrapper = styled.div`
  ${tw`sm:flex sm:!mt-[10px] mdl:!mt-6 sm:justify-between sm:!mb-7 mdl:!mb-11`}
`

export const EditAccountModalBoxWrapper = styled.div`
  ${tw`pt-4`}
`

export const LicenseModalBoxWrapper = styled.div`
  ${tw`mx-6 mb-6 flex flex-col gap-8`}
`

export const ContentWrapper = styled.div`
  ${tw`flex flex-col gap-6`}
`

export const HeadingWrapper = styled.p`
  ${tw`text-black-light text-4xl font-black font-secondary leading-none	`}
`

export const TextWrapper = styled.p`
  ${tw`leading-6 font-medium`}
`
export const ModalBoxWrapper = styled.div`
  ${tw`pt-4`}
`

export const AdminRoleErrorWrapper = styled.p`
  ${tw`text-3xl font-bold px-4 pt-0 pb-8 text-center text-primary-error`}
`
