import { IGetUserProfileAction } from 'components/AppDashboard/interfaces'

import {
  SET_TENANT_ID,
  GET_CHAT_USERS,
  UPDATE_MY_TOOLS,
  CLIENTS_SUMMARY,
  GET_USER_PROFILE,
  LOG_USER_ACTIVITY,
  RESEND_USER_INVITE,
  UPDATE_USER_PROFILE,
  GET_USER_NOTIFICATIONS,
  OPEN_CONFIRMATION_MODAL,
  GET_UNREAD_NOTIFICATIONS,
  CHAT_UNREAD_MESSAGE_COUNT,
  DELETE_USER_NOTIFICATIONS,
  GET_USER_BUSINESS_PROFILE,
  UPDATE_USER_NOTIFICATIONS,
  UPDATE_USER_BUSINESS_PROFILE,
  SET_SHOW_SUBSCRIPTION_PROMPT,
  PUBLISH_ANNOUNCEMENT_ACTION,
  SET_ANNOUNCEMENT_ACTIVE_STEP,
} from 'store/types'

export const getUserProfileAction = {
  STARTED: (payload?: IGetUserProfileAction) => ({
    type: GET_USER_PROFILE.STARTED,
    payload,
  }),
  FULLFILLED: (user) => ({ type: GET_USER_PROFILE.FULLFILLED, payload: user }),
  REJECTED: () => ({
    type: GET_USER_PROFILE.REJECTED,
  }),
  RESET: () => ({
    type: GET_USER_PROFILE.RESET,
  }),
}
export const getUserBusinessProfileAction = {
  STARTED: (payload) => ({
    type: GET_USER_BUSINESS_PROFILE.STARTED,
    payload,
  }),
  FULLFILLED: (user) => ({ type: GET_USER_BUSINESS_PROFILE.FULLFILLED, payload: user }),
  REJECTED: () => ({
    type: GET_USER_BUSINESS_PROFILE.REJECTED,
  }),
}

export const updateUserProfileAction = {
  STARTED: (payload) => ({
    type: UPDATE_USER_PROFILE.STARTED,
    payload,
  }),
  FULLFILLED: (user) => ({
    type: UPDATE_USER_PROFILE.FULLFILLED,
    payload: user,
  }),
  REJECTED: (error) => ({
    type: UPDATE_USER_PROFILE.REJECTED,
    payload: error,
  }),
}
export const updateUserBusinessProfileAction = {
  STARTED: (payload) => ({
    type: UPDATE_USER_BUSINESS_PROFILE.STARTED,
    payload,
  }),
  FULLFILLED: () => ({
    type: UPDATE_USER_BUSINESS_PROFILE.FULLFILLED,
  }),
  REJECTED: (error) => ({
    type: UPDATE_USER_BUSINESS_PROFILE.REJECTED,
    payload: error,
  }),
}

export const getChatUsersAction = {
  STARTED: (payload) => ({
    type: GET_CHAT_USERS.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({
    type: 'GET_CHAT_USERS',
    payload,
  }),
  REJECTED: (error) => ({
    type: GET_CHAT_USERS.REJECTED,
    payload: error,
  }),
}

export const logUserActivityAction = (payload: any) => ({
  type: LOG_USER_ACTIVITY,
  payload,
})

export const chatUnreadMessageCountAction = {
  STARTED: (payload) => ({
    type: CHAT_UNREAD_MESSAGE_COUNT.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: CHAT_UNREAD_MESSAGE_COUNT.FULLFILLED, payload }),
  REJECTED: (error) => ({ type: CHAT_UNREAD_MESSAGE_COUNT.REJECTED, payload: error }),
}

export const getUnreadNotificationsAction = {
  STARTED: () => ({
    type: GET_UNREAD_NOTIFICATIONS.STARTED,
  }),
  FULLFILLED: () => ({
    type: GET_UNREAD_NOTIFICATIONS.FULLFILLED,
  }),
  REJECTED: (error) => ({
    type: GET_UNREAD_NOTIFICATIONS.REJECTED,
  }),
}

export const setAnnouncementActiveStepAction = (step: string) => ({
  type: SET_ANNOUNCEMENT_ACTIVE_STEP,
  payload: step,
})

export const getUserNoticationsAction = {
  STARTED: (payload) => ({
    type: GET_USER_NOTIFICATIONS.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({
    type: GET_USER_NOTIFICATIONS.FULLFILLED,
    payload,
  }),
  REJECTED: (error) => ({
    type: GET_USER_NOTIFICATIONS.REJECTED,
    payload: error,
  }),
  RESET: (payload?) => ({
    type: GET_USER_NOTIFICATIONS.RESET,
    payload,
  }),
}

export const updateUserNotificationsAction = {
  STARTED: (payload) => ({
    type: UPDATE_USER_NOTIFICATIONS.STARTED,
    payload,
  }),
  FULLFILLED: () => ({
    type: UPDATE_USER_NOTIFICATIONS.FULLFILLED,
  }),
  REJECTED: (error) => ({
    type: UPDATE_USER_NOTIFICATIONS.REJECTED,
    payload: error,
  }),
}

export const publishAnnouncementAction = {
  STARTED: (payload) => ({
    type: PUBLISH_ANNOUNCEMENT_ACTION.STARTED,
    payload,
  }),
  FULLFILLED: () => ({
    type: PUBLISH_ANNOUNCEMENT_ACTION.FULLFILLED,
  }),
  REJECTED: (payload) => ({
    type: PUBLISH_ANNOUNCEMENT_ACTION.REJECTED,
    payload,
  }),
}

export const deleteUserNotificationsAction = {
  STARTED: (payload) => ({
    type: DELETE_USER_NOTIFICATIONS.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({
    type: DELETE_USER_NOTIFICATIONS.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({
    type: DELETE_USER_NOTIFICATIONS.REJECTED,
  }),
}

export const updateMyToolsAction = {
  STARTED: (payload) => ({
    type: UPDATE_MY_TOOLS.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({
    type: UPDATE_MY_TOOLS.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({
    type: UPDATE_MY_TOOLS.REJECTED,
  }),
}

export const getClientsSummaryAction = {
  STARTED: (payload) => ({
    type: CLIENTS_SUMMARY.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({
    type: CLIENTS_SUMMARY.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({
    type: CLIENTS_SUMMARY.REJECTED,
  }),
}

export const resendUserInviteAction = {
  STARTED: (payload) => ({
    type: RESEND_USER_INVITE.STARTED,
    payload,
  }),
  FULLFILLED: () => ({
    type: RESEND_USER_INVITE.FULLFILLED,
  }),
  REJECTED: (error) => ({
    type: RESEND_USER_INVITE.REJECTED,
    payload: error,
  }),
}
export const openConfirmationModalAction = (payload) => ({
  type: OPEN_CONFIRMATION_MODAL,
  payload,
})
export const setTenantIdAction = (payload) => ({
  type: SET_TENANT_ID,
  payload,
})
export const setShowSubscriptionPromptAction = (payload) => ({
  type: SET_SHOW_SUBSCRIPTION_PROMPT,
  payload,
})
