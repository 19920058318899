import React, { useEffect } from 'react'

import { PlaysWrapper } from 'components/Owner/Plays/card-style'

interface ITab {
  name?: string
  title
}

// todo: Remove references to plays in order to make this a true "common" component

// use BasicTabs.tsx for a use-case agnostic implementation of tab behavior

const Tabs = ({
  activeStep,
  steps,
  children = <> </>,
  updateTabHandler,
  classes = '',
  id = '',
}) => {
  // const handleChange = (step) => {
  //   updateTabHandler(step?.name)
  // }

  // const activeTab = useMemo(() => {
  //   return steps.find(s => s.name === activeStep)
  // }, [steps, activeStep])

  return (
    <PlaysWrapper
      id={id || 'play-tabs'}
      className={`${classes ? classes : 'border-b-2 border-primary-disabled'} w-full`}
    >
      <div className="">
        {children}

        <div className="flex flex-col grid-cols-12">
          <div className="tabMainWrapper shadow-md">
            <ul className={`playTabs xs:overflow-auto`}>
              {steps?.map((step, key) => {
                return (
                  <li
                    className={`${
                      activeStep === step.name ? 'active !font-bold' : 'font-normal'
                    } cursor-pointer whitespace-nowrap leading-7 font-primary xs:text-base lg:text-xl hover:border-b-4 hover:border-primary hover:!pb-4 hover:!pt-5`}
                    key={key}
                    onClick={() => updateTabHandler(step?.name)}
                  >
                    {step?.title}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </PlaysWrapper>
  )
}

export interface IBasicTabs<Tab extends ITab> {
  tabs: Tab[]
  activeTab: Tab
  onChange: (...args: any[]) => void
  className?: string
}

export const BasicTabs = ({ tabs, activeTab, onChange, className = '' }) => {
  /*
    Using titles to determine the active tab offers more flexibility to the parent component
    than checking for object equality. However, this means titles must be unique, so guarantee it
    by throwing an error otherwise.
  */
  useEffect(() => {
    const titles = tabs.map((t) => t.title)
    const uniqueTitles = [...new Set(titles)]
    const hasDuplicateTitle = titles.length > uniqueTitles.length
    if (hasDuplicateTitle) {
      throw new Error('Each tab must use a unique title.')
    }
  }, [tabs])

  // this check only exists to avoid regression with the original component's use of "name" property.
  // ultimately, it would be great to require only "title"
  const isActiveTab = (tab) => {
    if (tab?.name && activeTab?.name) {
      return tab.name === activeTab.name
    } else {
      return tab.title === activeTab.title
    }
  }

  return (
    <div className={`${className} flex shadow-md bg-white`}>
      <ul className="flex items-center justify-evenly w-full">
        {tabs?.map((tab, key) => {
          return (
            <li
              className={`${
                isActiveTab(tab)
                  ? 'border-b-primary border-b-4 font-bold text-primary'
                  : 'font-normal'
              } cursor-pointer whitespace-nowrap leading-7 font-primary xs:text-base lg:text-xl p-3 flex-grow text-center`}
              key={key}
              onClick={() => onChange(tab)}
            >
              {tab?.title}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Tabs
