import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { StepperWrapper } from 'components/Common/StyledComponents/StepperWrapper'
import InputField from 'components/Common/InputField'
import InputTextArea from 'components/Common/InputTextArea'
import SelectField, { IInputFieldProps } from 'components/Common/SelectField'
import { PLAY_TYPE } from 'config'
import Button from 'components/Common/Button'
import AssessmentAccordion from 'components/Clients/Client/ClientPlaybook/AssessmentAccordion'
import Play, { Setup, Step } from './models'
import ToolApi from '../../../api/tool'
import { Tool } from '__generated__/api-types-and-hooks'
import ToggleButton from 'components/Common/ToggleButton'
import copyToClipboard from 'utils/copyToClipboard'
import { BasicTabs } from 'components/Common/Tabs'

const usePlay = (): Play => {
  const [play, setPlay] = useState(new Play())
  const setter = (newPlay: Play) => {
    setPlay(newPlay)
  }
  play.setState = setter
  return play
}

const playTypeOptions = [
  { label: PLAY_TYPE.EDUCATION, value: PLAY_TYPE.EDUCATION },
  { label: PLAY_TYPE.CONNECT, value: PLAY_TYPE.CONNECT },
  { label: PLAY_TYPE.PROCESS, value: PLAY_TYPE.PROCESS },
  { label: PLAY_TYPE.TOOL, value: PLAY_TYPE.TOOL },
]

const categoryOptions = [
  { label: 'Sales', value: 'Sales' },
  { label: 'Business Essentials', value: 'Business Essentials' },
  { label: 'Finance', value: 'Finance' },
  { label: 'Marketing', value: 'Marketing' },
]

const CreatePlay = () => {
  const play = usePlay()
  const [viewJSON, setViewJSON] = useState(false)

  const [tools, setTools] = useState<Tool[]>([])

  const getTools = async () => {
    const data = (await ToolApi.getTools()).getTools.data
    setTools(data)
  }

  useEffect(() => {
    getTools()
  }, [])

  const [tabs, setTabs] = useState([{ title: 'General' }, { title: 'Guide' }, { title: 'Setup' }])
  const [activeTab, setActiveTab] = useState(tabs[0])

  return (
    <>
      <StepperWrapper>
        <div className="min-h-full w-full flex justify-center bg-white">
          <div className="xs:p-4 md:p-0 max-w-[700px] w-full justify-center flex-col bg-white">
            <div className="w-[100%] gap-[34px] flex flex-col items-center overflow-x-hidden bg-[white] pb-10"></div>
            <div
              className={`max-w-[${
                viewJSON ? '1000' : '600'
              }px] w-full h-full m-auto flex flex-col gap-2`}
            >
              <PageTitle />
              <JSONControls viewJSON={viewJSON} setViewJSON={setViewJSON} json={play.json} />
              {viewJSON ? (
                <PlayJSONView json={play.json} />
              ) : (
                <section className="flex flex-col gap-2 py-2">
                  <CreatePlayTabs
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    setTabs={setTabs}
                    play={play}
                    tabs={tabs}
                  />
                  <div className="flex flex-col gap-3">
                    {activeTab.title === 'General' && (
                      <TabContent.General play={play} tools={tools} />
                    )}
                    {activeTab.title === 'Guide' && <TabContent.Guide guide={play.guide} />}
                    {activeTab.title === 'Setup' && <TabContent.Setup setup={play.setup} />}
                    {activeTab.title === 'Use' && (
                      <TabContent.Use
                        setActiveTab={setActiveTab}
                        setTabs={setTabs}
                        use={play.use}
                        tabs={tabs}
                        play={play}
                      />
                    )}
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      </StepperWrapper>
    </>
  )
}

const CreatePlayTabs = ({ play, tabs, activeTab, setActiveTab, setTabs }) => {
  const onClickAddUse = () => {
    play.addUse()
    const newTab = { title: 'Use' }
    setTabs([...tabs, newTab])
    setActiveTab(newTab)
  }
  return (
    <div className="flex gap-1 items-center">
      <BasicTabs
        className={play.use ? 'w-full' : 'w-[75%]'}
        onChange={(tab) => setActiveTab(tab)}
        activeTab={activeTab}
        tabs={tabs}
      />
      {play.use === null && (
        <div className="w-1/4">
          <Button
            onClick={() => onClickAddUse()}
            label='Add "Use" Tab'
            variant="secondary"
            className="px-2 w-full"
            loading={false}
          />
        </div>
      )}
    </div>
  )
}

const TabContent = {
  General: ({ play, tools }) => {
    return (
      <>
        <InputField
          label="Title"
          value={play.title}
          onChange={(e) => play.set('title', e.target.value)}
        />
        <InputField
          label="Card Title"
          value={play.meta.playBookTitle}
          onChange={(e) => play.meta.set('playBookTitle', e.target.value)}
        />
        <InputTextArea
          label="Description"
          value={play.description}
          onChange={(e) => play.set('description', e.target.value)}
        />
        <InputField
          label="Banner Image Path"
          placeholder='(e.g. "uploads/play/file-name.svg")'
          value={play.meta.banner}
          onChange={(e) => play.meta.set('banner', e.target.value)}
        />
        <InputField
          label="Card Image Path"
          placeholder='(e.g. "uploads/play/file-name.svg")'
          value={play.meta.playImage}
          onChange={(e) => play.meta.set('playImage', e.target.value)}
        />
        <InputField
          label="Tool URL (optional)"
          placeholder='(e.g. "https://www.ebay.com/sl/sell")'
          value={play.meta.url}
          onChange={(e) => play.meta.set('url', e.target.value)}
        />
        <ToolSelectField play={play} tools={tools} />
        <SelectFieldWithOtherOption
          label="Category"
          options={categoryOptions}
          value={play.meta.category}
          onChange={(value) => play.meta.set('category', value)}
        />
        <SelectFieldWithOtherOption
          label="Type"
          options={playTypeOptions}
          value={play.meta.playType}
          onChange={(value) => play.meta.set('playType', value)}
        />
      </>
    )
  },
  Guide: ({ guide }) => {
    return (
      <>
        <div className="flex flex-col gap-4">
          <InputTextArea
            label="Guide Description (appears above action button on Guide tab)"
            value={guide!.guideDescription}
            onChange={(e) => guide!.set('guideDescription', e.target.value)}
          />
          <InputTextArea
            label="Step Heading Description (this has only been added to a couple existing plays)"
            value={guide!.headingDescription}
            onChange={(e) => guide!.set('headingDescription', e.target.value)}
          />
          <InputTextArea
            label='Action Button Label (e.g. "Go to Play!")'
            value={guide!.actionButtonLabel}
            onChange={(e) => guide!.set('actionButtonLabel', e.target.value)}
          />
        </div>
      </>
    )
  },
  Setup: ({ setup }) => {
    return (
      <div className="flex flex-col gap-4">
        <InputTextArea
          label="Price Description"
          value={setup!.pricing}
          onChange={(e) => setup!.set('pricing', e.target.value)}
        />
        {setup!.steps.map((step, idx) => {
          return (
            <StepAccordion
              key={`step-accordion-${idx + 1}`}
              setup={setup!}
              step={step}
              index={idx}
            />
          )
        })}
        <div className="flex justify-end w-full">
          <div className="grid grid-cols-2 gap-2">
            <Button
              label="Add Step"
              onClick={() => setup!.addStep()}
              className="px-2 py-1.5 flex-grow-0 w-full"
              variant="secondary"
            />
          </div>
        </div>
      </div>
    )
  },
  Use: ({ play, use, setTabs, setActiveTab, tabs }) => {
    const onDeleteUse = () => {
      play.deleteUse()
      const newTabs = [...tabs]
      newTabs.splice(
        newTabs.findIndex((t) => t.title === 'Use'),
        1
      )
      setTabs(newTabs)
      setActiveTab(newTabs[0])
    }
    return (
      <div className="pt-3">
        {use && (
          <>
            <div className="flex justify-end w-full">
              <div>
                <Button
                  label='Delete "Use" Tab'
                  className="px-2 py-1.5 flex-grow-0 w-full"
                  variant="secondary"
                  onClick={() => onDeleteUse()}
                />
              </div>
            </div>
            <InputField
              label="Play Type"
              value={use.playType}
              onChange={(e) => use.set('playType', e.target.value)}
              placeholder='(e.g. "setupAccountAndConnect", "Education", "Existing Tool - Usage")'
            />
          </>
        )}
      </div>
    )
  },
}

interface IToolSelectFieldProps {
  play: Play
  tools: Tool[]
}

type Option = { label: string; value: string }
const ToolSelectField = ({ play, tools }: IToolSelectFieldProps) => {
  const [options, setOptions] = useState<Option[]>([])
  const [selected, setSelected] = useState<Option | null>(null)

  const handleChange = (label) => {
    const newSelection = options.find((o) => o.label === label)!
    setSelected(newSelection)
  }

  useEffect(() => {
    const _options = tools.map((tool) => ({ label: tool.toolName!, value: tool.toolName! }))
    setOptions(_options)
  }, [tools])

  useEffect(() => {
    const initialSelected = options.find((o) => o.label === play.meta.toolName)
    if (initialSelected) {
      setSelected(initialSelected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  useEffect(() => {
    if (selected !== null) {
      play.meta.set('toolName', selected?.label || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <SelectField
      onChange={handleChange}
      options={options}
      value={selected?.value || null}
      label="Associated Tool (optional)"
    />
  )
}

// interface IPlayTabAccordionProps {
//   play: Play
//   playTab: PlayTab
//   index: number
// }

// interface ISetupAccordionProps {
//   play: Play
// }

// const SetupAccordion = ({ play }: ISetupAccordionProps) => {
//   return (
//     <div>
//       <AssessmentAccordion title="Setup" icon={null} open={true} onOpen={() => {}}>
//         <div className="flex flex-col gap-4">
//           <InputField
//             label="Path to Icon"
//             value={play.setup!.title}
//             onChange={(e) => play.setup!.set('title', e.target.value)}
//           />
//           <InputTextArea
//             label="Price Description"
//             value={play.setup!.pricing}
//             onChange={(e) => play.setup!.set('pricing', e.target.value)}
//           />
//           {play.setup!.steps.map((step, idx) => {
//             return (
//               <StepAccordion
//                 step={step}
//                 setup={play.setup!}
//                 index={idx}
//                 key={`step-accordion-${idx + 1}`}
//               />
//             )
//           })}
//           <div className="flex justify-end w-full">
//             <div className="grid grid-cols-2 gap-2">
//               <Button
//                 label="Add Step"
//                 className="px-2 py-1.5 flex-grow-0 w-full"
//                 variant="secondary"
//                 onClick={() => play.setup!.addStep()}
//               />
//             </div>
//           </div>
//         </div>
//       </AssessmentAccordion>
//     </div>
//   )
// }

const PageTitle = () => {
  return (
    <h2 className="text-4xl text-center font-regular text-primary-text font-primary leading-10 font-body pb-8">
      Create a New Play
    </h2>
  )
}

const JSONControls = ({ viewJSON, setViewJSON, json }) => (
  <div className="flex justify-between items-center">
    <ToggleButton
      label="View JSON"
      toggleButtonHandler={() => setViewJSON(!viewJSON)}
      isToggled={viewJSON}
    />
    {viewJSON ? (
      <div>
        <Button
          label="Copy JSON to Clipboard"
          onClick={() => copyToClipboard(json)}
          variant="secondary"
          className="px-2 w-full"
          loading={false}
        />
      </div>
    ) : null}
  </div>
)

const PlayJSONView = ({ json }) => (
  <section className="h-full flex max-h-full overflow-scroll">
    <textarea
      className="w-full p-2 h-full overflow-scroll border border-grey-darkest bg-cream-darker text-sm"
      defaultValue={json}
    />
  </section>
)

interface IStepAccordion {
  step: Step
  index: number
  setup: Setup
}

const StepAccordion = ({ setup, step, index }: IStepAccordion) => {
  return (
    <div>
      <AssessmentAccordion
        outlined={true}
        title={`Step #${index + 1}`}
        open={false}
        onOpen={() => {}}
      >
        <div className="flex flex-col gap-4">
          {/* <InputField
            label="Step Title"
            value={step.stepTitle}
            onChange={(e) => step.set('stepTitle', e.target.value)}
          />
          <InputField
            label="Path to Step Icon"
            value={step.stepIcon}
            onChange={(e) => step.set('stepIcon', e.target.value)}
          /> */}
          <div className="flex justify-end w-full">
            <div>
              <Button
                label="Delete Step"
                className="px-2 py-1.5 flex-grow-0 w-full"
                variant="secondary"
                onClick={() => setup.deleteStep(index)}
              />
            </div>
          </div>
          {/* <div className='flex justify-end w-full'>
          <div className='grid grid-cols-2 gap-2'>
              <Button
                label="Add Step"
                className="px-2 py-1.5 flex-grow-0 w-full"
                variant="secondary"
                onClick={() => play.setup!.addStep()}
              />
              <Button
                label="Delete"
                className="px-2 py-1.5 flex-grow-0 w-full"
                variant="secondary"
                onClick={() => play.deleteSetup()}
              />
            </div>
          </div> */}
        </div>
      </AssessmentAccordion>
    </div>
  )
}

const SelectFieldWithOtherOption = ({
  options,
  value,
  onChange,
  label,
  ...rest
}: IInputFieldProps) => {
  const _options = useMemo(() => [...options, { label: 'Other', value: 'Other' }], [options])
  const [showOtherInput, setShowOtherInput] = useState(false)
  const [otherValue, setOtherValue] = useState('')

  const handleChange = (value) => {
    setShowOtherInput(value === 'Other')
    if (value !== 'Other') {
      setOtherValue('')
    }
    onChange(value)
  }

  const handleSetOtherValue = useCallback(
    (e) => {
      setOtherValue(e.target.value)
      onChange(value)
    },
    [onChange, value]
  )

  return (
    <>
      <SelectField
        onChange={handleChange}
        options={_options}
        value={value}
        label={label}
        {...rest}
      />
      {showOtherInput && (
        <InputField
          label={`Specify Other ${label}`}
          onChange={handleSetOtherValue}
          value={otherValue}
        />
      )}
    </>
  )
}

export default CreatePlay
