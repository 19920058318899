import React from 'react'

function SearchIcon({ className }) {
  return (
    <svg
      className={className}
      width="26.47px"
      height="30px"
      viewBox="0 0 23 23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.7886 21.7736L16.4322 15.4172C17.8338 13.782 18.6468 11.6662 18.6468 9.34375C18.6468 4.18223 14.4633 0 9.30309 0C4.14291 0 -0.000244141 4.18357 -0.000244141 9.34375C-0.000244141 14.5039 4.18289 18.6875 9.30309 18.6875C11.6246 18.6875 13.7436 17.8358 15.3765 16.4347L21.733 22.7911C21.9127 22.9281 22.0968 23 22.281 23C22.4652 23 22.6488 22.9298 22.7891 22.7894C23.0716 22.5104 23.0716 22.0521 22.7886 21.7736ZM9.34352 17.25C4.94566 17.25 1.43726 13.7012 1.43726 9.34375C1.43726 4.98633 4.94566 1.4375 9.34352 1.4375C13.7414 1.4375 17.2498 4.9459 17.2498 9.34375C17.2498 13.7416 13.7009 17.25 9.34352 17.25Z" />
    </svg>
  )
}

export default SearchIcon
