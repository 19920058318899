import GrowingContainer from '../../../Common/GrowingContainer'
import React from 'react'
import AccountingOverview from './AccountingOverview'
import Sales from '../Sales/SalesChart'
import { getRoleFromPath, helpDesk, navigateTo } from 'utils/helper'
import { ROLES } from 'config'

export const OverView = () => {
  return (
    <GrowingContainer>
      <div className="grid col-span-12">
        <AccountingOverview />
        <h3 className="xs:text-[25px] sm:text-3xl leading-9 text-black p-7 font-primary mt-2">
          Sales
        </h3>
        {getRoleFromPath() === ROLES.BUSINESS_OWNER && (
          <p className="leading-9 text-black px-7 mb-2 font-primary">
            If you haven’t connected your Square account to our system, please visit{' '}
            <span
              className="text-primary hover:cursor-pointer"
              onClick={() => navigateTo('', 'tools/featured')}
            >
              Tools
            </span>
            .
          </p>
        )}
        <p className="leading-9 text-black px-7 mb-4 font-primary">
          Once Square is connected, you should see your data in the charts 24 hours after
          connecting. If you are still having problems, please{' '}
          <span className="text-primary hover:cursor-pointer" onClick={() => helpDesk()}>
            contact support
          </span>
          .
        </p>
        <Sales />
      </div>
    </GrowingContainer>
  )
}
