import { FORM, GOALIDS, OTHERTASKS } from 'appConfig/enums'
import marketingIcon from 'appAssets/Marketing.png'
import financeIcon from 'appAssets/Finance.png'
import salesIcon from 'appAssets/Sales.png'
import hrIcon from 'appAssets/HR.png'
import customerServiceIcon from 'appAssets/Customer-Service.png'
import { UserTaskItemStatus } from '__generated__/api-types-and-hooks'
import { BusinessGoalPriority } from '__generated__/api-types-and-hooks'

export const statusLabels = [
  { label: 'To Do', value: 'TODO' },
  { label: 'In Progress', value: 'IN_PROGRESS' },
  { label: 'Blocked', value: 'BLOCKED' },
  { label: 'Completed', value: 'DONE' },
]

export const taskPriorityStatus = {
  LOW_PRIORITY: 'Low',
  MEDIUM_PRIORITY: 'Medium',
  HIGH_PRIORITY: 'High',
}

export const userTaskItemStatusesStatus = {
  ARCHIVED: 'Archived',
  BLOCKED: 'Blocked',
  DONE: 'Completed',
  DRAFT: 'Draft',
  IN_PROGRESS: 'In Progress',
  PAUSED: 'Paused',
  TODO: 'To Do',
}

export const goalCategoryLabelsValues = [
  { label: 'Communications', value: 'COMMUNICATIONS' },
  { label: 'Customer Service', value: 'CUSTOMER_SERVICE' },
  { label: 'Finance', value: 'FINANCE' },
  { label: 'Human Resources', value: 'HR' },
  { label: 'Information Technology', value: 'INFORMATION_TECHNOLOGY' },
  { label: 'Legal', value: 'LEGAL' },
  { label: 'Marketing', value: 'MARKETING' },
  { label: 'Operations', value: 'OPERATIONS' },
  { label: 'Production', value: 'PRODUCTION' },
  { label: 'Research & Development', value: 'RESEARCH_AND_DEVELOPMENT' },
  { label: 'Sales', value: 'SALES' },
  { label: 'Strategy', value: 'STRATEGY' },
]

export const taskPriorityLabelsValues = [
  { label: 'Low', value: 'LOW_PRIORITY' },
  { label: 'Medium', value: 'MEDIUM_PRIORITY' },
  { label: 'High', value: 'HIGH_PRIORITY' },
]

export const formKeys = {
  [FORM.USER_TASK_FORM]: [
    'name',
    'estimatedEffortInMinutes',
    'order',
    'taskPriority',
    'status',
    'category',
    'instructionForTask',
    'expectedEndDate',
    'startDate',
    'planId',
    'milestoneId',
    'assigneeId',
    'howToLinks',
    'goalId',
    'goalExpectedEndDate',
  ],
}
export const goalPriorityStatus = {
  LOW_PRIORITY: 'Low Priority',
  MEDIUM_PRIORITY: 'Medium Priority',
  HIGH_PRIORITY: 'High Priority',
}
export const goalPriorityOptions = [
  { label: 'Low Priority', value: 'LOW_PRIORITY' },
  { label: 'Medium Priority', value: 'MEDIUM_PRIORITY' },
  { label: 'High Priority', value: 'HIGH_PRIORITY' },
]

export const goalTimeFrameStatus = {
  SHORT_TERM: 'Short-Term',
  Medium_TERM: 'Medium-Term',
  LONG_TERM: 'Long-Term',
}

export const FilterTooltips = {
  SHORT_TERM: 'less than 6 months',
  Medium_TERM: '6 months to  2 years',
  LONG_TERM: 'More than 2 years',
}

export const categoryType = {
  COMMUNICATIONS: 'Communications',
  CUSTOMER_SERVICE: 'Customer Service',
  FINANCE: 'Finance',
  HR: 'Human Resources',
  INFORMATION_TECHNOLOGY: 'Information Technology',
  LEGAL: 'Legal',
  MARKETING: 'Marketing',
  OPERATIONS: 'Operations',
  PRODUCTION: 'Production',
  RESEARCH_AND_DEVELOPMENT: 'Research & Development',
  SALES: 'Sales',
  STRATEGY: 'Strategy',
}
export const UserTaskItemStatusesStatus = {
  TODO: 'Not Started',
  IN_PROGRESS: 'In Progress',
  DONE: 'Completed',
  PAUSED: 'Paused',
}

export enum UserTaskItemStatusChips {
  Todo = 'TODO',
  InProgress = 'IN_PROGRESS',
  Done = 'DONE',
  Paused = 'PAUSED',
}

export const getIconByCategory = (label) => {
  switch (label) {
    case categoryType.MARKETING:
      return marketingIcon
    case categoryType.FINANCE:
      return financeIcon
    case categoryType.SALES:
      return salesIcon
    case categoryType.HR:
      return hrIcon
    case categoryType.CUSTOMER_SERVICE:
      return customerServiceIcon
    default:
      return undefined
  }
}

export const goalDropdownItems = [
  {
    status: UserTaskItemStatus.Archived,
    label: 'Archive',
    selectedLabel: 'Unarchive',
  },
  {
    status: UserTaskItemStatus.Done,
    label: 'Mark as complete',
    selectedLabel: 'Mark as incomplete',
  },
  {
    status: UserTaskItemStatus.Paused,
    label: 'Pause',
    selectedLabel: 'Unpause',
  },
]

export const otherGoalIds = [GOALIDS.MY_ALL_PLAYS, GOALIDS.MY_OTHER_TASKS]
export const myOtherTasksGoal = [
  {
    __typename: 'allPlays',
    name: OTHERTASKS.MY_ALL_PLAYS,
    goalPriority: BusinessGoalPriority.MediumPriority,
    progress: 0,
    goalId: 'all-plays',
    description:
      'View or update all plays (tasks) across all active goals.This includes “My Other Tasks” and excludes plays from paused, archived, or completed goals.',
  },
  {
    __typename: 'otherTask',
    name: OTHERTASKS.MY_OTHER_TASKS,
    goalPriority: BusinessGoalPriority.MediumPriority,
    progress: 0,
    goalId: 'other-tasks',
    description: 'View, update, or add plays (tasks) that are not tied to a specific goal.',
  },
]
