import React, { useState } from 'react'
import SelectField from 'components/Common/SelectField'

import { clientActivityIcons, filterByTimeOption, reschedule } from 'config/data'
import CardView from 'components/Common/CardView'
import Loader from 'components/Loaders'

const ClientActivity = ({ clientData, getAdvisorDashboardAction, isAdvisorDashboardLoading }) => {
  const [filterByTime, setFilterByTime] = useState()

  const getFilteredDataHandler = (value) => {
    getAdvisorDashboardAction({ filterType: value })
  }

  return (
    <div>
      <hr className="text-secondary-text mt-10 mb-7 mdl:mb-3"></hr>
      <p className="font-primary text-xl font-normal text-zinc my-3">Client Activity</p>
      <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-12 xxl:grid-cols-12 my-3 mdl:grid-cols-12 mdl:mb-10">
        <SelectField
          placeholder="Select"
          customStyle={{
            control: {
              backgroundColor: 'white',
            },
          }}
          classes={'mdl:col-span-7 xl:col-span-4 xxl:col-span-5'}
          label="Filter by Time"
          name={'filterByTime'}
          options={filterByTimeOption}
          onChange={(value) => {
            getFilteredDataHandler(value)
            setFilterByTime(value)
          }}
          value={filterByTime || filterByTimeOption[filterByTimeOption.length - 1].value}
        />
      </div>

      {isAdvisorDashboardLoading ? (
        <Loader loader="ClientStatsLoader" />
      ) : (
        <div className="grid md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-3 gap-3">
          {clientData &&
            clientData.map((client, index) => {
              const icon = clientActivityIcons.find((icon) => icon.iconLabel === client.icon)
              return (
                <CardView
                  padding="xl:px-[10px]"
                  margin=" md:mb-0 sm:mb-[10px]"
                  classes={`rounded-[15px] bg-white ${
                    client.title?.trim().toLowerCase() === reschedule.trim() ? 'hidden' : ''
                  }`}
                  key={index}
                >
                  <div className="pt-2 pl-4 xl:pl-0">
                    <div>
                      <label className="font-primary text-sm text-primary-text">
                        {client.title}
                      </label>
                    </div>
                    <span>
                      <label className="text-6xl text-black-light font-primary">
                        {client.count}
                      </label>
                    </span>
                    <span className="absolute right-2 bottom-[-1px]">
                      {icon && <icon.value className="" />}
                    </span>
                  </div>
                </CardView>
              )
            })}
        </div>
      )}
    </div>
  )
}

export default ClientActivity
