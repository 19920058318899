import React, { FC } from 'react'
import { ROLES } from 'config'
import { licenseErrorMessage } from 'components/Admin/UserManagement/config/data/index'

import ModalBox from 'components/Common/ModalBox'
import LicenseLimitReached from 'components/Admin/UserManagement/LicenseLimitReached/index'

import { navigateTo } from 'utils/helper'
import { useFlashParams } from 'hooks/useFlashParams'

interface ILicenseLimitModalBoxProps {
  isOwner: boolean
  resetAdvisorAndBusinessFormsAction: () => void
}

const LicenseLimitModalBox: FC<ILicenseLimitModalBoxProps> = ({
  isOwner,
  resetAdvisorAndBusinessFormsAction,
}) => {
  const { flashParams, removeFlashParams } = useFlashParams<{ licenseErrorMessage?: string }>()

  const handleModalBoxClose = () => {
    removeFlashParams(['licenseErrorMessage'])
    if (isOwner) {
      resetAdvisorAndBusinessFormsAction()
      navigateTo(ROLES.BSO_ADMIN, 'user-management/businesses')
    }
  }

  return flashParams?.licenseErrorMessage === licenseErrorMessage ? (
    <ModalBox rounded="3xl" margin="mx-6" onClose={handleModalBoxClose}>
      <LicenseLimitReached onClose={handleModalBoxClose} />
    </ModalBox>
  ) : (
    <></>
  )
}

export default LicenseLimitModalBox
