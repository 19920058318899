import styled from 'styled-components'
import tw from 'twin.macro'

export const AlertWrapper = styled.div`
  ${tw`border border-primary-disabled p-5 w-full rounded-xl`}
`
export const AlertSubWrapper = styled.div`
  ${tw`flex gap-6 shrink-0`}
`
export const TextWrapper = styled.p`
  ${tw`text-primary-text`}
`
