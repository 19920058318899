import React from 'react'
import history from 'utils/history'
import Button from 'components/Common/Button'
import { getRoleFromPath } from 'utils/helper'
import { ROLES } from 'config'

export enum IDialogType {
  SaveChanges,
  UpdatedIndustry,
}

const Dialog = ({
  path,
  dialogType,
  onSubmit,
  onClose,
  showModal,
  setActiveTab,
  setShowModal,
  disableButton,
  isIndustryChanged,
  setIsFormDataChanged,
  isFoodSubIndustryChanged,
}) => {
  const role = getRoleFromPath()
  const redirectToPath = () => {
    if (IDialogType.UpdatedIndustry === dialogType) {
      setShowModal(false)
      onClose()
      return
    }
    const splitLocation = path.split('/')
    setActiveTab(splitLocation[5])
    setIsFormDataChanged(false)
    history.push(`${path}`)
  }
  return (
    <div>
      <div className="flex flex-row justify-between items-center px-5 pt-8 pb-10">
        <label className="font-primary text-primary-text text-lg font-normal">
          {IDialogType.UpdatedIndustry === dialogType ? (
            role === ROLES.BUSINESS_OWNER ? (
              !isIndustryChanged && isFoodSubIndustryChanged ? (
                <>
                  You're about to change your Business Sub-Industry. When you save this change, we
                  will remove all the completed and in-progress assessments and plays from the old
                  one, and start you over with the assessments and plays for your new sub-industry.
                  <br />
                  Please be sure you want to make this change before you click save.
                </>
              ) : (
                <>
                  You’re about to change your Business Industry. When you save this change, we will
                  remove all the completed and in-progress assessments and plays from the old
                  industry, and start you over with the assessments and plays for your new industry.
                  <br />
                  Please be sure you want to make this change before you click save.
                </>
              )
            ) : !isIndustryChanged && isFoodSubIndustryChanged ? (
              <>
                You’re about to change the Business Sub-Industry. When you save this change, we will
                remove all the completed and in-progress assessments and plays for your client, and
                start them over with the assessments and plays for their new Sub-Industry.
                <br />
                Please be sure you want to make this change before you click save.
              </>
            ) : (
              <>
                You’re about to change the Business Industry. When you save this change, we will
                remove all the completed and in-progress assessments and plays for your client, and
                start them over with the assessments and plays for their new industry.
                <br />
                Please be sure you want to make this change before you click save.
              </>
            )
          ) : (
            <>Save any changes you made before leaving?</>
          )}
        </label>
      </div>
      <div className="mt-10 pb-4 px-5">
        <div className="grid grid-cols-12">
          <div className="xs:hidden sm:inline-grid sm:col-span-7"></div>
          <div className="xs:col-span-6 sm:col-span-3 pr-1">
            {/* check-button -- clean up custom styles */}
            <Button
              label="Don’t Save"
              variant="secondary"
              className="!outline-0 active:border-0 focus:border-0 w-full"
              onClick={() => redirectToPath()}
            />
          </div>
          <div className="xs:col-span-6 sm:col-span-2">
            <Button
              disabled={disableButton}
              label="Save"
              variant="primary"
              className="w-full"
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Dialog.defaultProps = {
  path: '',
  setActiveTab: '',
  isIndustryChanged: false,
  disableButton: false,
  setIsFormDataChanged: () => {},
  onClose: () => {},
  isFoodSubIndustryChanged: false,
}

export default Dialog
