import React from 'react'

function ChatNotificationIcon({ className }) {
  return (
    <svg
      className={className}
      width="26"
      height="22"
      viewBox="0 0 26 22"
      xmlns="http://www.w3.org/2000/svg"
      style={{ verticalAlign: 'middle', overflow: 'hidden' }}
    >
      <rect width="26" height="22" rx="11" fill="#F45E13" />
      <path
        d="M14.5043 6.27273V15H12.6591V8.02415H12.608L10.6094 9.27699V7.64062L12.7699 6.27273H14.5043Z"
        fill="#FAFAFA"
      />{' '}
    </svg>
  )
}

export default ChatNotificationIcon
