import { useEffect } from 'react'

/**
 * Adds drag/slide capability to an element identified by the provided selector.
 * Other CSS rules/setup are required on the element itself to complete this functionality!
 * See implementations for a full understanding of the setup.
 * */
const useDragElement = (elementSelector: string) => {
  useEffect(() => {
    const slider = document.querySelector(elementSelector)!
    let isDown = false
    let startX
    let scrollLeft

    function onMouseDown(e: any) {
      isDown = true
      slider.classList.add('active')
      // @ts-ignore
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
    }

    function onMouseLeave() {
      isDown = false
      slider.classList.remove('active')
    }

    function onMouseUp() {
      isDown = false
      slider.classList.remove('active')
    }

    function onMouseMove(e) {
      if (!isDown) return
      e.preventDefault()
      // @ts-ignore
      const x = e.pageX - slider.offsetLeft
      const walk = (x - startX) * 1 //scroll-fast
      slider.scrollLeft = scrollLeft - walk
    }

    slider.addEventListener('mousedown', onMouseDown)
    slider.addEventListener('mouseleave', onMouseLeave)
    slider.addEventListener('mouseup', onMouseUp)
    slider.addEventListener('mousemove', onMouseMove)

    return () => {
      slider.removeEventListener('mousedown', onMouseDown)
      slider.removeEventListener('mouseleave', onMouseLeave)
      slider.removeEventListener('mouseup', onMouseUp)
      slider.removeEventListener('mousemove', onMouseMove)
    }
  }, [elementSelector])
}

export default useDragElement
