import { GET_PLAYS } from 'store/types'
import { Play } from '__generated__/api-types-and-hooks'

export interface IManagePlaysState {
  plays: Play[]
  loaded: boolean
}

const initialState: IManagePlaysState = {
  plays: [],
  loaded: false,
}

function reducer(state: IManagePlaysState = initialState, action) {
  switch (action.type) {
    case GET_PLAYS.STARTED: {
      return {
        ...state,
        loaded: false,
      }
    }
    case GET_PLAYS.FULLFILLED: {
      return {
        ...state,
        plays: action?.payload?.plays || [],
        loaded: true,
      }
    }
    case GET_PLAYS.REJECTED: {
      return {
        ...state,
        loaded: false,
      }
    }
    default:
  }
  return state
}

export default reducer
