import React from 'react'
import { UPLOAD_TYPE } from 'config'
import SuccessTickIcon from 'components/Common/SvgIcons/SuccessTickIcon'

const UploadSuccessScreen = ({ selectedOption, uploadType }) => {
  return (
    <>
      <div className={`flex justify-center items-center h-full text-center  mt-6`}>
        <div className="pb-1">
          <div className="flex justify-center flex-col gap-5">
            <>
              <span className="flex justify-center">
                <SuccessTickIcon />
              </span>
              <label className="font-secondary text-4xl	font-black	text-black-light ">Success!</label>
            </>
            <label className="font-primary text-base font-bold	text-black-light flex justify-center">
              {uploadType.toLowerCase().trim() !== UPLOAD_TYPE.IMAGE ? (
                selectedOption?.name
              ) : (
                <img src={selectedOption?.url} alt="logo" className="mt-3" />
              )}
            </label>
            <label className="font-primary text-base font-medium	 text-black-light">
              Your file upload is complete. You can change this uploaded file at any time. Find it
              in your account settings.
            </label>
          </div>
        </div>
      </div>
    </>
  )
}
UploadSuccessScreen.defaultProps = {
  uploadType: 'image',
}

export default UploadSuccessScreen
