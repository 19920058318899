import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const ClientStatsLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        className="xs:hidden sm:block mdl:hidden"
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="0" y="3" width="430" height="10" />
        <rect x="0" y="165" width="430" height="10" />
        <rect x="0" y="3" width="10" height="170" />
        <rect x="430" y="3" width="10" height="170" />

        <rect x="15" y="30" width="200" height="20" />
        <rect x="15" y="100" width="250" height="30" />

        <rect x="0" y="190" width="430" height="10" />
        <rect x="0" y="350" width="430" height="10" />
        <rect x="0" y="190" width="10" height="170" />
        <rect x="430" y="190" width="10" height="170" />

        <rect x="15" y="220" width="200" height="20" />
        <rect x="15" y="285" width="250" height="30" />
      </ContentLoader>

      <ContentLoader
        speed={2}
        className="xs:hidden mdl:block lg:hidden"
        viewBox="0 0 460 100"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="0" y="3" width="200" height="6" />
        <rect x="0" y="80" width="200" height="6" />
        <rect x="0" y="3" width="6" height="80" />
        <rect x="200" y="3" width="6" height="80" />

        <rect x="15" y="18" width="80" height="10" />
        <rect x="15" y="50" width="110" height="14" />

        <rect x="220" y="3" width="200" height="6" />
        <rect x="220" y="80" width="200" height="6" />
        <rect x="220" y="3" width="6" height="80" />
        <rect x="420" y="3" width="6" height="80" />

        <rect x="235" y="18" width="80" height="10" />
        <rect x="235" y="50" width="110" height="14" />
      </ContentLoader>

      <ContentLoader
        speed={2}
        className="xs:hidden lg:block"
        viewBox="0 0 460 80"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="0" y="3" width="145" height="2" />
        <rect x="0" y="55" width="145" height="2" />
        <rect x="0" y="3" width="2" height="52" />
        <rect x="145" y="3" width="2" height="52" />

        <rect x="5" y="10" width="50" height="8" />
        <rect x="5" y="33" width="68" height="12" />

        <rect x="155" y="3" width="145" height="2" />
        <rect x="155" y="55" width="145" height="2" />
        <rect x="155" y="3" width="2" height="52" />
        <rect x="300" y="3" width="2" height="52" />

        <rect x="160" y="10" width="50" height="8" />
        <rect x="160" y="33" width="68" height="12" />

        <rect x="310" y="3" width="145" height="2" />
        <rect x="310" y="55" width="145" height="2" />
        <rect x="310" y="3" width="2" height="52" />
        <rect x="453" y="3" width="2" height="52" />

        <rect x="315" y="10" width="50" height="8" />
        <rect x="315" y="33" width="68" height="12" />
      </ContentLoader>
    </>
  )
}

export default ClientStatsLoader
