import { useEffect } from 'react'
import { isEmpty } from 'lodash'

export function useOutsideClick(ref, onClickOutside, activeStep) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (isEmpty(activeStep)) onClickOutside()
      }
    }
    // Bind the event listener

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClickOutside, activeStep])
}
