import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { DateRange } from 'react-date-range'
import { Popover } from 'react-tiny-popover'
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
export { useDatePickerHandler } from 'components/Common/DatePicker/useDatePickerHandler'

const enableCalendar = false

const calendarConfig = [
  {
    title: 'This week',
    onClick: (handleClick) =>
      handleClick(
        moment().startOf('week').format('YYYY-MM-DD'),
        moment().endOf('week').format('YYYY-MM-DD'),
        'week'
      ),
    disabled: false,
  },
  {
    title: 'Last week',
    onClick: (handleClick) =>
      handleClick(
        moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
        moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'),
        'week'
      ),
    disabled: false,
  },
  {
    title: 'This month',
    onClick: (handleClick) =>
      handleClick(
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD'),
        'month'
      ),
    disabled: false,
  },
  {
    title: 'Last month',
    onClick: (handleClick) =>
      handleClick(
        moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
        'month'
      ),
    disabled: false,
  },
  {
    title: 'This year',
    onClick: (handleClick) =>
      handleClick(
        moment().startOf('year').format('YYYY-MM-DD'),
        moment().endOf('year').format('YYYY-MM-DD'),
        'year'
      ),
    disabled: false,
  },
  {
    title: 'Last year',
    onClick: (handleClick) =>
      handleClick(
        moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD'),
        moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD'),
        'year'
      ),
    disabled: false,
  },
]

const Calendar = ({ handleClick }) => {
  const [state, setState] = useState<any>([
    {
      startDate: '',
      endDate: '',
      key: 'selection',
    },
  ])
  useEffect(() => {
    if (state[0].startDate && state[0].endDate && enableCalendar) {
      const startDate = moment(state[0].startDate).format('YYYY-MM-DD')
      const endDate = moment(state[0].endDate).format('YYYY-MM-DD')
      const type = 'day'
      handleClick(startDate, endDate, type)
    }
  }, [state, handleClick])
  return (
    <div className="bg-white flex  gap-2 p-2 relative">
      {enableCalendar && (
        <div className="pointer-events-none cursor-not-allowed font-primary">
          <DateRange
            editableDateInputs={enableCalendar}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
        </div>
      )}
      <div className="flex flex-col gap-3 font-primary">
        {calendarConfig.map((config, idx) => (
          <button
            key={`calendar-config-key-${idx + 1}`}
            className={`bg-grey-darker  text-black py-2  px-4 rounded ${
              config.disabled ? 'cursor-not-allowed' : ''
            }`}
            disabled={config.disabled}
            onClick={() => config.onClick(handleClick)}
          >
            {config.title}
          </button>
        ))}
      </div>
    </div>
  )
}

const DatePicker = ({
  value,
  isNextPrevAllowed,
  handleClick,
  onNextDate,
  onPreviousDate,
  showFullCalendar = true,
  setDatePickerOpened = (datePickerOpened?) => {},
  datePickerOpened = false,
}) => {
  const [isOpen, setOpen] = useState(datePickerOpened)
  const classNextPrev = !isNextPrevAllowed ? 'pointer-events-none cursor-not-allowed' : ''

  return (
    <Popover
      isOpen={isOpen}
      reposition={true}
      align="end"
      positions={['bottom']}
      content={<Calendar handleClick={handleClick} />}
    >
      <div className="border border-[#D4D4D8] bg-white rounded  gap-2 inline-flex h-[40px] items-center w-full text-base basis-[content] font-primary">
        <ChevronLeftIcon
          data-testid="left-icon"
          className={`${classNextPrev} text-primary cursor-pointer w-[40px] h-[40px]`}
          onClick={onPreviousDate}
        />
        {showFullCalendar ? (
          <div
            data-testid="down-icon"
            className="gap-3  px-2  flex items-center  justify-center border-x border-[#D4D4D8]"
            onClick={() => {
              setDatePickerOpened(!datePickerOpened)
              setOpen(!isOpen)
            }}
          >
            {value} <ChevronDownIcon className="text-primary  cursor-pointer w-[40px] h-[40px]  " />
          </div>
        ) : (
          <>{value}</>
        )}
        <ChevronRightIcon
          data-testid="right-icon"
          className={`${classNextPrev} text-primary cursor-pointer w-[40px] h-[40px]`}
          onClick={onNextDate}
        />
      </div>
    </Popover>
  )
}

DatePicker.defaultProps = {
  isNextPrevAllowed: true,
}

export default DatePicker
