import React, { Suspense, lazy } from 'react'
import { Redirect, Switch } from 'react-router-dom'

import PrivateRoute from 'routes/PrivateRoute'
import UserRoute from 'routes/UserRoute'
import map from 'lodash/map'
import { lazyRetry } from 'utils/lazy-retry'

const AuthRoutes = lazy(() => lazyRetry(() => import('pages/Auth')))
const AdminRoutes = lazy(() => lazyRetry(() => import('pages/Admin')))
const OwnerRoutes = lazy(() => lazyRetry(() => import('pages/Owner')))
const AdvisorRoutes = lazy(() => lazyRetry(() => import('pages/Advisor')))
const WelcomeRoutes = lazy(() => lazyRetry(() => import('pages/Welcome')))
const AssessmentRoutes = lazy(() => lazyRetry(() => import('pages/Assessment')))
const PrivacyAndPolicyRoutes = lazy(() => lazyRetry(() => import('pages/PrivacyAndPolicy')))
const TermsAndConditionsRoute = lazy(() => lazyRetry(() => import('pages/TermsAndConditions')))
const V2Routes = lazy(() => lazyRetry(() => import('V2/pages/index')))
const V2AdvisorRoutes = lazy(() => lazyRetry(() => import('V2/pages/V2-advisor')))
const V2AdminRoutes = lazy(() => lazyRetry(() => import('V2/pages/V2-admin')))

const RoutesHOC = (routes: any, DEFAULT_PATH: any) => {
  return function Component() {
    return (
      <Suspense fallback={<div></div>}>
        <Switch>
          {map(routes, (route) => {
            if (route.isPrivate) {
              return (
                <PrivateRoute
                  key={route.title}
                  title={route.title}
                  path={route.path}
                  component={route.component}
                  defaultPath={DEFAULT_PATH}
                  isV2={route.isV2}
                />
              )
            }
            return (
              <UserRoute
                key={route.title}
                title={route.title}
                defaultPath={DEFAULT_PATH}
                path={route.path}
                component={route.component}
              />
            )
          })}
          <Redirect to={DEFAULT_PATH} />
        </Switch>
      </Suspense>
    )
  }
}

export const routes = {
  AUTH: {
    path: '/auth',
    title: 'Login',
    component: AuthRoutes,
    isPrivate: false,
  },
  PRIVACY: {
    path: '/privacy-policy',
    title: 'Privacy Policy',
    component: PrivacyAndPolicyRoutes,
    isPrivate: false,
  },
  TERMS: {
    path: '/terms-and-conditions',
    title: 'Terms and Conditions',
    component: TermsAndConditionsRoute,
    isPrivate: false,
  },
  ADMIN: {
    path: '/admin',
    title: 'Admin',
    component: AdminRoutes,
    isPrivate: true,
    isV2: false,
  },
  V2: {
    path: '/owner-v2',
    title: 'Owner',
    component: V2Routes,
    isPrivate: true,
    isV2: true,
  },
  BUSINESS_OWNER: {
    path: '/owner',
    title: 'Owner',
    component: OwnerRoutes,
    isPrivate: true,
    isV2: false,
  },
  BSO_ADVISOR: {
    path: '/advisor',
    title: 'Advisor',
    component: AdvisorRoutes,
    isPrivate: true,
    isV2: false,
  },
  V2_ADVISOR: {
    path: '/advisor-v2',
    title: 'Advisor',
    component: V2AdvisorRoutes,
    isPrivate: true,
    isV2: true,
  },
  V2_ADMIN: {
    path: '/admin-v2',
    title: 'Admin',
    component: V2AdminRoutes,
    isPrivate: true,
    isV2: true,
  },
  WELCOME: {
    path: '/welcome',
    title: 'Welcome',
    component: WelcomeRoutes,
    isPrivate: true,
    isV2: false,
  },
  ASSESSMENT: {
    path: '/assessment',
    title: 'Assessment',
    component: AssessmentRoutes,
    isPrivate: true,
    isV2: false,
  },
}

const AppRoutes = RoutesHOC(routes, '/auth')
export default AppRoutes

export const DEFAULT_PATH = '/auth'
export const USER_LANDING_PAGE = '/'
