import React, { useEffect, useState } from 'react'
import map from 'lodash/map'
import get from 'lodash/get'
import { useConnectUser } from 'components/Chat/hooks/useConnectUser'
import FilesCard from 'components/Common/FilesCard'
import { StreamChatGenerics } from 'components/Chat/types'
import { APP_STREAM_KEY } from 'config'

const StreamChat = ({ userId, streamChatId, file }) => {
  const [attachments, setAttachments] = useState<any>([])
  const chatUser = { id: userId }
  const chatClient: any = useConnectUser<StreamChatGenerics>(
    APP_STREAM_KEY,
    chatUser,
    streamChatId,
    false
  )

  useEffect(() => {
    try {
      if (chatClient && chatClient.getMessage && file.streamMessageId)
        chatClient.getMessage(file.streamMessageId).then((resp) => {
          const attachments = map(resp.message.attachments, (attachment) => {
            return {
              name: get(attachment, 'fallback', get(attachment, 'title', '')),
              type: '',
              size: '',
              createdAt: resp.message.created_at,
            }
          })
          setAttachments(attachments)
        })
    } catch (e) {
      console.log({ e })
    }
  }, [chatClient, file])

  return (
    <>
      {map(attachments, (attachment: any, index) => (
        <FilesCard key={index} file={attachment} />
      ))}
    </>
  )
}

export default StreamChat
