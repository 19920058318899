import React, { FC } from 'react'
import InputField from 'components/Common/InputField'
import { checkError, getErrorMessage } from 'utils/helper'

const InputFieldComponent: FC<any> = ({
  mask,
  label,
  regex,
  isSubmitted,
  isCompletedAssessment,
  selectedOption,
  handleChange,
  errorMessage,
  placeholder,
}) => {
  return (
    <div className="sm:max-w-[510px] mt-6">
      <InputField
        mask={mask}
        placeholder={mask || placeholder}
        label={label}
        type="text"
        name="question"
        disabled={isCompletedAssessment}
        className="text-left"
        onChange={(e) => handleChange(e.target.value)}
        value={selectedOption ? selectedOption : ''}
        errors={
          checkError(regex, selectedOption, isSubmitted)
            ? { message: errorMessage ? errorMessage : getErrorMessage(label) }
            : ''
        }
      />
    </div>
  )
}

export default InputFieldComponent
