import React from 'react'

export default function CurvedRightTurnIcon({ className = '' }) {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 11.5C0 7.36 3.36 4 7.5 4H10V0L17 6L10 12V8H7.5C5.57 8 4 9.57 4 11.5V19H0V11.5Z"
        fill="black"
      />
    </svg>
  )
}
