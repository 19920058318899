import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'

import Button from 'components/Common/Button'
import CheckBoxField from 'components/Common/CheckBoxField'
import useFormContainer from 'hooks/useFormContainer'

import { FORM } from 'config'
import {
  TextWrapper,
  FormWrapper,
  ButtonWrapper,
  HeadingWrapper,
  CheckBoxWrapper,
  AdminAccessWrapper,
  CheckBoxTextWrapper,
} from 'components/Admin/AdvisorStepper/style'
import { replaceTerm } from 'utils/helper'
import { RootState } from 'App'

export default function AdminAccess({ handleOnSubmits }) {
  const methods = useForm({
    mode: 'onBlur',
  })

  const { handleSubmit, control } = methods

  useFormContainer(FORM.ADMIN_ACCESS, methods)

  const bsoProfile = useSelector((state: RootState) => state.bso.bsoProfile)
  const advisorTerm = replaceTerm('advisor', bsoProfile?.terminology)
  const advisorTermPlural = replaceTerm('advisor', bsoProfile?.terminology, { pluralized: true })

  return (
    <AdminAccessWrapper>
      <HeadingWrapper> Admin Access</HeadingWrapper>
      <form onSubmit={handleSubmit(handleOnSubmits)}>
        <FormWrapper>
          <TextWrapper>
            Granting a user admin access will allow them to see all Business Owner and{' '}
            {`${advisorTerm} `}
            accounts and to create accounts for both {`${advisorTermPlural}`} and Business Owners.{' '}
            {`${advisorTermPlural}`} without admin access can only see the account of the Business
            Owners assigned to them.
          </TextWrapper>
          <CheckBoxWrapper>
            <Controller
              defaultValue={false}
              name="adminAccess"
              control={control}
              render={({ field }) => <CheckBoxField {...field} id="adminAccess" />}
            />

            <CheckBoxTextWrapper htmlFor="flexCheckDefault">
              Give this account admin access
            </CheckBoxTextWrapper>
          </CheckBoxWrapper>
          <ButtonWrapper>
            <Button
              type="submit"
              label="CONTINUE"
              testId="submit"
              variant="primary"
              className="w-full"
            />
          </ButtonWrapper>
        </FormWrapper>
      </form>
    </AdminAccessWrapper>
  )
}
