import React, { Fragment, useEffect, useMemo, useState } from 'react'

import { AdvisorCrossIcon, AdvisorPlusIcon, AdvisorTickIcon } from 'components/Common/SvgIcons'

const OwnerAssignment = ({
  filteredAssignedOwners,
  allFilteredOwners,
  isAssignedOwner,
  assignedOwners,
  removeOwner,
  addOwner,
  owner,
  index,
}) => {
  const [display, setDisplay] = useState(false)

  const [isNew, setIsNew] = useState(false)

  useEffect(() => {
    if (owner?.isNew) {
      setIsNew(true)
      setDisplay(true)
      setTimeout(() => {
        setIsNew(false)
        delete owner.isNew
      }, 3000)
    } else {
      setDisplay(true)
    }
  }, [filteredAssignedOwners, owner.isNew])

  const isOwnerAssigned = useMemo(() => {
    return !isAssignedOwner && assignedOwners?.find((selected) => selected.value === owner.value)
  }, [assignedOwners, isAssignedOwner, owner.value])

  return (
    <Fragment key={index}>
      {display && (
        <div className="flex justify-between pr-3">
          <div
            className={` rounded-[53px] px-5 py-[3px] mb-[6px] flex items-center  
          ${
            isAssignedOwner &&
            filteredAssignedOwners?.find((selected) => selected.value === owner.value)
              ? `${isNew ? 'bg-sky-light !border-0' : ' bg-primary/[0.5] !border-0'} `
              : ''
          }
          ${isOwnerAssigned ? 'bg-tertiary-hover' : 'bg-sky'}
          `}
          >
            <div className="w-full font-primary">
              <div className="flex flex-col">
                <label className={` text-black-light text-base font-semibold break-all `}>
                  {owner.label},
                </label>
                <label className={`text-black-light text-base font-semibold break-all `}>
                  {owner.email}
                </label>
              </div>
            </div>
            {isAssignedOwner && (
              <span
                className={` inline-flex pl-1 cursor-pointer`}
                key={index}
                onClick={() => removeOwner(owner)}
              >
                {filteredAssignedOwners?.find((selected) => selected.value === owner.value) ? (
                  <AdvisorCrossIcon />
                ) : (
                  <AdvisorPlusIcon />
                )}
              </span>
            )}
            {!isAssignedOwner && (
              <span
                className={` inline-flex pl-1`}
                key={index}
                onClick={() =>
                  addOwner(allFilteredOwners?.find((user: any) => user.value === owner.value))
                }
              >
                {assignedOwners?.find((selected) => selected.value === owner.value) ? (
                  <AdvisorTickIcon />
                ) : (
                  <span className="cursor-pointer">
                    <AdvisorPlusIcon />
                  </span>
                )}
              </span>
            )}
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default OwnerAssignment
