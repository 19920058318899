import React, { useEffect } from 'react'

import { useForm } from 'react-hook-form'
import { FIELD_NAME, FORM, LABEL, industry } from 'config'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller } from 'react-hook-form'

import {
  BusinessProfileWrapper,
  TopHrWrapper,
  LabelWrapper,
} from 'components/Admin/UserManagement/style'
import InputField from 'components/Common/InputField'
import Loader from 'components/Loaders'

import useFormContainer from 'hooks/useFormContainer'
import { useCheckInitialAssessmentFormValid } from 'hooks/useCheckInitialAssessmentFormValid'

import hookForms from 'utils/hookForms'
import { businessSchema } from 'pages/Owner/validation'
import { SelectFieldWrapper } from 'components/Admin/BusinessOwnerStepper/style'
import SelectField from 'components/Common/SelectField'
import useRegisterIndustryContainer from 'hooks/useRegisterIndustryContainer'

const EditBusinessProfile = ({
  getBusinessAndAssessmentResponseAction,
  isBusinessProfileFormSet,
  setIsFormValid,
  onSubmit,
  setIndustryChanged,
  userId,
  businessClassificationOptionId,
  isUserManagement,
  options,
}) => {
  const formValues = hookForms.getForm(FORM.USER_MANAGEMENT_FORM)?.getValues()

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(businessSchema),
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { isDirty },
    setValue,
    unregister,
  } = methods

  useEffect(() => {
    getBusinessAndAssessmentResponseAction({
      setForms: [FORM.USER_MANAGEMENT_FORM],
      userId,
      type: 'initial',
    })
  }, [getBusinessAndAssessmentResponseAction, userId])

  useFormContainer(FORM.USER_MANAGEMENT_FORM, methods)

  useCheckInitialAssessmentFormValid(
    isDirty,
    formValues,
    setIsFormValid,
    setIndustryChanged,
    businessClassificationOptionId
  )
  useRegisterIndustryContainer(formValues, unregister, isDirty, setValue, !isUserManagement)

  return (
    <>
      {!isBusinessProfileFormSet ? (
        <Loader loader="EditBusinessProfile" />
      ) : (
        <BusinessProfileWrapper>
          <TopHrWrapper />
          <LabelWrapper>Business Profile</LabelWrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pt-6">
              <InputField
                type={'text'}
                label={LABEL.BUSINESS_NAME}
                placeholder={`Enter ${LABEL.BUSINESS_NAME}`}
                {...register(FIELD_NAME.BUSINESS_NAME)}
              />
            </div>
            <div className="grid grid-cols-12 gap-3">
              <SelectFieldWrapper applyStyling={isUserManagement}>
                <Controller
                  name={FIELD_NAME.BUSINESS_CLASSIFICATION_OPTION_ID}
                  control={control}
                  render={({ field }) => (
                    <SelectField
                      placeholder={`Select ${LABEL.INDUSTRY}`}
                      {...field}
                      classes={'mt-4'}
                      label={LABEL.INDUSTRY}
                      options={options[FIELD_NAME.BUSINESS_CLASSIFICATION]}
                    />
                  )}
                />
              </SelectFieldWrapper>
              {formValues?.businessClassificationOptionId?.trim() === industry.foodId && (
                <SelectFieldWrapper applyStyling={isUserManagement}>
                  <Controller
                    name={FIELD_NAME.FOOD_SUB_INDUSTRY_OPTION_IDS}
                    control={control}
                    render={({ field }) => (
                      <SelectField
                        placeholder="Select Food Industry Type"
                        isMulti={true}
                        {...field}
                        classes="mt-4"
                        label={LABEL.FOOD_INDUSTRY}
                        options={options[FIELD_NAME.FOOD_SUB_INDUSTRY]}
                      />
                    )}
                  />
                </SelectFieldWrapper>
              )}

              {formValues?.businessClassificationOptionId?.trim() === industry.servicesId && (
                <SelectFieldWrapper applyStyling={isUserManagement}>
                  <Controller
                    name={FIELD_NAME.SERVICES_SUB_INDUSTRY_OPTION_ID}
                    control={control}
                    render={({ field }) => (
                      <SelectField
                        placeholder="Select Services Industry Type"
                        isMulti={false}
                        {...field}
                        classes="mt-4"
                        label={LABEL.SERVICES_INDUSTRY}
                        options={options[FIELD_NAME.SERVICES_SUB_INDUSTRY]}
                      />
                    )}
                  />
                </SelectFieldWrapper>
              )}
            </div>
          </form>
        </BusinessProfileWrapper>
      )}
    </>
  )
}
EditBusinessProfile.defaultProps = {
  EditBusinessProfile: true,
}
export default EditBusinessProfile
