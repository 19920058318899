import React from 'react'

function FilterIcon({ className }) {
  return (
    <svg
      className={className}
      width="30"
      height="24"
      viewBox="0 0 30 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0078 0.75C19.8984 0.75 20.625 1.4748 20.625 2.36895C20.625 2.76094 20.4844 3.14063 20.2266 3.43359L14.0625 10.4766V21.8203C14.0625 22.6113 13.4238 23.25 12.6328 23.25C12.3223 23.25 12.0234 23.1504 11.7715 22.9629L7.30664 19.5938C6.83789 19.2363 6.5625 18.6855 6.5625 18.0938V10.4766L0.400547 3.43359C0.142324 3.14063 0 2.76094 0 2.36895C0 1.4748 0.724805 0.75 1.61895 0.75H19.0078ZM8.4375 10.125V18.0938L12.1875 20.9238V10.0723C12.1875 9.89648 12.2695 9.67969 12.3691 9.50977L18.4395 2.57227H2.1832L8.20312 9.50977C8.35547 9.67969 8.38477 9.89648 8.38477 10.0723L8.4375 10.125ZM29.0625 20.4375C29.5781 20.4375 30 20.8594 30 21.375C30 21.8906 29.5781 22.3125 29.0625 22.3125H19.6875C19.1719 22.3125 18.75 21.8906 18.75 21.375C18.75 20.8594 19.1719 20.4375 19.6875 20.4375H29.0625ZM18.75 12C18.75 11.4844 19.1719 11.0625 19.6875 11.0625H29.0625C29.5781 11.0625 30 11.4844 30 12C30 12.5156 29.5781 12.9375 29.0625 12.9375H19.6875C19.1719 12.9375 18.75 12.5156 18.75 12ZM29.0625 1.6875C29.5781 1.6875 30 2.10703 30 2.625C30 3.14062 29.5781 3.5625 29.0625 3.5625H23.4375C22.9219 3.5625 22.5 3.14062 22.5 2.625C22.5 2.10703 22.9219 1.6875 23.4375 1.6875H29.0625Z"
        fill="#71717A"
      />
    </svg>
  )
}

export default FilterIcon
