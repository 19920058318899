import React, { useEffect, useState } from 'react'
import { CheckBoxWrapper } from 'components/Common/CheckBox/style'

const CheckBox = ({ option, options, setOptions, moveToNext, disable = false }) => {
  const [checked, setChecked] = useState(false)
  const toggleCheckedHandler = () => setChecked(!checked)

  const checkboxSelectedOptionHandler = ({ selectedOption_id, checked }) => {
    if (checked) {
      // add selected option
      let selected_options = [...options, selectedOption_id]
      setOptions(selected_options)
      moveToNext(selected_options)
    } else {
      // delete unselected option
      let updatedOptions = options?.filter((option) => option !== selectedOption_id)
      setOptions(updatedOptions)
      moveToNext(updatedOptions)
    }
  }

  // mark checked (checkbox) (selected options)
  useEffect(() => {
    if (options && options.length > 0) {
      options?.forEach((option_id) => {
        if (option_id === option?.id) {
          setChecked(true)
        }
      })
    }
  }, [option?.id, options])

  return (
    <CheckBoxWrapper>
      <div className="flex items-start">
        <input
          disabled={disable}
          type="checkbox"
          value={option.id}
          className={`rounded ${
            disable
              ? 'bg-primary-disabled hover:!cursor-not-allowed before:!accent-primary-disabledText before:!border-primary-disabledText'
              : 'border border-1 border-primary bg-cream-dark hover:border-primary-brand focus:ring focus:ring-primary-brandLight focus:outline-none focus:bg-white hover:bg-white'
          } 
          `}
          onChange={() =>
            checkboxSelectedOptionHandler({ selectedOption_id: option.id, checked: !checked })
          }
          onClick={toggleCheckedHandler}
          checked={checked}
        />
        <label className="ml-2 overflow-hidden  mt-[2px]">
          <span className="text-black-light font-primary font-medium text-base">
            {option?.value}
            <div className="text-xs text-zinc font-normal"> {option?.meta?.info}</div>
          </span>
        </label>
      </div>
    </CheckBoxWrapper>
  )
}

export default CheckBox
