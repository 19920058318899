import styled from 'styled-components'
import tw from 'twin.macro'

export const TextWrapper = styled.h2`
  ${tw`text-left text-base leading-6 font-medium font-primary mb-6`}
`

export const ListItemWrapper = styled.li`
  ${tw`cursor-pointer mb-8 border border-primary-outline rounded-[20px] flex shrink-0 bg-white`}
`

export const RecommendationsWrapper = styled.div`
  ${tw`flex items-center justify-center`}
`

export const RecommendationsWidthWrapper = styled.div`
  ${tw`sm:w-[550px] md:w-[850px] lg:w-[850px]`}
`

export const LoaderWrapper = styled.div`
  ${tw`xs:w-[310px] sm:w-[510px]`}
`
