import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

export const PlaysRecommendationLoader = () => {
  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 1000 390"
      width={1000}
      height={390}
      backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
      foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
    >
      <rect x="0" y="1" rx="1" ry="0" width="1" height="300" />
      <rect x="0" y="300" rx="1" ry="0" width="212" height="1" />
      <rect x="0" y="1" rx="1" ry="0" width="212" height="1" />
      <rect x="212" y="1" rx="1" ry="0" width="1" height="300" />

      <rect x="40" y="40" rx="2" ry="2" width="100" height="10" />
      <rect x="40" y="70" rx="2" ry="2" width="150" height="10" />
      <rect x="40" y="100" rx="2" ry="2" width="150" height="10" />
      <rect x="40" y="190" rx="2" ry="2" width="150" height="10" />

      <rect x="230" y="1" rx="1" ry="0" width="1" height="300" />
      <rect x="230" y="300" rx="1" ry="0" width="212" height="1" />
      <rect x="230" y="1" rx="1" ry="0" width="212" height="1" />
      <rect x="230" y="1" rx="1" ry="0" width="1" height="300" />

      <rect x="270" y="40" rx="2" ry="2" width="100" height="10" />
      <rect x="270" y="70" rx="2" ry="2" width="150" height="10" />
      <rect x="270" y="100" rx="2" ry="2" width="150" height="10" />
      <rect x="270" y="190" rx="2" ry="2" width="150" height="10" />
    </ContentLoader>
  )
}
