import React from 'react'

function NotePadIcon({ className }) {
  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#5E06B9" fill-opacity="0.5" />
      <g clipPath="url(#clip0_8256_7861)">
        <path
          d="M28.9752 14.1182L23.7347 8.91797C23.1393 8.3292 22.3421 8 21.5056 8L14.1492 8C12.4098 8 11 9.39893 11 11.125L11.0003 29.875C11.0003 31.6011 12.4101 33 14.1495 33H26.7461C28.4856 33 29.8953 31.6011 29.8953 29.875V16.3252C29.8953 15.4951 29.5657 14.7041 28.9752 14.1182ZM22.0223 9.66407C22.2426 9.74053 22.4487 9.85186 22.6187 10.0205L27.8592 15.2207C28.0304 15.3877 28.1436 15.5928 28.2174 15.8125H22.8096C22.3766 15.8125 22.0223 15.4609 22.0223 15.0313V9.66407ZM28.3207 29.875C28.3207 30.7363 27.6141 31.4375 26.7461 31.4375H14.1492C13.2812 31.4375 12.5746 30.7363 12.5746 29.875V11.125C12.5746 10.2637 13.2812 9.5625 14.1492 9.5625H20.4477V15.0313C20.4477 16.3252 21.5056 17.375 22.8096 17.375H28.3207V29.875ZM15.7238 21.2813C15.7238 21.7109 16.0781 22.0625 16.5111 22.0625H24.3842C24.8172 22.0625 25.1715 21.7109 25.1715 21.2813C25.1715 20.8516 24.8172 20.5 24.3842 20.5H16.5111C16.0781 20.5 15.7238 20.8516 15.7238 21.2813ZM24.3842 23.625H16.5111C16.0781 23.625 15.7238 23.9766 15.7238 24.4063C15.7238 24.8359 16.0781 25.1875 16.5111 25.1875H24.3842C24.8191 25.1875 25.1715 24.8377 25.1715 24.4063C25.1715 23.9748 24.8172 23.625 24.3842 23.625ZM24.3842 26.75H16.5111C16.0781 26.75 15.7238 27.1016 15.7238 27.5313C15.7238 27.9609 16.0781 28.3125 16.5111 28.3125H24.3842C24.8191 28.3125 25.1715 27.9627 25.1715 27.5313C25.1715 27.0998 24.8172 26.75 24.3842 26.75Z"
          fill="#27272A"
        />
      </g>
      <defs>
        <clipPath id="clip0_8256_7861">
          <rect width="18.8953" height="25" fill="white" transform="translate(11 8)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NotePadIcon
