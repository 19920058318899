import React from 'react'
import { AppFC } from 'types'
import PillTextBox, { PillColorTheme } from 'components/Common/PillTextBox'

export interface IAppPillTextBoxList {
  items: Array<string>
}

export const AppPillTextBoxList: AppFC<IAppPillTextBoxList> = ({ items }) => (
  <>
    {items?.map((item, index) => (
      <div className="w-fit" key={index}>
        <PillTextBox
          className="text-start"
          colorTheme={PillColorTheme.SKY}
          size="large"
          textSize="normal"
        >
          {item}
        </PillTextBox>
      </div>
    ))}
  </>
)
