import { RootState } from 'App'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { updateUserProfileAction } from 'store/actions/user'

const userTimeZoneProps = (state: RootState) => {
  return {
    user: state.user?.user,
  }
}

function userTimeZoneDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateUserProfileAction: updateUserProfileAction.STARTED,
    },
    dispatch
  )
}
export const UserTimeZoneConnector = connect(userTimeZoneProps, userTimeZoneDispatch)
