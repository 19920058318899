import React, { FC, useState } from 'react'
import DeprecatedButton from 'components/Common/DeprecatedButton'
import { CalendarEventEditModal } from 'components/Calendar/Meetings/CalendarEventEditModal'
import { UserProfile } from '__generated__/api-types-and-hooks'
import { extendedCalendarEvents } from 'types'

interface IMeetingEventsProps {
  user: UserProfile
  data: extendedCalendarEvents
  stepType: string
  isDisabled: boolean
  errorMessage: string
  isMeetingDeletion: boolean
  cancelMeetingLoading: boolean
  cancelTackleMeetingEventAction: (param) => void
  updateUserCalendarEventAction: (param) => void
  openMeetingConfirmationModalBoxAction: (input: { error: string; stepType: string }) => void
  getTackleMeetingEventsAction: (param) => void
}
const MeetingEvents: FC<IMeetingEventsProps> = ({
  user,
  data,
  stepType,
  isDisabled,
  errorMessage,
  isMeetingDeletion,
  cancelMeetingLoading,
  cancelTackleMeetingEventAction,
  updateUserCalendarEventAction,
  openMeetingConfirmationModalBoxAction,
  getTackleMeetingEventsAction,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [showEditButton, setShowEditButton] = useState(true)
  const modalHandler = () => setOpenModal(!openModal)

  return (
    <>
      <td className="pt-[15px] pb-3 md:pl-3 md:pr-[23px] text-sm text-grey whitespace-nowrap  text-right col-span-4 self-center">
        <DeprecatedButton
          name={'View Calendar Event'}
          variant="secondary"
          className="text-primary font-primary font-semibold	text-lg"
          onClick={() => {
            setOpenModal(true)
          }}
        />
      </td>
      {openModal === true ? (
        <CalendarEventEditModal
          user={user}
          meetingType="upcoming"
          closeModal={modalHandler}
          cancelUserCalendarEventAction={cancelTackleMeetingEventAction}
          event={data}
          updateMeetingAction={updateUserCalendarEventAction}
          showEditButton={showEditButton}
          setShowEditButton={setShowEditButton}
          errorMessage={errorMessage}
          isMeetingDeletion={isMeetingDeletion}
          getTackleMeetingEventsAction={getTackleMeetingEventsAction}
          stepType={stepType}
          openMeetingConfirmationModalBoxAction={openMeetingConfirmationModalBoxAction}
          isDisabled={isDisabled || cancelMeetingLoading}
        />
      ) : null}
    </>
  )
}

export default MeetingEvents
