import React from 'react'

export default function PadLockIcon({ className = '' }) {
  return (
    <svg
      className={className}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="33" height="33" rx="16.5" fill="#D4D4D8" />
      <path
        d="M16.5039 19.6667C16.8686 19.6667 17.2183 19.5262 17.4762 19.2761C17.734 19.0261 17.8789 18.687 17.8789 18.3333C17.8789 17.5933 17.2602 17 16.5039 17C16.1392 17 15.7895 17.1405 15.5316 17.3905C15.2738 17.6406 15.1289 17.9797 15.1289 18.3333C15.1289 18.687 15.2738 19.0261 15.5316 19.2761C15.7895 19.5262 16.1392 19.6667 16.5039 19.6667ZM20.6289 13.6667C20.9936 13.6667 21.3433 13.8071 21.6012 14.0572C21.859 14.3072 22.0039 14.6464 22.0039 15V21.6667C22.0039 22.0203 21.859 22.3594 21.6012 22.6095C21.3433 22.8595 20.9936 23 20.6289 23H12.3789C12.0142 23 11.6645 22.8595 11.4066 22.6095C11.1488 22.3594 11.0039 22.0203 11.0039 21.6667V15C11.0039 14.26 11.6227 13.6667 12.3789 13.6667H13.0664V12.3333C13.0664 11.4493 13.4286 10.6014 14.0732 9.97631C14.7179 9.35119 15.5922 9 16.5039 9C16.9553 9 17.4023 9.08622 17.8194 9.25373C18.2364 9.42125 18.6154 9.66678 18.9346 9.97631C19.2538 10.2858 19.507 10.6533 19.6797 11.0577C19.8525 11.4621 19.9414 11.8956 19.9414 12.3333V13.6667H20.6289ZM16.5039 10.3333C15.9569 10.3333 15.4323 10.544 15.0455 10.9191C14.6587 11.2942 14.4414 11.8029 14.4414 12.3333V13.6667H18.5664V12.3333C18.5664 11.8029 18.3491 11.2942 17.9623 10.9191C17.5755 10.544 17.0509 10.3333 16.5039 10.3333Z"
        fill="black"
      />
    </svg>
  )
}
