import { Dispatch, SetStateAction, createContext, useContext } from 'react'

interface FlashParamsContextType<T> {
  flashParams: T
  setFlashParams: Dispatch<SetStateAction<T>>
}

export const FlashParamsContext = createContext<FlashParamsContextType<any>>({
  flashParams: {},
  setFlashParams: () => {},
})

export const useFlashParams = <T>() => {
  const { flashParams, setFlashParams } = useContext<FlashParamsContextType<T>>(FlashParamsContext)
  const removeFlashParams = (keys?: string[]) => {
    const newFlashParams = { ...flashParams }
    if (!keys?.length) {
      setFlashParams({} as T)
      return
    }
    keys?.forEach((key) => {
      if (newFlashParams[key]) {
        newFlashParams[key] = undefined
      }
    })
    setFlashParams(newFlashParams)
  }
  return { flashParams, removeFlashParams }
}
