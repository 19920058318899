import React, { Fragment } from 'react'
import { isEmpty } from 'lodash'
import { AppFC } from 'types'
import Info from 'pages/DataReports/Info'
import Button from 'components/Common/Button'
import useNavigateTo from 'hooks/useNavigateTo'
import { EllipseIcon } from 'components/Common/SvgIcons'
import PdfIcon from 'components/Common/SvgIcons/PdfIcon'
import {
  DateWrapper,
  NoReportText,
  ButtonWrapper,
  EllipseWrapper,
  ReportHistoryWrapper,
  ReportHistoryHeading,
  NoReportHistoryHeading,
  ReportHistoryHeadingWrapper,
  ReportHistoryGridRowWrapper,
} from 'pages/DataReports/styles'
import { downloadFile } from 'pages/DataReports/helper'
import { FILE_ACTION } from 'config'
import { ampli } from 'ampli'

const ReportHistory = ({ fileName, userName, organizationName, reportHistoryList }) => {
  const goToClientDetails = useNavigateTo('clients')

  return (
    <ReportHistoryWrapper>
      <ReportHistoryHeadingWrapper>
        <ReportHistoryHeading>Report History</ReportHistoryHeading>
        {/* Commenting this out as this feature is not built */}
        {/* {!isEmpty(reportHistoryList) && (
          <Button variant="text" label="View All" onClick={() => {}} />
        )} */}
      </ReportHistoryHeadingWrapper>
      {!isEmpty(reportHistoryList) &&
        reportHistoryList.map((report, idx) => {
          return (
            <ReportHistoryGridRow
              id={`report-${idx + 1}-row`}
              key={`report-${idx + 1}-row`}
              idx={idx}
              date={report?.date}
              signedDocumentUrl={report?.signedDocumentUrl}
              fileDownloadUrl={report?.downloadUrl}
              organizationName={organizationName}
              fileName={fileName}
              userName={userName}
            />
          )
        })}
      <Fragment>
        {isEmpty(reportHistoryList) && (
          <NoReportHistoryHeading>No report history available</NoReportHistoryHeading>
        )}
        <Info goToClientDetails={goToClientDetails} />
      </Fragment>
    </ReportHistoryWrapper>
  )
}

export default ReportHistory

interface IReportHistoryGridRow {
  idx: number
  date: string
  fileDownloadUrl: string
  signedDocumentUrl: string
  organizationName: string
  fileName: string
  userName: string
}

const ReportHistoryGridRow: AppFC<IReportHistoryGridRow> = ({
  id,
  idx,
  date,
  userName,
  fileName,
  fileDownloadUrl,
  organizationName,
  signedDocumentUrl,
}) => {
  return (
    <ReportHistoryGridRowWrapper id={id}>
      {!isEmpty(signedDocumentUrl) && (
        <Fragment>
          <EllipseWrapper>
            <EllipseIcon className={'fill-primary-disabled xs:h-6 xs:w-6 sm:h-9 sm:w-9'} />
            <PdfIcon className="xs:h-4 xs:w-4 sm:h-6 sm:w-5 absolute xs:left-1 sm:top-1.8 sm:left-2" />
            <DateWrapper id={`report-${idx + 1}-date`}>{date}</DateWrapper>
          </EllipseWrapper>
          <ButtonWrapper>
            <Button
              variant="text"
              label="Download TSV"
              onClick={() => {
                ampli.impactReportLinkClicked({
                  userName,
                  organizationName,
                  fileName,
                  fileAction: FILE_ACTION.DOWNLOAD,
                })
                downloadFile(fileDownloadUrl)
              }}
            />
            <Button
              variant="text"
              label="View PDF"
              onClick={() => {
                ampli.impactReportLinkClicked({
                  userName,
                  organizationName,
                  fileName,
                  fileAction: FILE_ACTION.VIEW,
                })
                window.open(signedDocumentUrl, '_blank')
              }}
            />
          </ButtonWrapper>
        </Fragment>
      )}
      {isEmpty(signedDocumentUrl) && (
        <Fragment>
          <DateWrapper id={`report-${idx + 1}-date`}>{date}</DateWrapper>
          <NoReportText>No report generated</NoReportText>
        </Fragment>
      )}
    </ReportHistoryGridRowWrapper>
  )
}
