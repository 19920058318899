import { isEmpty, map } from 'lodash'
import React, { FC, useEffect } from 'react'
import { TransformedRecommendation } from '../../../../../../common/src/types/common-types'
import Loader from 'components/Loaders'
import PlayItem from './PlayItem'
import RecommendationProgressBubble, {
  RecommendableStatus,
} from 'components/Owner/PlayBooksCategoryDetails/RecommendationProgressBubble'

export interface IPlays {
  clientId: string
  clientAssessmentRecommendations: TransformedRecommendation[]
  isLoadingRecommendations: boolean
  getClientRecommendedPlaysAssessments: (payload: { clientId?: string }) => void
  resetClientRecommendedPlaysAssessments: () => void
}

const Plays: FC<IPlays> = ({
  clientId,
  clientAssessmentRecommendations,
  isLoadingRecommendations,
  getClientRecommendedPlaysAssessments,
  resetClientRecommendedPlaysAssessments,
}) => {
  useEffect(() => {
    if (!isEmpty(clientId)) {
      getClientRecommendedPlaysAssessments({})
    }
    return () => resetClientRecommendedPlaysAssessments()
    // eslint-disable-next-line
  }, [getClientRecommendedPlaysAssessments, clientId])
  return (
    <div className="xs:col-span-12 xs:p-4 sm:p-0 col-span-4 sm:col-span-6 md:col-span-6 lg:col-span-3 xl:col-span-3 2xl:col-span-3">
      <div className="font-normal text-2xl text-zinc font-primary pb-3 mb-3 border-primary-outline border-0 border-b">
        Recommended Plays
      </div>
      {isLoadingRecommendations ? (
        <Loader loader={'advisorConnectedTool'} />
      ) : (
        map(clientAssessmentRecommendations, (value, key) => (
          <div key={key} className="mb-10">
            <div className="flex justify-between mb-8">
              <h3 className="text-[17px] font-semibold text-zinc font-primary">{value.category}</h3>
              <div className="flex items-center space-x-2">
                <RecommendationProgressBubble status={value.status as RecommendableStatus} />
              </div>
            </div>
            <h3 className="text-[17px] font-medium text-zinc font-primary pl-6 mb-3">
              Recommended Plays
            </h3>
            {map(value.plays, (item, index) => (
              <div key={index} className="mb-6">
                <PlayItem play={item} />
              </div>
            ))}
          </div>
        ))
      )}
    </div>
  )
}

export default Plays
