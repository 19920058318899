import React from 'react'

function SideIcon({ className }) {
  return (
    <svg
      width="67"
      height="50"
      className={className}
      viewBox="0 0 67 50"
      fill="#71717A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8711 46C37.4691 46 46.8711 36.598 46.8711 25C46.8711 13.402 37.4691 4 25.8711 4C14.2731 4 4.87109 13.402 4.87109 25C4.87109 36.598 14.2731 46 25.8711 46ZM25.8711 50C39.6782 50 50.8711 38.8071 50.8711 25C50.8711 11.1929 39.6782 0 25.8711 0C12.064 0 0.871094 11.1929 0.871094 25C0.871094 38.8071 12.064 50 25.8711 50Z"
      />
      <circle cx="58.8711" cy="8" r="8" fill="" />
    </svg>
  )
}

SideIcon.defaultProps = {
  className: '',
}
export default SideIcon
