import styled from 'styled-components'
import tw from 'twin.macro'

export const CheckboxWrapper = styled.input`
  ${tw`
    appearance-none
    cursor-pointer
    !bg-app-white-100
    border
    border-app-grey-40
    appShadow-s
    rounded
    h-4
    w-4
    relative
  `}

  ${({ disabled }) =>
    disabled ? tw`!bg-app-grey-20 border-app-grey-40 appShadow-s cursor-not-allowed` : ''}

  ${({ checked }) =>
    checked
      ? tw`!bg-app-primary-60 border-app-primary-60  bg-no-repeat bg-center`
      : tw`bg-app-white-100`}

  ${({ disabled }) =>
    !disabled
      ? tw` hover:!border-app-primary-60   focus:!border-app-grey-40 focus:!appShadow-focus checked:focus:appShadow-s`
      : ''}
`
