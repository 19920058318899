import React, { Fragment, useMemo } from 'react'
import { AppFC } from 'types'
import Card from 'components/Common/Card'
import Button from 'components/Common/Button'
import PillTextBox, { PillColorTheme } from 'components/Common/PillTextBox'
import { formatNumber } from 'utils/helper'
import {
  ExtendedITabOption,
  tabsOptions,
} from 'components/DataReports/ClientReportManagement/utils'

import {
  AttestationSignatureDetailCardsWrapper,
  CardContentWrapper,
  CountWrapper,
} from 'components/DataReports/AttestationSignatureDetailCards/styles'

interface IAttestationSignatureDetailCardsProps {
  unsignedCount: number | null
  signedCount: number | null
  isDisabled: boolean
  showViewDetailsButton: boolean

  setFilter: (value: ExtendedITabOption) => void
}

const AttestationSignatureDetailCards: AppFC<IAttestationSignatureDetailCardsProps> = ({
  setFilter,
  isDisabled,
  signedCount,
  unsignedCount,
  showViewDetailsButton,
}) => {
  const goToUnsignedClientDetails = function () {
    setFilter(tabsOptions[0])
  }
  const goToSignedClientDetails = function () {
    setFilter(tabsOptions[1])
  }

  const useFormattedCount = (count: number | null) => {
    return useMemo(() => (count === null ? null : formatNumber(count)), [count])
  }

  const formattedUnsignedCount = useFormattedCount(unsignedCount)
  const formattedSignedCount = useFormattedCount(signedCount)

  return (
    <AttestationSignatureDetailCardsWrapper>
      {formattedUnsignedCount !== null && (
        <Card className="border border-grey-lighter4x">
          <CardContentWrapper>
            <div>
              <PillTextBox colorTheme={PillColorTheme.ERROR}>Clients Not Signed</PillTextBox>
            </div>
            <CountWrapper>{formattedUnsignedCount}</CountWrapper>

            <ViewDetailsButton
              onClick={goToUnsignedClientDetails}
              isDisabled={isDisabled}
              showViewDetailsButton={showViewDetailsButton}
            />
          </CardContentWrapper>
        </Card>
      )}
      {formattedSignedCount !== null && (
        <Card className="border border-grey-lighter4x">
          <CardContentWrapper>
            <div>
              <PillTextBox colorTheme={PillColorTheme.SUCCESS}>Clients Signed</PillTextBox>
            </div>
            <CountWrapper>{formattedSignedCount}</CountWrapper>
            <ViewDetailsButton
              onClick={goToSignedClientDetails}
              isDisabled={false}
              showViewDetailsButton={true}
            />
          </CardContentWrapper>
        </Card>
      )}
    </AttestationSignatureDetailCardsWrapper>
  )
}

export default AttestationSignatureDetailCards

const ViewDetailsButton = ({ onClick, isDisabled, showViewDetailsButton }) => {
  return showViewDetailsButton ? (
    <Button variant="text" onClick={onClick} label="View Details" disabled={isDisabled} />
  ) : (
    <Fragment></Fragment>
  )
}
