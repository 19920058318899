import styled from 'styled-components'
import tw from 'twin.macro'

export const ActivityWrapper = styled.div`
  ${tw`grid grid-cols-12 gap-4 xs:px-2 sm:px-12 pt-6`}
`

export const ActivityScoreCardWrapper = styled.div`
  ${tw`xs:col-span-12 sm:col-span-6`}
`

export const ScoreCardHeadingWrapper = styled.div`
  ${tw`text-2xl font-normal`}
`

export const ClientScoreCardWrapper = styled.div`
  ${tw`grid grid-cols-2 gap-4 mt-5`}
`

export const ClientActivityLogWrapper = styled.div`
  ${tw`xs:col-span-12 sm:col-span-6 divide-y divide-grey-darker`}
`
export const ActivityLogHeadingWrapper = styled.div`
  ${tw`text-2xl font-normal pb-5`}
`
export const ActivityLogWrapper = styled.div`
  ${tw`col-span-6 pt-5 overflow-auto h-[500px]`}
`
