import React from 'react'

export default function ScrewdriverAndWrenchIcon({ className = '' }) {
  return (
    <svg
      /* width="25" height="25" */
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M3.83927 0.244178C3.37541 -0.11715 2.71623 -0.0732045 2.29631 0.341835L0.343181 2.29496C-0.0718585 2.71 -0.115804 3.36918 0.240642 3.83793L4.14689 8.91605C4.36662 9.20414 4.7133 9.37504 5.07463 9.37504H7.71623L13.0385 14.6973C12.3207 16.1133 12.5502 17.8907 13.7367 19.0723L19.2055 24.5411C19.8158 25.1514 20.807 25.1514 21.4174 24.5411L24.5424 21.4161C25.1528 20.8057 25.1528 19.8145 24.5424 19.2041L19.0737 13.7354C17.892 12.5537 16.1147 12.3194 14.6987 13.0371L9.37638 7.71488V5.07816C9.37638 4.71195 9.20549 4.37015 8.9174 4.15043L3.83927 0.244178ZM0.973063 19.3409C0.352946 19.961 0.00138369 20.8057 0.00138369 21.6846C0.00138369 23.5157 1.48576 25 3.31681 25C4.19572 25 5.04045 24.6485 5.66056 24.0284L11.4125 18.2764C11.0317 17.2559 10.9731 16.1475 11.2367 15.0977L8.22404 12.085L0.973063 19.3409ZM25.0014 7.03129C25.0014 6.51859 24.9477 6.02055 24.8451 5.54203C24.7279 4.99515 24.059 4.85355 23.6635 5.24906L20.5434 8.36918C20.3969 8.51566 20.1967 8.59867 19.9916 8.59867H17.1889C16.7592 8.59867 16.4076 8.24711 16.4076 7.81742V5.0098C16.4076 4.80473 16.4906 4.60453 16.6371 4.45805L19.7572 1.33793C20.1528 0.94242 20.0112 0.273475 19.4643 0.156288C18.9809 0.0537486 18.4828 3.76533e-05 17.9701 3.76533e-05C14.0883 3.76533e-05 10.9389 3.14945 10.9389 7.03129V7.07035L15.1039 11.2354C16.8617 10.7911 18.8051 11.2598 20.182 12.6368L20.9487 13.4034C23.3412 12.2803 25.0014 9.84867 25.0014 7.03129ZM5.07951 21.0938C5.07951 21.7432 4.55705 22.2657 3.90763 22.2657C3.25822 22.2657 2.73576 21.7432 2.73576 21.0938C2.73576 20.4444 3.25822 19.9219 3.90763 19.9219C4.55705 19.9219 5.07951 20.4444 5.07951 21.0938Z" />
    </svg>
  )
}
