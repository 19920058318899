import React from 'react'

const UserTableHeader = ({ children, className = '' }) => {
  return (
    <td
      className={`xs:px-0 xs:py-5 sm:px-3 lg:py-6 lg:px-8 text-sm text-grey font-primary self-center  ${className}`}
    >
      {children}
    </td>
  )
}

export default UserTableHeader
