import { RESET_FORM_DATA, SET_FORM_DATA } from 'store/types'

const initialState = {}

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_FORM_DATA:
      return {
        ...state,
        [action.payload.form]: action.payload.data,
      }

    case RESET_FORM_DATA:
      return {
        ...state,
        [action.payload.form]: {},
      }
    default:
  }
  return state
}

export default reducer
