import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_GOAL_DETAILS } from 'store/types'
import goalAPi from 'api/goal'
import { getGoalDetailsAction } from 'store/actions/goal'

function* getGoalDetails(action) {
  const getGoalDetailsRespone = yield call(goalAPi.getGoalDetails, action.payload)
  yield put(getGoalDetailsAction.FULLFILLED(getGoalDetailsRespone?.getGoalDetails))
}

/// /////////// Watchers ///////////////////////
export function* watcherGoal() {
  yield takeLatest(GET_GOAL_DETAILS.STARTED, getGoalDetails)
}
