import step1 from 'assets/images/data_step1.png'
import step2 from 'assets/images/data_step2.png'

export const CLIENT_DATA = [
  {
    title: 'Step 1:',
    text: 'Instruct your client to go to their Data page.',
    image: step1,
  },
  {
    title: 'Step 2:',
    text: 'Instruct your client to turn the ‘share with advisor’ toggle to on.',
    image: step2,
  },
  {
    title: 'Step 3',
    text: 'Once your client allows access, come back here and refresh your browser.',
    image: '',
  },
]
