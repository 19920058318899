import * as yup from 'yup'
import { UPPER_CASE_REGEX, NUMBER_REGEX, SPECIAL_CHARACTER_REGEX } from 'config'
import ValidationSchemaBuilder from 'utils/ValidationSchemaBuilder'
import { passwordErrors } from 'utils/helper'

const schemaBuilder = new ValidationSchemaBuilder()

export const schema = schemaBuilder.email().password().getSchema()

export const forgotPasswordSchema = schemaBuilder.email().getSchema()

export const resetPasswordSchema = schemaBuilder
  .custom({
    password: yup
      .string()
      .required(passwordErrors?.passwordNotComplete)
      .min(8, passwordErrors?.rulesNotMet)
      .test('check white spaces', 'Password cannot have spaces.', (value) => {
        if (value?.includes(' ')) return false
        return true
      })
      .matches(RegExp(UPPER_CASE_REGEX), passwordErrors?.rulesNotMet)
      .matches(RegExp(NUMBER_REGEX), passwordErrors?.rulesNotMet)
      .matches(RegExp(SPECIAL_CHARACTER_REGEX), passwordErrors?.rulesNotMet),
    confirmPassword: yup
      .string()
      .required(passwordErrors?.passwordNotComplete)
      .oneOf([yup.ref('password'), null], passwordErrors?.passwordNotMatch),
  })
  .getSchema()
