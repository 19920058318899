import React from 'react'

function CompletedIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.25" cy="10.25" r="10.25" fill="#059669" />
      <path
        d="M4.85612 11.6996L4.85613 11.6996L7.47113 14.3146C7.8366 14.6801 8.42915 14.6801 8.79462 14.3146L15.1868 7.92241C15.1868 7.9224 15.1868 7.9224 15.1868 7.9224C15.5523 7.55693 15.5523 6.96438 15.1868 6.59892C14.8214 6.23346 14.2289 6.23346 13.8634 6.59892L13.8634 6.59893L8.13287 12.3294L6.17961 10.3762C6.1796 10.3762 6.1796 10.3762 6.1796 10.3762C5.81413 10.0107 5.22159 10.0107 4.85612 10.3762C4.49067 10.7417 4.49067 11.3341 4.85612 11.6996Z"
        fill="white"
        stroke="white"
      />
    </svg>
  )
}

export default CompletedIcon
