import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import PrivacyPolicy from 'components/PrivacyPolicy'

/* --------------------------- Privacy Policy (Index) --------------------------- */

const privacyPolicyProps = (state: any) => {
  let { user } = state.user
  return { user }
}

function privacyPolicyDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const PrivacyPolicyContainer = connect(
  privacyPolicyProps,
  privacyPolicyDispatch
)(PrivacyPolicy)
