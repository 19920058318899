import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import Loader from 'components/Loaders'
import { AppFC } from 'types'
import { UserProfile as UserProfileObject } from '__generated__/api-types-and-hooks'
import PasswordToggleIcon from 'components/Common/SvgIcons/PasswordToggleIcon'
import { USER_STATUS } from 'config'
import Button from 'components/Common/Button'
import ResetPasswordConfirmationModalContainer from 'pages/Common/container'
import { formatMobileNumber } from 'appUtils'

interface IUserProfileProps {
  userProfile: UserProfileObject
  clientDetailsLoading: boolean
}
const UserProfile: AppFC<IUserProfileProps> = ({ userProfile, clientDetailsLoading }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [password, setPassword] = useState('')
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false)

  useEffect(() => {
    if (userProfile?.tempPassword) {
      setPassword(userProfile.tempPassword)
    }
  }, [userProfile])

  const handleToggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  const toggledPassword = isVisible ? password : '*'.repeat(password.length)
  return (
    <div className="xs:col-span-12 xs:p-4 sm:p-0 col-span-3 sm:col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-4 2xl:col-span-4 border-r border-grey-darker">
      {clientDetailsLoading ? (
        <Loader loader="AdvisorOwnerProfile" />
      ) : (
        <>
          <div className="font-primary text-2xl">User Profile </div>
          <div className="space-y-9 mt-[31px] whitespace-pre">
            <div className="space-y-1">
              <p className="text-base font-semibold font-primary leading-6">First Name</p>
              <p className="leading-6 font-primary text-lg">{get(userProfile, 'firstName', '')} </p>
            </div>

            <div className="space-y-1">
              <p className="text-base font-semibold font-primary leading-6">Last Name</p>
              <p className="leading-6 font-primary text-lg">{get(userProfile, 'lastName', '')} </p>
            </div>

            <div className="space-y-1">
              <p className="text-base font-semibold font-primary leading-6">
                Email Address (Sign-in)
              </p>
              <p className="leading-6 font-primary text-lg">{get(userProfile, 'email', '')} </p>
            </div>

            <div className="space-y-1">
              <p className="text-base font-semibold font-primary leading-6">Phone Number</p>
              <p className="leading-6 font-primary text-lg">
                {formatMobileNumber(userProfile?.mobileContactNumber as string)}
              </p>
            </div>
            {/* show temp password */}
            {userProfile.status === USER_STATUS.INVITED && password && (
              <div>
                <p className="text-base font-semibold font-primary leading-6">Temporary Password</p>
                <div className="flex items-center space-x-4">
                  <p className="leading-6 font-primary text-lg">{toggledPassword}</p>
                  <div className="cursor-pointer" onClick={handleToggleVisibility}>
                    <PasswordToggleIcon />
                  </div>
                </div>
              </div>
            )}
            {/* show reset button */}
            {userProfile.status === USER_STATUS.ACTIVE && (
              <div>
                <Button
                  className=""
                  variant="primary"
                  label="Reset Password"
                  onClick={() => setOpenResetPasswordModal(true)}
                />
              </div>
            )}
          </div>
          {/* Reset password modal */}
          {openResetPasswordModal && (
            <ResetPasswordConfirmationModalContainer
              email={userProfile.email}
              onClose={() => setOpenResetPasswordModal(false)}
            />
          )}
        </>
      )}
    </div>
  )
}

export default UserProfile
