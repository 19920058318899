import styled from 'styled-components'
import tw from 'twin.macro'

export interface IBannerStatCardProps {
  isLoading: boolean
}

export const BannerStatCardWrapper = styled.div.attrs({
  className: 'border border-grey-lighter3x rounded py-4 px-5',
})<IBannerStatCardProps>`
  .heading {
    ${tw`uppercase font-semibold leading-7 text-lg font-primary text-primary-text  `}
  }
  .value {
    ${tw`text-6xl font-black font-secondary leading-[69px]`}
  }
  .details {
    ${tw`flex gap-4 justify-between items-center`}
  }
  .footer-details {
    ${tw`text-2xl font-medium font-primary`}
    span {
      ${tw`text-2xl text-primary-text`}
    }
  }
  .sources {
    ${tw`text-purple-indigo text-xs leading-4 cursor-pointer`}
  }
  .tab {
    ${tw`text-silver-darker cursor-pointer`}
    &.activetab {
      ${tw`text-black cursor-pointer border-purple-lighter border-b-2`}
    }
  }
  ${(p) => p.isLoading && tw`hidden`}
`
