import styled from 'styled-components'

export const ChatWrapper = styled.div`
  .str-chat__reaction-selector {
    background: none !important;
    box-shadow: none !important;
  }

  .str-chat__reaction-list--reverse::before {
    background: none !important;
  }
  .str-chat__reaction-list--reverse {
    background: none !important;
  }
  .str-chat__reaction-list--reverse::after {
    background: none !important;
  }

  .str-chat__textarea textarea {
    background-color: #fafafa !important;
  }
  .str-chat__input--emojipicker {
    right: 0 !important;
    left: auto !important;
  }
  .str-chat__message-team-form-footer {
    margin-top: 0px !important;
  }
  .str-chat__small-message-input-emojipicker {
    top: 96px !important;
    left: -114px !important;
  }
  .str-chat__modal__close-button {
    padding: 15px !important;
  }
  .str-chat__edit-message-form {
    width: 360px;
  }
  .str-chat__message-simple__actions__action--thread {
    display: none !important;
  }
  .str-chat__li--bottom {
    margin: 0px !important;
  }
  .str-chat__li--top {
    margin: 0px !important;
  }
  .str-chat__modal {
    left: 0;
  }
  .str-chat__list {
    padding-top: 0 !important;
  }
  .str-chat__modal .str-chat__edit-message-form textarea {
    max-height: fit-content !important;
  }
  p {
    font-family: 'Futura Std' !important;
  }

  .str-chat__message--me .str-chat__message-text-inner,
  .str-chat__message-simple--me .str-chat__message-text-inner {
    border-radius: 10px 10px 0px 10px !important;
  }
  .str-chat__message-text-inner,
  .str-chat__message-simple-text-inner {
    border-radius: 0px 10px 10px 10px;
  }
  .str-chat__message-text-inner {
    font-weight: 500;
  }

  .str-chat__message-text-inner p {
    font-weight: 500;
  }

  @media screen and (min-device-width: 400px) and (max-device-width: 500px) and (min-device-height: 700px) and (max-device-height: 900px) {
    .str-chat-channel {
      height: calc(100% - 55px) !important;
    }
    .str-chat__list {
      max-height: calc(100vh - 242px);
    }
  }
  @media screen and (min-device-width: 350px) and (max-device-width: 400px) and (min-device-height: 700px) and (max-device-height: 900px) {
    .str-chat-channel {
      height: calc(100% - 55px) !important;
    }
    .str-chat__list {
      max-height: calc(100vh - 245px);
    }
    .str-chat__modal.str-chat__modal--open {
      padding: 0;
    }
  }
  @media screen and (max-device-width: 400px) and (max-device-height: 700px) {
    .str-chat-channel {
      height: 91vh !important;
    }
  }
  @media screen and(min-device-width: 400px) and(max-device-width: 500px) and (min-device-height: 700px) and (max-device-height: 900px) {
    .str-chat-channel {
      height: 92vh !important;
    }
  }
  @media only screen and (min-device-width: 500px) and (max-device-width: 1000px) {
    .str-chat-channel {
      height: 100% !important;
    }
    .messaging.str-chat .str-chat__list {
      max-height: calc(100% - 200px);
    }
  }
  @media screen and (max-device-width: 400px) and (max-device-height: 700px) {
    .str-chat-channel {
      box-shadow: none !important;
      height: 91vh !important;
    }
    .str-chat-channel-list {
      box-shadow: none !important;
      height: 71vh !important;
    }
  }

  @media only screen and (min-device-width: 1000px) and (max-device-width: 1299px) {
    .str-chat {
      height: 76vh !important;
      overflow-y: auto;
    }
    .str-chat-channel {
      box-shadow: none !important;
      height: 83vh !important;
    }
  }

  @media only screen and (min-device-width: 1400px) {
    .str-chat {
      height: 66vh !important;
      overflow-y: auto;
    }
    .str-chat-channel {
      box-shadow: none !important;
      height: 73vh !important;
    }
  }

  @media only screen and (min-device-width: 1000px) and (max-device-width: 1100px) and (min-device-height: 700px) and (max-device-height: 900px) {
    .str-chat {
      height: 48vh !important;
      overflow-y: auto;
    }
    .str-chat-channel {
      box-shadow: none !important;
      height: 70vh !important;
    }
  }
  @media only screen and (min-device-width: 1299px) and (max-device-width: 1399px) {
    .str-chat {
      height: 61vh !important;
      overflow-y: auto;
    }
    .str-chat-channel {
      box-shadow: none !important;
      height: 77vh !important;
    }
  }
`
