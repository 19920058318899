import React from 'react'

function MyBusinessIcon({ className }) {
  return (
    <svg
      className={className}
      width={'40px'}
      height={'39.9px'}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M380.4 123.7c-12.62-12.63-31.37-14.5-46.37-6.75c-23.62-13.25-50.23-20.98-77.97-20.98c-73.37 0-137.3 49.98-155 121.1c-17.87 71.25 14.1 145.4 79.75 180c64.75 34.5 144.6 20.63 193.9-33.75c49.37-54.38 55.25-135.3 14.5-196.3C395.3 152.1 391.8 135.1 380.4 123.7zM346.6 346.5c-48.37 48.38-132.7 48.38-181.1 0c-36.62-36.63-47.5-91.63-27.75-139.5c19.87-47.88 66.5-79 118.4-79c20.5 0 39.1 5.375 57.87 14.38c-3.375 13.25-.5 27.63 9.749 37.88c10.87 10.75 26.87 14.38 41.25 9.25C396.3 239.7 388.5 304.9 346.6 346.5zM437.1 75c-71.3-71.43-177.1-93.72-270.3-58.72C157.6 19.74 153.1 30.1 159.3 39.25c7.218 11.07 18.29 6.88 18.79 6.695c86.77-32.49 185.3-8.21 247 62.3c64.5 73.88 73.25 181.3 21.75 264.6c-25.5-11.5-55.62-2.5-70.75 21.13c-14.1 23.63-10.5 54.75 10.75 73c21.12 18.38 52.5 18.38 73.75 0c21.12-18.25 25.75-49.38 10.62-73C535.1 294.1 523.8 161.8 437.1 75zM424.1 447.1c-13.25 0-23.1-10.75-23.1-24s10.75-24 23.1-24c13.25 0 23.1 10.75 23.1 24S437.4 447.1 424.1 447.1zM351.2 472.9c-3.99-6.168-11.61-8.989-18.51-6.477c-66.26 24.11-140.3 15.74-199.7-23.22c-63.12-41.5-100.1-111.9-101.1-187.3c0-42 12.25-81.88 33.75-116.8c25.62 11.38 55.75 2.25 70.75-21.38C151.4 94.24 146.9 63.11 125.6 44.74C104.5 26.36 73.03 26.36 51.78 44.61C30.53 62.86 25.78 93.99 40.65 117.6c-63.87 99.13-52.43 232.6 34.31 319.4c70.79 70.91 175.9 93.45 268.8 59.43C350.4 493.1 358.3 483.9 351.2 472.9zM88.02 63.99c13.25 0 23.1 10.75 23.1 24S101.3 111.1 88.02 111.1c-13.25 0-23.1-10.75-23.1-24S74.78 63.99 88.02 63.99zM256.1 175.1c-44.25 0-79.99 35.88-79.99 80c0 44.25 35.75 80 79.99 80c44.12 0 79.99-35.88 79.99-80C336 211.9 300.3 175.1 256.1 175.1zM256.1 303.1c-26.62 0-47.1-21.5-47.1-48s21.5-48 47.1-48c26.5 0 47.1 21.5 47.1 48S282.6 303.1 256.1 303.1z" />{' '}
    </svg>
  )
}
export default MyBusinessIcon
