export const guessImageFileTypeFromFileName = (filename: string | undefined) => {
  if (!filename) return 'image/jpeg'

  const re = /(?:\.([^.]+))?$/
  const imageType = re.exec(filename)?.[1] || 'jpeg'
  return `image/${imageType}`
}

export const getFileSizeFromUrl = async (url: string): Promise<number> => {
  const response = await fetch(url)
  const fileBlob = await response.blob()
  return fileBlob.size
}
