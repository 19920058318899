import React from 'react'
import { ArrowRightIcon } from 'components/Common/SvgIcons'
import ArrowLeftIcon from 'components/Common/SvgIcons/ArrowLeftIcon'
import UpArrowIcon from 'components/Common/SvgIcons/UpArrowIcon'
import DownArrowIcon from 'components/Common/SvgIcons/DownArrowIcon'
const TablePagination = ({
  type,
  noOfItemsPerPage,
  currentPage,
  totalPages,
  hasMoreData,
  totalItems,
  startIndex,
  endIndex,
  moveNextHandler,
  movePreviousHandler,
  updateNoOfItemPerPageHandler,
}) => {
  return (
    <div className="w-full xs:mt-5 sm:mt-12">
      <div className="grid grid-cols-12 font-primary">
        <div className="xs:col-span-8 sm:col-span-4 lg:col-span-2 p-5 border flex flex-col justify-center items-stretch border-primary-outline whitespace-nowrap text-sm">
          <div className="w-auto flex flex-row justify-between">
            <div> {type}s per page </div>
            <div className="flex gap-2">
              <div> {noOfItemsPerPage} </div>
              <div className="flex flex-col gap-1">
                <div onClick={() => updateNoOfItemPerPageHandler(noOfItemsPerPage - 1)}>
                  <UpArrowIcon
                    className={`${
                      noOfItemsPerPage <= 1 ? 'fill-primary-outline' : 'fill-silver'
                    } cursor-pointer`}
                  />
                </div>
                <div onClick={() => updateNoOfItemPerPageHandler(noOfItemsPerPage + 1)}>
                  <DownArrowIcon
                    className={`${
                      !hasMoreData && currentPage === totalPages
                        ? 'fill-primary-outline'
                        : 'fill-silver'
                    } cursor-pointer`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xs:col-span-4 sm:col-span-8 lg:col-span-10 flex xs:justify-between sm:justify-end w-full  whitespace-nowrap text-sm">
          <div className="flex border border-primary-outline xs:border-l-0 sm:border-l divide-x divide-primary-outline">
            <div className="xs:p-4 sm:p-5 flex items-center justify-center whitespace-nowrap">
              <div className="flex flex-col">
                <div
                  className={`self-center ${
                    currentPage <= totalPages && currentPage !== 1 ? '' : 'pointer-events-none'
                  }`}
                  onClick={movePreviousHandler}
                >
                  <ArrowLeftIcon
                    className={`${
                      currentPage <= totalPages && currentPage !== 1
                        ? 'fill-zinc cursor-pointer'
                        : 'fill-primary-outline'
                    } `}
                  />
                </div>
              </div>
            </div>
            <div className="xs:p-4 sm:p-5 flex items-center justify-center whitespace-nowrap">
              <div className="flex flex-col">
                <div
                  className={`self-center ${
                    currentPage < totalPages || hasMoreData ? '' : 'pointer-events-none'
                  }`}
                  onClick={moveNextHandler}
                >
                  <ArrowRightIcon
                    className={`${
                      currentPage < totalPages || hasMoreData
                        ? 'fill-zinc cursor-pointer'
                        : 'fill-primary-outline'
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TablePagination
