import React, { useMemo } from 'react'
import { AppFC } from 'types'

export enum AppPillColorTheme {
  ORANGE = 'ORANGE',
  GREEN = 'GREEN',
  PURPLE = 'PURPLE',
  RED = 'RED',
  YELLOW = 'YELLOW',
  BROWN = 'BROWN',
  BLUE = 'BLUE',
  LIME = 'LIME',
  SKY = 'SKY',
  GREY = 'GREY',
}

export interface IAppPillTextBoxProps {
  colorTheme: AppPillColorTheme
  size?: 'small' | 'base' | 'large'
  textSize?: 'small' | 'normal' | 'large'
  fontSize?: 'bold' | 'normal' | 'inter'
}

export const AppPillTextBox: AppFC<IAppPillTextBoxProps> = ({
  children,
  colorTheme,
  size = 'base',
  className,
  textSize = 'small',
  fontSize = 'bold',
}) => {
  const pillBackgroundColor = useMemo((): string => {
    return {
      [AppPillColorTheme.ORANGE]: 'bg-orange-light',
      [AppPillColorTheme.GREEN]: 'bg-green-lighter2x',
      [AppPillColorTheme.PURPLE]: 'bg-purple-lighter3x',
      [AppPillColorTheme.RED]: 'bg-red-light',
      [AppPillColorTheme.YELLOW]: 'bg-yellow-light',
      [AppPillColorTheme.BROWN]: 'bg-brown-light',
      [AppPillColorTheme.BLUE]: 'bg-sky',
      [AppPillColorTheme.LIME]: 'bg-sky-light',
      [AppPillColorTheme.SKY]: 'bg-sky',
      [AppPillColorTheme.GREY]: 'bg-app-grey-20',
    }[colorTheme]
  }, [colorTheme])

  const textColor = useMemo(() => {
    return {
      [AppPillColorTheme.ORANGE]: 'text-orange',
      [AppPillColorTheme.GREEN]: 'text-green-dark',
      [AppPillColorTheme.PURPLE]: 'text-purple-light',
      [AppPillColorTheme.RED]: 'text-red',
      [AppPillColorTheme.YELLOW]: 'text-yellow-darkest',
      [AppPillColorTheme.BROWN]: 'text-brown-dark',
      [AppPillColorTheme.BLUE]: 'text-tertiary-light',
      [AppPillColorTheme.LIME]: 'text-green',
      [AppPillColorTheme.SKY]: 'text-black',
      [AppPillColorTheme.GREY]: 'text-app-grey-60',
    }[colorTheme]
  }, [colorTheme])

  const sizeStyles = useMemo(() => {
    return {
      small: 'py-0.5 px-3',
      base: 'py-[4px] px-5',
      large: 'py-[6px] px-5',
    }[size]
  }, [size])

  const textStyles = useMemo(() => {
    return {
      small: 'text-xs',
      normal: 'text-base',
      large: 'text-lg',
    }[textSize]
  }, [textSize])

  return (
    <div
      className={`${pillBackgroundColor} ${sizeStyles} rounded-full flex-grow-0 self-start ${
        className ? className : 'text-center'
      } `}
    >
      <div className={`capitalize ${textColor} font-${fontSize} ${textStyles}`}>{children}</div>
    </div>
  )
}

AppPillTextBox.defaultProps = {
  className: undefined,
}
