import {
  UPGRADE_CURRENT_ASSESSMENT_NUMBER,
  DOWNGRADE_ASSESSMENT_NUMBER,
  ASSESSMENT_BG_COLOR,
} from 'store/types'

export const upgradeAssessmentNumberAction = (selectedResponse) => ({
  type: UPGRADE_CURRENT_ASSESSMENT_NUMBER,
  payload: selectedResponse,
})

export const downgradeAssessmentNumberAction = () => ({
  type: DOWNGRADE_ASSESSMENT_NUMBER,
})
export const assessmentBgColorAction = (payload) => ({
  type: ASSESSMENT_BG_COLOR,
  payload,
})
