type TourState = {
  name: string
  id: number
  isAvailable: true
  state: 'never run' | 'inProgress' | 'closed'
  currentStep?: number
}
declare global {
  interface Window {
    usetifulTags: { userId: string }
    USETIFUL: {
      user: {
        setId: (userId: string | null) => void
        clearProgress: () => void
      }
      tour: {
        start: (tourId: number) => void
        jumpTo: (stepNumber: number) => void
        getState: () => TourState[]
      }
      reinitialize: () => void
    }
  }
}

// export const initializeUsetiful = (userId: string) => {
//   const usetiful = window.USETIFUL
//   usetiful?.user?.clearProgress()
//   usetiful?.user?.setId(userId)
//   usetiful?.reinitialize()
// }

// export const resetUsetiful = () => {
//   const usetiful = window.USETIFUL
//   usetiful?.user?.setId(null)
//   usetiful?.user?.clearProgress()
//   usetiful?.reinitialize()
// }

const usetifulManualTourTriggers = [
  {
    url: /owner\/.*\/dashboard/,
    tourIdOrder: [
      39240, // Welcome BOs
    ],
  },
  {
    url: /admin|advisor\/.*\/dashboard/,
    tourIdOrder: [
      41167, // Welcome BSOs
    ],
  },
]

export const openUsetifulTourIfNeeded = (currentUrl?: string) => {
  const url = currentUrl || window.location.href
  const usetiful = window.USETIFUL
  if (!usetiful?.tour) return

  const tourTrigger = usetifulManualTourTriggers.find((x) => url.match(x.url))
  if (!tourTrigger) return

  for (const tourId of tourTrigger.tourIdOrder || []) {
    const tours = usetiful.tour.getState() || []
    const tourDetails = tours.find((x) => x.id === tourId)
    if (tourDetails && tourDetails.state !== 'closed') {
      usetiful.tour.start(tourId)
    }
  }
}
