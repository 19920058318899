import React from 'react'

function ChildIcon({ className }) {
  return (
    <svg
      className={className}
      width="32"
      height="50"
      viewBox="0 0 32 50"
      fill="#71717A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: 'multiply' }}>
        <path d="M9.00584 7.03125C9.00584 3.14844 12.2383 0 16.0371 0C20.0117 0 23.0683 3.14844 23.0683 7.03125C23.0683 10.918 20.0117 14.0625 16.0371 14.0625C12.2383 14.0625 9.00584 10.918 9.00584 7.03125ZM16.0371 9.375C17.4238 9.375 18.3808 8.3252 18.3808 7.03125C18.3808 5.7373 17.4238 4.6875 16.0371 4.6875C14.8261 4.6875 13.6933 5.7373 13.6933 7.03125C13.6933 8.3252 14.8261 9.375 16.0371 9.375ZM13 37.5V47.6562C13 48.9551 11.9551 50 10.6562 50C9.36228 50 8.31248 48.9551 8.31248 47.6562V25.1855L4.76365 30.2539C4.02049 31.3086 2.55857 31.5723 1.499 30.8301C0.438757 30.0781 0.181716 28.623 0.924684 27.5586L5.39256 21.1035C7.83591 17.7051 11.7402 15.5371 16.0859 15.5371C20.2949 15.5371 24.25 17.6562 26.7011 21.0059L31.3105 27.5391C32.0625 28.5938 31.8183 30.0586 30.6855 30.8105C29.7187 31.5625 28.2539 31.3184 27.5019 30.2637L23.9375 25.2832V47.6562C23.9375 48.9551 22.8926 50 21.5937 50C20.2949 50 19.25 48.9551 19.25 47.6562V37.5H13ZM13 32.8125H19.25V20.9375C18.2539 20.5273 17.1797 20.3125 16.0859 20.3125C15.0117 20.3125 13.9668 20.5176 13 20.8984V32.8125Z" />
      </g>
    </svg>
  )
}

ChildIcon.defaultProps = {
  className: '',
}
export default ChildIcon
