import React from 'react'
import ContentLoader from 'react-content-loader'

import colors from 'colors'

const AnnouncementLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 70"
        backgroundColor={colors?.cream?.dark || '#F4F4F5'}
        foregroundColor={colors?.grey?.lighter || '#e8e8e8'}
      >
        <rect x="50" y="10" rx="14" ry="14" width="340" height="50" />
      </ContentLoader>
    </>
  )
}

export default AnnouncementLoader
