import React from 'react'

function MsWordIcon({ className }) {
  return (
    <svg
      className={className}
      width="33"
      height="44"
      viewBox="0 0 33 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.25 11V0H4.125C1.8468 0 0 1.8468 0 4.125V39.875C0 42.1523 1.8468 44 4.125 44H28.875C31.1532 44 33 42.1532 33 39.875V13.75H22.0773C20.4789 13.75 19.25 12.5211 19.25 11ZM24.1914 20.625H26.1998C26.8629 20.625 27.3539 21.242 27.2044 21.8883L23.5555 37.7008C23.4523 38.1648 23.0312 38.5 22.5586 38.5H20.0441C19.5778 38.5 19.1692 38.1871 19.048 37.7366L16.5 28.282L13.9554 37.6578C13.7586 38.1906 13.4234 38.5 12.9594 38.5H10.4414C9.96119 38.5 9.54422 38.1684 9.4368 37.7004L5.78789 21.8879C5.64266 21.2438 6.13336 20.625 6.79594 20.625H8.8043C9.28452 20.625 9.70148 20.9566 9.80891 21.4243L11.8422 30.25L14.2313 21.3898C14.3516 20.9344 14.7641 20.625 15.2281 20.625H17.7727C18.239 20.625 18.6476 20.9379 18.7687 21.3884L21.1578 30.25L23.1945 21.4242C23.2977 20.9602 23.6414 20.625 24.1914 20.625ZM22 0V11H33L22 0Z"
        fill="black"
      />
    </svg>
  )
}
export default MsWordIcon
