import styled from 'styled-components'
import tw from 'twin.macro'

export const CreateAccountHeadingWrapper = styled.h2`
  ${tw` text-4xl text-center text-primary-text font-primary leading-10  pb-8 `}
`

export const CreateAccountParagraphWrapper = styled.p`
  ${tw` bg-transparent font-bold text-black text-base py-2 rounded`}
`
export const CreateAccountEmailWrapper = styled.p`
  ${tw` bg-transparent font-normal text-black text-lg py-1 rounded`}
`
export const CreateAccountErrorWrapper = styled.p`
  ${tw` text-[#DC2626] text-sm leading-5 font-semibold m-3`}
`
