import { OnboardingFlowType } from '__generated__/api-types-and-hooks'

export const ChatBotFlowUrl = {
  [OnboardingFlowType.GetToKnowYou]: 'get-to-know-you',
  [OnboardingFlowType.GoalSetting]: 'goal-setting',
  [OnboardingFlowType.AddGoal]: 'add-goal',
}

export const ChatBotFlowData = {
  'get-to-know-you': OnboardingFlowType.GetToKnowYou,
  'goal-setting': OnboardingFlowType.GoalSetting,
  'add-goal': OnboardingFlowType.AddGoal,
}
