import React from 'react'

function PlusAnnouncementIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer"
    >
      <rect width="40" height="40" rx="20" fill="white" />
      <path
        d="M30 19.9995C30 20.4226 29.6538 20.7688 29.2308 20.7688H20.7692V29.2303C20.7692 29.6555 20.4252 30 20 30C19.5748 30 19.2308 29.6534 19.2308 29.2303V20.7688H10.7692C10.344 20.7688 10 20.425 10 20C10 19.5764 10.3442 19.2303 10.7692 19.2303H19.2308V10.7688C19.2308 10.3436 19.5748 10 20 10C20.4252 10 20.7692 10.3438 20.7692 10.7688V19.2303H29.2308C29.6538 19.2303 30 19.5764 30 19.9995Z"
        fill="#5E06B9"
      />
    </svg>
  )
}

export default PlusAnnouncementIcon
