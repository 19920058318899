import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const ChartLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 250 80"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="0" y="0" rx="0" ry="0" width="290" height="290" />
      </ContentLoader>
    </>
  )
}

export default ChartLoader
