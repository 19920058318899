import styled from 'styled-components'
import tw from 'twin.macro'

export const ProfileFormWrapper = styled.div`
  ${tw`max-w-[400px] w-full`}
`

export const Heading = styled.h2`
  ${tw`text-4xl text-center text-primary-text font-primary leading-10 pb-8`}
`

export const AssessmentListWrapper = styled.div`
  ${tw`grid grid-cols-1 mt-8`}
`

export const ContinueButtonWrapper = styled.div`
  ${tw`mt-16 mb-8`}
`

export const SkipButtonWrapper = styled.div`
  ${tw`flex justify-center`}
`
export const SkipButton = styled.div`
  ${tw`w-min`}
`

export const SelectFieldWrapper = styled.p<{ applyStyling?: boolean }>(({ applyStyling }) => {
  return [applyStyling ? tw`xs:col-span-12 sm:col-span-6 md:col-span-6 mdl:col-span-6 mt-3` : tw``]
})
