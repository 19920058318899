import React from 'react'

function FileUploadIcon({ className }) {
  return (
    <svg
      className={className}
      width="26"
      height="20"
      viewBox="0 0 26 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8679 3.40242C21.0633 1.5929 18.1361 1.5929 16.3315 3.40242L4.42524 15.345C1.5248 18.2561 1.5248 22.9712 4.42524 25.8824C7.32693 28.7935 12.0277 28.7935 14.93 25.8824L24.3563 16.4273C24.7407 16.0354 25.3733 16.0354 25.7578 16.4273C26.1485 16.813 26.1485 17.4475 25.7578 17.8331L16.3315 27.2882C12.654 30.9769 6.64476 30.9769 3.02246 27.2882C-0.653352 23.5995 -0.65329 17.5719 3.02246 13.9392L14.93 1.99536C17.5098 -0.592204 21.6896 -0.592204 24.2694 1.99536C26.8493 4.58243 26.8493 8.77623 24.2694 11.3639L12.8711 22.797C11.1222 24.5512 8.24475 24.3895 6.64476 22.4549C5.37779 20.7443 5.50989 18.4116 7.00445 16.9125L16.4183 7.46994C16.8028 7.07806 17.4354 7.07806 17.8199 7.46994C18.2106 7.85561 18.2106 8.49009 17.8199 8.87576L8.40599 18.3183C7.57499 19.1021 7.55638 20.3462 8.19514 21.2108C9.00133 22.2185 10.5579 22.3056 11.4695 21.3912L22.8679 9.95811C24.6725 8.14797 24.6725 5.21256 22.8679 3.40242Z"
        fill="rgba(94, 6, 185, 1)"
      />
    </svg>
  )
}

export default FileUploadIcon
