import styled from 'styled-components'
import tw from 'twin.macro'

export const DividerWrapper = styled.div<{
  error: boolean
}>`
  ${tw`border-r absolute left-10 p-3 h-full border-app-grey-40  `}
`

export const AppPhoneInputWrapper = styled.div<{
  error: boolean
  disabled: boolean
  size: 'lg' | 'md' | 'sm'
}>`
  ${tw`rounded-lg flex relative w-full gap-2 items-center `}

  ${({ disabled }) =>
    disabled && tw`bg-app-grey-20  border border-app-grey-40 appShadow-s cursor-not-allowed`}
  ${({ error }) => error && tw`bg-app-red-10 border border-app-red-60 appShadow-s`}
  ${({ error, disabled }) =>
    !error && !disabled && tw`bg-app-white-100 border border-app-grey-40 appShadow-s`}
  ${({ error }) =>
    error &&
    ` .divider {
     border-color:rgba(236, 89, 98, 1) !important
  }
  &:focus-within .divider  {
    border-color:rgba(219, 219, 219, 1) !important
  } 
  &:hover .divider {
    border-color:rgba(165, 165, 165, 1) !important
  }`}
  
  &:hover .divider {
    border-color: rgba(165, 165, 165, 1) !important;
  }

  &:hover {
    ${tw`border border-app-grey-50 appShadow-s`}
  }
  &:focus-within {
    ${tw`bg-app-white-100 border border-app-primary-60 appShadow-focus`}
  }
  ${({ size }) => size === 'lg' && tw`!h-[50px]`}
  ${({ size }) => size === 'md' && tw`!h-[42px]`}
  ${({ size }) => size === 'sm' && tw`!h-[34px]`}
  ${({ size }) => size === 'lg' && tw`px-4 py-3.5`}
  ${({ size }) => size === 'md' && tw`px-4 py-2.5`}
  ${({ size }) => size === 'sm' && tw`px-3 py-1.5`}

  .react-tel-input {
    width: 100%;
    border: none;
    background: transparent;
    &:focus {
      outline: none;
    }
  }

  .react-tel-input .form-control {
    border: none;
    background: transparent;
    &:disabled {
      background-color: transparent;
    }
    height: auto;
    line-height: 24px;
    padding-left: 56px;
    width:100% !important
  }
  .react-tel-input .selected-flag {
    background: transparent;
    padding: 0px 12px 0px 0px !important;
  }
  .react-tel-input .selected-flag .arrow {
    top: auto;
    margin-left: 6px;
  }
  .react-tel-input .arrow {
    width: 10px !important;
    height: 10px !important;
    border: solid 2px rgba(165, 165, 165, 1) !important;
    border-width: 0 0 2px 2px !important;
    transform: rotate(-45deg) !important;
  }
  .react-tel-input .up {
    width: 10px !important;
    height: 10px !important;
    border: solid 2px rgba(165, 165, 165, 1) !important;
    border-width: 0 0 2px 2px !important;
    transform: rotate(135deg); !important
    top: 5px !important;
  }
`
