import React, { useEffect, useState } from 'react'
import { listTimeZones } from 'timezone-support'
import { useHistory } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { ChangeTimezoneWrapper } from './style'
import SelectField from '../SelectField'
import { UserProfile } from '../../../__generated__/api-types-and-hooks'
import moment from 'moment-timezone'
import Button from 'components/Common/Button'
import {
  getTimeZoneDisplayRepresentation,
  getTimeZoneOffset,
  isTimeZoneNameValid,
} from 'utils/dates'

export interface ChangeTimezoneProps {
  user: UserProfile
  updateUserProfileAction: (payload: any) => {
    type: 'UPDATE_USER_PROFILE_STARTED'
    payload: any
  }
}
const ChangeTimezone = ({ updateUserProfileAction, user }: ChangeTimezoneProps) => {
  const [timezone, setTimezone] = useState(user?.timezone || moment.tz.guess())
  const [currentTime, setCurrentTime] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date())
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const handleSubmit = (value: string) => {
    updateUserProfileAction({ timezone: value })
  }

  const sortByOffset = (a: { label: string }, b: { label: string }) => {
    const offsetA = getTimeZoneOffset(a.label)
    const offsetB = getTimeZoneOffset(b.label)
    return offsetA - offsetB
  }

  const options = listTimeZones()
    .filter(isTimeZoneNameValid)
    .map((timezone: string) => {
      return {
        label: `${getTimeZoneDisplayRepresentation(timezone)} (${moment(currentTime)
          .tz(timezone)
          .format('hh:mm a')})`,
        value: timezone,
      }
    })
    .sort(sortByOffset)

  const history = useHistory()

  return (
    <div className="grid-flow-row auto-rows-max pt-6 w-full">
      <div className="xs:ml-0 md:ml-12 mb-12">
        <div className="flex items-center cursor-pointer" onClick={() => history.goBack()}>
          <div className="flex-initial">
            <ChevronLeftIcon className={`h-7 w-7 inline mt-1 mr-2 text-primary`} />
          </div>
          <div className="flex-initial pl-2">
            <p className="text-lg mt-1 text-primary font-primary">ACCOUNT SETTINGS</p>
          </div>
        </div>
      </div>

      <ChangeTimezoneWrapper>
        <h1 className="text-2xl smd:text-3xl md:text-4xl font-light mb-8">
          Change default time zone
        </h1>
        <SelectField
          label="Default Time Zone"
          placeholder="Select"
          isDropDownMenu={true}
          value={timezone}
          name="timezone"
          options={options}
          onChange={(selectedTimezone: string) => setTimezone(selectedTimezone)}
        />
        <Button
          onClick={() => handleSubmit(timezone)}
          label="Save"
          variant="primary"
          className="px-2 mt-12"
          loading={false}
        />
      </ChangeTimezoneWrapper>
    </div>
  )
}

export default ChangeTimezone
