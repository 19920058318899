import React, { FC } from 'react'
import Heading from 'components/Common/Heading'
import Product from 'components/Owner/Data/Product'
import { OverView } from 'components/Owner/Data/Overview'
import { PageHeading } from 'components/Owner/Data/styles'
import { FinanceContainer } from 'pages/Owner/container/data'
import { CLIENT_DATA } from 'components/Clients/Client/data'
import { useFeatureFlag } from 'hooks/userFeatureFlag'

interface IBusinessDataProps {
  isConsentGivenToAdvisor: boolean
  clientId: string
}
const BusinessData: FC<IBusinessDataProps> = ({ isConsentGivenToAdvisor, clientId }) => {
  const dataChartsEnabled = useFeatureFlag(clientId, 'release-data-charts')

  return (
    <div className="px-3 py-6 mdl:px-12">
      {isConsentGivenToAdvisor && dataChartsEnabled ? (
        <>
          <PageHeading>Overview</PageHeading>
          <OverView />
          <FinanceContainer />
          <Product />
        </>
      ) : (
        <>
          <Heading className="!text-2xl font-medium" variant="primary">
            Overview
          </Heading>
          <div className="grid grid-cols-1 mdl:grid-cols-11">
            <p className="col-span-7 pt-10">
              Your client’s data will appear here! First your client needs to allow you access. You
              can help by using the instructions below to guide them to their Data page and from
              there they can turn the share with advisor toggle to on.
            </p>
          </div>
          <div className="mt-16 mdl:grid mdl:grid-cols-12 gap-8">
            {CLIENT_DATA.map((data, index) => (
              <div
                className={`${index === 0 ? 'mdl:pr-7' : ''} pt-2 mdl:pt-0 mdl:col-span-4`}
                key={index}
              >
                <Heading className="text-sm">{data.title}</Heading>
                <p className="pt-1">{data.text}</p>
                {data.image && (
                  <img src={data.image} alt={data.image} className="mt-7 h-[294px] w-[460px]" />
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default BusinessData
