import React from 'react'

import history from 'utils/history'
import { IOptionTypes } from 'components/Assessment/AssessmentQuestion/utils'

import AssessmentAvatar from 'assets/svgs/AssessmentAvatar.svg'
import CloseButton from 'components/Common/CloseButton'

const QuestionBox = ({ closeURL, children, height, activeQuestion, playQuestion }) => {
  return (
    <div className="flex text-center items-center justify-center flex-col question-container">
      <div className="w-full">
        <div
          className={`flex-1 p:2 justify-between flex flex-col overflow-auto relative ${height} `}
        >
          <div
            id="messages"
            className="flex flex-col w-full max-w-[910px] my-0 mx-[auto] items-center  overflow-y-auto text-center scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
          >
            {(activeQuestion?.optionType === IOptionTypes.BUTTON || playQuestion) && (
              <div className="flex w-full items-center relative justify-center">
                <img src={AssessmentAvatar} className="w-28 h-28 rounded-full" alt="person Icon" />
              </div>
            )}
            <div className="w-full font-normal text-primary-text xs:max-w-full overflow-x-hidden lg:max-w-[700px] ">
              {children}
            </div>
          </div>
        </div>
      </div>
      {closeURL && (
        <CloseButton
          className="absolute top-4 right-6 close-icon-wrapper"
          onClick={() => history.push(closeURL)}
        />
      )}
    </div>
  )
}

QuestionBox.defaultProps = {
  closeURL: '',
  height: 'h-full',
  activeQuestion: {},
  playQuestion: false,
}

export default QuestionBox
