import React, { useEffect, useRef } from 'react'

import { isEmpty, size } from 'lodash'

import {
  ListItem,
  NotificationWrapper,
  NotificationPanelWrapper,
  NotificationPanelMobileIcon,
  NotificationPanelDesktopIcon,
  NotificationPanelHeader,
  NotificationPanelHeaderTitle,
  NotificationPanelHeaderLoading,
  NotificationBellIconWrapper,
  NotificationPanelCancelIcon,
} from 'components/NotificationPanel/style'
import Message from 'components/NotificationPanel/Message'
import CircularLoading from 'components/Common/CircularLoading'

import { useOutsideClick } from 'hooks/useClickOutside'

import { updateAmplitudeLog, viewNotificationDetails } from 'utils/helper'
import { AnnouncementContainer } from 'pages/Announcement/containers'

const NotificationPanel = ({
  name,
  isLoading,
  totalCount,
  activeStep,
  handleClose,
  businessName,
  notifications,
  lastEvaluatedKey,
  resetUserNotifications,
  getUserNoticationsAction,
  updateUserNoticationsAction,
  getUnreadNotificationsAction,
  setShowNotificationTooltip = (bool) => {},
}) => {
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, handleClose, activeStep)
  const handleScroll = (e) => {
    let scrollDifference = e.target.scrollHeight - e.target.scrollTop
    scrollDifference = scrollDifference - e.target.clientHeight - 10
    if (scrollDifference < 0 && !isEmpty(lastEvaluatedKey)) {
      getUserNoticationsAction({ viewMore: true })
    }
  }
  // const onClearNotifications = (notification?: any) => {
  //   if (notification) {
  //     const data = JSON.parse(notification.sender)
  //     const originator = join(compact([data?.firstName, data?.lastName]), ' ')

  //     ampli.notificationCleared({
  //       id: notification.id,
  //       type: notification.notificationType,
  //       originator: originator,
  //     })

  //     deleteUserNoticationsAction({ id: notification.id })
  //   } else {
  //     ampli.notificationsCleared()
  //   }
  // }

  useEffect(() => {
    getUserNoticationsAction()
    return () => {
      getUnreadNotificationsAction()
      updateUserNoticationsAction()
      resetUserNotifications()
    }
    // eslint-disable-next-line
  }, [getUserNoticationsAction, getUnreadNotificationsAction, updateUserNoticationsAction])
  return (
    <NotificationWrapper ref={wrapperRef}>
      <NotificationPanelWrapper>
        <NotificationPanelMobileIcon>
          <div onClick={handleClose}>
            <NotificationPanelCancelIcon className={'!fill-primary '} />
          </div>
        </NotificationPanelMobileIcon>

        <NotificationPanelDesktopIcon onClick={handleClose}>
          <NotificationPanelCancelIcon className={' !fill-primary'} />
        </NotificationPanelDesktopIcon>

        <NotificationPanelHeader>
          <NotificationPanelHeaderTitle>
            <NotificationBellIconWrapper className={'fill-black-light'} />
            <NotificationPanelHeaderLoading>
              Notifications {isLoading && <CircularLoading className="ml-1" />}
            </NotificationPanelHeaderLoading>
          </NotificationPanelHeaderTitle>
          <AnnouncementContainer
            setShowNotificationTooltip={setShowNotificationTooltip}
            totalCount={totalCount}
          />
          {size(notifications) === 0 && (
            <div className="text-xs font-primary pt-5">
              <p>
                When you receive a chat message or a platform update, you’ll see a notification in
                this area.
              </p>
            </div>
          )}

          {/* {size(notifications) > 0 ? (
            <NotificationDesktopClearCount onClick={() => onClearNotifications()}>
              Clear All
            </NotificationDesktopClearCount>
          ) : ( */}
          <div></div>
        </NotificationPanelHeader>
      </NotificationPanelWrapper>

      <ListItem id="notficationList" onScroll={handleScroll}>
        {notifications.map((notification) => {
          return (
            <li
              key={notification.id}
              className={`${notification.url ? 'cursor-pointer' : ''} li-body`}
              onClick={() => {
                viewNotificationDetails(notification, handleClose)
                updateAmplitudeLog(name, businessName, notification)
              }}
            >
              <div className="unread-icon-body">
                <Message notification={notification} handleClose={handleClose} />
              </div>
              {/* <div
                onClick={() => onClearNotifications(notification)}
                className="clear-notification"
              >
                Clear
              </div> */}
            </li>
          )
        })}
      </ListItem>
    </NotificationWrapper>
  )
}

export default NotificationPanel
