import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import {
  NameWrapper,
  ValueWrapper,
  DetailWrapper,
  DetailsWrapper,
} from 'components/Admin/UserManagement/style'
import { licenseLabels } from 'components/Admin/UserManagement/config/data/index'
import { USER_MANAGEMENT_TAB } from 'config'
import { useGetBusinessSupportOrganizationQuery } from '__generated__/api-types-and-hooks'

interface IDetailsProps {
  tenantId: string
  isUserAddedToGroup: boolean
  isDisableUserInProgress: boolean
}

const Details: FC<IDetailsProps> = ({ tenantId, isUserAddedToGroup, isDisableUserInProgress }) => {
  const { data, refetch } = useGetBusinessSupportOrganizationQuery({ input: { tenantId } })
  const { licensesUsed, licenseCount, adminCount, advisorCount } =
    data?.getBusinessSupportOrganization || {}

  const { isSelectedTab } = useParams()
  useEffect(() => {
    refetch()
  }, [refetch, isUserAddedToGroup, isDisableUserInProgress])

  return (
    <DetailsWrapper>
      {isSelectedTab === USER_MANAGEMENT_TAB.BUSINESSES ? (
        <>
          <DetailWrapper>
            <ValueWrapper>{licensesUsed}</ValueWrapper>
            <NameWrapper> {licenseLabels.filled}</NameWrapper>
          </DetailWrapper>
          <DetailWrapper>
            <ValueWrapper>{licenseCount! - licensesUsed!}</ValueWrapper>
            <NameWrapper>{licenseLabels.unFilled}</NameWrapper>
          </DetailWrapper>
          <DetailWrapper>
            <ValueWrapper>{licenseCount}</ValueWrapper>
            <NameWrapper> {licenseLabels.total}</NameWrapper>
          </DetailWrapper>
        </>
      ) : (
        <>
          <DetailWrapper>
            <ValueWrapper>{advisorCount}</ValueWrapper>
            <NameWrapper>{licenseLabels.advisorCountsLabel}</NameWrapper>
          </DetailWrapper>
          <DetailWrapper>
            <ValueWrapper>{adminCount}</ValueWrapper>
            <NameWrapper> {licenseLabels.adminCountsLabel}</NameWrapper>
          </DetailWrapper>
        </>
      )}
    </DetailsWrapper>
  )
}

export default Details
