import React from 'react'
import { ArrowRightIcon } from '@heroicons/react/solid'
import { COMPANY_NAME } from 'config'
import PolicyOverlayPage from 'components/Common/PolicyOverlayPage'
import PrivacyPolicyBannerImg from 'assets/svgs/privacy-policy-banner-image.svg'
import { AppFC } from 'types'

export const FIRST_PARAGRAPH_TEST_ID = 'FIRST_PARAGRAPH_TEST_ID'

const PrivacyPolicy = ({ user, setShow }) => {
  return (
    <PolicyOverlayPage
      user={user}
      setShow={setShow}
      title="Online Privacy Policy"
      lastRevised="December 27, 2022"
      bannerImageSrc={PrivacyPolicyBannerImg}
    >
      <p data-testid={FIRST_PARAGRAPH_TEST_ID}>
        This Privacy Policy for {COMPANY_NAME}, Inc., a Delaware Corporation ("Company", "we", "us"
        "our") describes how we collect, use and disclose information about users of the Company's
        platform (URL or name of platform here), applications, services, tools and features
        (collectively, the "Services" ). For the purposes of this Privacy Policy, "you" and " your "
        means you as the user of the Services.
      </p>
      <br />
      <p>
        PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY USING, ACCESSING, OR DOWNLOADING ANY OF THE
        SERVICES, YOU AGREE TO THE USE OF YOUR INFORMATION IT DESCRIBES AND TO THE OTHER TERMS OF
        THIS PRIVACY POLICY. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT ACCESS AND
        USE THE SERVICES.
      </p>
      <br />
      <ol className="list-decimal list-inside">
        <SectionTitle> UPDATING THIS PRIVACY POLICY</SectionTitle>
        <br />
        <p>
          We may modify this Privacy Policy from time to time in which case we will update the "Last
          Revised" date at the top of this Privacy Policy. If we make changes that are material, we
          will use reasonable efforts to attempt to provide notice to you and, where required by
          applicable law, we will obtain your consent. Notice may be by email to you at the last
          email address you provided us, by posting notice of such changes on the Services, or by
          other means, consistent with applicable law. However, it is your sole responsibility to
          review the Privacy Policy from time to time to view any such changes. The updated Privacy
          Policy will be effective as of the time of posting, or such later date as may be specified
          in the updated Privacy Policy.{' '}
          <strong className="md:text-lg">
            IF YOU DO NOT AGREE TO ANY UPDATES TO THIS PRIVACY POLICY PLEASE DO NOT ACCESS OR
            CONTINUE TO USE THE SERVICES.
          </strong>
        </p>
        <br />
        <SectionTitle>COMPANY’S COLLECTION AND USE OF INFORMATION</SectionTitle>
        <br />
        <p>
          In order to provide you with particular services, we may ask you to provide us with
          certain details or information about you. Information that you submit through our Services
          may include:
        </p>
        <br />
        <ul className="ml-5">
          <li role={'list'} className="list-disc list-inside">
            Basic contact details, including name, address and email. We collect basic contact
            details to respond to your inquiries, provide you with products and services and, with
            your consent, to market to you.
          </li>
          <br />
          <li role={'list'} className="list-disc list-inside">
            Profile and information used to create an account, including information about your
            business.
          </li>
          <br />
          <li role={'list'} className="list-disc list-inside">
            Any other information you choose to include in communications with us, for example, when
            emailing us or engaging with us on one of our social media platforms. We may use this
            information to share testimonials on the Services.
          </li>
          <br />
          <li role={'list'} className="list-disc list-inside">
            Information we receive about you from third parties. For example, if you access the
            Services through a small business development center's platform or log-in, we may also
            collect your email address and other Information associated with that third party, and
            any Information you have made public in connection with that service or which the third
            party shares with partner services. See also Section 7 "THIRD PARTY WEBSITES AND LINKS"
            below.
          </li>
        </ul>
        <br />
        <p>
          Some features of the Services may require you to enter certain information about yourself.
          You may elect not to provide this information, but doing so may prevent you from using or
          accessing these features.
        </p>
        <br />
        <p>
          We also automatically collect certain information about your interaction with the Services
          ("Usage Data"). To do this, we may use product analytics technologies (" Tracking
          Technologies "). Usage Data may include:
        </p>
        <br />
        <ul className="ml-5">
          <li role={'list'} className="list-disc list-inside">
            Unique device identifier
          </li>
          <br />
          <li role={'list'} className="list-disc list-inside">
            Device type, such as your phone, computer, or tablet
          </li>
          <br />
          <li role={'list'} className="list-disc list-inside">
            IP address
          </li>
          <br />
          <li role={'list'} className="list-disc list-inside">
            Browser type
          </li>
          <br />
          <li role={'list'} className="list-disc list-inside">
            Date and time stamps, such as the date and time you first accessed the Services
          </li>
          <br />
          <li role={'list'} className="list-disc list-inside">
            Operating system
          </li>
          <br />
          <li role={'list'} className="list-disc list-inside">
            Log data
          </li>
          <br />
        </ul>
        <br />
        <p>
          We use the information we collect automatically to<span> </span>
          run analytics and better understand user interaction with the Services.
        </p>
        <br />
        <p>
          We may obtain information about you from outside sources. Such information may include:
        </p>
        <br />
        <ul className="ml-5">
          <li role={'list'} className="list-disc list-inside">
            Information we collect about you from other sources, such as when you choose to interact
            with us on our social media accounts such as Facebook and Instagram. This information is
            used to communicate with you.
          </li>
          <br />
          <li role={'list'} className="list-disc list-inside">
            If you choose to connect your GoTackle account to Google Calendar or Outlook (Office
            365), you will be able to view ane edit events for such calendars within the platform.
            This function enables a User to coincide GoTackle events with your current calendar, and
            is used in filtering availability when users request a meeting with you. If you make
            changes in the external calendar, those changes will reflect in GoTackle.
            <p className="mt-2">
              To provide this Service, we need to access your Google or Outlook calendar and collect
              the data from such calendars. The data we collect include name of the event,
              description of the event, start and end time, link and calendar name. We ask Users for
              such authorization explicitly prior to processing such data.
            </p>
            <p className="mt-2">
              Personal data from your connected calendar are only displayed in GoTackle, while data
              from GoTackle will not be displayed or shared in Google Calendar or Outlook.
            </p>
            <p className="mt-2">
              GoTackle's use and transfer to any other app of information received from Google's
              APIs will adhere to the{' '}
              <a
                href="https://developers.google.com/terms/api-services-user-data-policy"
                target="_blank"
                className="text-blue"
                rel="noreferrer"
                tabIndex={-1}
              >
                Google API Services User Data Policy
              </a>
              , including the Limited Use requirements.
            </p>
          </li>
        </ul>

        <br />
        <p>
          Any information we receive from outside sources will be treated in accordance with this
          Privacy Policy. We are not responsible or liable for any third party's policies or
          practices. See Section 6 below for more information.
        </p>
        <br />
        <p>
          In addition to the foregoing, we may use all of the above information to comply with any
          applicable legal obligations, to enforce any applicable terms of service, to protect or
          defend the Services, our rights, the rights of our users, or others and for any other
          lawful purpose.
        </p>
        <br />
        <SectionTitle>HOW THE COMPANY SHARES YOUR INFORMATION</SectionTitle>
        <br />
        <p>
          In certain circumstances, the Company may share your information with third parties. Such
          circumstances may include:
        </p>
        <br />
        <ul className="ml-5">
          <li role={'list'} className="list-disc list-inside">
            [ With vendors or other service providers, such as ]
          </li>
          <ul className="list-disc marker:text-silver-darker list-inside ml-5">
            <br />
            <span className="">
              <li>[Cloud storage providers]</li>
            </span>
            <span className="ml-5">
              <li>[IT service management vendors]</li>
            </span>

            <span className="ml-5">
              <li>[Email marketing services vendors]</li>
            </span>

            <span className="ml-5">
              <li>[Security vendors]</li>
            </span>
          </ul>
          <br />
          <li role={'list'} className="list-disc list-inside">
            With the small business development center that has authorized your use of the Services
            and their employees and subcontractors.
          </li>
          <br />
          <li role={'list'} className="list-disc list-inside">
            To comply with applicable law or any obligations thereunder, including cooperation with
            law enforcement, judicial orders, and regulatory inquiries
          </li>
          <br />
          <li role={'list'} className="list-disc list-inside">
            In connection with an asset sale, merger, bankruptcy, or other business transaction
          </li>
          <br />
          <li role={'list'} className="list-disc list-inside">
            To enforce any applicable terms of service
          </li>
          <br />
          <li role={'list'} className="list-disc list-inside">
            To ensure the safety and security of the Company and/or its users
          </li>
          <br />
          <li role={'list'} className="list-disc list-inside">
            When you request us to share certain information with third parties, such as through
            your use of third party applications made available through the Services
          </li>
          <br />
          <li role={'list'} className="list-disc list-inside">
            With professional advisors, such as auditors, law firms, or accounting firms
          </li>
          <br />
        </ul>

        <p>
          You acknowledge that such sharing of information may occur in all of the aforementioned
          circumstances and is permitted by and subject to this Privacy Policy.
        </p>
        <br />
        <SectionTitle> COOKIES AND OTHER TRACKING TECHNOLOGIES</SectionTitle>
        <br />
        <p className="italic">Do Not Track Signals</p>
        <br />
        <p>
          Your browser settings may also allow you to transmit a "Do Not Track" signal when you
          visit various websites. Like many websites, our website is not designed to respond to "Do
          Not Track" signals received from browsers. To learn more about "Do Not Track" signals, you
          can visit{' '}
          <a
            href="http://www.allaboutdnt.com/"
            target="_blank"
            className="text-blue"
            rel="noreferrer"
            tabIndex={-1}
          >
            http://www.allaboutdnt.com/
          </a>
          .
        </p>
        <br />
        <p className="italic">Cookies and Other Tracking Technologies</p>
        <br />
        <p>
          You may control the way in which your devices permit the use of Tracking Technologies. If
          you so choose, you may block or delete our cookies from your browser; however, blocking or
          deleting cookies may cause some of the Services, including any portal features and general
          functionality, to work incorrectly.
        </p>
        <br />
        <p>
          Most browsers accept cookies automatically. However, you may be able to configure your
          browser settings to use the Services without some cookie functionality. You can delete
          cookies manually or set your browser to automatically delete cookies on a pre-determined
          schedule. For example, in the Internet Explorer menu bar, select: Tools{' '}
          <ArrowRightIcon className="h-5 w-5 inline-block" />
          Internet Options
          <ArrowRightIcon className="h-5 w-5 inline-block" />
          Browsing History
          <ArrowRightIcon className="h-5 w-5 inline-block" />
          Delete to view manual and automatic options.
        </p>
        <br />
        <p>
          <span>"To opt out of tracking by Google Analytics, click</span>
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            target="_blank"
            className="text-blue"
            rel="noreferrer"
            tabIndex={-1}
          >
            <span> here</span>
          </a>
          ."
        </p>
        <br />
        <p>
          We may also use Flash cookies (also known as "persistent identification elements" or
          "local shared objects") on certain pages. Because Flash cookies cannot be controlled
          through your browser settings, you may click here to adjust your preferences. You can also
          identify Flash cookies running on your computer by visiting the Flash Player folder. Flash
          cookies, or LSO files, are typically stored with a ".SOL" extension. Please note that if
          you block cookies, some functions otherwise available on the Services may be unavailable,
          and we may not be able to present you with personally-tailored content.
        </p>
        <br />
        <p>
          If you have questions regarding the specific information about you that we process or
          retain, as well as your choices regarding our collection and use practices, please contact
          us using the information listed below.
        </p>
        <br />
        <SectionTitle> SOCIAL FEATURES</SectionTitle>
        <br />
        <p>
          Certain features of the Services permit you to initiate interactions between the Services
          and third-party services or platforms, such as social networks (" Social Features ").
          Social Features include features that allow you to click and access the Company's pages on
          certain third-party platforms, such as Facebook and Twitter, and from there to "like" or
          "share" our content on those platforms. Use of Social Features may entail a third party's
          collection and/or use of your data. If you use Social Features or similar third-party
          services, information you post or otherwise make accessible may be publicly displayed by
          the third-party service you are using. Both the Company and the third party may have
          access to information about you and your use of both the Services and the third-party
          service. For more information on third-party websites and platforms, see Section 6.
        </p>
        <br />
        <SectionTitle> THIRD PARTY WEBSITES AND LINKS</SectionTitle>
        <br />
        <p>
          Our Services may contain links to other online platforms operated by third parties. We do
          not control such other online platforms and are not responsible for their content, their
          privacy policies, or their use of your information. Information you provide on public or
          semi-public venues, including information you share on third-party social networking
          platforms (such as Facebook or Instagram) may also be viewable by other users of the
          Services and/or users of those third-party online platforms without limitation as to its
          use by us or by a third party. Our inclusion of such links does not, by itself, imply any
          endorsement of the content on such platforms or of their owners or operators except as
          disclosed on the Services. We expressly disclaim any and all liability for the actions of
          third parties, including but without limitation to actions relating to the use and/or
          disclosure of personal information by third parties. Any information submitted by you
          directly to these third parties is subject to that third party's privacy policy.
        </p>
        <br />
        <SectionTitle> CHILDREN'S PRIVACY</SectionTitle>
        <br />
        <p>
          Children under the age of 13 are not permitted to use the Services, and we do not seek or
          knowingly collect any personal information about children under 13 years of age. If we
          become aware that we have unknowingly collected information about a child under 13 years
          of age, we will make commercially reasonable efforts to delete such information from our
          database.
        </p>
        <br />
        <p>
          If you are the parent or guardian of a child under 13 years of age who has provided us
          with their personal information, you may contact us using the below information to request
          that it be deleted.
        </p>
        <br />
        <SectionTitle> DATA SECURITY</SectionTitle>
        <br />
        <p>
          Please note that any information you send to us electronically, while using the Services
          or otherwise interacting with us, may not be secure when it is transmitted to us. We
          recommend that you do not use unsecure channels to communicate sensitive or confidential
          information to us. Please be aware though that, despite our best efforts, no security
          measures are perfect or impenetrable, and we cannot guarantee "perfect security." Any
          information you send us through any means is transmitted at your own risk.
        </p>
        <br />
        <SectionTitle> INTERNATIONAL USERS</SectionTitle>
        <br />
        <p>
          The Services are designed for users in the United States only and are not intended for
          users located outside the United States.
        </p>
        <br />
        <SectionTitle> DATA SECURITY</SectionTitle>
        <br />
        <p>
          We implement a range of security measures designed to protect your personal information,
          including encryption, anonymization, and secure storage practices. Sensitive data,
          including that provided by Google users, is encrypted both in transit and at rest. We
          regularly review our data collection, storage, and processing practices to ensure that
          only necessary data is collected and that data is protected from unauthorized access,
          alteration, or deletion. Please note that no security measures are perfect or
          impenetrable, and we cannot guarantee absolute security of your data.
        </p>
        <br />
        <SectionTitle> RETENTION AND DELETION OF USER DATA</SectionTitle>
        <br />
        <p>
          We retain user data only as long as necessary to provide the Services for which the data
          was collected or as required by law. Once this data is no longer needed for its initial
          purpose, or upon user request, we will securely delete or anonymize the data in accordance
          with applicable legal requirements. You may request deletion of your data by contacting us
          at legal@gologicsolutions.com. In such cases, we will promptly delete your data unless we
          are required to retain it for legal or regulatory reasons.
        </p>
        <br />
        <SectionTitle>HOW TO CONTACT US</SectionTitle>
        <br />
        <p>
          Should you have any questions about our privacy practices or this Privacy Policy, please
          email us at legal@gologicsolutions.com or contact us at 222 West Merchandise Plaza #1212,
          Chicago, IL 60654.
        </p>
        <br />
      </ol>
    </PolicyOverlayPage>
  )
}

PrivacyPolicy.defaultProps = {
  user: {},
  setShow: () => {},
}
export default PrivacyPolicy

const SectionTitle: AppFC = ({ children }) => (
  <li>
    <strong className="tracking-wide md:text-lg">{children}</strong>
  </li>
)
