import React from 'react'

function SeasonallyIcon({ className }) {
  return (
    <svg
      width="51"
      height="50"
      viewBox="0 0 51 50"
      className={className}
      fill="#71717A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5 46C37.098 46 46.5 36.598 46.5 25C46.5 13.402 37.098 4 25.5 4C13.902 4 4.5 13.402 4.5 25C4.5 36.598 13.902 46 25.5 46ZM25.5 50C39.3071 50 50.5 38.8071 50.5 25C50.5 11.1929 39.3071 0 25.5 0C11.6929 0 0.5 11.1929 0.5 25C0.5 38.8071 11.6929 50 25.5 50Z"
      />
      <rect x="27.5" y="48" width="4" height="48" transform="rotate(-180 27.5 48)" />
      <rect x="27.5" y="48" width="4" height="48" transform="rotate(-180 27.5 48)" />
      <rect x="27.5" y="48" width="4" height="48" transform="rotate(-180 27.5 48)" />
      <rect x="49.5" y="22" width="4" height="48" transform="rotate(90 49.5 22)" />
      <rect x="49.5" y="22" width="4" height="48" transform="rotate(90 49.5 22)" />
      <rect x="49.5" y="22" width="4" height="48" transform="rotate(90 49.5 22)" />
    </svg>
  )
}

SeasonallyIcon.defaultProps = {
  className: '',
}
export default SeasonallyIcon
