import React from 'react'

function AnnouncementYayIcon({ className = '' }) {
  return (
    <>
      <svg
        width="190"
        height="178"
        viewBox="0 0 190 178"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={` ${className}`}
      >
        <ellipse cx="95" cy="79.5" rx="63" ry="62.5" fill="url(#paint0_linear_23382_112304)" />
        <path
          d="M80.3002 105.283C80.3002 111.042 75.4978 120.151 71.363 120.151C67.9173 120.151 62.4258 111.042 62.4258 105.283C62.4258 99.5243 66.4271 94.8555 71.363 94.8555C76.2989 94.8555 80.3002 99.5114 80.3002 105.283Z"
          fill="#263238"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M71.1133 120.428L71.1994 122.517L72.9395 122.082L71.3631 120.152" fill="#263238" />
        <path
          d="M71.1133 120.428L71.1994 122.517L72.9395 122.082L71.3631 120.152"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M77.7443 99.0281C78.7995 100.613 78.7694 102.474 77.684 103.202C76.5986 103.93 74.8628 103.223 73.8076 101.638C72.7524 100.053 72.7825 98.1925 73.8722 97.4646C74.9619 96.7367 76.6933 97.4431 77.7443 99.0281Z"
          fill="white"
        />
        <path
          d="M72.9208 96.8417C72.8518 97.1949 72.3565 97.393 71.8138 97.2724C71.2711 97.1518 70.8878 96.79 70.9524 96.4368C71.017 96.0837 71.5166 95.8855 72.0593 96.0061C72.602 96.1267 72.9897 96.4971 72.9208 96.8417Z"
          fill="white"
        />
        <path
          d="M71.9066 121.224C71.5802 122.512 71.3669 123.826 71.2692 125.152C71.1873 126.086 71.1744 127.025 71.1787 127.968C71.1439 128.646 71.1916 129.326 71.3208 129.993C71.6504 131.013 72.1357 131.976 72.7594 132.848C73.498 133.947 74.1544 135.099 74.7234 136.294C75.0032 136.925 75.2224 137.581 75.3781 138.254C75.4571 138.603 75.5147 138.957 75.5504 139.313C75.5504 139.43 75.5504 139.542 75.5805 139.658C75.615 140.041 75.5805 139.391 75.5805 139.369C75.5332 139.636 75.5461 139.925 75.5116 140.192C75.2489 142.178 74.2927 143.9 73.2117 145.546C72.2167 147.057 71.1529 148.518 70.0976 149.986C69.3573 150.95 68.7085 151.981 68.1594 153.066C67.6112 154.289 67.2971 155.604 67.2334 156.942C67.1279 158.872 67.1581 160.806 67.3239 162.731C67.3239 162.472 67.3497 162.016 67.3239 161.693C67.1214 158.902 67.0741 156.094 68.4437 153.578C69.0439 152.531 69.7115 151.524 70.4422 150.563C71.4802 149.138 72.501 147.699 73.4572 146.222C74.3885 144.95 75.0417 143.497 75.3741 141.957C75.7066 140.416 75.7109 138.823 75.3867 137.28C74.8613 134.804 73.3236 132.818 72.0918 130.673C71.8087 130.2 71.5735 129.699 71.3898 129.179C71.174 128.384 71.114 127.554 71.2132 126.737C71.2917 125.229 71.5182 123.733 71.8894 122.27C71.9798 121.934 71.7989 121.529 71.8894 121.193L71.9066 121.224Z"
          fill="#263238"
        />
        <path
          d="M18.8744 39.7298C18.8744 45.4884 14.072 54.5979 9.93721 54.5979C6.49154 54.5979 1 45.4884 1 39.7298C1 33.9712 5.00129 29.3066 9.93721 29.3066C14.8731 29.3066 18.8744 33.9712 18.8744 39.7298Z"
          fill="#263238"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M9.6875 54.8733L9.77364 56.9579L11.5137 56.5229L9.93731 54.5977" fill="#263238" />
        <path
          d="M9.6875 54.8733L9.77364 56.9579L11.5137 56.5229L9.93731 54.5977"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.3212 33.4708C17.3722 35.0558 17.342 36.9164 16.2566 37.6443C15.1712 38.3722 13.4355 37.6443 12.3802 36.0809C11.325 34.5174 11.3595 32.6352 12.4448 31.9073C13.5302 31.1794 15.266 31.8901 16.3212 33.4708Z"
          fill="white"
        />
        <path
          d="M11.502 31.287C11.4331 31.6402 10.9378 31.8383 10.3951 31.7177C9.85237 31.5971 9.47334 31.2353 9.53364 30.8821C9.59394 30.529 10.0979 30.3308 10.6406 30.4514C11.1833 30.572 11.5666 30.9338 11.502 31.287Z"
          fill="white"
        />
        <path
          d="M10.4813 55.6692C10.157 56.9565 9.94379 58.2692 9.84386 59.5929C9.76633 60.5319 9.7491 61.4708 9.75341 62.4098C9.71874 63.0892 9.76647 63.7704 9.89554 64.4384C10.2267 65.4575 10.7134 66.4193 11.3384 67.2897C12.0772 68.3949 12.7323 69.5539 13.2981 70.7569C13.5784 71.3863 13.7977 72.0411 13.9528 72.7123C14.0315 73.0617 14.089 73.4155 14.1251 73.7719C14.1251 73.8882 14.1251 74.0045 14.1553 74.1207C14.1897 74.4998 14.1553 73.8494 14.1553 73.8279C14.1079 74.0949 14.1208 74.3835 14.0863 74.6548C13.8236 76.6361 12.8717 78.3589 11.7864 80.0085C10.7957 81.5203 9.72757 82.9804 8.67233 84.4448C7.93106 85.4098 7.28214 86.4423 6.73414 87.5287C6.1878 88.7522 5.87379 90.0667 5.80811 91.4051C5.70651 93.3317 5.73817 95.263 5.90287 97.1852C5.90287 96.9311 5.92871 96.4745 5.90287 96.1472C5.70044 93.3605 5.65306 90.548 7.02271 88.0369C7.62306 86.9899 8.29063 85.9828 9.0212 85.022C10.0592 83.5963 11.0843 82.1621 12.0362 80.6804C12.9675 79.409 13.6207 77.9557 13.9531 76.4152C14.2856 74.8746 14.2899 73.2813 13.9657 71.7389C13.4403 69.2623 11.907 67.2811 10.6751 65.1318C10.3898 64.6603 10.1531 64.161 9.96876 63.6416C9.75294 62.8451 9.69298 62.0144 9.79217 61.1952C9.87069 59.6892 10.0972 58.1946 10.4684 56.733C10.5588 56.3971 10.3822 55.9879 10.4684 55.6562L10.4813 55.6692Z"
          fill="#263238"
        />
        <path
          d="M189 61.1189C189 66.8774 184.193 75.9869 180.058 75.9869C176.613 75.9869 171.121 66.8774 171.121 61.1189C171.121 55.3603 175.122 50.6914 180.058 50.6914C184.994 50.6914 189 55.3603 189 61.1189Z"
          fill="#77FADB"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M179.809 76.262L179.895 78.3466L181.635 77.9116L180.058 75.9863" fill="#263238" />
        <path
          d="M179.809 76.262L179.895 78.3466L181.635 77.9116L180.058 75.9863"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M186.442 54.8601C187.493 56.4451 187.463 58.3058 186.378 59.0337C185.292 59.7616 183.557 59.0337 182.501 57.4702C181.446 55.9067 181.481 54.0245 182.566 53.2966C183.651 52.5687 185.391 53.2751 186.442 54.8601Z"
          fill="white"
        />
        <path
          d="M181.631 52.6757C181.562 53.0289 181.067 53.227 180.524 53.1064C179.981 52.9858 179.602 52.624 179.663 52.2708C179.723 51.9176 180.227 51.7195 180.769 51.8401C181.312 51.9607 181.687 52.3182 181.631 52.6757Z"
          fill="white"
        />
        <path
          d="M180.6 77.0586C180.276 78.3459 180.063 79.6587 179.963 80.9824C179.885 81.9213 179.868 82.8603 179.873 83.7992C179.838 84.4787 179.886 85.1599 180.015 85.8279C180.347 86.8466 180.833 87.8083 181.458 88.6792C182.195 89.7779 182.85 90.9296 183.417 92.1248C183.697 92.7559 183.916 93.4121 184.072 94.0846C184.153 94.4335 184.211 94.7875 184.244 95.1441C184.244 95.2604 184.244 95.3767 184.274 95.4887C184.309 95.872 184.274 95.2216 184.274 95.2001C184.227 95.4671 184.24 95.7557 184.205 96.0228C183.943 98.0083 182.991 99.7312 181.905 101.376C180.915 102.888 179.851 104.348 178.791 105.817C178.05 106.782 177.401 107.815 176.853 108.901C176.307 110.124 175.993 111.439 175.927 112.777C175.824 114.707 175.854 116.641 176.018 118.566C176.018 118.312 176.044 117.851 176.018 117.528C175.815 114.741 175.768 111.929 177.138 109.418C177.738 108.37 178.407 107.363 179.14 106.403C180.174 104.977 181.199 103.543 182.155 102.061C183.087 100.79 183.74 99.3366 184.072 97.796C184.405 96.2554 184.409 94.6621 184.085 93.1198C183.564 90.6432 182.026 88.6576 180.794 86.5127C180.509 86.0412 180.272 85.5419 180.088 85.0224C179.872 84.2259 179.812 83.3953 179.911 82.576C179.989 81.07 180.216 79.5754 180.587 78.1139C180.678 77.7736 180.501 77.3688 180.587 77.0371L180.6 77.0586Z"
          fill="#263238"
        />
        <path
          d="M148.687 20.5017C148.687 26.2603 143.881 35.3698 139.75 35.3698C136.304 35.3698 130.809 26.2603 130.809 20.5017C130.809 14.7431 134.814 10.0742 139.75 10.0742C144.686 10.0742 148.687 14.7431 148.687 20.5017Z"
          fill="#263238"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M139.496 35.6467L139.582 37.7357L141.322 37.3007L139.746 35.3711" fill="#263238" />
        <path
          d="M139.496 35.6467L139.582 37.7357L141.322 37.3007L139.746 35.3711"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M146.13 14.2305C147.181 15.8155 147.151 17.6761 146.065 18.404C144.98 19.1319 143.244 18.4256 142.189 16.8406C141.134 15.2556 141.168 13.3949 142.253 12.667C143.339 11.9391 145.079 12.6627 146.13 14.2305Z"
          fill="white"
        />
        <path
          d="M141.299 12.078C141.23 12.4312 140.735 12.6336 140.192 12.5087C139.649 12.3838 139.27 12.0263 139.331 11.6732C139.391 11.32 139.895 11.1219 140.437 11.2425C140.98 11.3631 141.372 11.7076 141.299 12.078Z"
          fill="white"
        />
        <path
          d="M140.284 36.4434C139.96 37.7321 139.746 39.0463 139.647 40.3714C139.569 41.3061 139.552 42.245 139.556 43.1883C139.521 43.8677 139.569 44.5489 139.698 45.2169C140.03 46.2356 140.517 47.1973 141.141 48.0682C141.878 49.167 142.533 50.3186 143.101 51.5139C143.381 52.1449 143.6 52.8011 143.756 53.4736C143.837 53.8225 143.894 54.1765 143.928 54.5331C143.928 54.6494 143.928 54.7614 143.958 54.8777C143.992 55.261 143.958 54.6107 143.958 54.5891C143.911 54.8562 143.923 55.1447 143.889 55.4118C143.626 57.3973 142.674 59.1202 141.589 60.7655C140.598 62.2773 139.535 63.7374 138.475 65.2061C137.735 66.1702 137.086 67.2012 136.537 68.2857C135.991 69.5092 135.677 70.8237 135.611 72.162C135.507 74.0914 135.538 76.0256 135.701 77.9508C135.701 77.6967 135.731 77.2358 135.701 76.9128C135.503 74.1218 135.451 71.3136 136.821 68.7982C137.422 67.7507 138.091 66.7435 138.824 65.7833C139.858 64.3576 140.883 62.919 141.839 61.4417C142.77 60.1703 143.423 58.717 143.756 57.1764C144.088 55.6359 144.093 54.0425 143.768 52.5002C143.247 50.0236 141.71 48.038 140.478 45.8931C140.192 45.4205 139.955 44.9197 139.771 44.3986C139.556 43.6035 139.496 42.7742 139.595 41.9564C139.682 40.459 139.912 38.9734 140.284 37.5201C140.374 37.1842 140.198 36.7793 140.284 36.4434Z"
          fill="#263238"
        />
        <path
          d="M73.058 11.4275C73.058 17.186 68.2556 26.2955 64.1208 26.2955C60.6751 26.2955 55.1836 17.186 55.1836 11.4275C55.1836 5.66888 59.1849 1 64.1208 1C69.0567 1 73.058 5.66888 73.058 11.4275Z"
          fill="#77FADB"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M63.8672 26.5706L63.9533 28.6595L65.6934 28.2245L64.1213 26.2949" fill="#263238" />
        <path
          d="M63.8672 26.5706L63.9533 28.6595L65.6934 28.2245L64.1213 26.2949"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M70.5104 5.18629C71.5613 6.7713 71.5312 8.63196 70.4458 9.36417C69.3604 10.0964 67.6289 9.36416 66.5694 7.80069C65.5098 6.23722 65.5486 4.35502 66.634 3.62282C67.7194 2.89061 69.4508 3.58836 70.5104 5.18629Z"
          fill="white"
        />
        <path
          d="M65.6743 2.98553C65.601 3.33872 65.1057 3.53684 64.563 3.41624C64.0203 3.29564 63.6413 2.93816 63.7016 2.58497C63.7619 2.23179 64.2702 2.02936 64.8085 2.15427C65.3469 2.27917 65.7432 2.63235 65.6743 2.98553Z"
          fill="white"
        />
        <path
          d="M64.6581 27.3691C64.3428 28.6573 64.1382 29.9699 64.0465 31.2929C63.9647 32.2318 63.9517 33.1708 63.956 34.1097C63.9175 34.789 63.9638 35.4705 64.0939 36.1384C64.425 37.1588 64.9117 38.122 65.5368 38.994C66.2739 40.0927 66.9289 41.2444 67.4965 42.4396C67.7795 43.0698 68.0002 43.7262 68.1555 44.3994C68.2345 44.7487 68.292 45.1025 68.3277 45.4589L68.3536 45.8035C68.3923 46.1868 68.3536 45.5321 68.3536 45.5149C68.3062 45.7819 68.3234 46.0662 68.2847 46.3376C68.0219 48.3231 67.0701 50.0417 65.989 51.6913C64.9941 53.2031 63.9302 54.6632 62.8707 56.1276C62.1318 57.0942 61.4831 58.1265 60.9325 59.2114C60.3875 60.4352 60.075 61.7497 60.0108 63.0878C59.9052 65.0171 59.9355 66.9515 60.1012 68.8766C60.0797 68.6181 60.127 68.1616 60.1012 67.8342C59.9031 65.0476 59.8514 62.235 61.221 59.724C61.8212 58.6758 62.4903 57.6686 63.2238 56.709C64.2618 55.2791 65.2826 53.8448 66.2388 52.3632C67.1676 51.0913 67.8193 49.6387 68.1517 48.0993C68.484 46.5598 68.4897 44.9678 68.1684 43.426C67.6429 40.9494 66.1053 38.9638 64.8735 36.8146C64.5885 36.3461 64.349 35.8514 64.1585 35.3373C63.947 34.5415 63.8871 33.713 63.9819 32.8951C64.0636 31.3938 64.2901 29.9038 64.6581 28.4459C64.7485 28.11 64.572 27.7051 64.6581 27.3691Z"
          fill="#263238"
        />
        <path
          d="M30.1283 29.1716C30.1283 34.9302 25.3259 44.0397 21.1911 44.0397C17.7454 44.0397 12.2539 34.9302 12.2539 29.1716C12.2539 23.413 16.238 18.7441 21.1911 18.7441C26.1443 18.7441 30.1283 23.413 30.1283 29.1716Z"
          fill="#7E57C2"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M20.9375 44.3167L21.0236 46.4056L22.7637 45.9706L21.1916 44.041" fill="#263238" />
        <path
          d="M20.9375 44.3167L21.0236 46.4056L22.7637 45.9706L21.1916 44.041"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.5729 22.9183C28.6238 24.499 28.5937 26.3639 27.5083 27.0918C26.4229 27.8197 24.6914 27.0918 23.6319 25.5284C22.5723 23.9649 22.6111 22.0827 23.6965 21.3505C24.7819 20.6183 26.5219 21.3332 27.5729 22.9183Z"
          fill="white"
        />
        <path
          d="M22.7446 20.7297C22.6714 21.0829 22.176 21.281 21.6333 21.1604C21.0906 21.0398 20.7116 20.6823 20.7719 20.3291C20.8322 19.9759 21.3405 19.7735 21.8788 19.8984C22.4172 20.0233 22.8135 20.3765 22.7446 20.7297Z"
          fill="white"
        />
        <path
          d="M21.7313 46.1904C23.6756 47.9994 25.4094 50.0222 26.8998 52.2203C27.7612 53.5383 28.4719 54.9036 28.5537 56.4973C28.5752 56.9021 28.6011 55.5971 28.5537 56.1656C28.5537 56.2733 28.5537 56.3767 28.5235 56.48C28.4997 56.6695 28.4608 56.8567 28.4073 57.04C28.2979 57.3736 28.1412 57.6898 27.9421 57.9789C27.3003 58.9394 26.4863 59.7965 25.7885 60.7053C24.3205 62.5346 22.9998 64.4774 21.8389 66.5156C21.3945 67.2887 21.0665 68.1232 20.8655 68.9921C20.7436 69.7031 20.7017 70.4254 20.7406 71.1457C20.7957 72.3566 21.2145 73.5229 21.9423 74.4923C22.8597 75.8232 23.9666 77.0033 24.8367 78.3687C25.97 80.1615 26.6445 82.2058 26.8007 84.3211C26.7706 83.9679 26.8266 83.6018 26.8007 83.2443C26.6645 81.3332 26.1008 79.4771 25.1511 77.813C24.3503 76.5446 23.4669 75.3302 22.5065 74.1779C21.7356 73.1829 21.0034 72.1019 20.7837 70.8313C20.7579 70.659 20.732 69.9698 20.7363 70.6288C20.7406 71.2878 20.7665 70.5341 20.7923 70.3704C20.8565 69.9854 20.953 69.6064 21.0809 69.2376C21.4384 68.272 21.9098 67.3524 22.485 66.4983C23.6651 64.6444 24.9448 62.8558 26.3183 61.1403C27.0027 60.3938 27.6196 59.5881 28.1618 58.7326C28.5134 57.9647 28.6617 57.1193 28.5925 56.2776C28.6169 55.6754 28.5808 55.0723 28.4848 54.4772C28.3068 53.7204 28.0164 52.9945 27.6234 52.3237C26.146 49.6404 23.9623 47.2327 21.7442 45.1309C21.7959 45.1782 21.7442 45.596 21.7442 45.6692C21.7442 45.7425 21.6839 46.1473 21.7442 46.2076L21.7313 46.1904Z"
          fill="#263238"
        />
        <path
          d="M122.874 13.4822C122.874 19.2407 118.072 28.3502 113.937 28.3502C110.492 28.3502 105 19.2407 105 13.4822C105 7.72357 109.001 3.05469 113.937 3.05469C118.873 3.05469 122.874 7.72357 122.874 13.4822Z"
          fill="url(#paint1_linear_23382_112304)"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M113.68 28.6253L113.77 30.7142L115.506 30.2792L113.934 28.3496" fill="#263238" />
        <path
          d="M113.68 28.6253L113.77 30.7142L115.506 30.2792L113.934 28.3496"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M120.313 7.22731C121.364 8.81232 121.338 10.673 120.249 11.4009C119.159 12.1288 117.432 11.4224 116.372 9.8374C115.313 8.2524 115.347 6.39173 116.433 5.66384C117.518 4.93594 119.262 5.6423 120.313 7.22731Z"
          fill="white"
        />
        <path
          d="M115.491 5.03897C115.422 5.39215 114.922 5.59458 114.384 5.46967C113.846 5.34477 113.458 4.98728 113.523 4.6341C113.587 4.28092 114.091 4.08279 114.634 4.20339C115.177 4.32399 115.56 4.68578 115.491 5.03897Z"
          fill="white"
        />
        <path
          d="M114.48 30.5033C116.424 32.312 118.158 34.3348 119.648 36.5332C120.51 37.8512 121.225 39.2165 121.302 40.8102C121.324 41.215 121.354 39.91 121.302 40.4828C121.302 40.5862 121.302 40.6896 121.272 40.7929C121.251 40.9828 121.212 41.1702 121.156 41.3529C121.046 41.6865 120.89 42.0027 120.691 42.2918C120.049 43.2566 119.239 44.1094 118.537 45.0225C117.063 46.8532 115.738 48.7991 114.575 50.8414C114.126 51.6147 113.797 52.451 113.597 53.3223C113.476 54.0333 113.436 54.7557 113.476 55.4758C113.528 56.6866 113.946 57.8533 114.674 58.8224C115.591 60.1533 116.702 61.3334 117.568 62.6988C118.702 64.4929 119.376 66.5388 119.532 68.6555C119.502 68.298 119.558 67.9319 119.532 67.5787C119.402 65.6576 118.839 63.7908 117.887 62.1173C117.089 60.8469 116.205 59.6324 115.242 58.4822C114.475 57.4872 113.739 56.4061 113.519 55.1355C113.493 54.9633 113.472 54.2741 113.472 54.9331C113.472 55.5921 113.502 54.8427 113.528 54.6747C113.592 54.2896 113.689 53.9107 113.816 53.5419C114.174 52.5763 114.645 51.6567 115.221 50.8026C116.402 48.9497 117.682 47.1612 119.054 45.4446C119.739 44.6989 120.356 43.8931 120.897 43.0369C121.249 42.2704 121.397 41.4265 121.328 40.5862C121.352 39.9841 121.317 39.3812 121.225 38.7858C121.046 38.0292 120.756 37.3033 120.363 36.6323C118.886 33.9447 116.707 31.5413 114.488 29.4395C114.536 29.4825 114.488 29.9003 114.488 29.9778C114.488 30.0554 114.428 30.4516 114.488 30.5162L114.48 30.5033Z"
          fill="#263238"
        />
        <path
          d="M167.417 27.8767C167.417 33.6353 162.615 42.7447 158.48 42.7447C155.035 42.7447 149.543 33.6353 149.543 27.8767C149.543 22.1181 153.544 17.4492 158.48 17.4492C163.416 17.4492 167.417 22.1181 167.417 27.8767Z"
          fill="#7E57C2"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M158.238 43.0198L158.324 45.1087L160.064 44.6737L158.492 42.7441" fill="#263238" />
        <path
          d="M158.238 43.0198L158.324 45.1087L160.064 44.6737L158.492 42.7441"
          stroke="#263238"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M164.872 21.6218C165.923 23.2068 165.897 25.0675 164.807 25.7954C163.718 26.5233 161.99 25.7954 160.931 24.2319C159.871 22.6685 159.906 20.7863 160.991 20.0584C162.077 19.3305 163.821 20.0368 164.872 21.6218Z"
          fill="white"
        />
        <path
          d="M160.05 19.4355C159.976 19.7886 159.481 19.9911 158.943 19.8662C158.404 19.7413 158.017 19.3838 158.081 19.0306C158.146 18.6774 158.65 18.4793 159.192 18.5999C159.735 18.7205 160.119 19.0823 160.05 19.4355Z"
          fill="white"
        />
        <path
          d="M159.038 44.8936C160.981 46.7035 162.715 48.7262 164.206 50.9235C165.068 52.2415 165.778 53.6068 165.86 55.2004C165.86 55.6053 165.908 54.3003 165.86 54.8731C165.86 54.9765 165.86 55.0798 165.83 55.1832C165.807 55.3727 165.768 55.56 165.714 55.7431C165.604 56.0767 165.448 56.3929 165.249 56.6821C164.607 57.6469 163.797 58.4997 163.095 59.4128C161.624 61.2336 160.299 63.1678 159.133 65.1972C158.688 65.9704 158.36 66.8049 158.159 67.6738C158.039 68.3849 157.998 69.1072 158.039 69.8273C158.091 71.0381 158.508 72.2048 159.236 73.1739C160.153 74.5048 161.265 75.685 162.13 77.0503C163.264 78.8444 163.939 80.8903 164.094 83.007C164.064 82.6495 164.12 82.2834 164.094 81.9302C163.959 80.0177 163.395 78.16 162.445 76.4947C161.645 75.2253 160.762 74.0108 159.8 72.8595C159.033 71.8646 158.297 70.7835 158.077 69.5129C158.051 69.3406 158.026 68.6515 158.03 69.3105C158.034 69.9695 158.06 69.2157 158.086 69.052C158.15 68.667 158.247 68.2881 158.374 67.9193C158.732 66.9536 159.203 66.034 159.779 65.18C160.959 63.3261 162.238 61.5375 163.612 59.822C164.297 59.0763 164.914 58.2705 165.455 57.4143C165.807 56.6478 165.955 55.8039 165.886 54.9636C165.911 54.3613 165.875 53.7582 165.778 53.1632C165.602 52.4059 165.311 51.6798 164.917 51.0096C163.44 48.322 161.256 45.9187 159.042 43.8125C159.066 43.9912 159.066 44.1722 159.042 44.3509C159.042 44.4413 158.982 44.829 159.042 44.8893L159.038 44.8936Z"
          fill="#263238"
        />
        <path
          d="M111.014 162.41C110.894 162.102 110.236 161.872 110.236 161.872C110.236 161.872 109.882 150.112 109.882 147.345C109.882 144.578 107.915 138.994 107.795 137.86C107.675 136.725 107.915 128.676 107.257 123.072C106.599 117.467 105.104 110.488 105.104 110.488L95.2323 107.386C95.2323 107.204 92.6067 108.102 92.6067 108.102L84.613 110.488C84.613 110.488 83.1218 117.464 82.4608 123.072C81.7998 128.679 82.0458 136.729 81.9259 137.86C81.806 138.991 79.8383 144.599 79.8383 147.345C79.8383 150.09 79.4816 161.862 79.4816 161.862C79.4816 161.862 78.836 162.102 78.7315 162.41C78.6269 162.717 78.3164 165.45 78.3164 165.45C78.3164 165.45 82.1933 166.585 82.8482 166.705C83.5031 166.825 83.6814 166.763 83.7429 166.527C83.8044 166.29 84.8159 164.915 84.8159 164.319C84.8159 163.723 84.5084 162.167 84.5084 161.635C84.5084 161.103 88.6221 141.328 88.6221 141.328C88.6221 141.328 94.5282 118.488 94.7711 118.844C94.7844 118.859 94.8005 118.87 94.8185 118.878C94.8365 118.886 94.856 118.89 94.8756 118.89C94.8953 118.89 94.9148 118.886 94.9328 118.878C94.9508 118.87 94.9669 118.859 94.9802 118.844C95.22 118.488 101.129 141.328 101.129 141.328C101.129 141.328 105.246 161.097 105.246 161.635C105.246 162.173 104.938 163.723 104.938 164.319C104.938 164.915 105.953 166.287 106.011 166.527C106.07 166.766 106.251 166.834 106.906 166.705C107.561 166.576 111.438 165.45 111.438 165.45C111.438 165.45 111.134 162.717 111.014 162.41Z"
          fill="#263238"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M82.1801 165.749C82.1801 165.749 80.9257 163.247 80.151 163.425C79.3762 163.603 78.4815 165.095 78.3063 165.451C78.131 165.808 75.3824 168.347 75.3824 168.347C75.3824 168.347 72.9228 169.66 72.64 170.017C72.456 170.277 72.371 170.594 72.4002 170.912H83.9111C84.1101 169.978 84.2294 169.03 84.2677 168.077C84.3242 167.32 84.2849 166.558 84.1509 165.811L82.1801 165.749Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M72.3956 170.912H83.9065C83.9065 170.912 83.9557 170.549 84.0172 170.018H72.6415C72.4554 170.277 72.3682 170.594 72.3956 170.912Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M108.148 165.749C108.148 165.749 109.399 163.247 110.174 163.425C110.949 163.603 111.844 165.095 112.019 165.451C112.194 165.808 114.943 168.347 114.943 168.347C114.943 168.347 117.402 169.66 117.685 170.017C117.869 170.277 117.954 170.594 117.925 170.912H106.405C106.405 170.912 106.097 168.704 106.048 167.154C105.999 165.605 106.165 165.811 106.165 165.811L108.148 165.749Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M117.927 170.912H106.404C106.404 170.912 106.354 170.549 106.293 170.018H117.669C117.859 170.275 117.951 170.593 117.927 170.912Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M95.2623 67.6144C93.9927 67.7272 92.7766 68.1783 91.7406 68.9207C90.7046 69.6632 89.8865 70.6697 89.3716 71.8356C89.1135 72.3909 88.9444 72.9834 88.8704 73.5912C88.7106 75.4359 88.7505 76.5427 88.3508 78.3382C87.9512 80.1337 86.1987 81.3666 86.8382 83.282C87.4777 85.1974 90.3862 88.1089 95.4898 88.1089C100.593 88.1089 103.905 84.478 104.145 83.282C104.384 82.086 103.548 80.93 102.949 78.6149C102.349 76.2998 102.749 74.5473 101.719 71.5436C100.689 68.5398 97.685 67.4084 95.333 67.6082L95.2623 67.6144Z"
          fill="#263238"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M116.326 79.6704C115.865 80.0547 115.711 80.2084 115.711 80.2084L104.465 91.8115L103.069 101.505L106.371 113.73C106.371 113.73 105.614 114.926 103.419 116.601C101.224 118.277 96.5631 120.869 96.5631 120.869L94.8967 112.032L94.5893 113.468L94.0635 120.869C94.0635 120.869 89.3965 118.277 87.2043 116.601C85.0122 114.926 83.3305 113.73 83.3305 113.73L87.1736 101.505L85.7778 91.8115L74.5282 80.2084C74.5282 80.2084 74.3714 80.0485 73.8918 79.6488C73.6243 79.4244 74.7403 74.6989 75.7764 70.576C76.5943 67.3263 77.3598 64.4547 77.3598 64.4547C76.8628 64.3022 76.3819 64.1014 75.924 63.8552C75.717 63.6838 75.5537 63.4656 75.4475 63.2188H82.1468L80.7909 64.5746L79.9485 71.234L79.1153 77.8134L89.006 84.7925L91.5978 85.9516H98.6691L101.264 84.7925L111.155 77.8134L110.331 71.3077L109.479 64.5746L108.123 63.2188H114.795C114.688 63.4662 114.524 63.6844 114.315 63.8552C113.858 64.1019 113.377 64.3028 112.879 64.4547C112.879 64.4547 113.679 67.4554 114.518 70.7882C115.21 73.5552 115.926 76.5282 116.234 78.2377C116.381 79.0524 116.437 79.5781 116.351 79.6488L116.326 79.6704Z"
          fill="#7D7D7D"
        />
        <path
          d="M116.326 79.6704C115.865 80.0547 115.711 80.2084 115.711 80.2084L104.465 91.8115L103.069 101.505L106.371 113.73C106.371 113.73 105.614 114.926 103.419 116.601C101.224 118.277 96.5631 120.869 96.5631 120.869L94.8967 112.032L94.5893 113.468L94.0635 120.869C94.0635 120.869 89.3965 118.277 87.2043 116.601C85.0122 114.926 83.3305 113.73 83.3305 113.73L87.1736 101.505L85.7778 91.8115L74.5282 80.2084C74.5282 80.2084 74.3714 80.0485 73.8918 79.6488C73.6243 79.4244 74.7403 74.6989 75.7764 70.576C76.5943 67.3263 77.3598 64.4547 77.3598 64.4547C76.8628 64.3022 76.3819 64.1014 75.924 63.8552C75.717 63.6838 75.5537 63.4656 75.4475 63.2188H82.1468L80.7909 64.5746L79.9485 71.234L79.1153 77.8134L89.006 84.7925L91.5978 85.9516H98.6691L101.264 84.7925L111.155 77.8134L110.331 71.3077L109.479 64.5746L108.123 63.2188H114.795C114.688 63.4662 114.524 63.6844 114.315 63.8552C113.858 64.1019 113.377 64.3028 112.879 64.4547C112.879 64.4547 113.679 67.4554 114.518 70.7882C115.21 73.5552 115.926 76.5282 116.234 78.2377C116.381 79.0524 116.437 79.5781 116.351 79.6488L116.326 79.6704"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M104.465 91.8111L103.069 101.505L106.371 113.729C106.371 113.729 105.614 114.925 103.419 116.601C101.224 118.276 96.5631 120.868 96.5631 120.868L95.0043 115.448L94.6969 116.884L94.0635 120.88C94.0635 120.88 89.3965 118.289 87.2043 116.613C85.0122 114.937 83.3305 113.741 83.3305 113.741L87.1736 101.517L85.7778 91.8234L74.5282 80.2203C74.5282 80.2203 74.3714 80.0604 73.8918 79.6607C73.6243 79.4363 74.7403 74.7108 75.7764 70.5879L79.9485 71.2458L79.1153 77.8253L89.006 84.8044L91.5978 85.9634H98.6691L101.264 84.8044L111.155 77.8253L110.331 71.3196L114.518 70.7908C115.21 73.5579 115.926 76.5309 116.234 78.2403L116.326 79.673C115.865 80.0573 115.711 80.2111 115.711 80.2111L104.465 91.8111Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M95.1367 109.271V110.677"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M95.1328 95.8418V108.02"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M104.465 91.8114L106.34 85.5117"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M107.156 85.8152L107.537 85.0312"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M104.961 90.3383L106.637 86.8887"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M84.5105 86.4707L84.2461 85.3516"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M85.7627 91.8115L84.7266 87.3965"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M85.4827 90.8146L83.0508 86.1875"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M91.2255 86.5879C91.2255 86.5879 90.749 90.019 91.4254 92.371"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M90.7465 86.4277C90.2223 87.6533 89.9244 88.9637 89.8672 90.2954"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M98.8017 87.3926C98.8017 87.3926 99.1091 90.1043 98.7617 92.0966"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M99.2031 86.627C99.6548 87.3998 99.9523 88.2528 100.079 89.1388"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M92.7783 82.5623L92.7383 86.267L94.3339 88.6621L95.0534 93.8457L96.1294 88.6221L98.0848 85.6706L97.9649 82.5254L92.7783 82.5623Z"
          fill="#7D7D7D"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M95.6784 83.903L92.8867 84.6378V82.5625L95.6784 83.903Z"
          fill="#263238"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M95.7944 73.0649C95.3656 72.5274 94.5389 72.2373 93.9762 72.6325C92.7246 73.5112 90.9062 74.8464 90.9062 75.1871C90.9062 75.7067 91.0262 80.4906 91.3828 81.3669C91.7394 82.2431 94.6141 84.5182 95.1736 84.5582C95.7332 84.5982 96.4496 84.3983 96.6094 84.2507C96.7693 84.1032 99.4011 81.8588 99.5609 81.1025C99.681 80.5343 99.8376 77.9762 99.908 76.7385C99.9313 76.3305 99.7041 75.9512 99.3438 75.7585C98.4469 75.2788 96.761 74.2764 95.7944 73.0649Z"
          fill="#7D7D7D"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M96.0765 77.4023C96.0765 77.4023 97.3491 78.0477 96.8039 78.8161C96.2588 79.5846 95.2227 78.8161 95.2227 78.8161"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M94.0156 80.1707C94.0156 80.1707 95.6513 80.9701 97.0901 80.0508C97.0901 80.0508 96.8503 81.3667 95.8603 81.5665C94.6736 81.8032 94.0156 80.1707 94.0156 80.1707Z"
          fill="#263238"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M92.9668 76.7296C92.9996 76.9256 93.2637 77.0447 93.5568 76.9956C93.8499 76.9466 94.0609 76.748 94.0281 76.552C93.9953 76.3561 93.7312 76.237 93.4381 76.286C93.145 76.3351 92.934 76.5337 92.9668 76.7296Z"
          fill="#263238"
        />
        <path
          d="M97.8446 76.2865C98.1395 76.3735 98.3146 76.595 98.2589 76.7837C98.2032 76.9725 97.9271 77.0609 97.641 76.9765C97.355 76.8921 97.1702 76.6709 97.2268 76.4792C97.2834 76.2875 97.5704 76.2055 97.8446 76.2865Z"
          fill="#263238"
        />
        <path
          d="M92.3438 75.5071C92.3438 75.5071 93.6197 74.5848 94.6558 75.1997"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M96.4102 75.1479C96.8113 74.9802 97.2494 74.9204 97.6809 74.9743C98.1123 75.0283 98.5222 75.1941 98.8698 75.4553"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M90.9473 77.099C90.9473 77.099 89.9081 76.1028 89.8681 77.099C89.8281 78.0951 91.0979 79.1343 91.0979 79.1343L90.9473 77.099Z"
          fill="#7D7D7D"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M99.945 77.2194C99.945 77.2194 100.824 76.4631 100.944 77.1794C101.064 77.8958 99.7852 79.1748 99.7852 79.1748L99.945 77.2194Z"
          fill="#7D7D7D"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M92.1419 84.9922L94.6138 88.3034L93.258 91.2549L91.2227 85.8315L92.1419 84.9922Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M98.3235 84.9922L95.8516 88.3034L97.2074 91.2549L99.2427 85.8315L98.3235 84.9922Z"
          fill="white"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M122.157 32.5195H67.0898V63.3321H122.157V32.5195Z"
          fill="#5E06B9"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M109.542 64.6763C109.542 64.6763 108.051 63.1052 107.79 62.767C107.528 62.4288 107.436 62.1244 107.667 61.8631C107.897 61.6018 107.651 60.1875 107.636 59.8339C107.621 59.4804 107.983 59.5265 108.346 59.9108C108.709 60.2951 108.884 61.7125 108.866 62.0168C108.865 62.2237 108.924 62.4265 109.035 62.601C109.035 62.601 110.428 62.6317 110.932 63.1236C111.436 63.6156 111.218 64.5963 111.218 64.5963"
          fill="#7D7D7D"
        />
        <path
          d="M109.542 64.6763C109.542 64.6763 108.051 63.1052 107.79 62.767C107.528 62.4288 107.436 62.1244 107.667 61.8631C107.897 61.6018 107.651 60.1875 107.636 59.8339C107.621 59.4804 107.983 59.5265 108.346 59.9108C108.709 60.2951 108.884 61.7125 108.866 62.0168C108.865 62.2237 108.924 62.4265 109.035 62.601C109.035 62.601 110.428 62.6317 110.932 63.1236C111.436 63.6156 111.218 64.5963 111.218 64.5963"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M80.8046 64.6763C80.8046 64.6763 82.2958 63.1052 82.5571 62.767C82.8184 62.4288 82.9107 62.1244 82.6832 61.8631C82.4556 61.6018 82.6832 60.1875 82.7139 59.8339C82.7446 59.4804 82.3665 59.5265 82.0037 59.9108C81.6409 60.2951 81.4657 61.7125 81.4841 62.0168C81.4834 62.2235 81.4248 62.4259 81.315 62.601C81.315 62.601 79.9192 62.6317 79.415 63.1236C78.9108 63.6156 79.129 64.5963 79.129 64.5963"
          fill="#7D7D7D"
        />
        <path
          d="M80.8046 64.6763C80.8046 64.6763 82.2958 63.1052 82.5571 62.767C82.8184 62.4288 82.9107 62.1244 82.6832 61.8631C82.4556 61.6018 82.6832 60.1875 82.7139 59.8339C82.7446 59.4804 82.3665 59.5265 82.0037 59.9108C81.6409 60.2951 81.4657 61.7125 81.4841 62.0168C81.4834 62.2235 81.4248 62.4259 81.315 62.601C81.315 62.601 79.9192 62.6317 79.415 63.1236C78.9108 63.6156 79.129 64.5963 79.129 64.5963"
          stroke="#263238"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M72.388 39.9161C72.3415 39.8299 72.3449 39.7536 72.3979 39.6873C72.4443 39.621 72.5139 39.5879 72.6067 39.5879H76.8433C77.0555 39.5879 77.2113 39.6973 77.3107 39.9161L78.9815 43.5758L80.6522 39.9161C80.7517 39.6973 80.9075 39.5879 81.1196 39.5879H85.3562C85.449 39.5879 85.522 39.621 85.575 39.6873C85.628 39.7536 85.6313 39.8299 85.5849 39.9161L81.0799 48.0212V53.3517C81.0799 53.4247 81.0467 53.491 80.9804 53.5506C80.9207 53.6103 80.8511 53.6401 80.7716 53.6401H77.1914C77.1118 53.6401 77.0389 53.6103 76.9726 53.5506C76.9129 53.491 76.8831 53.4247 76.8831 53.3517V48.0212L72.388 39.9161Z"
          fill="white"
        />
        <path
          d="M83.6652 53.3716L88.2697 39.8564C88.3028 39.7901 88.3691 39.7304 88.4686 39.6774C88.568 39.6177 88.6642 39.5879 88.757 39.5879H92.914C93.0068 39.5879 93.1029 39.6177 93.2024 39.6774C93.3018 39.7304 93.3681 39.7901 93.4013 39.8564L98.0058 53.3716C98.039 53.4313 98.0357 53.491 97.9959 53.5506C97.9627 53.6103 97.9064 53.6401 97.8268 53.6401H93.7891C93.5969 53.6401 93.4543 53.5506 93.3615 53.3716L92.7549 51.2335H88.9261L88.3095 53.3716C88.2166 53.5506 88.0741 53.6401 87.8818 53.6401H83.8541C83.7746 53.6401 83.7149 53.6103 83.6751 53.5506C83.6353 53.491 83.632 53.4313 83.6652 53.3716ZM89.7415 48.3196H91.9394L90.8355 44.451L89.7415 48.3196Z"
          fill="white"
        />
        <path
          d="M96.096 39.9161C96.0496 39.8299 96.0529 39.7536 96.1059 39.6873C96.1523 39.621 96.222 39.5879 96.3148 39.5879H100.551C100.763 39.5879 100.919 39.6973 101.019 39.9161L102.69 43.5758L104.36 39.9161C104.46 39.6973 104.616 39.5879 104.828 39.5879H109.064C109.157 39.5879 109.23 39.621 109.283 39.6873C109.336 39.7536 109.339 39.8299 109.293 39.9161L104.788 48.0212V53.3517C104.788 53.4247 104.755 53.491 104.688 53.5506C104.629 53.6103 104.559 53.6401 104.48 53.6401H100.899C100.82 53.6401 100.747 53.6103 100.681 53.5506C100.621 53.491 100.591 53.4247 100.591 53.3517V48.0212L96.096 39.9161Z"
          fill="white"
        />
        <path
          d="M110.874 52.218C110.874 52.4302 110.91 52.6324 110.983 52.8247C111.056 53.0103 111.172 53.1893 111.331 53.3617C111.497 53.5341 111.732 53.67 112.037 53.7694C112.342 53.8755 112.7 53.9285 113.111 53.9285C113.907 53.9285 114.481 53.7595 114.832 53.4214C115.183 53.0832 115.359 52.6821 115.359 52.218C115.359 52.0191 115.323 51.8268 115.25 51.6412C115.177 51.4556 115.057 51.2799 114.892 51.1141C114.732 50.9417 114.5 50.8058 114.195 50.7064C113.89 50.6003 113.529 50.5473 113.111 50.5473C112.329 50.5473 111.759 50.7163 111.401 51.0544C111.05 51.3859 110.874 51.7738 110.874 52.218ZM111.023 39.8962L111.321 49.9307C111.321 50.0036 111.351 50.0699 111.411 50.1296C111.477 50.1892 111.55 50.2191 111.63 50.2191H114.583C114.663 50.2191 114.732 50.1892 114.792 50.1296C114.858 50.0699 114.892 50.0036 114.892 49.9307L115.21 39.8962C115.21 39.8166 115.18 39.747 115.12 39.6873C115.061 39.621 114.991 39.5879 114.911 39.5879H111.321C111.242 39.5879 111.172 39.621 111.112 39.6873C111.053 39.747 111.023 39.8166 111.023 39.8962Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_23382_112304"
            x1="51.0577"
            y1="43.1762"
            x2="137.6"
            y2="66.9181"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#8400F7" />
            <stop offset="1" stop-color="#BB80F6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_23382_112304"
            x1="105"
            y1="3.05469"
            x2="128.328"
            y2="9.97953"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#BF84FB" />
            <stop offset="1" stop-color="#A8D4FC" />
          </linearGradient>
        </defs>
      </svg>
    </>
  )
}

export default AnnouncementYayIcon
