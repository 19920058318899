import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Loader from 'components/Loaders'
import { BusinessProfile as BusinessProfileResponse } from '__generated__/api-types-and-hooks'
import { AppFC } from 'types'
import { AppViewBusinessProfile } from 'components/AppAccount/AppBusinessProfile/AppViewBusinessProfile/AppViewBusinessProfile.component'

interface IBusinessProfile {
  businessProfile: BusinessProfileResponse
  clientBusinessProfileLoading: boolean
  getUserBusinessProfileAction: (payload) => {}
}
const BusinessProfile: AppFC<IBusinessProfile> = ({
  businessProfile,
  clientBusinessProfileLoading,
  getUserBusinessProfileAction,
}) => {
  let { clientId } = useParams()

  useEffect(() => {
    if (clientId) {
      getUserBusinessProfileAction({ userId: clientId })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserBusinessProfileAction])
  return (
    <div className="xs:col-span-12 xs:p-4 sm:p-0 col-span-3 sm:col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-4 2xl:col-span-4  lg:border-r lg:border-grey-darker break-words">
      {clientBusinessProfileLoading ? (
        <Loader loader="AdvisorOwnerProfile" />
      ) : (
        <>
          <div className="font-primary  text-2xl">Business Profile </div>
          <div className="space-y-9 mt-[31px]">
            <AppViewBusinessProfile businessProfile={businessProfile} />
          </div>
        </>
      )}
    </div>
  )
}

export default BusinessProfile
