import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ClientActivityScoreCard from 'components/Clients/Client/Activity/ScoreCard'
import ClientActivityLog from 'components/Clients/Client/Activity/Log'
import Loader from 'components/Loaders'
import {
  ActivityWrapper,
  ActivityLogWrapper,
  ClientScoreCardWrapper,
  ScoreCardHeadingWrapper,
  ActivityScoreCardWrapper,
  ClientActivityLogWrapper,
  ActivityLogHeadingWrapper,
} from 'components/Clients/Client/Activity/style'

const Activity = ({
  scoreCards,
  activityLogs,
  scoreCardLoading,
  clientActivityLogLoading,
  getClientActivityLogsAction,
  getClientActivityScoreAction,
}) => {
  let { clientId } = useParams()

  useEffect(() => {
    getClientActivityLogsAction({ clientId })
    getClientActivityScoreAction({ clientId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <ActivityWrapper>
        {/* Client Activity ScoreCards */}
        <ActivityScoreCardWrapper>
          <div>
            <ScoreCardHeadingWrapper> Client Activity ScoreCards </ScoreCardHeadingWrapper>
          </div>

          {scoreCardLoading ? (
            <Loader loader={'AdvisorOwnerActivity'} />
          ) : (
            <ClientScoreCardWrapper>
              {scoreCards?.map((scoreDetail, index) => {
                return (
                  <div key={`score-card-${index + 1}`}>
                    <ClientActivityScoreCard scoreDetail={scoreDetail} />
                  </div>
                )
              })}
            </ClientScoreCardWrapper>
          )}
        </ActivityScoreCardWrapper>

        {/* Client Activity Logs */}
        <ClientActivityLogWrapper>
          <div>
            <ActivityLogHeadingWrapper> Client Activity Log </ActivityLogHeadingWrapper>
          </div>
          <ActivityLogWrapper>
            {clientActivityLogLoading ? (
              <Loader loader={'AdvisorOwnerActivityLog'} />
            ) : (
              Object.keys(activityLogs || {}).map((key, index) => {
                return (
                  <div key={`activity-log-item-${index + 1}`}>
                    <ClientActivityLog date={key} logDetail={activityLogs[key]} />
                  </div>
                )
              })
            )}
          </ActivityLogWrapper>
        </ClientActivityLogWrapper>
      </ActivityWrapper>
    </div>
  )
}

export default Activity
