import React from 'react'

function SpeedRocketIcon({ className }) {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="#71717A"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: 'multiply' }}>
        <path d="M19.9219 6.9873C19.9219 8.11035 19.0478 8.94043 17.9687 8.94043C16.8896 8.94043 16.0156 8.11035 16.0156 6.9873C16.0156 5.95215 16.8896 5.03418 17.9687 5.03418C19.0478 5.03418 19.9219 5.95215 19.9219 6.9873ZM18.75 15.2393V19.248C18.75 20.4932 18.0957 21.6406 17.0264 22.2754L12.7051 24.834C12.3437 25.0488 11.8945 25.0537 11.5283 24.8486C11.1621 24.6387 10.9375 24.248 10.9375 23.8281V18.2275C10.9375 17.1191 10.498 16.0205 9.71679 15.2393C8.93554 14.458 7.88086 14.0625 6.77246 14.0625H1.17187C0.750973 14.0625 0.362008 13.8379 0.153609 13.4717C-0.0548378 13.1055 -0.0508827 12.6562 0.163912 12.2949L2.72607 7.97363C3.35888 6.9043 4.5083 6.20605 5.75195 6.20605H9.76074C13.7549 -0.185449 19.9609 -0.417285 23.6279 0.257422C24.1992 0.361865 24.6387 0.803222 24.7412 1.37061C25.415 5.03906 25.1855 11.2451 18.75 15.2393ZM9.66308 12.3975C10.293 12.7051 10.8691 13.1201 11.333 13.623C11.8799 14.1309 12.2949 14.707 12.6025 15.3369C17.7686 13.8721 20.2539 11.4502 21.4697 9.17969C22.6904 6.89941 22.7588 4.41748 22.5439 2.45752C20.5811 2.19824 18.1006 2.31152 15.7764 3.53174C13.5498 4.74414 11.1279 7.23144 9.66308 12.3975ZM2.0498 16.8701C3.729 15.1904 6.45019 15.1904 8.12988 16.8701C9.80957 18.5498 9.80957 21.2695 8.12988 22.9492C5.71289 25.3662 0.0232628 24.9756 0.0232628 24.9756C0.0232628 24.9756 -0.366996 19.2432 2.0498 16.8701ZM3.15332 21.8457C3.15332 21.8457 5.04394 21.9775 5.84961 21.1719C6.40625 20.6152 6.40625 19.707 5.84961 19.1504C5.28808 18.5938 4.38525 18.5938 3.82714 19.1504C3.02343 19.9561 3.15332 21.8457 3.15332 21.8457Z" />
      </g>
    </svg>
  )
}

SpeedRocketIcon.defaultProps = {
  className: '',
}
export default SpeedRocketIcon
