import {
  IUSER_NOTIFICATION_STATUS,
  IUSER_NOTIFICATION_TYPES,
} from 'components/NotificationPanel/types'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

const getDashboardEvents = (state) => state.user?.summary?.todayMeetings
const getNotificationList = (state) => state.user?.notifications?.data
const getTimeZone = (state) => state.user?.user?.timezone

export const getUpcomingMeetingEventSelector = createSelector(
  [getDashboardEvents],
  (todayMeetings) => {
    let upcomingMeetingEvents: any[] = []
    try {
      if (!isEmpty(todayMeetings)) {
        let meetingEvents = todayMeetings?.map((meeting) => ({
          ...meeting,
          start: moment(meeting?.startTime).tz(meeting?.timezone)?.toDate(),
          startTime: moment(meeting?.startTime).tz(meeting?.timezone)?.format(),
        }))

        meetingEvents = meetingEvents?.sort(
          (a, b) => moment(a.start).valueOf() - moment(b.start).valueOf()
        )
        upcomingMeetingEvents = meetingEvents?.filter(
          (event) => new Date(event?.start) >= new Date()
        )
      }
    } catch (err) {
      console.log('error: ', err)
    }
    return upcomingMeetingEvents
  }
)

export const getNotificationListSelector = createSelector(
  [getNotificationList, getTimeZone],
  (data, timezone) => {
    try {
      let timeZone = moment.tz.guess()
      const userTimeZone = timezone || timeZone
      const currentDate = moment(new Date()).tz(userTimeZone)?.format('YYYY-MM')
      return data?.filter((notification) => {
        const createdAt = moment(new Date(notification?.createdAt))
          .tz(userTimeZone)
          .format('YYYY-MM')
        return (
          notification.notificationType === IUSER_NOTIFICATION_TYPES.MONTHLY_ATTESTATION_UPDATE &&
          notification.status === IUSER_NOTIFICATION_STATUS.UNREAD &&
          createdAt >= currentDate
        )
      })
    } catch (err) {
      console.log('error: ', err)
    }
  }
)
