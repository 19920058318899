import React, { FC } from 'react'
import { USER_STATUS } from 'config'

import { InlineLabel } from 'components/Common/InlineLabel'
import {
  UserWrapper,
  AdvisorWrapper,
  UserAccountWrapper,
  AdvisorAndActionWrapper,
} from 'components/Admin/UserManagement/UserManagementList/style'
import UserInfo from 'components/Admin/UserManagement/UserManagementList/UserInfo'
import { UserActionContainer } from 'components/Admin/UserManagement/UserManagementList/containers'
import { IUser } from 'components/Admin/UserManagement/UserManagementList'

interface IUserAccountDetailsProps {
  user: IUser
  index: number
  setSelectedUser: (user: IUser | undefined) => void
  showAdminRightsToggle: boolean
  showAssociatedAdvisors: boolean
  setOpenResetPasswordModal: (value: boolean) => void
  setOpenUserManagementModal: (value: boolean) => void
}
const UserAccountDetails: FC<IUserAccountDetailsProps> = ({
  user,
  index,
  setSelectedUser,
  showAdminRightsToggle,
  showAssociatedAdvisors,
  setOpenResetPasswordModal,
  setOpenUserManagementModal,
}) => {
  return (
    <UserAccountWrapper key={index}>
      <UserWrapper>
        <UserInfo user={user} />
        <AdvisorAndActionWrapper>
          <AdvisorWrapper>
            {user.activated !== USER_STATUS.INACTIVE && showAssociatedAdvisors && (
              <p>
                <InlineLabel>Advisors: </InlineLabel>
                {user?.advisors || ''}
              </p>
            )}
          </AdvisorWrapper>
          <UserActionContainer
            user={user}
            setSelectedUser={setSelectedUser}
            showAdminRightsToggle={showAdminRightsToggle}
            setOpenResetPasswordModal={setOpenResetPasswordModal}
            setOpenUserManagementModal={setOpenUserManagementModal}
          />
        </AdvisorAndActionWrapper>
      </UserWrapper>
    </UserAccountWrapper>
  )
}

export default UserAccountDetails
