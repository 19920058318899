import React from 'react'
import { ROLES, clientTabs } from 'config'
import { navigateTo } from 'utils/helper'

const DictionaryList = ({ children, client }) => {
  return (
    <div
      className="group cursor-pointer"
      onClick={() => {
        if (window.location.href.includes(ROLES.BSO_ADVISOR)) {
          navigateTo(ROLES.BSO_ADVISOR, `clients/${client?.id}/${clientTabs[0].name}`)
        } else {
          navigateTo(ROLES.BSO_ADMIN, `clients/${client?.id}/${clientTabs[0].name}`)
        }
      }}
    >
      <div className="md:border-r border-b border-secondary-text px-2 py-[16.7px] group-hover:bg-white">
        <div className="flex pl-5 justify-between">{children}</div>
      </div>
    </div>
  )
}

export default DictionaryList
