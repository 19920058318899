import useNavItem from 'hooks/useNavItem'
import { useCallback, useMemo } from 'react'
import navigateTo from 'utils/navigateTo'
import { useTenantId } from 'utils/useTenantId'

export default function useNavigateTo(itemId: string, extension = '') {
  const navItem = useNavItem((item) => item.id === itemId)
  const tenantId = useTenantId()

  const destinationItem = useMemo(() => {
    const copy = { ...navItem }
    copy.path = copy?.path?.replace?.(':tenantId', tenantId) + extension
    return copy
  }, [tenantId, navItem, extension])

  const navigate = useCallback(() => {
    if (!navItem) {
      console.warn('Unable to find nav item to direct navigation.')
      return
    }
    if (!tenantId) {
      console.warn('Could not determine destination without tenantId.')
      return
    }

    navigateTo(destinationItem)
  }, [destinationItem, navItem, tenantId])

  return navigate
}
