import Card from 'components/Common/Card'
import { AppFC } from 'types'
import React, { ReactElement } from 'react'
import RightArrowIconButton from '../RightArrowIconButton'

interface IActionCardProps {
  leftSlot?: () => ReactElement | null
}
/** A simple, horizontal card with a (->) icon that does something when the user clicks it. */
const ActionCard: AppFC<IActionCardProps> = ({
  onClick,
  leftSlot = null,
  children,
  className = '',
}) => {
  return (
    <Card onClick={onClick} className={`hover:cursor-pointer !p-0 ${className}`}>
      <div className="w-full h-full flex justify-between">
        {leftSlot && (
          <div className="bg-primary rounded-tl-[20px] rounded-bl-[20px] flex justify-center items-center">
            {leftSlot()}
          </div>
        )}
        <div className="py-[18px] xs:px-4 sm:px-6 flex justify-between items-center gap-2 w-full">
          {children || <div />}
          <RightArrowIconButton />
        </div>
      </div>
    </Card>
  )
}

export default ActionCard
