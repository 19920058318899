import React from 'react'

function GenericDocumentIcon({ className }) {
  return (
    <svg
      id="generic-document-svg"
      className={className}
      width="33"
      height="44"
      viewBox="0 0 33 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 0V11H33L22 0ZM19.25 11V0H4.125C1.8468 0 0 1.8468 0 4.125V39.875C0 42.1523 1.8468 44 4.125 44H28.875C31.1532 44 33 42.1532 33 39.875V13.75H22.0773C20.4789 13.75 19.25 12.5211 19.25 11ZM23.375 35.75H9.625C8.86875 35.75 8.25 35.1312 8.25 34.375C8.25 33.6188 8.86875 33 9.625 33H23.375C24.1343 33 24.75 33.6155 24.75 34.375C24.75 35.1312 24.1313 35.75 23.375 35.75ZM23.375 30.25H9.625C8.86875 30.25 8.25 29.6313 8.25 28.875C8.25 28.1187 8.86875 27.5 9.625 27.5H23.375C24.1343 27.5 24.75 28.1155 24.75 28.875C24.75 29.6313 24.1313 30.25 23.375 30.25ZM24.75 23.375C24.75 24.1313 24.1313 24.75 23.375 24.75H9.625C8.86875 24.75 8.25 24.1313 8.25 23.375C8.25 22.6187 8.86875 22 9.625 22H23.375C24.1313 22 24.75 22.6187 24.75 23.375Z"
        fill="black"
        id="generic-document-svg"
      />
    </svg>
  )
}
export default GenericDocumentIcon
