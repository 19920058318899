import { FeatureFlag } from 'feature-flags'
import { useCallback, useEffect, useState } from 'react'
import { FeatureFlagManager } from 'utils/feature-flag-manager'
import { useTenantId } from 'utils/useTenantId'

export const useFeatureFlag = (
  userId: string,
  flagName: FeatureFlag,
  callback?: (flagValue: boolean) => void
) => {
  const [enableFeatureFlag, setEnableFeatureFlag] = useState<boolean | undefined>(undefined)
  const tenantId = useTenantId()

  const checkFeatureFlag = useCallback(async () => {
    const featureFlags = new FeatureFlagManager(userId, tenantId)
    const flagValue = await featureFlags.checkToggleFlag(flagName)
    setEnableFeatureFlag(flagValue)
    if (callback) callback(flagValue)
  }, [userId, flagName, tenantId, callback])

  useEffect(() => {
    checkFeatureFlag()
  }, [checkFeatureFlag])

  return enableFeatureFlag
}
