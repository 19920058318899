import { CalendarEventExternalReferenceId } from '../../../../__generated__/api-types-and-hooks'
import React from 'react'
import { calendarLabelMap } from '../../../../config'
import { RefreshIcon } from '@heroicons/react/solid'
import { WarningIcon } from '../../../Common/SvgIcons/WarningIcon'
import { IconWithLabel } from '../../style'

export const ExternalMeetingStatus = ({
  externalReferenceId,
}: {
  externalReferenceId: CalendarEventExternalReferenceId
}) => {
  const { status, type } = externalReferenceId
  const text = status === 'synced' ? 'Synced to' : 'Sync Error:'
  return (
    <IconWithLabel>
      {status === 'synced' ? (
        <RefreshIcon className="w-6 h-6 fill-green" />
      ) : (
        <WarningIcon className="w-6 h-6 fill-primary-error" />
      )}
      <p className="ml-2">{`${text} ${calendarLabelMap[type]}`}</p>
    </IconWithLabel>
  )
}
