import styled from 'styled-components'
import tw from 'twin.macro'

export const UpComingMeetingMobileViewStyle = styled.main.attrs({
  className: 'block w-full border-t border-primary',
})``
export const EventDetailNoMeeting = styled.main.attrs({
  className: 'mt-6 px-6',
})`
  & {
    .event-detail-no-meeting-text {
      ${tw`font-primary text-black-light italic text-lg`}
    }
  }
`

export const EventDetailStyle = styled.main.attrs({
  className: 'text-lg font-primary xs:border-b xs:border-primary-disabled md:border-none',
})`
  & {
    .event-detail-box {
      ${tw`p-6`}
    }
    .event-detail-date {
      ${tw`font-bold text-black-light`}
    }
    .event-detail-time {
      ${tw`py-2 text-primary-text`}
    }
    .event-detail-title {
      ${tw`pb-5 text-black-light `}
    }
    .event-detail-ipad-view {
      ${tw` xs:hidden sm:block md:hidden `}
    }
    .event-detail-btn {
      ${tw` w-full sm:max-w-[20%]`}
    }
    .event-detail-mobile-view {
      ${tw` xs:block sm:hidden`}
    }
  }
`
