import React from 'react'

function Users(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.82927 10.8492C6.60503 10.8492 8.04878 9.36938 8.04878 7.54922C8.04878 5.72906 6.60503 4.24922 4.82927 4.24922C3.05351 4.24922 1.60976 5.72906 1.60976 7.54922C1.60976 9.36938 3.05351 10.8492 4.82927 10.8492ZM27.3659 10.8492C29.1416 10.8492 30.5854 9.36938 30.5854 7.54922C30.5854 5.72906 29.1416 4.24922 27.3659 4.24922C25.5901 4.24922 24.1463 5.72906 24.1463 7.54922C24.1463 9.36938 25.5901 10.8492 27.3659 10.8492ZM28.9756 12.4992H25.7561C24.8707 12.4992 24.0709 12.8653 23.4873 13.4583C25.5146 14.5978 26.9534 16.6552 27.2652 19.0992H30.5854C31.4758 19.0992 32.1951 18.3619 32.1951 17.4492V15.7992C32.1951 13.9791 30.7514 12.4992 28.9756 12.4992ZM16.0976 12.4992C19.2114 12.4992 21.7317 9.91594 21.7317 6.72422C21.7317 3.5325 19.2114 0.949219 16.0976 0.949219C12.9837 0.949219 10.4634 3.5325 10.4634 6.72422C10.4634 9.91594 12.9837 12.4992 16.0976 12.4992ZM19.961 14.1492H19.5434C18.4971 14.6648 17.3351 14.9742 16.0976 14.9742C14.8601 14.9742 13.703 14.6648 12.6517 14.1492H12.2341C9.03476 14.1492 6.43902 16.8098 6.43902 20.0892V21.5742C6.43902 22.9406 7.52058 24.0492 8.85366 24.0492H23.3415C24.6745 24.0492 25.7561 22.9406 25.7561 21.5742V20.0892C25.7561 16.8098 23.1604 14.1492 19.961 14.1492ZM8.70778 13.4583C8.12424 12.8653 7.32439 12.4992 6.43902 12.4992H3.21951C1.44375 12.4992 0 13.9791 0 15.7992V17.4492C0 18.3619 0.71936 19.0992 1.60976 19.0992H4.92485C5.24177 16.6552 6.68049 14.5978 8.70778 13.4583Z"
        fill="white"
      />
    </svg>
  )
}

export default Users
