import React from 'react'

function UploadImage({ className }) {
  return (
    <svg
      className={className}
      width="158"
      height="112"
      viewBox="0 0 158 112"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M76.2103 33.5316C77.7409 31.9812 80.2591 31.9812 81.7897 33.5316L101.54 53.2191C103.095 54.7449 103.095 57.2551 101.54 58.7809C100.009 60.3312 97.4909 60.3312 95.9603 58.7809L82.95 45.8117V83.5625C82.95 85.7281 81.1725 87.5 79 87.5C76.8275 87.5 75.05 85.7281 75.05 83.5625V45.8117L62.0397 58.7809C60.5091 60.3312 57.9909 60.3312 56.4603 58.7809C54.905 57.2551 54.905 54.7449 56.4603 53.2191L76.2103 33.5316ZM67.15 0.875C81.8391 0.875 94.8247 8.14707 102.7 19.2828C106.28 17.5774 110.304 16.625 114.55 16.625C129.832 16.625 142.2 28.9543 142.2 44.1875C142.2 46.8207 141.83 49.134 141.138 51.7672C151.162 57.0336 158 67.5418 158 79.625C158 97.0238 143.854 111.125 126.4 111.125H35.55C15.916 111.125 0 95.252 0 75.6875C0 60.2328 9.91203 47.116 23.742 42.2434C24.7616 19.2336 43.7956 0.875 67.15 0.875ZM67.15 8.75C48.0419 8.75 32.4641 23.7617 31.6247 42.5879C31.5013 45.8117 29.4028 48.3957 26.3663 49.6754C15.5976 53.4652 7.9 63.6781 7.9 75.6875C7.9 90.9207 20.2783 103.25 35.55 103.25H126.4C139.484 103.25 150.1 92.668 150.1 79.625C150.1 70.5687 144.99 62.6938 137.46 58.7316C134.152 56.7629 132.523 53.1699 133.535 49.3801C134.028 47.8789 134.3 46.0824 134.3 44.1875C134.3 33.3102 125.462 24.5 114.55 24.5C111.513 24.5 108.65 25.1891 106.082 26.3949C102.626 28.0437 98.4538 26.9363 96.2319 23.8109C89.7884 14.6858 79.1728 8.75 67.15 8.75Z" />
    </svg>
  )
}

export default UploadImage
