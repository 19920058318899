import React, { FC, Fragment, useState } from 'react'
import InputField from 'components/Common/InputField'
import InputTextButton from 'components/Common/InputTextButton'
import { FieldValues, UseFormRegister, FieldErrors, UseFormTrigger } from 'react-hook-form'

interface IPasswordFieldsProps {
  errors: FieldErrors
  confirmPasswordCheck: string
  trigger: UseFormTrigger<FieldValues>
  register: UseFormRegister<FieldValues>
}
const PasswordFields: FC<IPasswordFieldsProps> = ({
  errors,
  trigger,
  register,
  confirmPasswordCheck,
}) => {
  const [showPasswordFields, setShowPasswordFields] = useState({
    newPassword: false,
    confirmNewPassword: false,
  })

  const registerPassword = register('password')

  const togglePasswordFields = (passwordField) => {
    setShowPasswordFields({
      ...showPasswordFields,
      [passwordField]: !showPasswordFields[passwordField],
    })
  }

  return (
    <Fragment>
      <InputTextButton
        title={showPasswordFields.newPassword ? 'Hide' : 'Show'}
        action={() => togglePasswordFields('newPassword')}
      />
      <InputField
        defaultValue=""
        type="password"
        label="New Password"
        aria-invalid={errors}
        {...registerPassword}
        errors={errors.password}
        showPassword={showPasswordFields.newPassword}
        onChange={(rest) => {
          if (confirmPasswordCheck) {
            trigger('confirmPassword')
          }
          registerPassword.onChange(rest)
        }}
      />
      <InputTextButton
        title={showPasswordFields.confirmNewPassword ? 'Hide' : 'Show'}
        action={() => togglePasswordFields('confirmNewPassword')}
      />
      <InputField
        defaultValue=""
        type="password"
        aria-invalid={errors}
        label="Confirm New Password"
        errors={errors.confirmPassword}
        {...register('confirmPassword')}
        showPassword={showPasswordFields.confirmNewPassword}
      />
    </Fragment>
  )
}

export default PasswordFields
