import React, { useEffect } from 'react'

import { Controller } from 'react-hook-form'

import InputField from 'components/Common/InputField'

import { scrollToTop } from 'utils/helper'
import { AppCountryPhoneInput } from 'components/Common/AppFormFields/AppCountryPhoneInput/AppCountryPhoneInput.component'
import Label from 'components/Common/Label'

const EditUserProfile = ({
  error,
  errors,
  control,
  register,
  resetErrorAction,
  mobileContactError,
  clearErrors,
}) => {
  useEffect(() => {
    scrollToTop()
  }, [error, mobileContactError])

  useEffect(() => {
    return () => resetErrorAction()
    // eslint-disable-next-line
  }, [errors])

  return (
    <>
      <div className="xs:col-span-12 sm:col-span-6">
        <InputField
          errors={errors.firstName}
          type={'text'}
          label="First Name"
          placeholder="Enter First Name"
          {...register('firstName')}
        />
      </div>
      <div className="xs:col-span-12 sm:col-span-6">
        <InputField
          errors={errors.lastName}
          type={'text'}
          label="Last Name"
          placeholder="Enter Last Name"
          {...register('lastName')}
        />
      </div>
      <div className="xs:col-span-12 sm:col-span-6 mt-7">
        <InputField
          errors={errors.email}
          type={'email'}
          label="Email Address (used for login)"
          placeholder="name@email.com"
          {...register('email')}
          disabled={true}
        />
      </div>
      <div className="col-span-12 sm:col-span-6 mt-7">
        <Label>Mobile Number</Label>
        <Controller
          control={control}
          name={'mobileContactNumber'}
          render={({ field }) => (
            <AppCountryPhoneInput
              error={(errors.mobileContactNumber?.message as string) || mobileContactError?.message}
              value={field.value}
              onChange={(formattedValue) => {
                field.onChange(formattedValue)
                clearErrors('mobileContactNumber')
              }}
              name="mobileContactNumber"
              placeholder="ex: (380) 221-7275"
              size="md"
            />
          )}
        />
      </div>
    </>
  )
}
export default EditUserProfile
