import React, { FC } from 'react'

import { includes } from 'lodash'
import { EllipseIcon, TickIcon } from 'components/Common/SvgIcons'
import * as Icons from 'components/Common/SvgIcons'

const CardSelect: FC<any> = ({ value, options, onChange, isMulti }) => {
  const handleSelect = (option) => {
    onChange(option)
  }

  return (
    <div className="mt-0 w-[100%] xs:block  max-w-[910px] xxl:max-w-[760px] sm:flex text-center items-center justify-center">
      <div className="sm:w-[550px] md:w-full lg:w-full ">
        <div className="flex flex-row flex-wrap gap-5">
          {options.map((option) => {
            const Icon = Icons[option.icon]
            return (
              <div
                key={option.value}
                className={`group w-[154px] h-[140px] px-3 bg-cream-dark rounded-[20px] xxl:py-[20px] flex flex-col justify-center items-center align-middle cursor-pointer gap-y-3 relative ${
                  includes(value, option.value)
                    ? 'group bg- bg-opacity-25 border border-1 border-tertiary bg-tertiary  shadow-md rounded-[20px] text-black-light'
                    : 'text-silver border border-cream-dark border-1  hover:border-tertiary hover:border hover:border-1'
                }`}
                onClick={() => handleSelect(option.value)}
              >
                <div>
                  {isMulti && (
                    <span className="absolute top-2 right-0  -translate-x-2/4">
                      <span>
                        <EllipseIcon
                          className={`h-[25px] w-[25px] ${
                            includes(value, option.value)
                              ? 'fill-tertiary'
                              : 'fill-primary-disabledText'
                          }`}
                        />
                      </span>
                      <span className="absolute top-[20%] left-1/2  -translate-x-2/4">
                        <TickIcon
                          className={`${
                            includes(value, option.value)
                              ? 'stroke-[15px] stroke-cream-dark w-[13px]'
                              : 'stroke-primary-disabledText stroke-[15px] w-[13px]'
                          }`}
                        />
                      </span>
                    </span>
                  )}

                  <div>
                    {Icon && (
                      <Icon
                        className={`w-[67px] h-[53px] ${
                          includes(value, option.value) ? 'fill-tertiary' : 'fill-silver'
                        }`}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <p
                    className={`font-primary text-base font-semibold ${
                      includes(value, option.value) ? 'text-black-light' : 'text-silver'
                    }`}
                  >
                    {option.label}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
CardSelect.defaultProps = {
  isMulti: false,
}
export default CardSelect
