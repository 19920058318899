import styled from 'styled-components'
import tw from 'twin.macro'

export interface IDropZoneProps {
  hasPadding: boolean
}

export const AcceptedFileType = styled.div.attrs({
  className: 'block text-xs text-center leading-4 !pointer-events-none',
})<IDropZoneProps>`
  ${(p) => (p.hasPadding ? tw`pt-4` : '')}
`
export const FileType = styled.p`
  ${tw`text-xs text-center leading-4 px-6 pt-2 pb-7 xs:block sm:hidden`}
`
