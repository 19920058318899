import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const AdvisorEditOwnerDetailLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 200 300"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="0" y="5" rx="2" ry="2" width="80" height="15" />

        <rect x="0" y="26" rx="2" ry="2" width="200" height="35" />

        <rect x="0" y="75" rx="2" ry="2" width="80" height="15" />
        <rect x="0" y="95" rx="2" ry="2" width="200" height="35" />

        <rect x="0" y="140" rx="2" ry="2" width="80" height="15" />
        <rect x="0" y="158" rx="2" ry="2" width="200" height="35" />

        <rect x="0" y="199" rx="2" ry="2" width="80" height="15" />
        <rect x="0" y="220" rx="2" ry="2" width="200" height="35" />
      </ContentLoader>
    </>
  )
}

export default AdvisorEditOwnerDetailLoader
