import React, { FC } from 'react'
import { ToolTipWrapper } from 'components/Calendar/EventForm/style'
import { Tooltip, PlacesType } from 'react-tooltip'

export interface IToolTip {
  name: string
  text: string
  width?: string
  place?: PlacesType
  className?: string
  children: React.ReactChild | React.ReactNode
  opacity?: number
  backgroundColor?: string
  toolTipPadding?: string
}

const ToolTip: FC<IToolTip> = ({
  name,
  text,
  children,
  className,
  width,
  place,
  opacity,
  backgroundColor,
  toolTipPadding,
}) => {
  return (
    <span
      data-tooltip-id={name}
      data-testid={name}
      data-tooltip-content={`${text}`}
      className={`${className ? className : 'whitespace-pre-line text-center'} `}
    >
      <ToolTipWrapper>
        <Tooltip
          id={name}
          place={`${place ? place : 'top'}`}
          style={{
            zIndex: '1000',
            width: `${width}`,
            padding: `${toolTipPadding}`,
            backgroundColor: `${backgroundColor ? backgroundColor : '#D5E0FA'}`,
          }}
          opacity={opacity ? opacity : 100}
        />
      </ToolTipWrapper>
      {children}
    </span>
  )
}

export default ToolTip
