import React, { FC } from 'react'

export interface ICancelIconProps {
  className?: string
  id?: string
}
const DropDownIcon: FC<ICancelIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="12"
      viewBox="0 0 21 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.55073 12.0594C10.1311 12.6453 11.0736 12.6453 11.6539 12.0594L20.5682 3.05938C21.1486 2.47344 21.1486 1.52188 20.5682 0.935944C19.9879 0.350006 19.0454 0.350006 18.465 0.935944L10.6 8.87657L2.73502 0.940631C2.15466 0.354693 1.21216 0.354693 0.631801 0.940631C0.0514439 1.52657 0.0514439 2.47813 0.631801 3.06407L9.54609 12.0641L9.55073 12.0594Z" />
    </svg>
  )
}
DropDownIcon.defaultProps = {
  className: '',
}
export default DropDownIcon
