import React, { FC } from 'react'

export interface ICancelIconProps {
  className?: string
  id?: string
}
const CancelIcon: FC<ICancelIconProps> = ({ className = '', id = '' }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.458983 2.85352C-0.151368 2.24316 -0.151368 1.25195 0.458983 0.641602C1.06933 0.03125 2.06055 0.03125 2.6709 0.641602L7.8125 5.78809L12.959 0.646484C13.5693 0.0361327 14.5605 0.0361327 15.1709 0.646484C15.7812 1.25684 15.7812 2.24805 15.1709 2.8584L10.0244 8L15.166 13.1465C15.7764 13.7568 15.7764 14.748 15.166 15.3584C14.5557 15.9688 13.5645 15.9688 12.9541 15.3584L7.8125 10.2119L2.66602 15.3535C2.05566 15.9639 1.06445 15.9639 0.454101 15.3535C-0.156251 14.7432 -0.156251 13.752 0.454101 13.1416L5.60059 8L0.458983 2.85352Z" />
    </svg>
  )
}
CancelIcon.defaultProps = {
  className: '',
}
export default CancelIcon
