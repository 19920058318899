import React from 'react'

const CardView = ({
  children,
  type = '',
  topBottomPadding = '',
  margin = '',
  padding = '',
  classes = '',
  locked = false,
}) => {
  return (
    <div
      className={`${topBottomPadding} ${padding} ${margin} ${classes || 'bg-white'} ${
        locked ? 'text-brown' : 'text-primary-text'
      }  relative`}
    >
      {type && (
        <label className={`font-primary ${locked ? 'text-sm' : 'text-base'} font-normal	`}>
          {type.toUpperCase()}
        </label>
      )}
      {children}
    </div>
  )
}

export default CardView
