import React from 'react'

import { InfoContainer, InfoWrapper } from 'components/Assessment/AssessmentQuestion/styles'
import { createMarkup } from 'components/Assessment/AssessmentQuestion/utils'
import { InfoIcon } from 'components/Common/SvgIcons'

const Info = ({ text, user, businessName, id = '' }) => {
  if (!text) return <></>
  return (
    <InfoContainer id={id}>
      <span>
        <InfoIcon className={'fill-primary-brandBlueLight inline-flex w-[22px] flex-shrink-0'} />
      </span>
      <InfoWrapper>
        <div
          dangerouslySetInnerHTML={createMarkup(text, user, businessName)}
          className={`font-medium inline font-primary text-base text-black-light`}
        />
      </InfoWrapper>
    </InfoContainer>
  )
}

export default Info
