import React from 'react'
import ContentLoader from 'react-content-loader'
import get from 'lodash/get'

import colors from 'colors'

const AdvisorOwnerActivityLogLoader = () => {
  return (
    <>
      <ContentLoader
        speed={2}
        viewBox="0 0 450 400"
        backgroundColor={`${get(colors, 'cream.dark', '#F4F4F5')}`}
        foregroundColor={`${get(colors, 'grey.lighter', '#e8e8e8')}`}
      >
        <rect x="10" y="1" rx="2" ry="2" width="70" height="14" />

        <circle cx="18" cy="36" r="6" />
        <rect x="17" y="40" rx="2" ry="2" width="1" height="21" />
        <rect x="32" y="29" rx="2" ry="2" width="200" height="12" />

        <circle cx="18" cy="66" r="6" />
        <rect x="17" y="71" rx="2" ry="2" width="1" height="21" />
        <rect x="32" y="60" rx="2" ry="2" width="200" height="12" />

        <circle cx="18" cy="96" r="6" />
        <rect x="32" y="89" rx="2" ry="2" width="200" height="12" />
      </ContentLoader>
    </>
  )
}

export default AdvisorOwnerActivityLogLoader
