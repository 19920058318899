import { SplitFactory } from '@splitsoftware/splitio'
import { FEATURE_FLAG_CONFIG, FeatureFlag, TrafficType } from '../feature-flags'
export class FeatureFlagManager {
  private factory: SplitIO.ISDK
  private userClient: SplitIO.IClient
  private orgClient: SplitIO.IClient

  constructor(userId?: string, tenantId?: string) {
    if (!process.env.REACT_APP_SPLIT_IO_CLIENT_KEY)
      throw new Error('Feature flag configuration not set.')

    this.factory = SplitFactory({
      core: {
        authorizationKey: process.env.REACT_APP_SPLIT_IO_CLIENT_KEY,
        key: userId ?? 'key',
        trafficType: TrafficType.USER,
      },
      startup: {
        readyTimeout: 5, // seconds
      },
      streamingEnabled: true,
    })

    this.userClient = this.factory.client()
    this.orgClient = this.factory.client(tenantId ?? 'key', TrafficType.ORG)
  }

  public async checkToggleFlag(flagName: FeatureFlag): Promise<boolean> {
    const featureFlagConfig = FEATURE_FLAG_CONFIG[flagName]
    if (!featureFlagConfig) {
      throw new Error(
        `Feature configuration not found for ${flagName}. Results could be incorrect...`
      )
    }

    let treatment: SplitIO.Treatment
    if (featureFlagConfig.level === TrafficType.USER) {
      await this.userClient.ready().catch((e) => console.error(e))
      treatment = this.userClient.getTreatment(flagName)
    } else if (featureFlagConfig.level === TrafficType.ORG) {
      await this.orgClient.ready().catch((e) => console.error(e))
      treatment = this.orgClient.getTreatment(flagName)
    } else {
      throw new Error('Feature configuration incorrect.')
    }
    return treatment === 'on'
  }

  // public async checkFlag(flagName: FeatureFlag): Promise<string> {
  //   await this.userClient.ready().catch((e) => console.error(e))

  //   const treatment: SplitIO.Treatment = this.userClient.getTreatment(flagName)
  //   return treatment
  // }
}
