import React from 'react'

function StepperCircle(props) {
  return (
    <svg width="43" height="43" viewBox="0 0 43 43" fill="white" xmlns="http://www.w3.org/2000/svg">
      <circle cx="21.5" cy="21.5" r="20.5" stroke="#18181B" strokeWidth="2" />
    </svg>
  )
}

export default StepperCircle
