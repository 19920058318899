import styled from 'styled-components'
import tw from 'twin.macro'
import { CommunicationIcon } from 'components/Common/SvgIcons'
import TackleAvatarLogo from 'components/Common/TackleAvatarLogo'

export const StyledCommunicationIconWrapper = styled(CommunicationIcon)`
  ${tw`fill-primary`}
`

export const MobileStyledCommunicationIconWrapper = styled(CommunicationIcon)`
  ${tw`h-[28px] w-[22px] fill-primary`}
`

export const TackleAvatarLogoWrapper = styled(TackleAvatarLogo)`
  ${tw`border-white outline-8 outline-offset-4 border-4 border-solid bg-blue-lighter1x h-full w-full rounded-full fill-white cursor-pointer sm:h-[54px] sm:w-[54px] md:w-[79.8px] md:h-[79.8px] xl:h-[107px] xl:w-[107px]`}
`

export const ActiveClientWrapper = styled.div`
  ${tw`px-3 pt-5 pb-5 mdl:block`}
`

export const CommunicationIconWrapper = styled.div`
  ${tw`absolute top-5 md:right-1 mdl:right-3 lg:right-8 xl:right-5 xxl:right-8 z-[10] cursor-pointer`}
`

export const ClientWrapper = styled.div`
  ${tw`flex items-center justify-center flex-col`}
`

export const LogoWrapper = styled.div`
  ${tw`relative sm:h-[54px] sm:w-[54px] md:w-[79.8px] md:h-[79.8px] xl:h-[107px] xl:w-[107px]`}
`

export const NavigateToClientDetailWrapper = styled.div`
  ${tw`text-primary font-bold mt-3 leading-5 cursor-pointer`}
`

export const BusinessInfoWrapper = styled.div`
  ${tw`flex justify-between mb-1 mt-6 gap-3`}
`

export const BusinessTextWrapper = styled.p`
  ${tw`text-sm font-normal text-primary-text`}
`

export const BusinessNameWrapper = styled.p`
  ${tw`text-base	font-normal line-clamp-1`}
`

export const HorizontalBreakWrapper = styled.hr`
  ${tw`text-secondary-text`}
`

export const GoalInfoWrapper = styled.div`
  ${tw`flex justify-between mt-1`}
`

export const GoalTextWrapper = styled.p`
  ${tw`text-sm font-normal text-primary-text`}
`

export const TopGoalWrapper = styled.p`
  ${tw`text-base font-normal`}
`

export const MobileActiveClientWrapper = styled.div`
  ${tw`px-2 pt-[13.8px] pb-[13.8px]`}
`

export const MobileClientWrapper = styled.div`
  ${tw`flex pl-1 justify-between items-center`}
`

export const MobileClientInfoWrapper = styled.div`
  ${tw`inline-flex`}
`

export const MobileClientIconWrapper = styled.div`
  ${tw`relative h-[54px] w-[54px]`}
`

export const MobileActiveClientCardWrapper = styled.div`
  ${tw`flex flex-col ml-4 inline-flex justify-center`}
`

export const MobileNavigateToClientDetailWrapper = styled.p`
  ${tw`text-primary font-primary text-lg font-semibold cursor-pointer`}
`

export const MobileBusinessNameWrapper = styled.p`
  ${tw`text-sm font-semibold text-zinc font-primary`}
`

export const CommunicationWrapper = styled.div`
  ${tw`flex flex-col mr-2`}
`

export const MobileCommunicationIconWrapper = styled.div`
  ${tw`flex justify-end cursor-pointer`}
`

export const LabelWrapper = styled.div`
  ${tw`text-xs flex justify-end`}
`
