import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Error from 'components/Error'
import Page from 'routes/Page'

export const AppErrorRoutes = ({ match: { url } }) => {
  return (
    <Switch>
      <Route
        path={`${url}/:tenantId/*`}
        exact
        render={() => (
          <Page title="Error">
            <Error />
          </Page>
        )}
      />

      <Redirect to={`${url}`} />
    </Switch>
  )
}
