import styled from 'styled-components'
import tw from 'twin.macro'

export const ClientReportsWrapper = styled.div`
  ${tw`bg-white w-full h-full xs:p-5 md:p-10`} {
  }
`
export const HeadingWrapper = styled.div`
  ${tw`xs:pt-6 sm:pt-16 flex gap-2 items-center`} {
  }
`
export const SearchFieldWrapper = styled.div`
  ${tw`grid grid-cols-10 xs:pt-5 sm:pt-10`} {
  }
`
export const SearchField = styled.div`
  ${tw`xs:col-span-12 sm:col-span-4 lg:col-span-2`} {
    /* ${tw`xs:col-span-12 sm:col-span-4 lg:col-span-2 pl-4 bg-grey-light rounded-[10px]`} { */
  }
`
export const ClientName = styled.div`
  ${tw`font-primary text-base font-medium text-black-light line-clamp-1`} {
  }
`
export const BusinessName = styled.div`
  ${tw`font-primary text-sm font-normal text-black-light leading-4 pt-1 line-clamp-1`} {
  }
`
