import React from 'react'
import Logo from 'assets/images/GoTackle-Logo.svg'
import PasswordRules from 'components/Auth/PasswordRules'

const AuthCardContainer = ({ children, passwordCheck, showPasswordRules }) => {
  return (
    <div className="px-6 py-9 max-w-[400px] bg-white rounded-[36px] shadow-lg">
      <div className="pb-9 pt-3 max-width-[200] flex text-center items-center justify-center">
        <img src={Logo} alt="GoTackle icon" className="object-contain h-[39px]" />
      </div>
      {showPasswordRules && <PasswordRules passwordCheck={passwordCheck} />}
      {children}
    </div>
  )
}

AuthCardContainer.defaultProps = {
  passwordCheck: false,
  showPasswordRules: false,
}

export default AuthCardContainer
