import React from 'react'

function ThreeDotsIcon({ className }) {
  return (
    <svg
      className={className}
      width="14"
      height="4"
      viewBox="0 0 14 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.625 2C2.625 2.34518 2.34518 2.625 2 2.625C1.65482 2.625 1.375 2.34518 1.375 2C1.375 1.65482 1.65482 1.375 2 1.375C2.34518 1.375 2.625 1.65482 2.625 2Z"
        stroke="#5E5E5E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.625 2C7.625 2.34518 7.34518 2.625 7 2.625C6.65482 2.625 6.375 2.34518 6.375 2C6.375 1.65482 6.65482 1.375 7 1.375C7.34518 1.375 7.625 1.65482 7.625 2Z"
        stroke="#5E5E5E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.625 2C12.625 2.34518 12.3452 2.625 12 2.625C11.6548 2.625 11.375 2.34518 11.375 2C11.375 1.65482 11.6548 1.375 12 1.375C12.3452 1.375 12.625 1.65482 12.625 2Z"
        stroke="#5E5E5E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

ThreeDotsIcon.defaultProps = {
  className: '',
}
export default ThreeDotsIcon
