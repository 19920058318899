import React from 'react'
import { AppChevronDownIcon } from 'components/Common/AppSvgIcons/AppChevronDownIcon'
import { AppButtonWrapper, AppStyledButtonLabel } from './AppButton.style'
import appColors from 'appColors'
interface IconProps {
  color?: string
  className?: string
}

interface AppButtonProps {
  size: 'lg' | 'md' | 'sm' | 'xs'
  variant: 'alert' | 'clear' | 'success' | 'secondary' | 'primary' | 'tertiary'
  label?: string
  onClick?: () => void
  isSelector?: boolean
  disabled?: boolean
  LeftIcon?: React.ComponentType<IconProps>
  RightIcon?: React.ComponentType<IconProps>
  isFullWidth?: boolean
  type?: 'button' | 'submit'
}

export const AppButton: React.FC<AppButtonProps> = ({
  size,
  label,
  variant,
  onClick,
  disabled,
  isSelector,
  LeftIcon,
  RightIcon,
  isFullWidth = false,
  type = 'button',
}) => {
  return (
    <AppButtonWrapper isFullWidth={isFullWidth}>
      <button
        className={`button ${size} ${variant} ${label ? 'on' : 'off'} ${
          disabled ? 'disabled' : 'default'
        } ${isFullWidth ? 'w-full' : ''}`}
        onClick={onClick}
        disabled={disabled}
        type={type}
        id={label}
      >
        {LeftIcon && (
          <LeftIcon
            color={
              disabled
                ? variant === 'secondary' || variant === 'clear' || variant === 'tertiary'
                  ? appColors['app-grey']['50']
                  : appColors['app-white']['100']
                : variant === 'secondary' || variant === 'clear' || variant === 'tertiary'
                ? appColors['app-grey']['70']
                : appColors['app-white']['100']
            }
          />
        )}
        {label && (
          <AppStyledButtonLabel disabled={disabled} variant={variant}>
            {label}
          </AppStyledButtonLabel>
        )}
        {RightIcon && (
          <RightIcon
            color={
              disabled
                ? variant === 'secondary' || variant === 'clear' || variant === 'tertiary'
                  ? appColors['app-grey']['50']
                  : appColors['app-white']['100']
                : variant === 'secondary' || variant === 'clear' || variant === 'tertiary'
                ? appColors['app-grey']['70']
                : appColors['app-white']['100']
            }
          />
        )}
        {isSelector && (
          <AppChevronDownIcon
            color={
              disabled
                ? variant === 'secondary' || variant === 'clear' || variant === 'tertiary'
                  ? appColors['app-grey']['50']
                  : appColors['app-white']['100']
                : variant === 'secondary' || variant === 'clear' || variant === 'tertiary'
                ? appColors['app-grey']['70']
                : appColors['app-white']['100']
            }
          />
        )}
      </button>
    </AppButtonWrapper>
  )
}
