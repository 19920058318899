import React from 'react'

import { AppFC } from 'types'

import Button, { IButtonProps } from 'components/Common/Button'

type IFilterButtonProps = Omit<IButtonProps, 'variant' | 'rightContent' | 'rightIcon' | 'label'>

const FilterButton: AppFC<IFilterButtonProps> = ({ onClick, ...rest }) => {
  return (
    <Button
      variant="secondary"
      onClick={onClick}
      className="!rounded-xl !font-primary !font-medium"
      rightIcon="filter-menu"
      label="Filter"
      {...rest}
    />
  )
}

export default FilterButton
