import React, { useEffect } from 'react'
import {
  AccountingCharts,
  ChartStatus,
  DateRanges,
  LiquidCashReport,
  QueryAccountingReportInput,
} from '../../../../__generated__/api-types-and-hooks'
import AccountingStatValue from '../AccountingStatValue'
import Loader from '../../../Loaders'
import { ReduxAction } from '../../../../store/types'
import OwnerApi from 'api/ownerData'
import { OperationName } from 'types'
import withChartWrapper, { WithChartWrapperProps } from '../ChartHOC'
import { useFetchChartData } from 'hooks/useFetchChartData'
import { RootState } from 'App'
import { ROLES } from 'config'
import { useSelector } from 'react-redux'
import { getRoleFromPath } from 'utils/helper'

export interface ILiquidCashChartProps {
  chartData: { data?: LiquidCashReport[]; status: ChartStatus }
  isLoading?: boolean
  getDataAction: ReduxAction<QueryAccountingReportInput>
  period?: DateRanges
}

type LiquidCashData = { data?: LiquidCashReport[]; status: ChartStatus }

const onFetchDataMethod = async (
  params: QueryAccountingReportInput
): Promise<LiquidCashData | undefined> => {
  const data = await OwnerApi.getOwnerAccountingData<LiquidCashReport[]>(params)
  return data?.getAccountingCharts
}

const ACCOUNTING_CHARTS_QUERY_KEY: OperationName = 'getAccountingCharts'

interface ILiquidCashProps
  extends WithChartWrapperProps<QueryAccountingReportInput, LiquidCashData> {
  period: DateRanges
}

export const LiquidCashChart = withChartWrapper(
  onFetchDataMethod,
  ({ onFetchData, chartData, period }: ILiquidCashProps) => {
    const { mutate: fetchData, isLoading } = useFetchChartData(
      onFetchData,
      ACCOUNTING_CHARTS_QUERY_KEY
    )

    const userId =
      getRoleFromPath() === ROLES.BUSINESS_OWNER
        ? useSelector((state: RootState) => state.user.user.id)
        : useSelector((state: RootState) => state.clients.client.profile.id)

    useEffect(() => {
      fetchData({
        chartType: AccountingCharts.LiquidCash,
        dateRange: period,
        userId,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period])

    return (
      <div className="flex flex-col font-semibold leading-7 text-lg font-primary p-10">
        <p>LIQUID CASH</p>
        {isLoading && <Loader loader="TableLoader" />}
        {chartData?.data?.length ? (
          <AccountingStatValue
            isLoading={isLoading}
            chartValue={chartData?.data[0].liquidCash ?? 0}
            chartGrowthValue={chartData?.data[0].growth ?? 0}
            dateRange={chartData?.data[0].dateRange ?? DateRanges.ThisYearToDate}
          />
        ) : (
          <></>
        )}
      </div>
    )
  }
)
