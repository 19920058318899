import React, { FC } from 'react'

import RecommendationProgressBubble, {
  RecommendableStatus,
} from 'components/Owner/PlayBooksCategoryDetails/RecommendationProgressBubble'

interface IProps {
  play: {
    playType: string
    playBookTitle: string
    isCompleted: boolean
    playStatus: RecommendableStatus | string
  }
}
const PlayItem: FC<IProps> = ({ play }) => {
  return (
    <div className="flex items-start justify-between">
      <span className="text-lg font-semibold font-primary inline-block w-[80%]">
        {play.playBookTitle}
      </span>
      <div className="flex items-center space-x-2">
        <RecommendationProgressBubble status={play.playStatus as RecommendableStatus} />
      </div>
    </div>
  )
}

export default PlayItem
