import PropTypes from 'prop-types'
import React from 'react'

interface AppAlertIconProps {
  color?: string
}

export const AppAlertIcon: React.FC<AppAlertIconProps> = ({ color }) => {
  return (
    <svg
      className={`alert-circle-1`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_368_4087)">
        <path
          className="path"
          d="M7.99998 14.6666C11.6819 14.6666 14.6666 11.6818 14.6666 7.99992C14.6666 4.31802 11.6819 1.33325 7.99998 1.33325C4.31808 1.33325 1.33331 4.31802 1.33331 7.99992C1.33331 11.6818 4.31808 14.6666 7.99998 14.6666Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.33333"
        />
        <path
          className="path"
          d="M8 10.6667H8.00667"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.33333"
        />
        <path
          className="path"
          d="M8 5.33325V7.99992"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.33333"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_368_4087">
          <rect className="rect" fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  )
}

AppAlertIcon.propTypes = {
  color: PropTypes.string,
}
