import React, { FC } from 'react'
import Button from 'components/Common/Button'
import MeetingCreationErrorIcon from 'components/Common/SvgIcons/MeetingCreationErrorIcon'
import MeetingCreationSuccessIcon from 'components/Common/SvgIcons/MeetingCreationSuccessIcon'
import WarningIconV1 from 'components/Common/SvgIcons/WarningIconV1'
import { STATUS } from 'config'

interface IMeetingConfirmationProps {
  errorMessage: string
  handleClose: () => void
  isWarning?: boolean
  Heading: string
  successMessage: string
  handleCancel?: () => void
  isLoading?: boolean
}

const MeetingConfirmation: FC<IMeetingConfirmationProps> = ({
  errorMessage,
  successMessage,
  handleClose,
  isWarning = false,
  Heading,
  handleCancel,
  isLoading,
}) => {
  return (
    <div className="p-8">
      <div className="flex items-center justify-center">
        {isWarning ? (
          <WarningIconV1 />
        ) : !errorMessage ? (
          <MeetingCreationSuccessIcon />
        ) : (
          <MeetingCreationErrorIcon />
        )}
      </div>
      <div className="flex items-center justify-center flex-col pt-4">
        <div className="text-primary-text text-2xl font-secondary text-center px-3">{Heading}</div>
        <div className="!font-medium pt-5 pb-6 px-9 text-center">
          {successMessage && <p>{successMessage}</p>}

          {errorMessage && <p>{errorMessage}</p>}
        </div>
      </div>
      {!isWarning && (
        <Button
          label={`${errorMessage ? 'Try again' : 'Got it'}`}
          type="button"
          variant="primary"
          className="w-full"
          disabled={isLoading}
          onClick={() => handleClose()}
        />
      )}
      {isWarning && (
        <div className="flex justify-center gap-2">
          <Button
            label={`No`}
            type="button"
            variant="secondary"
            disabled={isLoading}
            onClick={() => handleCancel && handleCancel()}
          />
          <Button
            label={`Yes, delete`}
            type="button"
            variant="primary"
            className="!px-12"
            disabled={isLoading}
            status={isLoading ? STATUS.LOADING : ''}
            onClick={() => handleClose()}
          />
        </div>
      )}
    </div>
  )
}

export default MeetingConfirmation
