import React from 'react'

const FilledArrowIcon = ({ className, color }) => {
  return (
    <svg
      width="11"
      height="20"
      className={className}
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 10L0.500002 19.5263L0.500002 0.473721L11 10Z" fill={color || 'white'} />
    </svg>
  )
}

FilledArrowIcon.defaultProps = {
  className: '',
  color: '',
}

export default FilledArrowIcon
