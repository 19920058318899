import { each, filter, get, map, sum, uniq, values } from 'lodash'
import moment from 'moment'

export enum IPNLData {
  GROSS_PROFIT = 'Gross Profit',
  COGS = 'COGS',
  Expenses = 'Expenses and Other OtherExpenses',
  Net_Income = 'Net Income',
  Other_Income = 'Income and Other Income',
  Cash_In = 'Cash In',
  Cash_Out = 'Cash Out',
  Burn_Rate = 'Burn Rate',
}

export enum ICFData {
  Cash_In = 'Cash In',
  Cash_Out = 'Cash Out',
}

export const formatPNLData = (data) => {
  const resp = {}
  each(data, (row) => {
    resp[row.Activity_type] = row
  })
  return resp
}

export const setPNLData = (data) => {
  return (attr, key = 'Value') => get(data, `pnlData.chartData.${attr}.${key}`, 0)
}

export const getCashBalancePercentage = (data) => {
  const getCF = setPNLData(data)
  return (
    Number(getCF(IPNLData.Cash_In, 'day_percentage_change')) -
    Number(getCF(IPNLData.Cash_Out, 'day_percentage_change'))
  )
}

const getValues = (type: ICFData, data, startDate, endDate) => {
  const details = {}
  const filteredData = filter(data, (row) => row?.Activity_type === type)
  each(filteredData, (row) => {
    if (startDate && endDate) {
      if (moment(get(row, 'date.value')).isBetween(startDate, endDate, undefined, '[]'))
        details[get(row, 'date.value')] = Number(row.Value)
    } else {
      details[get(row, 'date.value')] = Number(row.Value)
    }
  })
  return details
}

export const getCashBalance = (data, startDate, endDate) => {
  const getAggregateValues = (type: ICFData) => {
    const details = getValues(type, data, startDate, endDate)
    return sum(values(details))
  }

  const cashIn = getAggregateValues(ICFData.Cash_In)
  const cashOut = getAggregateValues(ICFData.Cash_Out)
  return sum([cashIn, cashOut])
}

export const getCashFlowData = (data, startDate, endDate) => {
  const cashIn = getValues(ICFData.Cash_In, data, startDate, endDate)
  const cashOut = getValues(ICFData.Cash_Out, data, startDate, endDate)
  const dates = uniq(map(data, 'date.value'))
  const details: any = []
  each(dates, (date) => {
    details.push({
      name: moment(date).format('MMM YYYY'),
      cashIn: Math.abs(Number(get(cashIn, `${date}`, 0))),
      cashOut: Math.abs(Number(get(cashOut, `${date}`, 0))),
    })
  })
  return details
}
