import {
  ACTIVESTEP,
  ADD_EDIT_ASSESSMENT,
  GET_ASSESSMENT_QUESTION,
  GET_ASSESSMENT_RESPONSE_BY_QUESTION,
  GET_USER_ASSESSMENT_RESPONSE,
  HANDLE_GOAL_ACTION,
  SAVE_ASSESSMENT_RESPONSE,
  SET_ACTIVE_QUESTION,
  INITIALIZE_OWNER_STEPPER,
  PREVIOUS_QUESTION,
  GET_USER_ASSESSMENT_RESPONSE_BY_QUESTION,
  OWNER_ONBOARDING_COMPLETED,
  RESET_ASSESSMENT,
  GET_ASSESSMENT_OPTIONS,
} from 'store/types'

export const setActiveStepAction = (title) => ({
  type: ACTIVESTEP,
  payload: title,
})

export const getAssessmentOptionsAction = {
  STARTED: (payload: any) => ({ type: GET_ASSESSMENT_OPTIONS.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_ASSESSMENT_OPTIONS.FULLFILLED, payload }),
  REJECTED: () => ({ type: GET_ASSESSMENT_OPTIONS.REJECTED }),
}

export const getAssessmentQuestionAction = {
  STARTED: (payload: any) => ({ type: GET_ASSESSMENT_QUESTION.STARTED, payload }),
  FULLFILLED: (questions: any) => ({
    type: GET_ASSESSMENT_QUESTION.FULLFILLED,
    payload: questions,
  }),
  REJECTED: () => ({ type: GET_ASSESSMENT_QUESTION.REJECTED }),
}

export const getUserAssessmentResponseAction = {
  STARTED: (payload: any) => ({ type: GET_USER_ASSESSMENT_RESPONSE.STARTED, payload }),
  FULLFILLED: (questions: any) => ({
    type: GET_USER_ASSESSMENT_RESPONSE.FULLFILLED,
    payload: questions,
  }),
  REJECTED: () => ({ type: GET_USER_ASSESSMENT_RESPONSE.REJECTED }),
}

export const addEditAssessmentAction = {
  STARTED: (payload: any) => ({ type: ADD_EDIT_ASSESSMENT.STARTED, payload }),
  FULLFILLED: (questions: any) => ({
    type: ADD_EDIT_ASSESSMENT.FULLFILLED,
    payload: questions,
  }),
  REJECTED: () => ({ type: ADD_EDIT_ASSESSMENT.REJECTED }),
}

export const resetAssessment = () => ({
  type: RESET_ASSESSMENT,
})

export const saveAssessmentResponseAction = {
  STARTED: (payload: any) => ({ type: SAVE_ASSESSMENT_RESPONSE.STARTED, payload }),
  FULLFILLED: (questions: any) => ({
    type: SAVE_ASSESSMENT_RESPONSE.FULLFILLED,
    payload: questions,
  }),
  REJECTED: (payload: { message: string }) => ({
    type: SAVE_ASSESSMENT_RESPONSE.REJECTED,
    payload,
  }),
  RESET: () => ({ type: SAVE_ASSESSMENT_RESPONSE.RESET }),
}

export const setActiveQuestion = (activeQuestionId) => ({
  type: SET_ACTIVE_QUESTION,
  payload: activeQuestionId,
})

export const initializeOwnerStepperAction = (payload) => ({
  type: INITIALIZE_OWNER_STEPPER,
  payload,
})

export const handleUserGoal = (type) => ({
  type: HANDLE_GOAL_ACTION,
  payload: { type },
})

export const getAssessmentResponseByQuestionAction = {
  STARTED: (payload: any) => ({ type: GET_ASSESSMENT_RESPONSE_BY_QUESTION.STARTED, payload }),
  FULLFILLED: (data: any) => ({
    type: GET_ASSESSMENT_RESPONSE_BY_QUESTION.FULLFILLED,
    payload: data,
  }),
  REJECTED: () => ({ type: GET_ASSESSMENT_RESPONSE_BY_QUESTION.REJECTED }),
}

export const getUsersAssessmentResponseByQuestionAction = {
  STARTED: (payload: any) => ({ type: GET_USER_ASSESSMENT_RESPONSE_BY_QUESTION.STARTED, payload }),
  FULLFILLED: (data: any) => ({
    type: GET_USER_ASSESSMENT_RESPONSE_BY_QUESTION.FULLFILLED,
    payload: data,
  }),
  REJECTED: () => ({ type: GET_USER_ASSESSMENT_RESPONSE_BY_QUESTION.REJECTED }),
}

export const previousQuestionAction = (payload) => ({
  type: PREVIOUS_QUESTION,
  payload,
})

export const ownerOnBoardingCompletionAction = (payload) => ({
  type: OWNER_ONBOARDING_COMPLETED,
  payload,
})
