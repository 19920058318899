import React, { FC } from 'react'

export interface ICrossIconProps {
  className?: string
  id?: string
  onClick?: (...args: any[]) => any
}
const CrossIcon: FC<ICrossIconProps> = ({ className = '', id = '', onClick }) => {
  return (
    <svg
      id={`${id}-svg`}
      className={className}
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      onClick={onClick}
    >
      <path d="M313 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L41 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L7 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L279 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L313 137z" />
    </svg>
  )
}
export default CrossIcon
