import { SetMonthlyAttestationFormPayload } from 'store/sagas/monthlyAttestation'
import { RESET_ATTESTATION_URL, SET_MONTHLY_ATTESTATION_FORM, SAVE_ATTESTATION } from 'store/types'

export const setMonthlyAttestationFormAction = {
  STARTED: (payload: SetMonthlyAttestationFormPayload) => ({
    type: SET_MONTHLY_ATTESTATION_FORM.STARTED,
    payload,
  }),
  FULLFILLED: (payload) => ({ type: SET_MONTHLY_ATTESTATION_FORM.FULLFILLED, payload }),
  REJECTED: () => ({ type: SET_MONTHLY_ATTESTATION_FORM.REJECTED }),
}
export const saveAttestationAction = {
  STARTED: (payload) => ({ type: SAVE_ATTESTATION.STARTED, payload }),
  FULLFILLED: (payload) => ({ type: SAVE_ATTESTATION.FULLFILLED, payload }),
  REJECTED: () => ({ type: SAVE_ATTESTATION.REJECTED }),
}
export const resetAttestationUrlAction = () => ({
  type: RESET_ATTESTATION_URL,
})
