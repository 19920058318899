import React, { FC } from 'react'

interface IInfoTipIconProps {
  className?: string
}
const InfoTipIcon: FC<IInfoTipIconProps> = ({ className }) => {
  return (
    <svg
      className={`shrink-0 ${className ?? ''}`}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M0 10C0 4.47715 4.47715 0 10 0H23C28.5228 0 33 4.47715 33 10V23C33 28.5228 28.5228 33 23 33H10C4.47715 33 0 28.5228 0 23V10Z"
        fill="#3B82F6"
      />
      <circle cx="16.5" cy="16.5" r="9.5" fill="#3B82F6" />
      <path
        d="M16.5016 10.65C14.3477 10.65 12.6016 12.3961 12.6016 14.55C12.6016 15.7624 13.1553 16.8458 14.0219 17.5604C14.7059 18.1243 15.2016 18.7378 15.2016 19.3963V19.8152C15.2016 20.0399 15.3551 20.2355 15.5734 20.2888C15.8714 20.3616 16.1823 20.4 16.5016 20.4C16.8208 20.4 17.1318 20.3616 17.4297 20.2888C17.648 20.2355 17.8016 20.0399 17.8016 19.8152V19.3963C17.8016 18.7378 18.2972 18.1243 18.9812 17.5604C19.8478 16.8458 20.4016 15.7624 20.4016 14.55C20.4016 12.3961 18.6555 10.65 16.5016 10.65Z"
        fill="#D4E2F9"
      />
      <path
        d="M15.7624 21.3194C15.4962 21.279 15.2476 21.4619 15.2072 21.7281C15.1667 21.9943 15.3497 22.2429 15.6159 22.2833C15.905 22.3273 16.2008 22.35 16.5016 22.35C16.8024 22.35 17.0983 22.3273 17.3874 22.2833C17.6536 22.2429 17.8366 21.9943 17.7961 21.7281C17.7556 21.4619 17.5071 21.279 17.2409 21.3194C17.0001 21.356 16.7532 21.375 16.5016 21.375C16.25 21.375 16.0032 21.356 15.7624 21.3194Z"
        fill="#D4E2F9"
      />
    </svg>
  )
}

export default InfoTipIcon
