import React from 'react'
import GrowingContainer from 'components/Common/GrowingContainer'
import CashBalanceByMonthChart from 'components/Owner/Data/Overview/CashBalanceByMonth/CashBalanceByMonthChart'
import CashBalanceChart from 'components/Owner/Data/Overview/CashBalance/CashBalanceChart'

const AccountingOverview = () => {
  return (
    <GrowingContainer>
      <div className="grid col-span-12 p-7">
        <div className="grid grid-cols-10 gap-4">
          <div className="col-span-10 smd:col-span-4 bg-white">
            <CashBalanceChart />
          </div>
          <div className="col-span-10 smd:col-span-6 bg-white">
            <CashBalanceByMonthChart />
          </div>
        </div>
      </div>
    </GrowingContainer>
  )
}

export default AccountingOverview
