import styled from 'styled-components'
import tw from 'twin.macro'

export const EditorWrapper = styled.div`
  [title^='Underline'] {
    display: none;
  }
  [title^='Strikethrough'] {
    display: none;
  }
  [title^='Monospace'] {
    display: none;
  }
  [title^='Superscript'] {
    display: none;
  }
  [title^='Subscript'] {
    display: none;
  }
  [title^='Block Type'] {
    display: none;
  }
  [title^='Font'] {
    display: none;
  }
  [title^='Indent'] {
    display: none;
  }
  [title^='Outdent'] {
    display: none;
  }
  [title^='Undo'] {
    display: none;
  }
  .rdw-text-align-wrapper {
    display: none;
  }
  .rdw-block-wrapper {
    display: none;
  }
  .rdw-fontsize-wrapper {
    display: none;
  }
  .rdw-fontfamily-wrapper {
    display: none;
  }
  .rdw-colorpicker-wrapper {
    display: none;
  }
  .rdw-option-disabled {
    display: none;
  }
  .rdw-remove-wrapper {
    display: none;
  }
  .rdw-image-wrapper {
    display: none;
  }
  .rdw-emoji-wrapper {
    display: none;
  }
  .rdw-embedded-wrapper {
    display: none;
  }
  .rdw-link-wrapper {
    display: none;
  }
  .rdw-editor-toolbar {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #d4d4d8;
    background-color: #fafafa;
    margin: 0px;
  }
  .rdw-editor-wrapper {
    border-radius: 4px;
    border: 1px solid #d4d4d8;
    background-color: #fafafa;
    margin: 0.75rem auto;
    height: 230px;
  }
  .public-DraftStyleDefault-ol,
  .public-DraftStyleDefault-ul {
    margin: 0.25rem 0;
  }
  .rdw-option-wrapper {
    border: none;
    background-color: #fafafa;
  }
  .demo-wrapper {
    border: 1px solid #d4d4d8;
    border-radius: 4px;
    overflow: hidden;
  }

  .demo-wrapper-readonly {
    border: none;
    background-color: white;
    margin: -2rem 0rem 1rem -1rem !important;
    height: fit-content !important;
  }

  .demo-editor-readonly {
    border: none;
    background-color: white;
    min-height: fit-content !important;
    color: #18181b;
  }

  .demo-toolbar-readonly {
    visibility: hidden !important;
  }

  .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
    color: rgb(176, 176, 176);
  }
  .rdw-editor-main {
    max-height: 180px;
    padding: 1rem 0px;
    min-height: 180px;
    overflow-y: auto;
  }
  .public-DraftStyleDefault-block {
    margin: 0 16px !important;
    font-size: 14px;
    line-height: 17px;
  }

  .public-DraftStyleDefault-block-readonly {
    margin: 0 0 !important;
    font-size: 14px;
    line-height: 17px;
  }

  .public-DraftStyleDefault-block span {
    color: black;
  }
  .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
    color: black;
    margin-left: 2rem;
  }
  .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0
    .public-DraftStyleDefault-block {
    margin: 0 !important;
  }
  .rdw-link-modal-target-option {
    display: none;
  }
  .rdw-link-modal {
    top: 0px;
    height: 175px;
    left: 30px;
  }
  .rdw-option-active {
    background: #ebebeb !important;
    box-shadow: none;
  }
  .rdw-link-decorator-wrapper {
    text-decoration: underline;
    color: blue;
  }
  .disableToolBoar {
    .rdw-inline-wrapper,
    .rdw-option-wrapper,
    .rdw-editor-toolbar,
    .rdw-list-wrapper,
    .rdw-editor-main,
    .rdw-link-wrapper {
      background-color: #ebebeb;
      cursor: default !important;
      pointer-events: none !important;
    }
  }
  .enableToolBar {
    .rdw-option-wrapper:hover {
      background-color: #eaeaea;
      box-shadow: none;
    }
  }
`

export const MeetingDetailsHeader = styled.main.attrs({
  className: ' p-3 px-7 pb-2 	',
})`
  & {
    .tools-guide {
      ${tw`flex justify-center flex-col xs:gap-5 md:gap-10`}
    }
    .tools-guide-description {
      ${tw`xs:text-base md:text-lg text-primary-text xs:leading-5 sm:leading-7 font-semibold text-center font-primary `}
    }
    .tools-guide-sub-description {
      ${tw`xs:text-base sm:text-xl text-primary font-bold xs:leading-5 sm:leading-7 font-primary text-center`}
    }
    .tools-guide-btn {
      ${tw`py-3 px-5 bg-secondary w-auto m-auto`}
    }
  }
`

export const MeetingDetailsHeaderDesktopStyle = styled.main.attrs({
  className: 'gap-10 justify-between xs:hidden sm:flex items-center pb-7	',
})`
  & {
    .meeting-details-header-desktop {
      ${tw`flex flex-col gap-2`}
    }
    .meeting-details-header-desktop-title {
      ${tw`font-primary font-semibold text-lg text-primary-text`}
    }
    .meeting-details-header-desktop-label {
      ${tw`font-primary text-lg text-black-light`}
    }
    .meeting-details-header-desktop-block {
      ${tw`flex`}
    }
    .meeting-details-header-desktop-btn {
      ${tw`xs:px-5 xs:py-3 sm:p-3 bg-secondary`}
    }
  }
`
export const MeetingDetailsHeaderMobileStyle = styled.main.attrs({
  className: 'xs:flex flex-col gap-6 sm:hidden	',
})`
  & {
    .meeting-details-header-mobile {
      ${tw`flex gap-10`}
    }
    .meeting-details-header-mobile-block {
      ${tw`flex flex-col gap-2 `}
    }
    .meeting-details-header-mobile-title {
      ${tw`font-primary font-semibold text-lg text-primary-text`}
    }
    .meeting-details-header-mobile-label {
      ${tw`font-primary text-lg text-black-light`}
    }
    .meeting-details-header-mobile-btn {
      ${tw`xs:px-5 xs:mb-6 xs:py-3 sm:p-3 bg-secondary`}
    }
  }
`
export const MeetingDetailsBodyStyle = styled.main.attrs({
  className: 'p-7',
})`
  & {
    .meeting-details-body-block {
      ${tw`flex flex-col gap-3`}
    }
    .meeting-details-body-title-button-block {
      ${tw`flex justify-between items-center `}
    }
    .meeting-details-body-title {
      ${tw`text-primary-text font-primary font-semibold text-lg `}
    }
    .meeting-details-body-label {
      ${tw`font-primary text-lg text-black-light`}
    }
    .meeting-details-body-radio-block {
      ${tw`pt-10 flex flex-col gap-3 pb-6`}
    }
    .meeting-details-body-editor {
      ${tw`py-8`}
    }
  }
`

export const MeetingDetailsButtonStyle = styled.main.attrs({
  className: 'flex flex-row justify-between gap-2',
})`
  & {
    .meeting-details-button-cancel {
      ${tw`px-3`}
    }
    .meeting-details-button-primary {
      ${tw`px-4 py-3`}
    }
    .meeting-details-button-secondary {
      ${tw`px-4 py-3 outline-none border-0 `}
    }
    .meeting-details-button-edit {
      ${tw`flex flex-row gap-2`}
    }
  }
`
