import React, { useState } from 'react'

import { isNil } from 'lodash'
import Toast from 'components/Common/Toast'
import { useTenantId } from 'utils/useTenantId'

import {
  IUSER_NOTIFICATION_STATUS,
  IUSER_NOTIFICATION_TYPES,
} from 'components/NotificationPanel/types'

import useNotificationList from './NotificationList.hooks'

const NotificationList = () => {
  const tenantId = useTenantId()
  const [closeToast, setCloseToast] = useState(false)
  const { notificationList, updateUserNotications } = useNotificationList()

  return (
    <div className="absolute w-full ">
      {notificationList.map((notification, index) => {
        const meta = JSON.parse(notification.meta)

        return (
          <div className="inline-block" key={index}>
            {notification.notificationType ===
              IUSER_NOTIFICATION_TYPES.MONTHLY_ATTESTATION_UPDATE &&
              !closeToast &&
              notification.status === IUSER_NOTIFICATION_STATUS.UNREAD && (
                <Toast
                  variant="info"
                  showCloseButton={true}
                  onClose={() => {
                    const payload = {
                      id: notification?.id,
                      status: 'read',
                      updateAll: isNil(notification.id),
                      tenantId,
                    }
                    updateUserNotications({ input: payload })
                    setCloseToast(true)
                  }}
                >
                  {meta.text}
                </Toast>
              )}
          </div>
        )
      })}
    </div>
  )
}

export default NotificationList
