import React, { Fragment, useEffect, useState } from 'react'
import Button from 'components/Common/Button'
import { CommentsContainer, MeetingEventsContainer } from 'pages/Clients/container'
import InfiniteScroll from 'react-infinite-scroll-component'
import { EventDetailNoMeeting, EventDetailStyle } from 'components/Clients/Client/Meeting/style'

const EventDetail = ({ meeting, showCommentsAction, clientComments, ownerIds, filter }) => {
  const [meetingEvents, setMeetingEvent] = useState<any>([])

  useEffect(() => {
    setMeetingEvent(meeting?.slice(0, 5))
  }, [meeting])

  const getMoreData = (pageSize) => {
    setMeetingEvent([
      ...meetingEvents,
      ...meeting?.slice(meetingEvents.length, meetingEvents.length + pageSize),
    ])
  }
  return (
    <>
      {!meetingEvents.length ? (
        <EventDetailNoMeeting>
          <span className="event-detail-no-meeting-text">No {filter} meetings</span>
        </EventDetailNoMeeting>
      ) : (
        <InfiniteScroll
          dataLength={meetingEvents.length}
          next={() => getMoreData(5)}
          hasMore={meeting.length !== meetingEvents.length ? true : false}
          loader={'loading'}
        >
          {meetingEvents.map((data, index) => {
            const updatedComment = clientComments.find((comment) => comment.id === data.id)
            return (
              <Fragment key={index}>
                <EventDetailStyle>
                  <div className="event-detail-box">
                    <div className=" event-detail-date">{data?.eventDate}</div>
                    <div className=" event-detail-time">{data?.time}</div>
                    <div className="event-detail-title">{data?.title}</div>
                    <div className="event-detail-ipad-view">
                      {/* check-button -- clean up custom styles */}
                      <Button
                        className="event-detail-btn w-full"
                        label={`${
                          updatedComment?.comment !== '' && updatedComment?.comment !== ' '
                            ? 'View'
                            : 'Add'
                        }`}
                        variant="secondary"
                        onClick={() => {
                          showCommentsAction(data.id)
                        }}
                      />
                    </div>
                    <div className="event-detail-mobile-view">
                      <Button
                        label={`${ownerIds?.includes(data?.id) ? 'Hide' : 'Add Comment'}`}
                        variant="secondary"
                        onClick={() => {
                          showCommentsAction(data.id)
                        }}
                        className="w-full"
                      />
                    </div>
                    {filter.trim() !== 'previous' && <MeetingEventsContainer data={data} />}
                  </div>
                  <CommentsContainer row={data} />
                </EventDetailStyle>
              </Fragment>
            )
          })}
        </InfiniteScroll>
      )}
    </>
  )
}

export default EventDetail
