import React from 'react'

function DashboardIcon({ className }) {
  return (
    <svg
      className={className}
      width="70px"
      height="50px"
      viewBox="-130 0 730 500"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 168C0 145.9 17.91 128 40 128H88C110.1 128 128 145.9 128 168V216C128 238.1 110.1 256 88 256H40C17.91 256 0 238.1 0 216V168zM32 168V216C32 220.4 35.58 224 40 224H88C92.42 224 96 220.4 96 216V168C96 163.6 92.42 160 88 160H40C35.58 160 32 163.6 32 168zM0 328C0 305.9 17.91 288 40 288H88C110.1 288 128 305.9 128 328V376C128 398.1 110.1 416 88 416H40C17.91 416 0 398.1 0 376V328zM32 328V376C32 380.4 35.58 384 40 384H88C92.42 384 96 380.4 96 376V328C96 323.6 92.42 320 88 320H40C35.58 320 32 323.6 32 328zM248 128C270.1 128 288 145.9 288 168V216C288 238.1 270.1 256 248 256H200C177.9 256 160 238.1 160 216V168C160 145.9 177.9 128 200 128H248zM248 160H200C195.6 160 192 163.6 192 168V216C192 220.4 195.6 224 200 224H248C252.4 224 256 220.4 256 216V168C256 163.6 252.4 160 248 160zM160 328C160 305.9 177.9 288 200 288H248C270.1 288 288 305.9 288 328V376C288 398.1 270.1 416 248 416H200C177.9 416 160 398.1 160 376V328zM192 328V376C192 380.4 195.6 384 200 384H248C252.4 384 256 380.4 256 376V328C256 323.6 252.4 320 248 320H200C195.6 320 192 323.6 192 328zM408 128C430.1 128 448 145.9 448 168V216C448 238.1 430.1 256 408 256H360C337.9 256 320 238.1 320 216V168C320 145.9 337.9 128 360 128H408zM408 160H360C355.6 160 352 163.6 352 168V216C352 220.4 355.6 224 360 224H408C412.4 224 416 220.4 416 216V168C416 163.6 412.4 160 408 160zM320 328C320 305.9 337.9 288 360 288H408C430.1 288 448 305.9 448 328V376C448 398.1 430.1 416 408 416H360C337.9 416 320 398.1 320 376V328zM352 328V376C352 380.4 355.6 384 360 384H408C412.4 384 416 380.4 416 376V328C416 323.6 412.4 320 408 320H360C355.6 320 352 323.6 352 328z" />
    </svg>
  )
}
export default DashboardIcon
