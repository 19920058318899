import {
  OPEN_USER_MANAGEMENT_EDIT_MODAL,
  GET_ADVISORS,
  ADD_USER_TO_GROUP,
  ENABLE_DISABLE_USER,
  UPDATE_OWNER_PROFILE,
  UPDATE_ADVISOR_PROFILE,
  GET_ADVISORS_ACCOUNT_DETAILS,
  GET_BUSINESSES,
  GET_ADVISORS_ACCOUNT_DETAILS_UM,
  GET_BUSINESSES_UM,
  CHECK_USER_PROFILE,
  CHECK_BUSINESS_PROFILE,
  RESET_ERROR,
  SET_CURRENT_PAGE,
  SET_PER_PAGE,
  SET_TOTAL_PAGES,
  SET_FILTERS,
  SET_SORT_VALUE,
} from 'store/types'

const initialState = {
  businesses: { list: [], lastEvaluatedKey: '' },
  advisorsAccountDetails: { list: [], lastEvaluatedKey: '' },
  businessesUM: { list: [], lastEvaluatedKey: '' },
  advisorsAccountDetailsUM: { list: [], lastEvaluatedKey: '' },
  advisors: [], // this is likely set to an object with list & lastEvaluatedKey properties..
  disableButton: false,
  disableUserButton: false,
  openUserManagementEditModal: true,
  isUserProfileFormSet: false,
  isBusinessProfileFormSet: false,
  errorMessage: '',
  error: null,
  mobileContactError: null,
  isUserAddedToGroup: false,
  isDisableUserInProgress: false,
  currentPage: 1,
  noOfItemsPerPage: 5,
  totalPages: 0,
  filters: [],
  sortValue: '',
  businessesError: '',
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_BUSINESSES.STARTED:
      return {
        ...state,
        businessesError: initialState.businessesError,
        businesses: [],
        error: null,
        mobileContactError: null,
      }
    case GET_BUSINESSES.FULLFILLED:
      return {
        ...state,
        businesses: action.payload,
      }
    case GET_BUSINESSES.REJECTED:
      return {
        ...state,
        businessesError: action.payload,
      }
    case GET_BUSINESSES.RESET:
      return {
        ...state,
        businessesError: initialState.businessesError,
      }
    case GET_BUSINESSES_UM.STARTED:
      return {
        ...state,
        businessesUM: [],
        error: null,
        mobileContactError: null,
      }
    case GET_BUSINESSES_UM.FULLFILLED:
      return {
        ...state,
        businessesUM: action.payload,
      }
    case GET_ADVISORS_ACCOUNT_DETAILS.STARTED:
      return {
        ...state,
        advisorsAccountDetails: [],
      }
    case GET_ADVISORS_ACCOUNT_DETAILS.FULLFILLED:
      return {
        ...state,
        advisorsAccountDetails: action.payload,
      }
    case GET_ADVISORS_ACCOUNT_DETAILS_UM.STARTED:
      return {
        ...state,
        advisorsAccountDetailsUM: [],
      }
    case GET_ADVISORS_ACCOUNT_DETAILS_UM.FULLFILLED:
      return {
        ...state,
        advisorsAccountDetailsUM: action.payload,
      }
    case ADD_USER_TO_GROUP.FULLFILLED:
      return {
        ...state,
        isUserAddedToGroup: true,
      }
    case ADD_USER_TO_GROUP.STARTED:
    case ADD_USER_TO_GROUP.RESET:
      return {
        ...state,
        isUserAddedToGroup: false,
      }
    case GET_ADVISORS.FULLFILLED:
      return {
        ...state,
        advisors: action.payload,
      }
    case UPDATE_OWNER_PROFILE.STARTED:
      return {
        ...state,
        disableButton: true,
        error: null,
        mobileContactError: null,
      }
    case UPDATE_OWNER_PROFILE.FULLFILLED:
      return {
        ...state,
        disableButton: false,
        openUserManagementEditModal: true,
      }
    case UPDATE_OWNER_PROFILE.REJECTED:
      return {
        ...state,
        disableButton: false,
        openUserManagementEditModal: true,
        ...action.payload,
      }
    case ENABLE_DISABLE_USER.STARTED:
      return {
        ...state,
        disableUserButton: true,
        isDisableUserInProgress: true,
      }
    case ENABLE_DISABLE_USER.FULLFILLED:
      return {
        ...state,
        disableUserButton: false,
        isDisableUserInProgress: false,
      }
    case ENABLE_DISABLE_USER.REJECTED:
      return {
        ...state,
        disableUserButton: false,
        isDisableUserInProgress: false,
      }
    case UPDATE_ADVISOR_PROFILE.STARTED:
      return {
        ...state,
        disableButton: true,
        error: null,
      }
    case UPDATE_ADVISOR_PROFILE.FULLFILLED:
      return {
        ...state,
        disableButton: false,
        openUserManagementEditModal: true,
        error: null,
      }
    case UPDATE_ADVISOR_PROFILE.REJECTED:
      return {
        ...state,
        disableButton: false,
        openUserManagementEditModal: true,
        ...action.payload,
      }

    case OPEN_USER_MANAGEMENT_EDIT_MODAL:
      return {
        ...state,
        openUserManagementEditModal: action.payload,
      }
    case CHECK_USER_PROFILE:
      return {
        ...state,
        isUserProfileFormSet: action.payload,
      }
    case CHECK_BUSINESS_PROFILE:
      return {
        ...state,
        isBusinessProfileFormSet: action.payload,
      }
    case RESET_ERROR:
      return {
        ...state,
        error: null,
        mobileContactError: null,
      }
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      }
    case SET_PER_PAGE:
      return {
        ...state,
        noOfItemsPerPage: action.payload,
      }
    case SET_TOTAL_PAGES:
      return {
        ...state,
        totalPages: action.payload,
      }
    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      }
    case SET_SORT_VALUE:
      return {
        ...state,
        sortValue: action.payload,
      }
    default:
  }
  return state
}

export default reducer
